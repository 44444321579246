import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadAssessmentData,
  loadAssessmentDataSuccess,
  loadAssessmentDataError,
  getIsScopingComplete,
  getIsScopingCompleteSuccess,
  setScheduleDate,
  setAssessmentStage,
  finalizeAssessment,
  finalizeAssessmentSuccess,
  finalizeAssessmentError,
  changeStage,
  changeStageSuccess,
  changeStageError,
  setAssessmentFieldWorkEndDate,
} from "./slice";
import { toggleThirdPartyAccessSuccess } from "../ProductDownload/services/slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { SCHEDULE_DATE_LABELS, SCHEDULE_DATE_KEYS } from "app/constants";

export function* loadAssessmentDataSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = yield response.json();
    if (response.status >= 400) {
      const message = data.errors ? data.errors.id[0] : data.Title;
      throw new Error(message);
    }
    yield put(
      toggleThirdPartyAccessSuccess(data.productsThirdPartyAccessEnabled)
    );
    yield put(loadAssessmentDataSuccess(data));
  } catch ({ message }) {
    yield put(loadAssessmentDataError(message));
  }
}

export function* getIsScopingCompletedSaga({ payload }) {
  try {
    const { scopingDocumentId, accessToken, assessmentId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/scoping/${scopingDocumentId}/completed`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const isScopingCompleted = yield response.json();
    yield put(getIsScopingCompleteSuccess(isScopingCompleted));
  } catch ({ message }) {
    yield message;
  }
}

export function* setScheduleDateSaga({ payload }) {
  try {
    const { value, key, accessToken, assessmentId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/scheduleDate`,
      {
        method: "PUT",
        body: JSON.stringify({ fieldValue: value, fieldName: key }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(
        `Failed to save '${SCHEDULE_DATE_LABELS[key]}' please try again.`
      );
    }
  } catch ({ message }) {
    yield showError(message);
  }
}

export function* submitChangeStageSaga({ payload }) {
  try {
    const { accessToken, assessmentId, assessmentStageToChange } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/changeStage`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ assessmentStageToChange }),
      }
    );
    const data = yield response.json();
    if (response.status !== 200) {
      throw new Error(data?.Title);
    } else {
      if (
        SCHEDULE_DATE_KEYS.fieldWorkEndDate.toLowerCase() ===
          data.dateFieldName &&
        data.dateFieldValue
      ) {
        yield put(
          setAssessmentFieldWorkEndDate({
            fieldWorkEndDate: data.dateFieldValue,
          })
        );
      }
      yield put(changeStageSuccess());
      yield put(setAssessmentStage({ stageId: assessmentStageToChange }));
    }
  } catch ({ message }) {
    yield showError(message);
    yield put(changeStageError());
  }
}

export function* finalizeAssessmentSaga({ payload }) {
  try {
    const { accessToken, assessmentId } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL + `/assessments/${assessmentId}/finalize`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      const data = yield response.json().catch(() => null);
      throw new Error(data?.Title);
    } else {
      yield put(finalizeAssessmentSuccess());
    }
  } catch ({ message }) {
    yield showError(message);
    yield put(finalizeAssessmentError());
  }
}

export default function* assessmentSagas() {
  yield all([
    takeLatest(loadAssessmentData.type, loadAssessmentDataSaga),
    takeLatest(getIsScopingComplete.type, getIsScopingCompletedSaga),
    takeLatest(setScheduleDate.type, setScheduleDateSaga),
    takeLatest(changeStage.type, submitChangeStageSaga),
    takeLatest(finalizeAssessment.type, finalizeAssessmentSaga),
  ]);
}
