import { call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  requestTablesLoading,
  requestTablesSuccess,
  requestTablesError,
  getPlannedAssessments,
} from "./slice";

export function* loadPlannedAssessmentsSaga({ payload }) {
  yield put(requestTablesLoading());
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/RequestAssessments/plannedAssessments",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(requestTablesSuccess(data));
  } catch ({ message }) {
    yield put(requestTablesError(message));
  }
}

export default function* requestAssessmentsSagas() {
  yield takeLatest(getPlannedAssessments.type, loadPlannedAssessmentsSaga);
}
