import {
  all,
  call,
  put,
  takeLatest,
  take,
  fork,
  cancel,
  delay,
} from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadApiDocumentation,
  setApiDocumentationSuccess,
  setApiDocumentationError,
  autoRefreshApiDocumentationKey,
  cancelAutoRefreshApiDocumentationKey,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";

export function* loadApiDocumentationSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + `/apikeys/documentationkey`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const documentationKey = yield response.json();
    yield put(setApiDocumentationSuccess(documentationKey));
  } catch ({ message }) {
    yield put(setApiDocumentationError(message));
    yield showError(message);
  }
}

export function* refreshApiDocumentationKeySaga({ payload }) {
  // delay 1min earlier than key expires
  const delayInMs = (payload.documentationKeyDuration - 1) * 60000;

  try {
    while (true) {
      yield delay(delayInMs);
      const response = yield call(
        fetch,
        process.env.REACT_APP_API_URL +
          `/apikeys/documentationkey/` +
          payload.documentationKey,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + payload.accessToken,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to refresh");
      }
    }
  } catch ({ message }) {
    yield put(setApiDocumentationError(message));
    yield showError(message);
  }
}

export function* autoRefreshApiDocumentationKeySaga({ payload }) {
  const autoRefreshTask = yield fork(refreshApiDocumentationKeySaga, {
    payload,
  });
  yield take(cancelAutoRefreshApiDocumentationKey);
  yield cancel(autoRefreshTask);
}

export default function* apiDocumentationSagas() {
  yield all([
    takeLatest(loadApiDocumentation.type, loadApiDocumentationSaga),
    takeLatest(
      autoRefreshApiDocumentationKey.type,
      autoRefreshApiDocumentationKeySaga
    ),
  ]);
}
