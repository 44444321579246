import { put, takeLatest, call, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadThirdPartyDetails,
  loadThirdPartyDetailsError,
  loadThirdPartyDetailsSuccess,
  updateThirdPartyDetails,
  updateThirdPartyDetailsSuccess,
  updateThirdPartyDetailsError,
} from "./slice";
import { setIsDirty } from "services/form/slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";

export function* loadThirdPartyDetailsSaga({ payload }) {
  const { accessToken, id } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/my-third-parties/${id}/book-of-work`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();

    yield put(loadThirdPartyDetailsSuccess(data));
  } catch ({ message }) {
    yield put(loadThirdPartyDetailsError(message));
  }
}

export function* submitThirdPartyDetailsUpdateSaga({ payload }) {
  const {
    accessToken,
    thirdPartyChanges,
    thirdPartyChanges: { customerThirdPartyId },
    updateSuccessMessage,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customerThirdParties/${customerThirdPartyId}`,
      {
        method: "PUT",
        body: JSON.stringify(thirdPartyChanges),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to save");
    }

    yield put(setIsDirty({ value: false }));
    yield put(updateThirdPartyDetailsSuccess());
    yield showInfo(updateSuccessMessage);
  } catch ({ message }) {
    yield put(updateThirdPartyDetailsError(message));
    yield showError();
  }
}

export default function* thirdPartyDetailsProviderSagas() {
  yield all([
    takeLatest(loadThirdPartyDetails.type, loadThirdPartyDetailsSaga),
    takeLatest(updateThirdPartyDetails.type, submitThirdPartyDetailsUpdateSaga),
  ]);
}
