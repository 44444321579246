import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import {
  setTpResponse,
  setTpResponseSuccess,
  setTpResponseError,
} from "./slice";
import { updateTpResponseToQuestion } from "pages/AssessmentPage/BPQ/services/slice";
import { setIsDirty } from "services/form/slice";

function* setAnswerToTpResponseDrawerSaga({ payload }) {
  try {
    const { assessmentId, thirdPartyResponse, accessToken } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/third-party-response/`,
      {
        method: "PUT",
        body: JSON.stringify(thirdPartyResponse),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const savedThirdPartyResponse = yield response.json();
    yield put(setIsDirty({ value: false, key: null }));
    yield put(
      updateTpResponseToQuestion({
        questionId: thirdPartyResponse[0].questionId,
        responseObservation: savedThirdPartyResponse,
      })
    );
    yield put(setTpResponseSuccess());
  } catch ({ message }) {
    yield put(setTpResponseError());
    yield showError();
  }
}

export default function* thirdPartyResponseDrawerSaga() {
  yield all([
    takeLatest([setTpResponse.type], setAnswerToTpResponseDrawerSaga),
  ]);
}
