import {
  put,
  take,
  takeLatest,
  delay,
  fork,
  cancel,
  cancelled,
  call,
  all,
  takeEvery,
} from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { PRODUCT_STATUS_CHECK_INTERVAL } from "app/constants";
import {
  getUserInitiatedDownloadIds,
  saveUserInitiatedDownloadIds,
} from "../utils/localStorage";
import {
  updateDownloadFileName,
  downloadFile,
} from "utils/handleDownload/handleDownload";
import {
  loadCatalogData,
  loadCatalogDataSuccess,
  loadCatalogDataError,
  downloadAssessment,
  assessmentDownloaded,
} from "./slice";
import {
  removeInProgressId,
  cancelLoadPurchasedAssessments,
} from "pages/PurchasedPage/services/slice";

export function* loadCatalogDataSaga({ payload }) {
  try {
    const response = yield fetch(process.env.REACT_APP_API_URL + "/Catalog", {
      headers: {
        Authorization: `Bearer ${payload.accessToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadCatalogDataSuccess(data));
  } catch ({ message }) {
    yield put(loadCatalogDataError(message));
  }
}

export function* downloadAssessmentSagaCancellable({ payload }) {
  const abortController = new AbortController();

  try {
    const { assessmentId, productId, accessToken, isReload } = payload;
    if (isReload) {
      yield delay(PRODUCT_STATUS_CHECK_INTERVAL);
    }

    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        "/thirdpartyassessments/download/" +
        `${assessmentId}/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal: abortController.signal,
      }
    );

    const isFile =
      response.headers.map["content-disposition"]?.includes("attachment");

    if (response.ok && isFile) {
      downloadFile(response, updateDownloadFileName(response));
      yield put(assessmentDownloaded({ productId }));
      yield call(removeLocalStorageDownloadIdSaga, {
        payload: { productId },
      });
    }

    if (!isFile) {
      const data = yield response.json();
      if (data.ErrorCode) {
        // reset icon on error
        yield put(assessmentDownloaded({ productId }));
        yield call(removeLocalStorageDownloadIdSaga, {
          payload: { productId },
        });
      }
      if (data.inProgress) {
        yield call(downloadAssessmentSagaCancellable, {
          payload: { ...payload, isReload: true },
        });
      }
    }
  } catch ({ message }) {
    yield put(assessmentDownloaded({ productId: payload.productId }));
    yield call(removeLocalStorageDownloadIdSaga, {
      payload: { productId: payload.productId },
    });
  } finally {
    if (yield cancelled()) {
      // remove productId from store, but not from localStorage
      yield put(assessmentDownloaded({ productId: payload.productId }));
      abortController.abort();
    }
  }
}

export function* downloadAssessmentSaga({ payload }) {
  const downloadTask = yield fork(downloadAssessmentSagaCancellable, {
    payload,
  });
  yield take(cancelLoadPurchasedAssessments);
  yield cancel(downloadTask);
}

export function* addLocalStorageDownloadIdSaga({ payload: { productId } }) {
  const userInitiatedDownloadIds = getUserInitiatedDownloadIds();

  if (!userInitiatedDownloadIds.includes(productId)) {
    const updatedDownloadIds = [productId, ...userInitiatedDownloadIds];
    yield saveUserInitiatedDownloadIds(updatedDownloadIds);
  }
}

export function* removeLocalStorageDownloadIdSaga({ payload: { productId } }) {
  const userInitiatedDownloadIds = getUserInitiatedDownloadIds();

  if (userInitiatedDownloadIds.includes(productId)) {
    const updatedDownloadIds = userInitiatedDownloadIds.filter(
      (id) => id !== productId
    );
    yield saveUserInitiatedDownloadIds(updatedDownloadIds);
  }
}

// additionally cleanup inProgressIds in PurchasedPage store
export function* removeInProgressIdSaga({ payload: { productId } }) {
  yield put(removeInProgressId({ productId }));
}

export default function* catalogSagas() {
  yield all([
    takeLatest(loadCatalogData.type, loadCatalogDataSaga),
    takeEvery(downloadAssessment.type, downloadAssessmentSaga),
    takeEvery(downloadAssessment.type, addLocalStorageDownloadIdSaga),
    takeEvery(assessmentDownloaded.type, removeInProgressIdSaga),
  ]);
}
