import { createSlice } from "@reduxjs/toolkit";

const NAMESPACE = "manageCustomerPage";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    loadCustomer: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadCustomerSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadCustomerError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setLastNotifiedAt: (state, { payload }) => {
      if (!state.data) {
        state.data = {
          lastNotifiedAt: payload,
        };
      } else {
        state.data.lastNotifiedAt = payload;
      }
    },
  },
});

export const {
  loadCustomer,
  loadCustomerSuccess,
  loadCustomerError,
  setLastNotifiedAt,
} = slice.actions;

export default slice;
