import { flatten, uniqBy } from "lodash";

export const getIntersectingBulkOptions = (selectedAssessments) => {
  const allOptions = flatten(
    selectedAssessments.map((assessment) => assessment.options)
  );
  const uniqOptions = uniqBy(allOptions, "id");

  return uniqOptions.filter(
    (optionToFilterBy) =>
      allOptions.filter((option) => option.id === optionToFilterBy.id)
        .length === selectedAssessments.length
  );
};
