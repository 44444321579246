import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    peerReviewDrawer: {
      isOpen: false,
      isSubmitInProgress: false,
      drawerData: null,
    },
  },
  reducers: {
    setPeerReviewDrawerOpen: (state, { payload }) => {
      state.peerReviewDrawer.isOpen = payload.isOpen;

      if (payload.drawerData) {
        state.peerReviewDrawer.drawerData = payload.drawerData;
      }
    },
    setAnswerToPeerReviewDrawer: (state) => {
      state.peerReviewDrawer.isSubmitInProgress = true;
    },
    setPeerReviewDrawerSubmitInProgress: (state, { payload }) => {
      state.peerReviewDrawer.isSubmitInProgress = payload.isInProgress;
    },
  },
});

export const {
  setPeerReviewDrawerOpen,
  setAnswerToPeerReviewDrawer,
  setPeerReviewDrawerSubmitInProgress,
} = slice.actions;

export default slice;
