import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";

const SelectOptionWithDescription = (props) => {
  const { info, label } = props.data;
  const { Option } = components;

  return (
    <Option {...props}>
      {info ? (
        <div className="item">
          <div className="item__label">{label}</div>
          <div className="item__info">{info}</div>
        </div>
      ) : (
        label
      )}
    </Option>
  );
};

SelectOptionWithDescription.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export default SelectOptionWithDescription;
