import { createSlice } from "@reduxjs/toolkit";

const NAMESPACE = "assessment";

export const initialState = {
  productOverviewDetails: {
    data: null,
    loading: false,
    error: null,
  },
  assessmentDetails: {
    data: {
      statusId: null,
      fieldWorkEndDate: null,
    },
    loading: false,
    error: null,
  },
  isScopingCompleted: null,
  isActionRequiredLoading: false,
  finalizeModal: {
    isOpen: false,
    isFinalizing: false,
  },
  changeStageModal: {
    isOpen: false,
    isSubmitting: false,
  },
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    loadAssessmentData: (state) => {
      state.assessmentDetails.data = null;
      state.assessmentDetails.loading = true;
      state.assessmentDetails.unauthorized = false;
    },
    loadAssessmentDataSuccess: (state, action) => {
      state.assessmentDetails.loading = false;
      state.assessmentDetails.data = action.payload;
      state.assessmentDetails.error = null;
    },
    loadAssessmentDataError: (state, action) => {
      state.assessmentDetails.loading = false;
      state.assessmentDetails.error = action.payload;
    },
    confirmAssessmentTypeSuccess(state, action) {
      state.assessmentDetails.data = {
        ...state.assessmentDetails.data,
        assessmentTypeId: action.payload.assessmentTypeId,
        templateId: action.payload.templateId,
        bpqDocumentId: action.payload.bpqDocumentId,
        scopingDocumentId: action.payload.scopingDocumentId,
        name: action.payload.assessmentName
          ? action.payload.assessmentName
          : state.assessmentDetails.data.name,
      };
      state.assessmentDetails.loading = false;
      state.assessmentDetails.error = null;
    },
    getIsScopingComplete: (state) => {
      state.isActionRequiredLoading = true;
    },
    getIsScopingCompleteSuccess: (state, action) => {
      state.isActionRequiredLoading = false;
      state.isScopingCompleted = action.payload;
    },
    setIsScopingComplete: (state, action) => {
      state.isScopingCompleted = action.payload;
    },
    setAssessmentStage: (state, { payload }) => {
      state.assessmentDetails.data.statusId = payload.stageId;
    },
    setAssessmentFieldWorkEndDate: (state, { payload }) => {
      state.assessmentDetails.data.fieldWorkEndDate = payload.fieldWorkEndDate;
    },
    setScheduleDate: (state, { payload }) => {
      state.assessmentDetails.data[payload.key] = payload.value;
    },
    setFinalizeModalOpen: (state, { payload }) => {
      state.finalizeModal.isOpen = payload;
    },
    finalizeAssessment: (state) => {
      state.finalizeModal.isFinalizing = true;
    },
    finalizeAssessmentSuccess: (state) => {
      state.finalizeModal.isFinalizing = false;
      state.finalizeModal.isOpen = false;
      state.assessmentDetails.data.isFinalized = true;
    },
    finalizeAssessmentError: (state) => {
      state.finalizeModal.isFinalizing = false;
      state.assessmentDetails.data.isFinalized = false;
    },
    changeStage: (state) => {
      state.changeStageModal.isSubmitting = true;
    },
    changeStageSuccess: (state) => {
      state.changeStageModal.isSubmitting = false;
      state.changeStageModal.isOpen = false;
    },
    changeStageError: (state) => {
      state.changeStageModal.isSubmitting = false;
    },
    setChangeStageModalOpen: (state, { payload }) => {
      state.changeStageModal.isOpen = payload;
    },
    resetAssessmentStore: () => initialState,
  },
});

export const {
  loadAssessmentData,
  loadAssessmentDataSuccess,
  loadAssessmentDataError,
  confirmAssessmentTypeSuccess,
  getIsScopingComplete,
  getIsScopingCompleteSuccess,
  setIsScopingComplete,
  setAssessmentStage,
  setScheduleDate,
  setFinalizeModalOpen,
  finalizeAssessment,
  finalizeAssessmentSuccess,
  finalizeAssessmentError,
  changeStage,
  changeStageSuccess,
  changeStageError,
  setChangeStageModalOpen,
  resetAssessmentStore,
  setAssessmentFieldWorkEndDate,
} = slice.actions;

export default slice;
