import { defineMessages } from "react-intl";

const scope = "pages.assessment.overview.";

export default defineMessages({
  pageTitle: {
    id: `${scope}pageTitle`,
    defaultMessage: "Product Summary",
  },
  pageDescription: {
    id: `${scope}pageDescription`,
    defaultMessage: "Review details for the Third Party Description & Control Environment Summary. You may be able to revise these descriptions in their respective locations.",
  },
  organizationNameLabel: {
    id: `${scope}organizationNameLabel`,
    defaultMessage: "Name",
  },
  productTypesLabel: {
    id: `${scope}productTypesLabel`,
    defaultMessage: "Product Types Available",
  },
  assessmentStatusLabel: {
    id: `${scope}productStatusLabel`,
    defaultMessage: "Assessment Status",
  },
  categoryL1Label: {
    id: `${scope}categoryL1Label`,
    defaultMessage: "Service Category L1",
  },
  categoryL2Label: {
    id: `${scope}categoryL2Label`,
    defaultMessage: "Service Category L2",
  },
  thirdPartyDescriptionLabel: {
    id: `${scope}thirdPartyDescriptionLabel`,
    defaultMessage: "Third Party Description",
  },
  assessmentYearLabel: {
    id: `${scope}assessmentYearLabel`,
    defaultMessage: "Assessment Year",
  },
  bpqSubmissionDateLabel: {
    id: `${scope}bpqSubmissionDateLabel`,
    defaultMessage: "BPQ Submission Date",
  },
  fieldworkEndDateLabel: {
    id: `${scope}fieldworkEndDateLabel`,
    defaultMessage: "Fieldwork End Date",
  },
  controlEnvironmentLabel: {
    id: `${scope}controlEnvironmentLabel`,
    defaultMessage: "Control Environment",
  },
  controlEnvironmentSummaryLabel: {
    id: `${scope}controlEnvironmentSummaryLabel`,
    defaultMessage: "Control Environment Summary",
  },
  thirdPartyDescriptionButtonLabel: {
    id: `${scope}thirdPartyDescriptionButtonLabel`,
    defaultMessage: "Go to Third Party: Description",
  },
  scheduleButtonLabel: {
    id: `${scope}scheduleButtonLabel`,
    defaultMessage: "Go to Schedule",
  },
  scopingButtonLabel: {
    id: `${scope}scopingButtonLabel`,
    defaultMessage: "Go to Scoping",
  },
  keyControlsProductType: {
    id: `${scope}keyControlsProductType`,
    defaultMessage: "Key Controls {bpqVersion}",
  },
  standardProductType: {
    id: `${scope}standardProductType`,
    defaultMessage: "Standard {bpqVersion}",
  },
  comprehensiveProductType: {
    id: `${scope}comprehensiveProductType`,
    defaultMessage: "Comprehensive {bpqVersion}",
  },
  enhancedComprehensiveProductType: {
    id: `${scope}enhancedComprehensiveProductType`,
    defaultMessage: "Enhanced Comprehensive {bpqVersion}",
  },
  fmifmuProductType: {
    id: `${scope}fmifmuProductType`,
    defaultMessage: "FMI-FMU {bpqVersion}",
  },
  enhancedComprehensivePlusProductType: {
    id: `${scope}enhancedComprehensivePlusProductType`,
    defaultMessage: "Enhanced Comprehensive Plus {bpqVersion}",
  },
  comprehensivePlusProductType: {
    id: `${scope}comprehensivePlusProductType`,
    defaultMessage: "Comprehensive Plus {bpqVersion}",
  },
  enhancedDesktopProductType: {
    id: `${scope}enhancedDesktopProductType`,
    defaultMessage: "Enhanced Desktop {bpqVersion}",
  },
  desktopProductType: {
    id: `${scope}desktopProductType`,
    defaultMessage: "Desktop {bpqVersion}",
  },
  expressPlusProductType: {
    id: `${scope}expressPlusProductType`,
    defaultMessage: "Express Plus {bpqVersion}",
  },
  customProductType: {
    id: `${scope}customProductType`,
    defaultMessage: "Custom {bpqVersion}",
  },
  ddqOnlyProductType: {
    id: `${scope}ddqOnlyProductType`,
    defaultMessage: "DDQ Only {bpqVersion}",
  },
  verifiedBpqProductType: {
    id: `${scope}verifiedBpqProductType`,
    defaultMessage: "Verified Bpq {bpqVersion}",
  },
  productOptions: {
    id: `${scope}productOptions`,
    defaultMessage: "Product Options",
  },
  allowCustomTemplatesLabel: {
    id: `${scope}allowCustomTemplatesLabel`,
    defaultMessage: "Eligible for Custom Templates",
  },
  allowCustomTemplatesDescription: {
    id: `${scope}allowCustomTemplatesDescription`,
    defaultMessage: "While toggled on, customers will be able to export this assessment using custom templates.",
  },
  saveFailed: {
    id: `${scope}saveFailed`,
    defaultMessage: "Save Failed",
  },
  saveCompleted: {
    id: `${scope}saveCompleted`,
    defaultMessage: "Save Completed",
  },
  notCompatibleWithCustomTemplates: {
    id: `${scope}notCompatibleWithCustomTemplates`,
    defaultMessage: "Not compatible with custom templates",
  },
  CompatibleWithCustomTemplates: {
    id: `${scope}CompatibleWithCustomTemplates`,
    defaultMessage: "Compatible with custom templates",
  },
});
