import { put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadAssessmentList,
  loadAssessmentListSuccess,
  loadAssessmentListError,
} from "./slice";

export function* loadPackageDetailsSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/WIPAssessments",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadAssessmentListSuccess(data));
  } catch ({ message }) {
    yield put(loadAssessmentListError(message));
  }
}

export default function* assessmentsProviderSagas() {
  yield takeLatest(loadAssessmentList.type, loadPackageDetailsSaga);
}
