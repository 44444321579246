export const getIsCookiePolicyAccepted = (uniqueUserId) => {
  const cookiePolicyItemValue = localStorage.getItem(
    getCookiePolicyKey(uniqueUserId)
  );

  return cookiePolicyItemValue === "1";
};

export const setIsCookiePolicyAccepted = (uniqueUserId) => {
  localStorage.setItem(getCookiePolicyKey(uniqueUserId), 1);
};

function getCookiePolicyKey(uniqueUserId) {
  return `privacy_policy_${uniqueUserId}`;
}
