import React from "react";
import { sanitize } from "dompurify";

import { TOAST_TYPES } from "app/constants";
import { ReactComponent as EnvelopeIcon } from "assets/envelope.svg";
import { ReactComponent as ErrorIcon } from "assets/error-large.svg";
import { ReactComponent as PaperPlaneIcon } from "assets/paper-plane.svg";
import { ReactComponent as UserIcon } from "assets/user.svg";
import { ReactComponent as InfoIcon } from "assets/info.svg";

export const getToastIcon = (type, customIcon) => {
  if (customIcon) {
    return (
      <div
        className="toast__icon"
        data-testid="toast-icon-custom"
        dangerouslySetInnerHTML={{ __html: sanitize(customIcon) }}
      />
    );
  }

  switch (type) {
    case TOAST_TYPES.email: {
      return (
        <EnvelopeIcon
          className="toast__icon toast__icon--envelope"
          data-testid="toast-icon-envelope"
        />
      );
    }
    case TOAST_TYPES.error: {
      return (
        <ErrorIcon
          className="toast__icon toast__icon--error"
          data-testid="toast-icon-error"
        />
      );
    }
    case TOAST_TYPES.emailPaperPlane: {
      return <PaperPlaneIcon className="toast__icon toast__icon--paperPlane" />;
    }
    case TOAST_TYPES.user: {
      return (
        <UserIcon
          className="toast__icon toast__icon--user"
          data-testid="toast-icon-user"
        />
      );
    }
    default:
      return (
        <InfoIcon
          className="toast__icon toast__icon--info"
          data-testid="toast-icon-info"
        />
      );
  }
};
