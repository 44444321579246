import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "thematicFlagsDrawer",
  initialState: {
    isOpen: false,
    data: null,
    loading: false,
  },
  reducers: {
    setThematicFlagDrawerOpen: (state, { payload }) => {
      state.isOpen = payload.isOpen;
      if (payload.drawerData) {
        state.data = payload.drawerData;
      }
      state.loading = false;
    },
    saveFlags: (state, action) => {
      state.loading = true;
    },
  },
});

export const { setThematicFlagDrawerOpen, saveFlags } = slice.actions;

export default slice;
