import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import {
  saveFlag,
  setQCFlagDrawerOpen,
  saveFlagSuccess,
  saveFlagError,
} from "./slice";
import { setQcFlagToDocumentState } from "pages/AssessmentPage/BPQ/services/slice";
import { setIsDirty } from "services/form/slice";

function* saveFlagSaga({ payload }) {
  try {
    const { assessmentId, accessToken, qcFlag } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/qc-flag`,
      {
        method: "PUT",
        body: JSON.stringify(qcFlag),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put(setIsDirty({ value: false, key: null }));
    yield put(saveFlagSuccess());
    yield put(setQcFlagToDocumentState(data));
    yield put(setQCFlagDrawerOpen(false));
  } catch ({ message }) {
    yield put(saveFlagError());
    yield showError();
  }
}

export default function* qcFlagDrawerSagas() {
  yield all([yield takeLatest(saveFlag.type, saveFlagSaga)]);
}
