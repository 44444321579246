import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchaseAssessmentDetails: [],
  selectedAssessmentsCount: 0,
  isPurchaseSubmitting: false,
  isBulkPurchaseModalOpen: false,
};

const slice = createSlice({
  name: "purchaseAssessment",
  initialState,
  reducers: {
    setSinglePurchaseAssessmentDetails: (state, action) => {
      state.purchaseAssessmentDetails = [{ ...action.payload }];
    },
    setMultiplePurchaseAssessmentDetails: (state, action) => {
      state.purchaseAssessmentDetails = action.payload.selectedAssessments;
      state.selectedAssessmentsCount = action.payload.selectedAssessmentsCount;
    },
    submitAssessmentPurchase: () => {},
    setIsPurchaseSubmitting: (state, action) => {
      state.isPurchaseSubmitting = action.payload;
    },
    setIsBulkPurchaseModalOpen: (state, action) => {
      state.isBulkPurchaseModalOpen = action.payload;
    },
    resetPurchaseAssessments: () => initialState,
  },
});

export const {
  setSinglePurchaseAssessmentDetails,
  setMultiplePurchaseAssessmentDetails,
  submitAssessmentPurchase,
  setIsPurchaseSubmitting,
  setIsBulkPurchaseModalOpen,
  resetPurchaseAssessments,
} = slice.actions;
export default slice;
