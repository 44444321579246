import React from "react";
import { defineMessages } from "react-intl";

const scope = "pages.thirdParty.";

export default defineMessages({
  inviteUserTitle: {
    id: `${scope}inviteUserTitle`,
    defaultMessage: "Invite {thirdPartyName} User",
  },
  generalBEValidationError: {
    id: `${scope}generalBEValidationError`,
    defaultMessage: "System error: Your request could not be processed.",
  },
  firstNameLength: {
    id: `${scope}firstNameLengthError`,
    defaultMessage: "First name must be between 2 and 50 characters",
  },
  lastNameLength: {
    id: `${scope}lastNameLengthError`,
    defaultMessage: "Last name must be between 2 and 50 characters",
  },
  titleLength: {
    id: `${scope}titleLengthError`,
    defaultMessage: "Title must be up to 100 characters long",
  },
  locationLength: {
    id: `${scope}locationLengthError`,
    defaultMessage: "Location must be up to 100 characters long",
  },
  emailLength: {
    id: `${scope}emailLengthError`,
    defaultMessage: "Email must be up to 400 characters long",
  },
  addThirdPartyUserModalTitleText: {
    id: `${scope}addThirdPartyUserModalTitleText`,
    defaultMessage: "Invite {thirdPartyName} User",
  },
  addThirdPartyUserModalHeaderText: {
    id: `${scope}addThirdPartyUserModalHeaderText`,
    defaultMessage: "Add users from your organization. Users will not have access to any specific assessments until you or another admin grants their access.",
  },
  loadThirdPartyContactError: {
    id: `${scope}loadThirdPartyContactError`,
    defaultMessage: "Failed to load contacts",
  },
  editUser: {
    id: `${scope}editUser`,
    defaultMessage: "Edit User",
  },
  activateUser: {
    id: `${scope}deactivateUser`,
    defaultMessage: "Deactivate",
  },
  deactivateUser: {
    id: `${scope}reactivateUser`,
    defaultMessage: "Reactivate",
  },
  addedOn: {
    id: `${scope}addedOn`,
    defaultMessage: "<strong>{email}</strong> was added on {date}.",
    values: {
      // eslint-disable-next-line react/display-name
      strong: (text) => <strong>{text}</strong>,
    },
  },
});
