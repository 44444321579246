import { Children } from "react";
import { components } from "react-select";

export const getSubText = ({ isMulti, value, prefixText, placeholder }) => {
  if (((isMulti && value?.length > 0) || (!isMulti && value)) && prefixText) {
    return prefixText;
  }

  return placeholder;
};

export const getMultiSelectChildren = (children) =>
  Children.map(children, (child) => {
    const { MultiValue } = components;
    if (child?.type === MultiValue) {
      return child;
    }
  });
