import { findLast } from "lodash";

export function createDraftSummary(
  changedAnswers,
  questionnaireDocument,
  thirdPartyName
) {
  const questions = questionnaireDocument.questions.map((question) => {
    return {
      questionId: question.questionId,
      responseSummary: question.responseSummary,
    };
  });
  changedAnswers.forEach((answer) => {
    const questionToUpdate = questions.find(
      (x) => x.questionId === answer.questionId
    );
    if (questionToUpdate) {
      questionToUpdate.responseSummary =
        answer.responseSummary || answer.revisedSummary;
    }
  });
  const lastQuestionWithTemplate = findLast(
    questionnaireDocument.questions,
    (x) => x.summaryTemplate
  );
  const questionsWithoutLast = questions.filter(
    (x) => x.questionId !== lastQuestionWithTemplate.questionId
  );
  let summary = getResponseSummaries(questionsWithoutLast).join(" ");
  let domains = questionnaireDocument?.bpqMask?.domains;
  if (domains?.length) {
    summary += getMaskedDomainSummary(domains, thirdPartyName);
  }

  const lastSummary = getResponseSummaries([lastQuestionWithTemplate]);
  if (lastSummary.length === 1) {
    summary += `\n\n${lastSummary[0]}`;
  }
  return summary;
}

function getMaskedDomainSummary(domains, thirdPartyName) {
  const domainLength = domains.length;
  const formattedDomains = formatDomains(domains);
  return ` Based on the services provided by ${thirdPartyName}, ${formattedDomains} ${
    domainLength === 1 ? "is" : "are"
  } out of scope.`;
}

function formatDomains(domains) {
  if (!domains?.length) {
    return null;
  }
  const names = domains.map((x) => x.name);
  if (names.length === 1) {
    return names[0];
  }
  if (names.length === 2) {
    return `${names[0]} and ${names[1]}`;
  }

  return `${names.slice(0, names.length - 1).join(", ")}, and ${
    names[names.length - 1]
  }`;
}
const getResponseSummaries = (questions) => {
  const wordsToRemove = /<span>|<\/span>/g;

  return questions
    .filter((question) => question.responseSummary)
    .map((question) => question.responseSummary.replace(wordsToRemove, ""));
};
