import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./table-dropdown.scss";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import Button from "components/GlobalComponents/Buttons/Button";
import DropdownMenu from "components/DropdownMenu";
import routes from "app/routes";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import { buttonTypes } from "components/GlobalComponents/Buttons/Button/Button";
import { selectSlice as selectedAssessmentsSlice } from "components/BulkAssessmentActions/services/selectors";

function TableDropDown({ className, customTitle }) {
  const dropdownWrapper = useRef();
  const location = useLocation();
  const permissions = useSelector(selectPermissions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [currentItem, setCurrentItem] = useState("");
  const { selectedAssessments } = useSelector(selectedAssessmentsSlice);

  useEffect(() => {
    if (permissions) {
      const values = Object.values(routes).filter(
        (el) =>
          el.showInTableDropDown === true &&
          (!el.permission ||
            permissions.includes(el.permission) ||
            (Array.isArray(el.permission) &&
              permissions.some((p) => el.permission.includes(p))))
      );
      setDropdownItems(values);
      setCurrentItem(location.pathname);
      const currentElement = values.find((el) => {
        return location.pathname.includes(el.path);
      });
      if (currentElement) {
        setCurrentItem(currentElement.title);
      }
    }
  }, [location, permissions]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useCheckOutsideClick(dropdownWrapper, toggleDropdown, isDropdownOpen);

  if (selectedAssessments.length !== 0) {
    return null;
  }

  if (permissions) {
    return (
      <div
        className={classNames("table-dropdown", "dropdown-wrapper", className)}
        ref={dropdownWrapper}
      >
        <Button
          ariaExpanded={isDropdownOpen}
          ariaHasPopup={true}
          className="table-dropdown__btn"
          followingIcon={<ChevronIcon />}
          onClick={toggleDropdown}
          buttonType={buttonTypes.link}
          data-testid="table-dropdown-title"
        >
          {customTitle || currentItem}
        </Button>
        <DropdownMenu
          className="table-dropdown__dropdown-menu"
          isDropdownOpen={isDropdownOpen}
        >
          {dropdownItems.map((value, i) => {
            return (
              <Link
                to={value.path}
                className={classNames("dropdown-menu__item", {
                  "dropdown-menu__item--selected": value.title === currentItem,
                })}
                key={i}
                onClick={toggleDropdown}
              >
                {value.title}
              </Link>
            );
          })}
        </DropdownMenu>
      </div>
    );
  }
  return null;
}

TableDropDown.defaultProps = {
  className: "",
  customTitle: "",
};

TableDropDown.propTypes = {
  className: PropTypes.string,
  customTitle: PropTypes.string,
};

export default TableDropDown;
