const REQUIRED_FIELD = "Required Field";
const DEFAULT_ARIA_LABEL = "Select";
const SELECTED = "selected";

const formatSelectedOptionText = (optionLabel, optionText) => {
  return (
    (optionLabel ? optionLabel : "") +
    (optionText ? ` ${optionText}` : "") +
    (optionLabel || optionText ? ` ${SELECTED},` : "")
  );
};

const formatAriaLabelText = (
  isRequired,
  errorText,
  label,
  selectedOptionText
) => {
  const selectedOptionsPrefix = label || DEFAULT_ARIA_LABEL;
  const selectedOptionsSuffix =
    isRequired && !errorText ? REQUIRED_FIELD : errorText;

  const ariaLabelMessage = `${selectedOptionsPrefix} ${selectedOptionText} ${selectedOptionsSuffix}`;
  return ariaLabelMessage.trim();
};

export const ariaLabelMessageSingleSelect = (
  isRequired,
  errorText,
  label,
  selectedOption
) => {
  const selectedOptionText = formatSelectedOptionText(
    selectedOption?.label,
    selectedOption?.text
  );

  return formatAriaLabelText(isRequired, errorText, label, selectedOptionText);
};

export const ariaLabelMessageMultiSelect = (
  isRequired,
  errorText,
  label,
  selectedOptions
) => {
  const selectedOptionsText = selectedOptions
    .map((selectedOption) => {
      return formatSelectedOptionText(
        selectedOption?.label,
        selectedOption?.text
      );
    })
    .join(" ");

  return formatAriaLabelText(isRequired, errorText, label, selectedOptionsText);
};
