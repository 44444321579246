import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const slice = createSlice({
  name: "globalService",
  initialState: {
    toasts: [],
  },
  reducers: {
    addToast: (state, action) => {
      action.payload.id = uuidv4();
      state.toasts = [action.payload, ...state.toasts];
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload.id
      );
    },
  },
});

export const { addToast, removeToast } = slice.actions;
export default slice;
