import { put, takeLatest, all, takeEvery } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  updateDownloadFileName,
  downloadFile,
} from "utils/handleDownload/handleDownload";
import {
  loadArtifactsData,
  loadArtifactsDataSuccess,
  loadArtifactsDataError,
  downloadArtifact,
  deleteArtifactSuccess,
  deleteArtifactError,
  deleteArtifact,
  loadArtifactReferences,
  loadArtifactReferencesSuccess,
  loadArtifactReferencesError,
} from "./slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";

export function* loadArtifactsDataSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${id}/artifacts`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === 200 || response.status === 204) {
      const data = yield response.json();
      yield put(loadArtifactsDataSuccess(data));
    } else {
      throw new Error("Failed to load artifacts.");
    }
  } catch ({ message }) {
    yield put(loadArtifactsDataError(message));
  }
}

export function* downloadArtifactSaga({ payload }) {
  const url = payload.artifactId
    ? process.env.REACT_APP_API_URL +
      `/assessments/${payload.assessmentId}/artifacts/${payload.artifactId}/download?isRedacted=${payload.isRedacted}`
    : process.env.REACT_APP_API_URL +
      `/assessments/${payload.assessmentId}/artifacts/download?redacted=${payload.isRedacted}`;

  try {
    const response = yield fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + payload.accessToken,
      },
    });
    if (response.status === 200) {
      downloadFile(response, updateDownloadFileName(response));
    } else {
      const data = yield response.json();
      throw new Error(data?.Title);
    }
  } catch ({ message }) {
    yield showError(message);
  }
}

export function* loadArtifactReferencesSaga({ payload }) {
  try {
    const { assessmentId, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/artifacts/references`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200 || response.status === 204) {
      const data = yield response.json();
      yield put(loadArtifactReferencesSuccess(data));
    } else {
      throw new Error("Failed to load artifact references.");
    }
  } catch ({ message }) {
    yield put(loadArtifactReferencesError());
    yield showError(message);
  }
}

export function* deleteArtifactSaga({ payload }) {
  const { accessToken, assessmentId, artifactId, fileName } = payload;

  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/artifacts/${artifactId}/delete`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`An error occurred. ${fileName} could not be deleted.`);
    }

    yield put(deleteArtifactSuccess());
    yield put(
      loadArtifactsData({
        id: assessmentId,
        accessToken,
      })
    );
    yield showInfo(`${fileName} has been deleted.`);
  } catch ({ message }) {
    yield put(deleteArtifactError());
    yield showError(message);
  }
}

export default function* assessmentArtifactsSagas() {
  yield all([
    takeLatest(loadArtifactsData.type, loadArtifactsDataSaga),
    takeEvery(downloadArtifact.type, downloadArtifactSaga),
    takeLatest(loadArtifactReferences.type, loadArtifactReferencesSaga),
    takeLatest(deleteArtifact.type, deleteArtifactSaga),
  ]);
}
