import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  filters: null,
  loading: false,
  error: null,
  downloadingIds: [],
};

const slice = createSlice({
  name: "catalogFilters",
  initialState,
  reducers: {
    loadCatalogData: (state) => {
      state.data = null;
      state.filters = null;
      state.loading = true;
    },
    loadCatalogDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.catalogEntries;
      state.filters = action.payload.filters;
      state.error = null;
    },
    loadCatalogDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    downloadAssessment: (state, action) => {
      state.downloadingIds = [
        action.payload.productId,
        ...state.downloadingIds,
      ];
    },
    assessmentDownloaded: (state, action) => {
      state.downloadingIds = state.downloadingIds.filter(
        (id) => id !== action.payload.productId
      );
    },
    setWatchStatusForListItem: (state, action) => {
      if (state.data) {
        action.payload.assessmentIds.forEach((id) => {
          let item = state.data.find((x) => x.assessmentId === id);
          if (item) {
            item.isWatched = action.payload.isWatched;
          }
        });
      }
    },
    setPurchasedForListItem: (state, action) => {
      if (state.data) {
        action.payload.purchases.forEach((purchase) => {
          let item = state.data.find((x) => x.assessmentId === purchase.id);
          if (item) {
            item.isPurchased = true;
            item.isWatched = true;
            item.canPurchase = purchase.canPurchase;
            item.authorizationStatus = purchase.authorizationStatus;
            item.isProductAvailable = purchase.isProductAvailable;
            item.productId = purchase.productId;
          }
        });
      }
    },
  },
});

export const {
  loadCatalogData,
  loadCatalogDataSuccess,
  loadCatalogDataError,
  downloadAssessment,
  assessmentDownloaded,
  setPurchasedForListItem,
  setWatchStatusForListItem,
} = slice.actions;

export default slice;
