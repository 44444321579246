import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { loadCustomer, loadCustomerSuccess, loadCustomerError } from "./slice";

export function* loadCustomerSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadCustomerSuccess(data));
  } catch ({ message }) {
    yield put(loadCustomerError(message));
  }
}

export default function* manageCustomerSagas() {
  yield all([yield takeLatest(loadCustomer.type, loadCustomerSaga)]);
}
