import { call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadAssessmentDetails,
  setAssessmentDetails,
  setLoadingDetailsError,
} from "./slice";

export function* loadAssessmentDetailsSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/catalog/" + payload.assessmentId,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error();
    }
    const data = yield response.json();
    yield put(setAssessmentDetails(data));
  } catch {
    yield put(setLoadingDetailsError());
  }
}

export default function* assessmentDetailsProviderSagas() {
  yield takeLatest(loadAssessmentDetails.type, loadAssessmentDetailsSaga);
}
