import { orderBy } from "lodash";

import TableToggle from "components/TableComponents/TableToggle";
import { USER_TYPES } from "./constants";

export const frameworkComponents = {
  tableToggle: TableToggle,
};

const THIRD_PARTY_USER_ROLES = ["Third party admin", "Third party contributor"];

export const getSortedAndFormattedUsersList = (usersList, type) => {
  let filteredAndFormattedUsersList = usersList.filter(
    (user) => user.type === type
  );

  filteredAndFormattedUsersList = orderBy(
    filteredAndFormattedUsersList,
    (user) => {
      let valueToSortBy;

      if (type === USER_TYPES.trusight) {
        valueToSortBy = user.role + user.surname;
      } else {
        let priorityOfRoleInList = THIRD_PARTY_USER_ROLES.indexOf(user.role);
        if (priorityOfRoleInList === -1) {
          priorityOfRoleInList = 2;
        }
        valueToSortBy = priorityOfRoleInList + user.surname;
      }

      return valueToSortBy;
    }
  );

  filteredAndFormattedUsersList = filteredAndFormattedUsersList.map(
    (user, index) => {
      const previousUserInList = filteredAndFormattedUsersList[index - 1];

      return {
        ...user,
        isFirstInGroup:
          !!previousUserInList && user.role !== previousUserInList.role,
      };
    }
  );

  return filteredAndFormattedUsersList;
};
