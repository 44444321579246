import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import "./notifications-drawer.scss";
import AssessmentDetails from "components/AssessmentDetails";
import Button from "components/GlobalComponents/Buttons/Button";
import AssessmentStatusChangedNotification from "../AssessmentStatusChangedNotification/AssessmentStatusChangedNotification";
import AssessmentFieldworkChangedNotification from "../AssessmentFieldworkChangedNotification/AssessmentFieldworkChangedNotification";
import NotificationItem from "../NotificationItem/NotificationItem";
import Drawer from "components/Drawer";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import {
  selectNotifications,
  selectLoading,
  selectError,
} from "components/Notifications/services/selectors";
import { selectSlice } from "components/AssessmentDetails/services/selectors";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import { loadAssessmentDetails } from "components/AssessmentDetails/services/slice";
import {
  setIsBulkPurchaseModalOpen,
  setSinglePurchaseAssessmentDetails,
} from "components/BulkAssessmentActions/components/PurchaseAssessment/services/slice";
import {
  watchAssessments,
  unwatchAssessments,
} from "services/watchAssessments/slice";
import WatchButton from "components/AssessmentDetails/components/WatchButton/WatchButton";
import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";
import useAccessToken from "utils/useAccessToken/useAccessToken";

const NotificationsDrawer = ({
  closeNotifications,
  closeDrawer,
  loadNotifications,
  newNotifications,
  notifications,
  setShowAssessmentDetails,
  setDrawerOpen,
  showAssessmentDetails,
  drawerOpen,
}) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const { hasMore } = useSelector(selectNotifications);
  const hasNotifications = notifications && notifications.length > 0;
  const loading = useSelector(selectLoading);
  const seenNotifications =
    hasNotifications && notifications.filter((x) => x.seenAt != null);
  const accessToken = useAccessToken();
  const { assessmentDetails, loadingDetails, loadingDetailsError } =
    useSelector(selectSlice);
  const permissions = useSelector(selectPermissions);

  const openPurchaseModal = () => {
    dispatch(setSinglePurchaseAssessmentDetails({ ...assessmentDetails }));
    dispatch(setIsBulkPurchaseModalOpen(true));
    closeDrawer();
  };

  const newNotificationsCount = newNotifications ? newNotifications.length : 0;

  const isDrawerControlsVisible =
    permissions?.includes(PERMISSIONS.CanPurchase) &&
    !loadingDetails &&
    !loadingDetailsError &&
    showAssessmentDetails &&
    !assessmentDetails?.isPurchased &&
    assessmentDetails?.canPurchase;

  const renderNotification = (data) => {
    const messageData = JSON.parse(data.messageData);
    if (data.messageText) {
      return (
        <NotificationItem
          key={data.id}
          messageText={data.messageText}
          messageData={messageData}
          createdAt={data.createdAt}
          closeDrawer={closeDrawer}
        />
      );
    } else {
      switch (data.type) {
        case "AssessmentStatus": {
          return (
            <AssessmentStatusChangedNotification
              key={data.id}
              messageData={messageData}
              createdAt={data.createdAt}
              onClick={showDetails}
            />
          );
        }
        case "AssessmentFieldworkChanged": {
          return (
            <AssessmentFieldworkChangedNotification
              key={data.id}
              messageData={messageData}
              createdAt={data.createdAt}
              onClick={showDetails}
            />
          );
        }
        default:
          return null;
      }
    }
  };

  const showDetails = (assessmentId) => {
    dispatch(
      loadAssessmentDetails({
        accessToken,
        assessmentId,
      })
    );
    closeNotifications();
    setShowAssessmentDetails(true);
  };

  const showNotifications = () => {
    setShowAssessmentDetails(false);
  };

  const removeFromWatchlist = () => {
    dispatch(
      unwatchAssessments({
        accessToken,
        assessmentIds: [assessmentDetails.productId],
        name: assessmentDetails.name,
      })
    );
  };

  const addToWatchlist = () => {
    dispatch(
      watchAssessments({
        accessToken,
        assessmentIds: [assessmentDetails.productId],
        assessments: [assessmentDetails],
      })
    );
  };

  const DrawerHeaderControls = () => {
    return (
      <>
        <div className="notifications-drawer__header-controls">
          <Button
            className="drawer__header-btn"
            isSmall
            leadingIcon={
              <ChevronIcon className="notifications-drawer__icon notifications-drawer__icon--rotate" />
            }
            onClick={showNotifications}
          >
            Notifications
          </Button>
        </div>
        {assessmentDetails &&
          assessmentDetails.canWatch &&
          permissions?.includes(PERMISSIONS.CanWatch) && (
            <WatchButton
              assessmentDetails={assessmentDetails}
              unwatch={removeFromWatchlist}
              watch={addToWatchlist}
            />
          )}
      </>
    );
  };

  return (
    <>
      <Drawer
        autoHeight
        className={classNames("notifications-drawer", {
          "notifications-drawer--show-notifications": !showAssessmentDetails,
        })}
        headerControls={showAssessmentDetails && <DrawerHeaderControls />}
        footerControls={
          isDrawerControlsVisible && (
            <Button
              className="notifications-drawer__purchase-assessment-button"
              isHighlighted
              isLarge
              onClick={openPurchaseModal}
            >
              Purchase Assessment
            </Button>
          )
        }
        hideCloseButton
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
      >
        {!showAssessmentDetails ? (
          <>
            <h2 className="notifications-drawer__header">Notifications</h2>
            <div className="notifications-drawer__content">
              {error && (
                <p className="notifications-drawer__text paragraph">
                  Notifications failed to load. Please try reloading the page.
                </p>
              )}
              {!loading &&
                !error &&
                (!notifications || !notifications.length) && (
                  <p className="notifications-drawer__text paragraph">
                    You have no notifications.
                  </p>
                )}
              {!loading && !error && newNotificationsCount > 0 && (
                <>
                  <h3 className="notifications-drawer__subheader heading4">
                    New
                  </h3>
                  <div className="notifications-drawer__notifications notifications-drawer__notifications--new">
                    {newNotifications.map((data) => renderNotification(data))}
                  </div>
                </>
              )}
              {seenNotifications && seenNotifications.length > 0 && (
                <div
                  className={classNames("notifications-drawer__notifications", {
                    "notifications-drawer__notifications--gutter-top":
                      newNotificationsCount > 0,
                  })}
                >
                  {seenNotifications.map((data) => renderNotification(data))}
                </div>
              )}
              {loading && (
                <p className="notifications-drawer__text notifications-drawer__text--loading paragraph">
                  Loading...
                </p>
              )}
              {!loading && hasMore && (
                <Button
                  className="notifications-drawer__btn"
                  isLarge
                  isLight
                  onClick={() => loadNotifications(true)}
                >
                  Show More
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            {!loadingDetails && !loadingDetailsError && assessmentDetails && (
              <AssessmentDetails assessmentDetails={assessmentDetails} />
            )}
            {loadingDetails && (
              <p className="notifications-drawer__text notifications-drawer__text--gutter paragraph">
                Loading..
              </p>
            )}
            {loadingDetailsError && (
              <p className="notifications-drawer__text notifications-drawer__text--gutter paragraph">
                Assessment details failed to load. Please try again.
              </p>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

NotificationsDrawer.defaultProps = {
  newNotifications: [],
  notifications: [],
};

NotificationsDrawer.propTypes = {
  closeNotifications: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  newNotifications: PropTypes.array,
  notifications: PropTypes.array,
  setShowAssessmentDetails: PropTypes.func.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  showAssessmentDetails: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
};

export default NotificationsDrawer;
