import { put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { loadUserInfo, loadUserInfoError, loadUserInfoSuccess } from "./slice";

export function* loadUserInfoSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/users/info",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadUserInfoSuccess(data));
  } catch ({ message }) {
    yield put(loadUserInfoError(message));
  }
}

export default function* headerSagas() {
  yield takeLatest(loadUserInfo.type, loadUserInfoSaga);
}
