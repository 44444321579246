import { createSlice, createAction } from "@reduxjs/toolkit";

import { NAMESPACE } from "../constants";

export const initialState = {
  plannedAssessments: null,
  plannedAssessmentsLoading: false,
  plannedAssessmentsError: null,
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    requestTablesLoading: (state) => {
      state.plannedAssessmentsLoading = true;
    },
    requestTablesSuccess: (state, action) => {
      state.plannedAssessmentsLoading = false;
      state.plannedAssessments = action.payload;
      state.plannedAssessmentsError = null;
    },
    requestTablesError: (state, action) => {
      state.plannedAssessmentsLoading = false;
      state.plannedAssessmentsError = action.payload;
    },
  },
});

export const getPlannedAssessments = createAction(
  `${NAMESPACE}/getPlannedAssessments`
);

export const {
  requestTablesLoading,
  requestTablesSuccess,
  requestTablesError,
} = slice.actions;

export default slice;
