import React from "react";
import PropTypes from "prop-types";
import ReactTimeAgo from "react-time-ago";
import { sanitize } from "dompurify";

import "./notification-item.scss";
import useInteraction from "../../utils/useInteraction";

const NotificationItem = ({
  messageText,
  messageData,
  createdAt,
  closeDrawer,
}) => {
  const onClick = useInteraction(messageData, closeDrawer);

  return (
    <div className="notification-item">
      <button
        className="notification-item__details-button"
        type="button"
        onClick={onClick}
      >
        <div dangerouslySetInnerHTML={{ __html: sanitize(messageText) }} />
        <ReactTimeAgo
          className="assessment-fieldwork-changed-notification__time"
          date={new Date(createdAt)}
        />
      </button>
    </div>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  messageText: PropTypes.string.isRequired,
  messageData: PropTypes.object,
  createdAt: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};
