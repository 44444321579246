import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "bulkAssessmentActions",
  initialState: {
    selectedAssessments: [],
  },
  reducers: {
    setSelectedAssessments: (state, action) => {
      state.selectedAssessments = action.payload;
    },
    setPurchasedStatusForSelected: (state, action) => {
      if (!state.selectedAssessments) {
        return;
      }
      action.payload.purchases.forEach((purchase) => {
        const item = state.selectedAssessments.find(
          (x) => x.assessmentId === purchase.id
        );
        if (item) {
          item.isPurchased = true;
          item.isWatched = true;
          item.canPurchase = purchase.canPurchase;
          item.authorizationStatus = purchase.authorizationStatus;
          item.isProductAvailable = purchase.isProductAvailable;
          item.productId = purchase.productId;
        }
      });
    },
  },
});

export const { setSelectedAssessments, setPurchasedStatusForSelected } =
  slice.actions;
export default slice;
