import React, { useEffect, useRef, useState } from "react";
import FocusLock from "react-focus-lock";
import { useSelector } from "react-redux";

import Toast from "components/Toast";
import { selectToasts } from "services/toast/selectors";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";

const ToastController = () => {
  const [isFocusLockDisabled, setIsFocusLockDisabled] = useState(false);
  const focusLockWrapper = useRef();
  const toasts = useSelector(selectToasts);
  const [oldToastsLength, setOldToastsLength] = useState(toasts.length);

  useCheckOutsideClick(
    focusLockWrapper,
    setIsFocusLockDisabled,
    isFocusLockDisabled,
    true
  );

  useEffect(() => {
    if (oldToastsLength < toasts.length) {
      setIsFocusLockDisabled(false);
    }
    setOldToastsLength(toasts.length);
  }, [oldToastsLength, toasts]);
  return (
    <FocusLock
      className="main-layout__toasts"
      ref={focusLockWrapper}
      disabled={!toasts || toasts.length === 0 || isFocusLockDisabled}
      persistentFocus
      returnFocus
    >
      {toasts.length !== 0 &&
        toasts.map((toast) => (
          <Toast
            autoWidth={toast.autoWidth}
            buttonText={toast.buttonText}
            customIcon={toast.customIcon}
            description={toast.description}
            hasButton={toast.hasButton}
            id={toast.id}
            isPausedInitial={toast.isPausedInitial}
            isLink={toast.isLink}
            key={toast.id}
            onClick={toast.onClick}
            showCloseButton={toast.showCloseButton}
            to={toast.to}
            type={toast.type}
          />
        ))}
    </FocusLock>
  );
};

export default ToastController;
