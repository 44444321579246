import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    commentBuilderDrawer: {
      isOpen: false,
      drawerData: null,
      saveDraftInProgress: false,
      publishInProgress: false,
      saveDraftFailed: false,
      publishFailed: false,
    },
  },
  reducers: {
    setCommentBuilderDrawerOpen: (state, { payload }) => {
      state.commentBuilderDrawer.isOpen = payload.isOpen;
      if (payload.drawerData) {
        state.commentBuilderDrawer.drawerData = {
          ...payload.drawerData,
          isDraft:
            !payload.drawerData.summary && !payload.drawerData.revisedSummary,
        };
      }
    },
    setAnswerAndSummaryToCommentBuilderDrawer: (state) => {
      state.commentBuilderDrawer.publishInProgress = true;
    },
    setCommentBuilderDrawerData: (state, { payload }) => {
      if (payload) {
        state.commentBuilderDrawer.drawerData = {
          ...payload,
          isDraft:
            payload.isDraft || (!payload.summary && !payload.revisedSummary),
        };
      } else {
        state.commentBuilderDrawer.drawerData = payload;
      }
    },
    setAnswerToCommentBuilderDrawer: (state) => {
      state.commentBuilderDrawer.saveDraftInProgress = true;
    },
    getGeneratedSummaryReview: () => {},
    setSummaryDraftReview: (state, { payload }) => {
      if (state.commentBuilderDrawer.drawerData) {
        state.commentBuilderDrawer.drawerData.summaryDraft = payload;
      }
    },
    setCommandBuilderSaveDraftInProgress: (state, { payload }) => {
      state.commentBuilderDrawer.saveDraftInProgress = payload;
    },
    setCommandBuilderPublishInProgress: (state, { payload }) => {
      state.commentBuilderDrawer.publishInProgress = payload;
    },
    setCommentBuilderDraftSaveFailed: (state, { payload }) => {
      state.commentBuilderDrawer.saveDraftFailed = payload;
    },
    setCommentBuilderPublishFailed: (state, { payload }) => {
      state.commentBuilderDrawer.publishFailed = payload;
    },
  },
});

export const {
  setCommentBuilderDrawerOpen,
  setAnswerAndSummaryToCommentBuilderDrawer,
  setCommentBuilderDrawerData,
  setAnswerToCommentBuilderDrawer,
  getGeneratedSummaryReview,
  setSummaryDraftReview,
  setCommandBuilderSaveDraftInProgress,
  setCommandBuilderPublishInProgress,
  setCommentBuilderDraftSaveFailed,
  setCommentBuilderPublishFailed,
} = slice.actions;

export default slice;
