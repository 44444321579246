import { call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import {
  loadBpqVersions,
  loadBpqVersionsSuccess,
  loadBpqVersionsError,
} from "./slice";

export function* loadBpqVersionsSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/documentTemplates/bpq-minor-versions`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadBpqVersionsSuccess(data));
  } catch ({ message }) {
    yield put(loadBpqVersionsError(message));
    yield showError();
  }
}

export default function* templatesAndMappingSagas() {
  yield takeLatest(loadBpqVersions.type, loadBpqVersionsSaga);
}
