import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "drawerFieldsSlice",
  initialState: {
    fieldState: { isOpen: false, id: "" },
  },
  reducers: {
    setFieldState: (state, action) => {
      state.fieldState = { ...action.payload };
    },
  },
});

export const { setFieldState } = slice.actions;

export default slice;
