import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectPermissions } from "components/AuthorizedComponent/selectors";
import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";
import routes, { assessmentsRoutes } from "app/routes";
import TableDropDown from "components/TableDropDown";
import BulkAssessmentActions from "components/BulkAssessmentActions";
import MyListNavigation from "pages/MyListPage/components/MyListNavigation/MyListNavigation";
import ManageCustomersRightSideControls from "pages/ManageCustomersPage/components/ManageCustomersRightSideControls";
import {
  PERMISSIONS_TO_SEE_ALL_CONTROLS,
  PERMISSIONS_TO_SEE_BULK_ACTIONS,
} from "./utils/constants";

const AssessmentControls = () => {
  const permissions = useSelector(selectPermissions);
  const { pathname } = useLocation();
  const renderControls = assessmentsRoutes.includes(pathname);

  if (!renderControls || !permissions) {
    return null;
  }

  const shouldRenderAllControls = PERMISSIONS_TO_SEE_ALL_CONTROLS.every(
    (permission) => permissions.includes(permission)
  );

  const shouldRenderMyListNavigation =
    permissions.includes(PERMISSIONS.MyList) &&
    PERMISSIONS_TO_SEE_BULK_ACTIONS.every(
      (permission) => !permissions.includes(permission)
    );

  const shouldRenderManageCustomersActions =
    permissions.includes(PERMISSIONS.ManageCustomers) &&
    pathname === routes.manageCustomers.path;

  // const shouldRenderMyNetworkViewActions =
  //   permissions.includes(PERMISSIONS.MyNetwork.View) &&
  //   pathname.includes(routes.myNetwork.path);

  return (
    <>
      <TableDropDown />
      {shouldRenderAllControls && (
        <>
          <BulkAssessmentActions />
          <MyListNavigation />
        </>
      )}
      {shouldRenderMyListNavigation && <MyListNavigation />}
      {shouldRenderManageCustomersActions && (
        <ManageCustomersRightSideControls />
      )}
      {/*{shouldRenderMyNetworkViewActions && <MyNetworkViewControls />}*/}
    </>
  );
};

export default AssessmentControls;
