import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    assessmentReviewCommentDrawer: {
      isOpen: false,
      drawerData: null,
    },
  },
  reducers: {
    setAssessmentReviewCommentDrawerOpen: (state, { payload }) => {
      state.assessmentReviewCommentDrawer.isOpen = payload.isOpen;
      if (payload.drawerData) {
        state.assessmentReviewCommentDrawer.drawerData = payload.drawerData;
      }
    },
  },
});

export const { setAssessmentReviewCommentDrawerOpen } = slice.actions;

export default slice;
