import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadPurchasableTemplates,
  loadPurchasableTemplatesSuccess,
  loadPurchasableTemplatesError,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";

export function* loadPurchasableTemplatesSaga({ payload }) {
  try {
    const { accessToken, assessmentsIds } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/purchase/custom-templates?${assessmentsIds}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load custom templates");
    }
    const data = yield response.json();
    yield put(loadPurchasableTemplatesSuccess(data));
  } catch ({ message }) {
    yield showError(message);
    yield put(loadPurchasableTemplatesError());
  }
}

export default function* purchaseAssessmentWithTemplatesSagas() {
  yield all([
    takeLatest(loadPurchasableTemplates.type, loadPurchasableTemplatesSaga),
  ]);
}
