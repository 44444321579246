import { getPositionById } from "utils/stageByPosition/stageByPosition";

export const hasAssessmentPermission = (
  permission,
  permissions,
  assessmentData,
  stages
) => {
  return getActiveAssessmentPermissions(
    permissions,
    assessmentData,
    stages
  ).some((x) =>
    Array.isArray(permission)
      ? permission.includes(x.permission)
      : x.permission === permission
  );
};

export const getActiveAssessmentPermissions = (
  permissions,
  assessmentData,
  stages
) => {
  if (!assessmentData || !permissions || !stages) {
    return [];
  }

  const stagePosition = getPositionById(stages, assessmentData?.statusId);

  return permissions
    .filter((x) => !x.stage || x.stage === stagePosition)
    .filter(
      (x) =>
        !x.relationshipToAssessment ||
        assessmentData?.userRelationshipToAssessment?.includes(
          x.relationshipToAssessment
        )
    );
};
