import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import routes from "app/routes";
import { setDrawerId } from "../services/slice";

const useInteraction = (messageData, closeDrawer) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = () => {
    switch (messageData?.InteractionType?.toLowerCase()) {
      case "navigate":
        navigate(messageData);
        break;
      default:
        return;
    }
  };

  const navigate = (messageData) => {
    if (!messageData?.Link) {
      return;
    }

    const assessmentId = messageData?.assessmentId || messageData?.AssessmentId;

    if (messageData.Link.toLowerCase() === "catalog") {
      closeDrawer();
      if (assessmentId) {
        dispatch(setDrawerId(assessmentId));
      }
      history.push(routes.catalog.path);
      return;
    }

    if (
      assessmentId &&
      messageData.Link.toLowerCase().startsWith("assessment")
    ) {
      const route = routes.assessment.childRoutes.find(
        (route) => route.id.toLowerCase() === messageData.Link.toLowerCase()
      );

      if (route) {
        closeDrawer();
        history.push(route.path.replace(":id", assessmentId));
      }
    } else {
      closeDrawer();
      history.push(routes[messageData?.Link].path);
    }
  };

  return onClick;
};

export default useInteraction;
