import AnimateHeight from "react-animate-height";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import { buttonTypes } from "components/GlobalComponents/Buttons/Button/Button";
import messages from "../../messages";
import "./upload-error.scss";

const UploadError = ({ errors }) => {
  const [height, setHeight] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (showErrors) {
      setHeight("100%");
    } else {
      setHeight(0);
    }
  }, [showErrors]);

  const toggleErrors = () => {
    setShowErrors(!showErrors);
  };

  return (
    <div data-testid="upload-error-div" className="upload-error">
      <Button
        ariaControls="error-list"
        ariaExpanded={showErrors}
        data-testid="upload-error-btn"
        className="upload-error__btn"
        followingIcon={<ChevronIcon className="upload-error__icon" />}
        onClick={toggleErrors}
        buttonType={buttonTypes.link}
      >
        {intl.formatMessage(messages.errorsFound, { count: errors.length })}
      </Button>
      <AnimateHeight
        className="upload-error__list-wrapper"
        animateOpacity={true}
        height={height}
        duration={200}
      >
        <ul id="error-list" className="upload-error__list">
          {errors.map((err, i) => (
            <li className="upload-error__list-item" key={i}>
              {err}
            </li>
          ))}
        </ul>
      </AnimateHeight>
    </div>
  );
};

UploadError.defaultProps = {
  errors: [],
};

UploadError.propTypes = {
  errors: PropTypes.array,
};

export default UploadError;
