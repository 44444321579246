import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadBpqScopingDocument,
  loadBpqScopingSuccess,
  loadBpqScopingError,
  setQuestionsIsMasked,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";

export function* loadBpqScopingDocumentSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/bpq/bpq-scoping`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const bpqScopingDocument = yield response.json();
    yield put(loadBpqScopingSuccess(bpqScopingDocument));
  } catch {
    yield put(loadBpqScopingError());
    yield showError();
  }
}

export function* setQuestionsIsMaskedSaga({ payload }) {
  try {
    const questionsToSend = payload.questions.map((question) => {
      return {
        masked: question.masked,
        serialId: question.serialId,
        questionDomain: question.questionDomain,
      };
    });

    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/bpq/bpq-scoping`,
      {
        method: "PUT",
        body: JSON.stringify(questionsToSend),
        headers: {
          Authorization: "Bearer " + payload.accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
  } catch {
    yield put(loadBpqScopingError());
    yield showError();
  }
}

export default function* bpqDocumentSagas() {
  yield all([
    takeLatest(loadBpqScopingDocument.type, loadBpqScopingDocumentSaga),
    takeLatest(setQuestionsIsMasked.type, setQuestionsIsMaskedSaga),
  ]);
}
