import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "documents",
  initialState: {
    bpqVersions: null,
    error: null,
  },
  reducers: {
    loadBpqVersions: (state) => {
      state.bpqVersions = null;
      state.error = null;
    },
    loadBpqVersionsSuccess: (state, action) => {
      state.bpqVersions = action.payload.items;
      state.error = null;
    },
    loadBpqVersionsError: (state, action) => {
      state.bpqVersions = null;
      state.error = action.payload;
    },
  },
});

export const { loadBpqVersions, loadBpqVersionsSuccess, loadBpqVersionsError } =
  slice.actions;

export default slice;
