import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { notifyCustomer, setIsNotificationSending } from "./slice";
import { setLastNotifiedAt } from "pages/ManageCustomerPage/services/slice";
import { addToast } from "services/toast/slice.js";
import { showError } from "utils/sagaEffects/sagaEffects";

function* notifyCustomerSaga({ payload }) {
  try {
    const { organizationId, accessToken, toastInfo } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/customer-management/notify/${organizationId}`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const lastNotifiedAt = yield response.json();
    yield put(setLastNotifiedAt(lastNotifiedAt));
    yield put(setIsNotificationSending(false));
    yield put(addToast(toastInfo));
  } catch {
    yield showError();
    yield put(setIsNotificationSending(false));
  }
}

export default function* manageCustomerSagas() {
  yield all([yield takeLatest(notifyCustomer.type, notifyCustomerSaga)]);
}
