import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import routes from "app/routes";
import { watchAssessments, unwatchAssessments, addToMyList } from "./slice";
import { setAssessmentWatchStatus } from "components/AssessmentDetails/services/slice";
import { setWatchStatusForListItem as setWatchStatusForMyList } from "pages/MyListPage/MyThirdPartiesPage/services/slice";
import { setWatchStatusForListItem as setWatchStatusForCatalog } from "pages/CatalogPage/services/slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";

export function* watchAssessmentsSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/watched-assessments/watch`,
      {
        method: "post",
        body: JSON.stringify(payload.assessmentIds),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      const data = yield response.json();
      let message;

      if (data.assessmentIds.length > 0) {
        message =
          data.assessmentIds.length > 1
            ? `You started watching ${data.assessmentIds.length} of the ${payload.assessmentIds.length} selected assessments`
            : `You started watching ${
                payload.assessments.find(
                  (a) =>
                    a.assessmentId === data.assessmentIds[0] ||
                    a.id === data.assessmentIds[0]
                ).name
              }`;
      } else {
        message = "You are already watching selected assessment(s)";
      }

      yield put(setAssessmentWatchStatus(true));
      yield put(
        setWatchStatusForMyList({
          assessmentIds: payload.assessmentIds,
          isWatched: true,
        })
      );
      yield put(
        setWatchStatusForCatalog({
          assessmentIds: payload.assessmentIds,
          isWatched: true,
        })
      );
      yield showInfo(message);
    }
  } catch ({ message }) {
    yield showError();
  }
}

export function* unwatchAssessmentsSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/watched-assessments/unwatch`,
      {
        method: "post",
        body: JSON.stringify(payload.assessmentIds),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      const data = yield response.json();
      let message;

      if (data.assessmentIds.length > 0) {
        message =
          data.assessmentIds.length > 1
            ? `You stopped watching ${data.assessmentIds.length} of the ${payload.assessmentIds.length} selected assessments`
            : `You stopped watching ${payload.name}`;
      } else {
        message = "You are not watching selected assessment(s)";
      }

      yield put(setAssessmentWatchStatus(false));
      yield put(
        setWatchStatusForMyList({
          assessmentIds: payload.assessmentIds,
          isWatched: false,
        })
      );
      yield put(
        setWatchStatusForCatalog({
          assessmentIds: payload.assessmentIds,
          isWatched: false,
        })
      );
      yield showInfo(message);
    }
  } catch ({ message }) {
    yield showError();
  }
}

export function* addToMyListSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/ThirdPartyAssessments/addToMyList`,
      {
        method: "post",
        body: JSON.stringify(payload.assessmentIds),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      const data = yield response.json();
      let message;

      if (data.assessmentIds.length > 0) {
        message =
          data.assessmentIds.length > 1
            ? `${data.assessmentIds.length} of the ${payload.assessmentIds.length} selected assessments were added to "My List"`
            : `${
                payload.assessments.find(
                  (a) => a.assessmentId === parseInt(data.assessmentIds[0])
                ).name
              } has been added to "My List"`;
      } else {
        message = "The selected assessment(s) are already in your list";
      }

      yield showInfo(
        message,
        data.assessmentIds.length === 1 && {
          hasButton: true,
          buttonText: "Go to My List",
          isLink: true,
          to: routes.myListMyThirdParties.path,
        }
      );
    }
  } catch ({ message }) {
    yield showError();
  }
}

export default function* watchAssessmentsSagas() {
  yield all([
    takeLatest(watchAssessments.type, watchAssessmentsSaga),
    takeLatest(unwatchAssessments.type, unwatchAssessmentsSaga),
    takeLatest(addToMyList.type, addToMyListSaga),
  ]);
}
