import React from "react";
import { useLocation } from "react-router-dom";

const LoadingPage = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="title" data-testid="loading-page">
      <span role="img" aria-label={`Loading page "${currentPath}".`}>
        ⏳
      </span>
    </div>
  );
};

export default LoadingPage;
