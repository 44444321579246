import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadThirdPartyData,
  loadThirdPartyDataSuccess,
  loadThirdPartyDataError,
  setUnauthorized,
  getOrganizationThirdParty,
  setOrganizationThirdPartyId,
} from "./slice";

export function* loadThirdPartyDataSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === 403) {
      yield put(setUnauthorized());
      return;
    }

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadThirdPartyDataSuccess(data));
  } catch ({ message }) {
    yield put(loadThirdPartyDataError(message));
  }
}

export function* loadOrganizationThirdPartySaga({ payload: { accessToken } }) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/organization`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === 403) {
      yield put(setUnauthorized());
      return;
    }

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(setOrganizationThirdPartyId(data.toString()));
  } catch ({ message }) {
    yield put(loadThirdPartyDataError(message));
  }
}

export default function* thirdPartySagas() {
  yield all([
    yield takeLatest(loadThirdPartyData.type, loadThirdPartyDataSaga),
    yield takeLatest(
      getOrganizationThirdParty.type,
      loadOrganizationThirdPartySaga
    ),
  ]);
}
