import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import FocusLock from "react-focus-lock";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { canAccess, globalNavigationRoutes } from "../../utils/utils";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import Button from "components/GlobalComponents/Buttons/Button";
import { buttonTypes } from "components/GlobalComponents/Buttons/Button/Button";
import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import "./global-navigation.scss";

const GlobalNavigation = ({ isNavigationOpen, setIsNavigationOpen }) => {
  const location = useLocation();
  const permissions = useSelector(selectPermissions);

  const toggleNavigation = () => setIsNavigationOpen(!isNavigationOpen);
  const accessibleMainNavigationRoutes = globalNavigationRoutes.mainRoutes
    .map((route) => ({
      title: route.title,
      links: route.links.filter(
        (link) => canAccess(link.permission, permissions) && !link.disabled
      ),
    }))
    .filter((route) => route.links.length > 0);

  //Filter out any bottom navigate through permissions, except Contact US (no permissions allowed)
  const accessibleBottomNavigationRoutes =
    globalNavigationRoutes.bottomNavigationRoutes;

  return (
    <FocusLock
      group="global-navigation-drawer"
      disabled={!isNavigationOpen}
      returnFocus
    >
      <div
        className={classNames("global-navigation", {
          "global-navigation--hidden": !isNavigationOpen,
        })}
      >
        <div className="global-navigation__wrapper">
          <div className="global-navigation__close-icon-wrapper">
            <ButtonIcon
              ariaExpanded={isNavigationOpen}
              className="global-navigation__close-icon"
              icon={<CloseIcon />}
              label="Close global navigation"
              onClick={() => setIsNavigationOpen(false)}
            />
          </div>
          <nav className="global-navigation__main-navigation">
            {accessibleMainNavigationRoutes.map((route) => (
              <div
                data-testid="global-navigation-section"
                key={route.title}
                className="global-navigation__section"
              >
                <div className="global-navigation__section-title">
                  {route.title}
                </div>
                <div className="global-navigation__section-links">
                  {route.links.map((link) => (
                    <Button
                      data-testid="global-navigation-section-link"
                      key={link.path}
                      className={classNames(
                        "global-navigation__link global-navigation__link--section",
                        {
                          "global-navigation__link--selected":
                            location.pathname.includes(link.path),
                        }
                      )}
                      onClick={toggleNavigation}
                      to={link.path}
                      isLink
                      buttonType={buttonTypes.link}
                    >
                      {link.title}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
          </nav>
          <div className="global-navigation__bottom-navigation">
            {accessibleBottomNavigationRoutes.map((route) => {
              //check if isSiteMap permissions are available
              const isSiteMap =
                permissions &&
                permissions.length > 0 &&
                route.title === "Site Map";

              //If permissions available, show both sitemap and contactus, else only contactUS
              return isSiteMap || route.title === "Contact Us" ? (
                <Button
                  data-testid="global-navigation-bottom-navigation-link"
                  key={route.path}
                  className={classNames(
                    "global-navigation__link global-navigation__link--bottom-navigation",
                    {
                      "global-navigation__link--selected":
                        location.pathname.includes(route.path),
                    }
                  )}
                  onClick={toggleNavigation}
                  to={route.path}
                  isLink
                  buttonType={buttonTypes.link}
                >
                  {route.title}
                </Button>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </FocusLock>
  );
};

GlobalNavigation.propTypes = {
  setIsNavigationOpen: PropTypes.func.isRequired,
  isNavigationOpen: PropTypes.bool.isRequired,
};

export default memo(GlobalNavigation);
