import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    exportAgenda: {
      isLoading: false,
    },
    exportExcel: {
      isLoading: false,
    },
    exportScoping: {
      isLoading: false,
    },
    exportObservation: {
      isLoading: false,
    },
  },
  reducers: {
    exportAgenda: (state) => {
      state.exportAgenda.isLoading = true;
    },
    exportAgendaSuccess: (state) => {
      state.exportAgenda.isLoading = false;
    },
    exportAgendaError: (state) => {
      state.exportAgenda.isLoading = false;
    },
    exportExcel: (state) => {
      state.exportExcel.isLoading = true;
    },
    exportExcelSuccess: (state) => {
      state.exportExcel.isLoading = false;
    },
    exportExcelError: (state) => {
      state.exportExcel.isLoading = false;
    },
    exportScoping: (state) => {
      state.exportScoping.isLoading = true;
    },
    exportScopingSuccess: (state) => {
      state.exportScoping.isLoading = false;
    },
    exportScopingError: (state) => {
      state.exportScoping.isLoading = false;
    },
    exportObservation: (state) => {
      state.exportObservation.isLoading = true;
    },
    exportObservationSuccess: (state) => {
      state.exportObservation.isLoading = false;
    },
    exportObservationError: (state) => {
      state.exportObservation.isLoading = false;
    },
  },
});

export const {
  exportAgenda,
  exportAgendaSuccess,
  exportAgendaError,
  exportExcel,
  exportExcelSuccess,
  exportExcelError,
  exportScoping,
  exportScopingSuccess,
  exportScopingError,
  exportObservation,
  exportObservationSuccess,
  exportObservationError,
} = slice.actions;

export default slice;
