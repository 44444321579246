import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "scopingTableRow",
  initialState: {},
  reducers: {
    registerCustomizableResponseSummary: (state, { payload }) => {
      state[payload] = {
        isEditable: true,
      };
    },
    unregisterCustomizableResponseSummary: (state, { payload }) => {
      delete state[payload];
    },
  },
});

export const {
  registerCustomizableResponseSummary,
  unregisterCustomizableResponseSummary,
} = slice.actions;

export default slice;
