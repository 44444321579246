import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

export const initialState = {
  questionnaireDocument: null,
  loading: false,
  error: null,
  thirdPartiesAddresses: null,
  controlEnvironments: null,
  isRevisedSummarySaving: false,
};

export const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    loadQuestionnaireDocument: (state) => {
      state.questionnaireDocument = null;
      state.loading = true;
    },
    loadQuestionnaireDocumentSuccess: (state, action) => {
      state.loading = false;
      action.payload.questions.forEach((question) => {
        question.isComplete = isQuestionComplete(question);
        question.fields?.forEach((field) => {
          field.questionId = question.questionId;
          field.rules?.operands?.forEach((operand) => {
            operand.questionId = question.questionId;
          });
        });
      });
      const summary = action.payload.controlEnvironmentSummary;
      if (!summary) {
        action.payload.controlEnvironmentSummary = {};
      }
      if (summary && !summary?.isRevisedSummaryOverwritten) {
        summary.revisedSummary = {
          value: null,
        };
      }
      state.questionnaireDocument = action.payload;
      state.error = null;
    },
    loadQuestionnaireDocumentError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setAnswerToQuestions: (state, { payload }) => {
      payload.answers.forEach((answer) => {
        const targetQuestion = state.questionnaireDocument.questions.find(
          (question) => question.questionId === answer.questionId
        );
        targetQuestion.answer = answer.answer;
        targetQuestion.isComplete = answer.isComplete;
        targetQuestion.responseSummary =
          answer.responseSummary || answer.revisedSummary;
        targetQuestion.answeredBy = answer.answer ? payload.answeredBy : null;
        targetQuestion.answeredAt = answer.answer ? new Date().getTime() : null;
      });
    },
    setSummaryToQuestions: (state, { payload }) => {
      payload.answers.forEach((answer) => {
        const targetQuestion = state.questionnaireDocument.questions.find(
          (question) => question.questionId === answer.questionId
        );
        targetQuestion.isComplete = answer.isComplete;
        targetQuestion.responseSummary =
          answer.responseSummary || answer.revisedSummary;
        targetQuestion.answeredBy = answer.answer ? answer.answeredBy : null;
        targetQuestion.answeredAt = answer.answer ? new Date().getTime() : null;
      });
    },
    setRevisedSummary: (state, { payload }) => {
      payload.answers.forEach((answer) => {
        const targetQuestion = state.questionnaireDocument.questions.find(
          (question) => question.questionId === answer.questionId
        );
        targetQuestion.responseSummary = answer.revisedSummary;
        targetQuestion.answeredBy = payload.answeredBy;
        targetQuestion.answeredAt = new Date().getTime();
      });
    },
    confirmAssessmentType: (state) => {
      state.loading = true;
    },
    setQuestionState: (state, { payload }) => {
      if (state.loading) {
        return;
      }
      payload.forEach((question) => {
        const targetQuestion = state.questionnaireDocument.questions.find(
          (scopingQuestion) =>
            scopingQuestion.questionId === question.questionId
        );
        targetQuestion[question.activeRuleType] =
          question[question.activeRuleType];
      });
    },
    setFieldState: (state, { payload }) => {
      const scopingDocument = state.questionnaireDocument;
      payload.forEach((field) => {
        const targetQuestion = scopingDocument.questions.find(
          (question) => question.questionId === field.questionId
        );

        const targetField = targetQuestion.fields.find(
          (questionField) => questionField.name === field.name
        );
        targetField[field.activeRuleType] = field[field.activeRuleType];
        targetQuestion.isComplete = isQuestionComplete(targetQuestion);
      });
    },
    setControlEnvironmentSummary: (state, { payload }) => {
      const controlEnvironmentSummary =
        state.questionnaireDocument.controlEnvironmentSummary;
      controlEnvironmentSummary.isRevisedSummaryOverwritten = !!payload.summary;
      controlEnvironmentSummary.revisedSummary = {
        value: payload.summary,
      };
    },
    loadThirdPartyAddresses: (state) => {
      state.loading = true;
    },
    loadThirdPartyAddressesSuccess: (state, { payload }) => {
      state.loading = false;
      state.thirdPartiesAddresses = payload;
    },
    loadThirdPartyAddressesError: (state) => {
      state.loading = false;
      state.thirdPartiesAddresses = null;
    },
    setQuestionnaireAnswersSuccess: (state, { payload }) => {
      state.questionnaireDocument.bpqMask = payload.bpqMask;
    },
    setDraftSummarySuccess: (state, { payload }) => {
      state.questionnaireDocument.controlEnvironmentSummary.draftSummary =
        payload;
    },
    loadControlEnvironments: () => {},
    loadControlEnvironmentsSuccess: (state, { payload }) => {
      state.controlEnvironments = payload;
    },
    loadControlEnvironmentsError: (state) => {
      state.controlEnvironments = null;
    },
    setIsRevisedSummarySaving: (state, { payload }) => {
      state.isRevisedSummarySaving = payload;
    },
    resetScopingStore: () => initialState,
  },
});

const isQuestionComplete = (question) => {
  let isQuestionComplete = true;
  question.fields.forEach((field) => {
    if (field.optional) {
      return;
    }
    const answerExists = question.answer && question.answer[field.name];
    if (!field.hidden && !answerExists) {
      isQuestionComplete = false;
    }
  });
  return isQuestionComplete;
};

export const {
  loadQuestionnaireDocument,
  loadQuestionnaireDocumentSuccess,
  loadQuestionnaireDocumentError,
  setAnswerToQuestions,
  setSummaryToQuestions,
  confirmAssessmentType,
  setQuestionState,
  setFieldState,
  setControlEnvironmentSummary,
  loadThirdPartyAddresses,
  loadThirdPartyAddressesSuccess,
  loadThirdPartyAddressesError,
  setQuestionnaireAnswersSuccess,
  loadControlEnvironments,
  loadControlEnvironmentsSuccess,
  loadControlEnvironmentsError,
  setDraftSummarySuccess,
  setIsRevisedSummarySaving,
  resetScopingStore,
  setRevisedSummary,
} = slice.actions;

export default slice;
