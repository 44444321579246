import { put, takeLatest, call, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadConnectionDetails,
  loadConnectionDetailsError,
  loadConnectionDetailsSuccess,
} from "./slice";

export function* loadConnectionDetailsSaga({ payload }) {
  const { accessToken, id } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/graph/${id}/connection-details`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();

    yield put(loadConnectionDetailsSuccess(data));
  } catch ({ message }) {
    yield put(loadConnectionDetailsError(message));
  }
}

export default function* connectionDetailsSagas() {
  yield all([
    takeLatest(loadConnectionDetails.type, loadConnectionDetailsSaga),
  ]);
}
