import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isOpen: false,
  selectedCompany: null,
  organizationConnections: null,
  isOrganizationConnectionsLoading: false,
  organizationConnectionsLoadingError: null,
  isSaveRelationshipLoading: false,
};

const slice = createSlice({
  name: "myNetworkPageAddRelationshipModal",
  initialState,
  reducers: {
    setIsAddRelationshipModalOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    loadOrganizationConnections: (state) => {
      state.isOrganizationConnectionsLoading = true;
      state.organizationConnections = null;
    },
    loadOrganizationConnectionsSuccess: (state, action) => {
      state.organizationConnections = action.payload;
      state.isOrganizationConnectionsLoading = false;
    },
    loadOrganizationConnectionsError: (state, action) => {
      state.organizationConnectionsLoadingError = action.payload;
      state.isOrganizationConnectionsLoading = false;
    },
    saveRelationship: (state) => {
      state.isSaveRelationshipLoading = true;
    },
    saveRelationshipSuccess: (state) => {
      state.isSaveRelationshipLoading = false;
    },
    saveRelationshipError: (state) => {
      state.isSaveRelationshipLoading = false;
    },
  },
});

export const {
  setIsAddRelationshipModalOpen,
  setSelectedCompany,
  loadOrganizationConnections,
  loadOrganizationConnectionsSuccess,
  loadOrganizationConnectionsError,
  saveRelationship,
  saveRelationshipSuccess,
  saveRelationshipError,
} = slice.actions;

export default slice;
