import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadCustomerUnmatchedThirdParties,
  loadCustomerThirdPartiesPossibleMatches,
  loadUnmatchedCustomerThirdPartiesError,
  loadUnmatchedCustomerThirdPartiesSuccess,
  loadUnmatchedThirdPartyDetails,
  loadUnmatchedThirdPartyDetailsSuccess,
  loadUnmatchedThirdPartyDetailsError,
  updateUnmatchedThirdPartyDetails,
  updateUnmatchedThirdPartyDetailsSuccess,
  updateUnmatchedThirdPartyDetailsError,
  createCandidateError,
  createCandidate,
  deleteUnmatchedThirdParty,
  deleteUnmatchedThirdPartySuccess,
  deleteUnmatchedThirdPartyError,
  overrideThirdParty,
  overrideThirdPartyError,
  overrideThirdPartySuccess,
  proposeMatch,
  removeProposedMatch,
  createCandidateSuccess,
} from "./slice";
import { setIsDirty } from "services/form/slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";
import { removeThirdPartyWithPendingAction } from "components/UnmatchedThirdParties/services/slice";
import { addRowToRefreshQueue } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/services/slice";
import { UNMATCHED_THIRD_PARTIES_ACTIONS } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/utils/constants";

export function* loadUnmatchedCustomerThirdPartiesSaga({ payload }) {
  const { accessToken, organizationId, setData } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield setData(data);
    yield put(loadUnmatchedCustomerThirdPartiesSuccess());
  } catch ({ message }) {
    yield put(loadUnmatchedCustomerThirdPartiesError(message));
  }
}

export function* loadCustomerThirdPartiesPossibleMatchesSaga({ payload }) {
  const { accessToken, organizationId, thirdPartyId, onSuccess, onFail } =
    payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}/${thirdPartyId}/possible-matches`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        details: data,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.addDetails,
        masterGridId: thirdPartyId,
      })
    );
    if (onSuccess) {
      yield call(onSuccess, data);
    }
  } catch ({ message }) {
    if (onFail) {
      yield call(onFail, message);
    }
  }
}
export function* getUnmatchedCustomerThirdPartiesDetailsSaga({ payload }) {
  const { accessToken, organizationId, thirdPartyId } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}/${thirdPartyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadUnmatchedThirdPartyDetailsSuccess(data));
  } catch ({ message }) {
    yield put(loadUnmatchedThirdPartyDetailsError(message));
  }
}

export function* submitUnmatchedCustomerThirdPartyDetailsUpdateSaga({
  payload,
}) {
  const {
    accessToken,
    organizationId,
    thirdPartyChanges,
    thirdPartyChanges: { thirdPartyId },
    updateSuccessMessage,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}/${thirdPartyId}`,
      {
        method: "PUT",
        body: JSON.stringify(thirdPartyChanges),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to save");
    }

    yield put(setIsDirty({ value: false }));
    yield put(updateUnmatchedThirdPartyDetailsSuccess());
    yield showInfo(updateSuccessMessage);
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.updateDetails,
        masterGridId: thirdPartyId,
        details: thirdPartyChanges,
      })
    );
  } catch ({ message }) {
    yield put(updateUnmatchedThirdPartyDetailsError(message));
    yield showError();
  }
}

export function* createCandidateSaga({ payload }) {
  const { accessToken, successMessage, thirdPartyId } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/candidate-third-parties`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          customerThirdPartyId: thirdPartyId,
        }),
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    yield showInfo(successMessage);
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.createCandidate,
        masterGridId: thirdPartyId,
      })
    );
    yield put(createCandidateSuccess());
  } catch ({ message }) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(createCandidateError());
    yield showError(message);
  }
}

export function* deleteUnmatchedThirdPartySaga({ payload }) {
  const { organizationId, accessToken, thirdPartyId, deleteSuccessMessage } =
    payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}/${thirdPartyId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to delete");
    }

    yield showInfo(deleteSuccessMessage);
    yield put(deleteUnmatchedThirdPartySuccess(thirdPartyId));
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.delete,
        masterGridId: thirdPartyId,
      })
    );
  } catch (error) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(deleteUnmatchedThirdPartyError());
    yield showError();
  }
}

export function* overrideThirdPartySaga({
  payload: {
    accessToken,
    candidateId,
    organizationId,
    suggestedThirdParty,
    thirdPartyId,
    truSightId,
    uploadedThirdParty,
  },
}) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${organizationId}/override`,
      {
        method: "POST",
        body: JSON.stringify({
          candidateId,
          thirdPartyId,
          truSightId,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to match third party.");
    }

    yield put(overrideThirdPartySuccess(thirdPartyId));
    yield showInfo(
      `${uploadedThirdParty} has been matched to ${suggestedThirdParty}.`
    );
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.confirmMatch,
        masterGridId: thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(overrideThirdPartyError(message));
    yield showError(message);
  }
}

export function* proposeMatchSaga({ payload }) {
  const {
    accessToken,
    candidateId,
    thirdPartyId,
    toastDescription,
    truSightId,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${thirdPartyId}/propose`,
      {
        method: "POST",
        body: JSON.stringify({ candidateId, truSightId }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to propose third party.");
    }

    yield showInfo(toastDescription);
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: truSightId || candidateId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.propose,
        masterGridId: thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield showError(message);
  }
}

export function* removeProposedMatchSaga({ payload }) {
  const {
    thirdPartyId,
    truSightId,
    candidateId,
    accessToken,
    toastDescription,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/unmatched/${thirdPartyId}/remove-proposal`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to remove proposed third party.");
    }

    yield showInfo(toastDescription);
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: truSightId || candidateId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.removeProposal,
        masterGridId: thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield showError(message);
  }
}

export default function* manageCustomersUnmatchedThirdPartiesSagas() {
  yield all([
    takeLatest(
      loadCustomerUnmatchedThirdParties.type,
      loadUnmatchedCustomerThirdPartiesSaga
    ),
    takeEvery(
      loadCustomerThirdPartiesPossibleMatches.type,
      loadCustomerThirdPartiesPossibleMatchesSaga
    ),
    takeEvery(deleteUnmatchedThirdParty.type, deleteUnmatchedThirdPartySaga),
    takeEvery(overrideThirdParty.type, overrideThirdPartySaga),
    takeEvery(proposeMatch.type, proposeMatchSaga),
    takeEvery(removeProposedMatch.type, removeProposedMatchSaga),
    takeLatest(
      loadUnmatchedThirdPartyDetails.type,
      getUnmatchedCustomerThirdPartiesDetailsSaga
    ),
    takeLatest(
      updateUnmatchedThirdPartyDetails.type,
      submitUnmatchedCustomerThirdPartyDetailsUpdateSaga
    ),
    takeEvery(createCandidate.type, createCandidateSaga),
  ]);
}
