import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import escape from "escape-html";

import {
  setIsBulkPurchaseModalOpen,
  setIsPurchaseSubmitting,
  submitAssessmentPurchase,
} from "./slice";
import { setPurchasedForListItem as setPurchasedAssessmentForCatalog } from "pages/CatalogPage/services/slice";
import { setPurchasedForListItem as setPurchasedAssessmentForMyThirdParties } from "pages/MyListPage/MyThirdPartiesPage/services/slice";
import { setPurchasedStatusForSelected } from "components/BulkAssessmentActions/services/slice";
import { addToast } from "services/toast/slice";
import routes from "app/routes";
import { showError } from "utils/sagaEffects/sagaEffects";

function* submitAssessmentPurchaseSaga({ payload }) {
  try {
    yield put(setIsPurchaseSubmitting(true));
    const { accessToken, data, selectedAssessmentsCount, assessmentsNames } =
      payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/catalog/purchase`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = yield response.json();
    if (
      response.status !== 200 ||
      (responseData.purchases.length === 0 && responseData.errors.length !== 0)
    ) {
      throw new Error("Failed to post");
    }

    const successfulPurchaseCount = responseData.purchases.length;
    let description;
    if (successfulPurchaseCount === 1) {
      const purchasedAssessmentName = assessmentsNames.find(
        (assessment) => assessment.assessmentId === responseData.purchases[0].id
      ).assessmentName;
      description = `Order has been placed for ${escape(
        purchasedAssessmentName
      )}.`;
    } else {
      description = `Order has been placed for ${successfulPurchaseCount} of the ${selectedAssessmentsCount} selected assessments.`;
    }
    const toastInfo = {
      description,
      hasButton: true,
      buttonText: "View Purchases",
      isLink: true,
      to: routes.purchased.path,
    };
    yield put(addToast(toastInfo));
    yield put(setIsPurchaseSubmitting(false));
    yield put(setIsBulkPurchaseModalOpen(false));
    yield put(
      setPurchasedAssessmentForCatalog({ purchases: responseData.purchases })
    );
    yield put(
      setPurchasedAssessmentForMyThirdParties({
        purchases: responseData.purchases,
      })
    );
    yield put(
      setPurchasedStatusForSelected({ purchases: responseData.purchases })
    );
  } catch ({ message }) {
    yield showError();
    yield put(setIsPurchaseSubmitting(false));
    yield put(setIsBulkPurchaseModalOpen(false));
  }
}

export default function* purchaseAssessmentSaga() {
  yield all([
    yield takeLatest(
      submitAssessmentPurchase.type,
      submitAssessmentPurchaseSaga
    ),
  ]);
}
