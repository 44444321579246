import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectPurchaseAssessment = (state) => state[slice.name];

export const selectAssessmentsToPurchase = createSelector(
  [selectPurchaseAssessment],
  (slice) => slice.purchaseAssessmentDetails
);

export const selectIsPurchaseSubmitting = createSelector(
  [selectPurchaseAssessment],
  (slice) => slice.isPurchaseSubmitting
);

export const selectIsBulkPurchaseModalOpen = createSelector(
  [selectPurchaseAssessment],
  (slice) => slice.isBulkPurchaseModalOpen
);
