import { defineMessages } from "react-intl";

const scope = "components.assessmentDetails.watchButton.";

export default defineMessages({
  watching: {
    id: `${scope}watching`,
    defaultMessage: "Watching Assessment",
  },
  watch: {
    id: `${scope}watch`,
    defaultMessage: "Watch Assessment",
  },
});
