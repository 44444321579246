import { createSlice } from "@reduxjs/toolkit";
import { uniq, uniqBy, isEqual } from "lodash";

import {
  TAG_COLLECTION_NAMESPACE,
  TAG_COLLECTION_REDUCER_KEYS,
} from "pages/TemplatesAndMappingPage/TagCollectionPage/utils/constants";

const initialState = {
  data: null,
  domainCategories: null,
  initialTagCollection: null,
  modals: {
    tagCollectionSettingsModal: {
      isOpen: false,
      isSaving: false,
    },
    saveTagCollectionModal: {
      isOpen: false,
      isSaving: false,
    },
    cancelTagCollectionChangesModal: {
      isOpen: false,
    },
  },
  loading: false,
  error: null,
  saving: false,
  editableTitleHasErrors: false,
  changesDetected: false,
  tagCollectionChangesDetected: false,
};

const slice = createSlice({
  name: TAG_COLLECTION_NAMESPACE,
  initialState,
  reducers: {
    loadTagCollection: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadTagCollectionSuccess: (state, { payload }) => {
      state.domainCategories = uniqBy(payload.questions, "domainCategory");
      state.loading = false;
      state.data = payload;
      state.initialTagCollection = payload;
      state.error = null;
    },
    loadTagCollectionError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setTagCollectionSettingsModalOpen: (state, { payload }) => {
      state.modals.tagCollectionSettingsModal.isOpen = payload;
    },
    saveTagCollectionSettings: (state) => {
      state.modals.tagCollectionSettingsModal.isSaving = true;
    },
    saveTagCollectionSettingsSuccess: (state, { payload }) => {
      state.data.isPublished = payload.isPublished;
      state.data.description = payload.description;
      state.data.modifiedOn = payload.modifiedOn;
      state.modals.tagCollectionSettingsModal.isSaving = false;
      state.modals.tagCollectionSettingsModal.isOpen = false;
    },
    saveTagCollectionSettingsError: (state) => {
      state.modals.tagCollectionSettingsModal.isSaving = false;
    },
    setEditableTitleErrorState: (state, { payload }) => {
      state.editableTitleHasErrors = payload;
    },
    setNewTitle: (state, { payload }) => {
      state.data.name = payload;
    },
    setSaveTagCollectionModalOpen: (state, { payload }) => {
      state.modals.saveTagCollectionModal.isOpen = payload;
    },
    saveTagCollection: (state) => {
      state.modals.saveTagCollectionModal.isSaving = true;
    },
    saveTagCollectionSuccess: (state, { payload }) => {
      state.modals.saveTagCollectionModal.isSaving = false;
      state.data.isPublished = payload.isPublished;
      state.changesDetected = false;
      state.data.modifiedOn = payload.modifiedOn;
      state.initialTagCollection = state.data;
    },
    saveTagCollectionError: (state) => {
      state.modals.saveTagCollectionModal.isSaving = false;
    },
    [TAG_COLLECTION_REDUCER_KEYS.cancelTagCollectionChanges]: (state) => {
      state.data = state.initialTagCollection;
      state.tagCollectionChangesDetected = false;
    },
    setCancelTagCollectionChangesModalOpen: (state, { payload }) => {
      state.modals.cancelTagCollectionChangesModal.isOpen = payload;
    },
    setTagToQuestion: (state, { payload }) => {
      if (payload.serialId) {
        let questionToSetTags;
        state.data.questions.forEach((question) => {
          if (question.serialId === payload.serialId) {
            questionToSetTags = question;
          } else {
            const foundItem = question.checklist?.find(
              (item) => item.serialId === payload.serialId
            );
            if (foundItem) {
              questionToSetTags = foundItem;
              if (!question.tags) {
                question.tags = [];
              }
            }
          }
        });
        if (questionToSetTags) questionToSetTags.tags = payload.tags;
      } else {
        const questions = state.data.questions.filter(
          (question) => question.domain === payload.domain
        );
        questions.forEach((question) => {
          question.tags = question.tags
            ? uniq([...question.tags, ...payload.tags])
            : payload.tags;
          question.checklist?.forEach(
            (item) =>
              (item.tags = item.tags
                ? uniq([...item.tags, ...payload.tags])
                : payload.tags)
          );
        });
      }
      state.tagCollectionChangesDetected = !isEqual(
        state.data,
        state.initialTemplate
      );
    },
    resetTagCollectionStore: () => initialState,
    showTagError: () => {},
  },
});

export const {
  loadTagCollection,
  loadTagCollectionSuccess,
  loadTagCollectionError,
  setTagCollectionSettingsModalOpen,
  saveTagCollectionSettings,
  saveTagCollectionSettingsSuccess,
  saveTagCollectionSettingsError,
  resetTagCollectionStore,
  setCancelTagCollectionChangesModalOpen,
  cancelTagCollectionChanges,
  setEditableTitleErrorState,
  setNewTitle,
  setSaveTagCollectionModalOpen,
  saveTagCollection,
  saveTagCollectionSuccess,
  saveTagCollectionError,
  setTagToQuestion,
  showTagError,
} = slice.actions;

export default slice;
