import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  loading: false,
  error: null,
  exportPossibleMatchesInProgress: false,
};

const slice = createSlice({
  name: "manageCustomersPage",
  initialState,
  reducers: {
    loadCustomersList: (state) => {
      state.data = null;
      state.loading = true;
    },
    setCustomersListSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    setCustomersListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCustomersList: () => initialState,
    exportPossibleMatches: (state) => {
      state.exportPossibleMatchesInProgress = true;
    },
    exportPossibleMatchesCompleted: (state) => {
      state.exportPossibleMatchesInProgress = false;
    },
  },
});

export const {
  loadCustomersList,
  setCustomersListSuccess,
  setCustomersListError,
  resetCustomersList,
  exportPossibleMatches,
  exportPossibleMatchesCompleted,
} = slice.actions;

export default slice;
