import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "thirdPartyDetailsDrawer",
  initialState: {
    isOpen: false,
    thirdPartyDetails: null,
    loading: false,
    error: null,
    formSavePending: false,
    formSaveError: null,
  },
  reducers: {
    loadThirdPartyDetailsSuccess: (state, { payload }) => {
      state.thirdPartyDetails = { ...payload };
      state.error = null;
      state.loading = false;
    },
    loadThirdPartyDetails: (state) => {
      state.error = null;
      state.thirdPartyDetails = null;
      state.loading = true;
    },
    loadThirdPartyDetailsError: (state, { payload }) => {
      state.error = payload;
      state.thirdPartyDetails = null;
      state.loading = false;
    },
    setThirdPartyDetails: (state, action) => {
      state.thirdPartyDetails = action.payload;
    },
    updateThirdPartyDetails: (state) => {
      state.formSavePending = true;
    },
    updateThirdPartyDetailsSuccess: (state) => {
      state.formSavePending = false;
      state.formSaveError = null;
    },
    updateThirdPartyDetailsError: (state, { payload }) => {
      state.formSavePending = false;
      state.formSaveError = payload;
    },
    setIsDrawerOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});

export const {
  loadThirdPartyDetails,
  loadThirdPartyDetailsSuccess,
  loadThirdPartyDetailsError,
  setThirdPartyDetails,
  updateThirdPartyDetails,
  updateThirdPartyDetailsSuccess,
  updateThirdPartyDetailsError,
  setIsDrawerOpen,
} = slice.actions;
export default slice;
