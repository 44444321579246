import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectState = (state) => state[slice.name];

export const selectLoading = createSelector(
  [selectState],
  (state) => state.loading
);

export const selectError = createSelector(
  [selectState],
  (state) => state.error
);

export const selectQuestionnaireDocument = createSelector(
  [selectState],
  (state) => state.questionnaireDocument
);

export const selectThirdPartyAddresses = createSelector(
  [selectState],
  (state) => state.thirdPartiesAddresses
);

export const selectControlEnvironments = createSelector(
  [selectState],
  (state) => state.controlEnvironments
);

export const selectIsRevisedSummarySaving = createSelector(
  [selectState],
  (state) => state.isRevisedSummarySaving
);
