import React from "react";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { selectIsUploadThirdPartiesModalOpen } from "pages/ManageCustomerPage/UnmatchedAdminPage/services/selectors";
import routes from "app/routes";
import Button from "components/GlobalComponents/Buttons/Button";
import UploadThirdParties from "components/UploadThirdPartiesModal/UploadThirdParties";
import { setMyListActiveTable } from "utils/myListActiveSessionStorage/myListActiveSessionStorage";
import { buttonTypes } from "components/GlobalComponents/Buttons/Button/Button";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";
import { setShouldUnmatchedThirdPartiesReload } from "pages/MyListPage/UnmatchedPage/services/slice";
import { setUploadThirdPartiesModalOpen } from "pages/ManageCustomerPage/UnmatchedAdminPage/services/slice";
import myListMessages from "../../messages";
import "pages/MyListPage/my-list-navigation.scss";

const MyListNavigation = () => {
  Modal.setAppElement("#root");

  const intl = useIntl();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const permissions = useSelector(selectPermissions);
  const isUploadThirdPartiesModalOpen = useSelector(
    selectIsUploadThirdPartiesModalOpen
  );
  const isUploadFeatureEnabled =
    process.env.REACT_APP_FEATURE_CUSTOMER_TP_UPLOAD === "true";

  const checkActiveLink = (currentLink, activeLink) => {
    return currentLink === activeLink;
  };

  if (!pathname.includes(routes.myList.path)) {
    return null;
  }

  const setIsModalOpen = (isOpen) => {
    dispatch(setUploadThirdPartiesModalOpen(isOpen));
  };

  return (
    <>
      <div className="my-list-page__secondary-controls">
        <div className="my-list-page__secondary-navigation">
          {!routes.myListMyThirdParties.disabled && (
            <Button
              className="my-list-page__btn-tab"
              isLink
              to={routes.myListMyThirdParties.path}
              isActive={checkActiveLink(
                routes.myListMyThirdParties.path,
                pathname
              )}
              onClick={() =>
                setMyListActiveTable(routes.myListMyThirdParties.path)
              }
              buttonType={buttonTypes.tab}
            >
              {intl.formatMessage(myListMessages.allThirdParties)}
            </Button>
          )}
          {!routes.myListRequests.disabled && (
            <Button
              className="my-list-page__btn-tab"
              isLink
              to={routes.myListRequests.path}
              isActive={checkActiveLink(routes.myListRequests.path, pathname)}
              onClick={() => setMyListActiveTable(routes.myListRequests.path)}
              buttonType={buttonTypes.tab}
            >
              {intl.formatMessage(myListMessages.requests)}
            </Button>
          )}
          {!routes.myListUnmatched.disabled && (
            <Button
              className="my-list-page__btn-tab"
              isLink
              to={routes.myListUnmatched.path}
              isActive={checkActiveLink(routes.myListUnmatched.path, pathname)}
              onClick={() => setMyListActiveTable(routes.myListUnmatched.path)}
              buttonType={buttonTypes.tab}
            >
              {intl.formatMessage(myListMessages.unmatched)}
            </Button>
          )}
        </div>
        {permissions &&
          permissions.includes(PERMISSIONS.CanUploadCsv) &&
          isUploadFeatureEnabled && (
            <Button
              dataTestIdPrefix={"add-third-parties"}
              className="my-list-page__btn-tab"
              onClick={() => setIsModalOpen(true)}
              buttonType={buttonTypes.tab}
            >
              {intl.formatMessage(myListMessages.addThirdParties)}
            </Button>
          )}
      </div>
      <UploadThirdParties
        isOpen={isUploadThirdPartiesModalOpen}
        setIsOpen={setIsModalOpen}
        parentPagePath={routes.myListUnmatched.path}
        uploadThirdPartiesTitle={intl.formatMessage(
          myListMessages.uploadThirdPartiesTitle
        )}
        uploadThirdPartiesSubTitle={intl.formatMessage(
          myListMessages.uploadThirdPartiesSubTitle
        )}
        reloadUnmatchedThirdPartiesReducer={
          setShouldUnmatchedThirdPartiesReload
        }
      />
    </>
  );
};

export default MyListNavigation;
