import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  dataList: null,
  dataGraph: null,
  loading: false,
  error: null,
  myOrganizationName: null,
};

const slice = createSlice({
  name: "myNetworkPage",
  initialState,
  reducers: {
    loadOrganizationName: (state) => {
      state.myOrganizationName = null;
    },
    loadOrganizationNameSuccess: (state, action) => {
      state.myOrganizationName = action.payload;
    },
    loadOrganizationNameError: (state, action) => {
      state.myOrganizationName = null;
      state.error = action.payload;
    },
    loadMyNetwork: (state) => {
      state.dataList = null;
      state.loading = true;
    },
    loadGraphData: (state) => {
      state.dataGraph = null;
      state.loading = true;
    },
    loadMyNetworkSuccess: (state, action) => {
      state.loading = false;
      state.dataList = action.payload;
      state.error = null;
    },
    loadGraphDataSuccess: (state, action) => {
      state.loading = false;
      state.dataGraph = action.payload;
      state.error = null;
    },
    loadMyNetworkError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loadGraphDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addNewConnectionToMyNetwork: (state, action) => {
      if (state.dataList) {
        let isConnectionAlreadyInList = false;

        state.dataList.forEach((data) => {
          if (data.id === action.payload.id) {
            isConnectionAlreadyInList = true;
          }
        });

        if (isConnectionAlreadyInList) {
          state.dataList = state.dataList.map((data) => {
            if (data.id === action.payload.id) {
              return {
                ...data,
                connections: [
                  ...data.connections,
                  ...action.payload.connections,
                ],
              };
            }

            return data;
          });
          return;
        }

        state.dataList = [...state.dataList, action.payload];
        return;
      }

      state.dataList = [action.payload];
    },
    addNewConnectionToGraphData: (state, action) => {
      let isNodeAlreadyInGraphData = false;

      state.dataGraph.nodes.forEach((data) => {
        if (data.id === action.payload.node.id) {
          isNodeAlreadyInGraphData = true;
        }
      });

      if (!isNodeAlreadyInGraphData) {
        state.dataGraph.nodes = [...state.dataGraph.nodes, action.payload.node];
      }

      state.dataGraph.edges = [...state.dataGraph.edges, action.payload.edge];
    },
  },
});

export const {
  loadMyNetwork,
  loadMyNetworkSuccess,
  loadMyNetworkError,
  loadGraphData,
  loadGraphDataSuccess,
  loadGraphDataError,
  loadOrganizationName,
  loadOrganizationNameSuccess,
  loadOrganizationNameError,
  addNewConnectionToMyNetwork,
  addNewConnectionToGraphData,
} = slice.actions;

export default slice;
