import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  unmatchedPartiesLoading: false,
  unmatchedPartiesError: null,
  shouldUnmatchedThirdPartiesReload: false,
  hasUnmatchedThirdPartiesLoaded: false,
  errorUseThirdParty: false,
  unmatchedVendorInfo: null,
  unmatchedVendorInfoError: null,
  unmatchedVendorInfoLoading: false,
  requestAssessmentError: null,
  requestAssessmentLoading: false,
  matchedVendorInfo: {},
  displayUploadedVendorInfo: true,
  displayPossibleMatchVendorInfo: false,
  drawers: {
    vendorInfoDrawer: {
      isOpen: false,
    },
  },
  modals: {
    deleteVendorModal: {
      isOpen: false,
      isLoading: false,
    },
    modalData: null,
  },
  formSavePending: false,
  formSaveError: null,
};

const slice = createSlice({
  name: "customerUnmatchedThirdPartiesPage",
  initialState,
  reducers: {
    loadUnmatchedThirdParties: (state) => {
      state.unmatchedPartiesLoading = true;
      state.unmatchedPartiesError = null;
      state.shouldUnmatchedThirdPartiesReload = false;
    },
    loadUnmatchedThirdPartiesSuccess: (state) => {
      state.unmatchedPartiesLoading = false;
      state.unmatchedPartiesError = null;
      state.hasUnmatchedThirdPartiesLoaded = true;
    },
    loadUnmatchedThirdPartiesError: (state, { payload }) => {
      state.unmatchedPartiesLoading = false;
      state.unmatchedPartiesError = payload;
    },
    setShouldUnmatchedThirdPartiesReload: (state, { payload }) => {
      state.shouldUnmatchedThirdPartiesReload = payload;
    },
    loadUnmatchedThirdPartiesPossibleMatches: () => {},
    selectThirdParty: (state) => {
      state.errorUseThirdParty = false;
    },
    setErrorUseThisParty: (state, { payload }) => {
      state.errorUseThirdParty = payload;
    },
    loadUnmatchedThirdPartyDetails: (state) => {
      state.unmatchedVendorInfoError = null;
      state.unmatchedVendorInfo = null;
      state.unmatchedVendorInfoLoading = true;
    },
    loadUnmatchedThirdPartyDetailsSuccess: (state, { payload }) => {
      state.unmatchedVendorInfoError = null;
      state.unmatchedVendorInfo = payload;
      state.unmatchedVendorInfoLoading = false;
    },
    loadUnmatchedThirdPartyDetailsError: (state, { payload }) => {
      state.unmatchedVendorInfoError = payload;
      state.unmatchedVendorInfo = null;
      state.unmatchedVendorInfoLoading = false;
    },
    setPossibleMatchVendorInfo: (state, { payload }) => {
      state.matchedVendorInfo = payload;
    },
    setParentVendorInfo: (state, { payload }) => {
      state.matchedVendorInfo.matchedThirdPartyId = payload.matchedThirdPartyId;
      state.matchedVendorInfo.uploadedName = payload.uploadedName;
    },
    setDisplayUploadedVendorInfo: (state, { payload }) => {
      state.displayUploadedVendorInfo = payload;
    },
    setDisplayPossibleMatchVendorInfo: (state, { payload }) => {
      state.displayPossibleMatchVendorInfo = payload;
    },
    deleteUnmatchedThirdParty: (state) => {
      state.modals.deleteVendorModal.isLoading = true;
    },
    deleteUnmatchedThirdPartySuccess: (state) => {
      state.modals.deleteVendorModal.isLoading = false;
      state.modals.deleteVendorModal.isOpen = false;
    },
    deleteUnmatchedThirdPartyError: (state) => {
      state.modals.deleteVendorModal.isLoading = false;
    },
    updateUnmatchedThirdPartyDetails: (state) => {
      state.formSavePending = true;
    },
    updateUnmatchedThirdPartyDetailsSuccess: (state) => {
      state.formSavePending = false;
      state.formSaveError = null;
    },
    updateUnmatchedThirdPartyDetailsError: (state, { payload }) => {
      state.formSavePending = false;
      state.formSaveError = payload;
    },
    setVendorInfoDrawerOpen: (state, { payload }) => {
      state.drawers.vendorInfoDrawer.isOpen = payload;
    },
    setDeleteVendorModalOpen: (state, { payload }) => {
      state.modals.deleteVendorModal.isOpen = payload;
    },
    setModalData: (state, { payload }) => {
      state.modals.modalData = payload;
    },
    requestAssessment: (state) => {
      state.requestAssessmentError = null;
      state.requestAssessmentLoading = true;
    },
    requestAssessmentSuccess: (state) => {
      state.requestAssessmentError = null;
      state.requestAssessmentLoading = false;
    },
    requestAssessmentError: (state, action) => {
      state.requestAssessmentError = action.payload;
      state.requestAssessmentLoading = false;
    },
    setUnmatchedThirdPartiesLoaded: (state, action) => {
      state.hasUnmatchedThirdPartiesLoaded = action.payload;
    },
    resetCustomerUnmatchedThirdPartiesPage: () => initialState,
  },
});

export const {
  loadUnmatchedThirdParties,
  loadUnmatchedThirdPartiesSuccess,
  loadUnmatchedThirdPartiesError,
  loadUnmatchedThirdPartiesPossibleMatches,
  loadUnmatchedThirdPartyDetails,
  loadUnmatchedThirdPartyDetailsSuccess,
  loadUnmatchedThirdPartyDetailsError,
  selectThirdParty,
  setErrorUseThisParty,
  setDisplayUploadedVendorInfo,
  setPossibleMatchVendorInfo,
  setParentVendorInfo,
  setDisplayPossibleMatchVendorInfo,
  deleteUnmatchedThirdParty,
  deleteUnmatchedThirdPartySuccess,
  deleteUnmatchedThirdPartyError,
  updateUnmatchedThirdPartyDetails,
  updateUnmatchedThirdPartyDetailsSuccess,
  updateUnmatchedThirdPartyDetailsError,
  setVendorInfoDrawerOpen,
  setDeleteVendorModalOpen,
  setModalData,
  requestAssessment,
  requestAssessmentSuccess,
  requestAssessmentError,
  setShouldUnmatchedThirdPartiesReload,
  setUnmatchedThirdPartiesLoaded,
  resetCustomerUnmatchedThirdPartiesPage,
} = slice.actions;

export default slice;
