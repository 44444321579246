import {
  SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
} from "app/../../tools/intl/locales";
export { SUPPORTED_LOCALES, DEFAULT_LOCALE };

export const ASSESSMENT_DOWNLOAD_STATUS_TYPES = {
  preOrdered: "PreOrdered ",
  inProgress: "InProgress",
  done: "Done",
  failed: "Failed",
};

export const ASSESSMENT_AUTHORIZATION_STATUSES = {
  authorized: "Authorized",
  pending: "Pending",
  rejected: "Rejected",
};

export const ASSESSMENT_ICON_LABELS = {
  unAuthorized: "Assessment authorization is declined",
  authorizationPending: "Order pending",
  authorizationMissing: "Authorization missing, contact support",
  authorizationRejected: "Authorization declined",
  download: "Download assessment files",
  productUnavailable: "Product unavailable",
  watched: "Assessment is in your watched list",
  notWatched: "Assessment is not in your watched list",
  goToPurchases: "Go to Purchases",
  inProgress:
    "The product is currently being generated. It will be automatically downloaded once it’s ready.",
  ky3pProduct: "Available in KY3P",
};

export const ASSESSMENT_ICON_TOOLTIPS = {
  authorizationPending: ASSESSMENT_ICON_LABELS.authorizationPending,
  authorizationRejected: ASSESSMENT_ICON_LABELS.authorizationRejected,
  authorizationMissing: ASSESSMENT_ICON_LABELS.authorizationMissing,
  download: ASSESSMENT_ICON_LABELS.download,
  productUnavailable: ASSESSMENT_ICON_LABELS.productUnavailable,
  notWatched: "Watch assessment",
  watched: "Stop watching",
  goToPurchases: ASSESSMENT_ICON_LABELS.goToPurchases,
  inProgress: ASSESSMENT_ICON_LABELS.inProgress,
  ky3pProduct: "Available in KY3P",
};

export const ASSESSMENT_ICON_TYPES = {
  alert: "alert",
  download: "download",
  notWatched: "notWatched",
  shield: "shield",
  watched: "watched",
  inProgress: "inProgress",
  ky3pProduct: "Available in KY3P",
};

export const ASSESSMENT_ICON_OPTIONS = [
  { label: "Watching", value: ASSESSMENT_ICON_TYPES.watched },
  { label: "Order Authorized", value: ASSESSMENT_ICON_TYPES.download },
  { label: "Product Unavailable", value: ASSESSMENT_ICON_TYPES.alert },
  { label: "Order Pending", value: ASSESSMENT_ICON_TYPES.shield },
];

export const FIELD_TYPES = {
  datePicker: "DatePicker",
  textArea: "TextArea",
  select: "Select",
};

export const CLASSIFIERS = {
  controlEnvironment: "ControlEnvironment",
  serviceCategoryL1: "ServiceCategoryL1",
  serviceCategoryL2: "ServiceCategoryL2",
  assessmentStatus: "AssessmentStatus",
  country: "Country",
  peerReviewFlagType: "PeerReviewFlagType",
  peerReviewFlagStatus: "PeerReviewFlagStatus",
  flagMetric: "FlagMetric",
  qcFlagStatus: "qcFlagStatus",
  thematicFlagStatus: "ThematicFlagStatus",
  state: "State",
};

export const ADDRESS_INPUT_PREFIXES = {
  label: "label_",
  website: "website_",
  phoneNumber: "phoneNumber_",
};

export const DOCUMENT_TYPES = {
  scoping: 2,
  bpq: 3,
};

export const CONTAINER_EXPAND_MODES = {
  single: "Single",
  multiple: "Multiple",
};

export const ORGANIZATION_TYPES = {
  none: "None",
  externalAssessor: "ExternalAssessor",
  thirdParty: "ThirdParty",
  truSight: "TruSight",
  founder: "Founder",
  customer: "Customer",
};

export const BPQ_ANSWERS = {
  notAnswered: null,
  yes: "Yes",
  no: "No",
  notApplicable: "Not Applicable",
};

export const BPQ_ANSWER_TYPES = {
  checkboxes: "checkboxes",
  select: "select",
  textarea: "textarea",
};

export const CELL_STATES = {
  incomplete: "Incomplete",
  complete: "Complete",
  error: "Error",
};

export const QC_VALIDATION_TEST_RESULTS = {
  pass: "Pass",
  fail: "Fail",
  notApplicable: "Not applicable",
  error: "Error",
};

export const QC_VALIDATION_TEST_RESULTS_ICONS = {
  green: "Green",
  yellow: "Yellow",
  red: "Red",
};

export const QUESTIONNAIRE_RULES_OPERATORS = {
  and: "AND",
  or: "OR",
};

export const QUESTIONNAIRE_RULE_TYPES = {
  disabled: "disable",
  notApplicable: "not applicable",
  hidden: "hidden",
  show: "show",
  required: "require",
};

export const ACCESSIBILITY_PHRASES = {
  bpqQuestionScreenReaderText: "Press Enter or Space to see required",
  tableCellDisabled: "Disabled cell",
  tableCellEditable: "Editable cell",
  tableCellEditableRequired: "Required editable cell",
  tableCellReadOnly: "",
  tableCellClickable: "Press Enter or Space to interact with cell",
  tableCellHasDownloadableArtifacts: "Press D to download attached Artifacts",
  tableCellDropdownClickable: "Press Enter or Space to open a dropdown menu",
  tableCellArtifactClickable:
    "Press Enter or Space to open artifact upload modal",
  tableCellArtifactRequired:
    "Artifacts required. Press Enter or Space to open artifact upload modal",
  tableCellAssessmentReviewClickable:
    "Press Enter or Space to open assessment review (comment builder) drawer",
  tableCellThirdpartyResponseClickable:
    "Press Enter or Space to open third party response drawer",
  tableCellQcFlagClickable: "Press Enter or Space to open QC flag drawer",
  tableCellAuthorizationStatusClickable:
    "Press Enter or Space to open authorization dropdown menu",
  tableCellTemplateActionsClickable:
    "Press B to toggle Actions and Space to submit",
};

export const OBSERVATION = {
  noObservationNoted: "No Observation Noted",
  controlGap: "Control Gap",
  evidenceGap: "Evidence Gap",
  designGap: "Design Gap",
  notApplicable: "Not Applicable",
  noEntry: "No Observation",
};

export const OBSERVATION_CELL_OPTIONS = Object.values(OBSERVATION).filter(
  (observation) => observation !== OBSERVATION.noEntry
);

export const OBSERVATION_FILTER_OPTIONS = Object.values(OBSERVATION).map(
  (observation) => ({
    label: observation,
    value: observation,
  })
);

// Don't change order as filter depends on it!
export const NOTES_COLUMN_OPTIONS = [
  { label: "Note Added", value: "Note Added" },
  { label: "No Note Added", value: "No Note Added" },
];

export const INPUT_LENGTH_RESTRICTIONS = {
  scopeOfServices: 1500,
  medium: 400,
  fileNameMax: 255,
  name: 250,
  firstName: 40,
  lastName: 80,
  alias: 250,
  address: 250,
  url: 250,
  inherentRisk: 50,
  trusightId: 50,
  internalId: 50,
  country: 50,
  city: 50,
  state: 50,
  dunsId: 15,
  zipCode: 15,
  tagName: 250,
  templatesAndCollectionsTitle: 100,
  title: 128,
  email: 80,
  location: 255,
  tagCollectionName: 100,
  customTemplateTitle: 100,
  thirdPartiesLookUp: 400,
};

export const TEXT_AREA_LENGTH_RESTRICTIONS = {
  long: 4000,
  short: 200,
  templatesAndCollectionsDescription: 300,
};

export const BPQ_FIELDS = {
  mainAnswer: "mainAnswer",
  additionalComments: "additionalComments",
  evidence: "evidence",
  assessmentReview: "assessmentReview",
  observation: "observation",
  internalNotes: "internalNotes",
  sharedNotes: "sharedNotes",
  thirdPartyResponse: "thirdPartyResponse",
};

export const BPQ_COLUMNS = {
  visibilitySettings: "visibilitySettings",
  questionId: "label",
  question: "question",
  mainAnswer: "mainAnswer",
  comments: "comments",
  artifacts: "artifacts",
  assessmentReview: "assessmentReview",
  observation: "observation",
  internalNotes: "internalNotes",
  peerReview: "peerReview",
  sharedNotes: "sharedNotes",
  thirdPartyResponse: "thirdPartyResponse",
  qcFlag: "qcFlag",
};

export const REDACTION_FIELDS = {
  BPQ: [BPQ_FIELDS.additionalComments],
};

export const QUESTION_REVIEW_FIELDS = {
  revisedComment: "revisedComment",
  revisedMainAnswer: "revisedMainAnswer",
};

export const EVIDENCE_TYPES = { uploaded: "file", private: "private" };

export const ASSESSOR_REVIEW_FIELDS = {
  assessorWorkSteps: "assessorWorkSteps",
  validationSteps: "validationSteps",
  customerResponsibility: "customerResponsibility",
  checklist: "checklist",
};

export const ASSESSMENT_REVIEW_TYPES = {
  incomplete: "Incomplete",
  complete: "Complete",
  overridden: "Overridden",
  notStarted: "No Assessment Review",
};

export const PEER_REVIEW_STATUSES = {
  Open: "Open",
  Disputed: "Disputed",
  Resolved: "Resolved",
  Removed: "Removed",
  Closed: "Closed",
  NoPeerReview: "No Peer Review",
};

export const TP_RESPONSE = {
  Disputed: "Disputed",
  Acknowledged: "Acknowledged",
  Remediated: "Remediated",
  NoTPResponse: "No TP Response",
};

export const MAIN_ANSWER_OPTION = {
  Yes: "Yes",
  No: "No",
  NA: "N/A",
  Other: "Other",
  NoAnswer: "No Answer",
};

export const MAIN_ANSWER_RADIO_OPTIONS = [
  MAIN_ANSWER_OPTION.Yes,
  MAIN_ANSWER_OPTION.No,
  MAIN_ANSWER_OPTION.NA,
];

export const ASSESSMENT_REVIEW_TYPE_FILTER_OPTIONS = Object.values(
  ASSESSMENT_REVIEW_TYPES
).map((value) => ({
  label: value,
  value: value,
}));

export const PEER_REVIEW_STATUS_FILTER_OPTIONS = Object.values(
  PEER_REVIEW_STATUSES
).map((value) => ({
  label: value,
  value: value,
}));

export const TP_RESPONSE_FILTER_OPTIONS = Object.values(TP_RESPONSE).map(
  (value) => ({
    label: value,
    value: value,
  })
);

export const MAIN_ANSWER_FILTER_OPTIONS = Object.values(MAIN_ANSWER_OPTION).map(
  (value) => ({
    label: value,
    value: value,
  })
);

export const BPQ_DRAWER_TYPES = {
  assessmentReview: "Assessment Review",
  thirdPartyResponse: "Third Party Response",
};

export const FLAG_STATUSES = {
  open: "Open",
  disputed: "Disputed",
  resolved: "Resolved",
  removed: "Removed",
  closed: "Closed",
};

export const QC_FLAGS_FILTER_OPTIONS = Object.values(FLAG_STATUSES)
  .map((value) => ({
    label: value,
    value: value,
  }))
  .concat([
    {
      label: "No QC Flag",
      value: null,
    },
  ]);

export const TP_RESPONSE_ACTIONABLE_TYPE = "Disputed";

export const THEMATIC_FLAGS = {
  open: "Open",
};

export const NO_FLAG = "No flag";

export const PRODUCT_TYPES = {
  enhancedComprehensive: "Enhanced Comprehensive",
  comprehensive: "Comprehensive",
  standard: "Standard",
  keyControls: "Key Controls",
  fmifmu: "FMI-FMU",
  enhancedComprehensivePlusProductType: "Enhanced Comprehensive Plus",
  comprehensivePlusProductType: "Comprehensive Plus",
  enhancedDesktopProductType: "Enhanced Desktop",
  desktopProductType: "Desktop",
  expressPlusProductType: "Express Plus",
  customProductType: "Custom",
  ddqOnlyProductType: "DDQ Only",
  verifiedBpqProductType: "Verified Bpq",
};

export const PRODUCT_TYPES_WITH_TEMPLATES = {
  enhancedComprehensive: PRODUCT_TYPES.enhancedComprehensive,
  comprehensive: PRODUCT_TYPES.comprehensive,
  customizable: "Customizable",
};

export const GLOBAL_TEMPLATE_SORT_ORDER = [
  PRODUCT_TYPES.enhancedComprehensive,
  PRODUCT_TYPES.comprehensive,
  PRODUCT_TYPES.standard,
  PRODUCT_TYPES.keyControls,
  PRODUCT_TYPES_WITH_TEMPLATES.customizable,
];

export const ROUTE_TITLES = {
  OVERVIEW: "Product Overview",
  SETTINGS: "Settings & Integrations",
  TEMPLATES: "Templates & Tags",
  CUSTOM_TEMPLATE: "Custom Template",
  CUSTOM_TEMPLATES: "Custom Templates",
  TAG_COLLECTION: "Tags & Collections",
  DEVELOPER_GUIDE: "Developer Guide",
  MANAGE_CUSTOMERS: "Manage Customers",
  THIRD_PARTIES: "Third Parties",
  BOOK_OF_WORK: "Book Of Work",
};

export const FLAG_STATUS_OPTIONS = Object.values(FLAG_STATUSES).map(
  (value) => value
);

export const DISABLED_FLAG_STATUS_OPTIONS = [
  FLAG_STATUSES.removed,
  FLAG_STATUSES.closed,
];

export const REACT_SELECT_PORTAL_PLACEHOLDER = document.querySelector(
  ".react-select-portal"
);

export const SCHEDULE_DATE_KEYS = {
  fieldWorkEndDate: "fieldWorkEndDate",
  fieldWorkStartDate: "fieldWorkStartDate",
  bpqDueDate: "bpqDueDate",
  observationWindowStartDate: "observationStartDate",
  observationWindowEndDate: "observationDueDate",
};

export const SCHEDULE_DATE_LABELS = {
  [SCHEDULE_DATE_KEYS.fieldWorkEndDate]: "Fieldwork End Date",
  [SCHEDULE_DATE_KEYS.fieldWorkStartDate]: "Fieldwork Start Date",
  [SCHEDULE_DATE_KEYS.bpqDueDate]: "BPQ Due Date",
  [SCHEDULE_DATE_KEYS.observationWindowStartDate]: "Observation Window Start",
  [SCHEDULE_DATE_KEYS.observationWindowEndDate]: "Observation Window End",
};

export const TOAST_TYPES = {
  error: "error",
  info: "info",
  email: "email",
  emailPaperPlane: "emailPaperPlane",
  user: "user",
};

export const DATE_FORMATS = {
  dateTime: "MM/DD/YYYY hh:mm A",
  timeDate: "hh:mm A MM/DD/YYYY",
  timeDateCommaSeparated: "h:mm A, MM/DD/YY",
};

export const PROMPT_KEYS = {
  customTemplate: "customTemplate",
  tagCollection: "tagCollection",
};

export const momentTimezones = {
  USEastern: "US/Eastern",
  UTC: "UTC",
};

export const ROLE_TYPES = {
  externalAssessor: 1,
  trusight: 2,
  thirdParty: 3,
};

export const PRODUCT_STATUS_CHECK_INTERVAL = 5000;

export const CUSTOM_AG_GRID_COLUMN_PROPERTIES = {
  exportable: "exportable",
  excelHeaderName: "excelHeaderName",
};

export const UNMATCHED_THIRD_PARTY_BTN_IDS = {
  proposeOrRemoveSwitch: "propose-remove-switch",
  confirmMatch: "confirm-match",
  useThisThirdParty: "use-this-third-party",
};

export const OKTA_AUTH_CONFIG = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + process.env.REACT_APP_OKTA_REDIRECT_URI,
  originalURL: "originalURL",

  // Configure TokenManager to use sessionStorage instead of localStorage
  tokenManager: {
    storage: "sessionStorage",
  },
};

export const FORM_REDUCER_KEYS = {
  setIsDirty: "setIsDirty",
  setIsCustomCommentDirty: "setIsCustomCommentDirty",
};

export const FORM_PROMPT_MESSAGE_KEYS = {
  commentBuilderDrawerCustomComment: "commentBuilderDrawerCustomComment",
  commentBuilderDrawer: "commentBuilderDrawer",
};

export const COMMENT_BUILDER_MAIN_FIELDS = [
  "assessorWorkSteps",
  "validationSteps",
  "evidenceReferenced",
  "internalNotes",
  "sharedNotes",
  "revisedComment",
  "revisedMainAnswer",
  "summary",
  "revisedSummary",
];

export const COMMENT_BUILDER_ASSESSOR_RESPONSE_FIELD_GROUP = [
  "customerResponsibility",
];

export const COMMENT_BUILDER_CLOUD_SERVICE_FIELD_GROUP = [
  "iaaS",
  "paaS",
  "saaS",
];

export const TOOLTIP_POSITIONS = {
  bottomRight: "bottom-right",
  bottomCenter: "bottom-center",
  right: "right",
  topCenter: "top-center",
  topLeft: "top-left",
  topRight: "top-right",
};

export const STANDART_PAGINATION_PAGE_SIZE = 50;

export const DEMAND_TYPES = {
  ordered: "Ordered",
  addedManually: "Added manually",
  uploaded: "Uploaded",
};

//Constant used in /ThirdPartyPage/People/Components/UserForm
export const THIRD_PARTIES_PAGE_PEOPLE = {
  isDisabledLabel: "Active / Inactive",
  activeButton: {
    id: "false",
    label: "Activate",
    name: false,
    value: "false",
  },
  deactiveButton: {
    id: "true",
    label: "Deactivate",
    name: true,
    value: "true",
  },
  trusightUsers: "trusightsolutions",
};

export const ORGANIZATION_MAPPING_ACTION = {
  add: 0,
  remove: 1,
};

export const HUB_CONNECTION_ERROR_MESSAGE =
  "Unable to establish real-time hub connection with the server";

export const HUB_CONNECTION_TYPES = {
  BPQ_UPDATED_FROM_QC_VALIDATION_MESSAGE: "BpqUpdatedFromQcValidationMessage",
  ADD_USER_TO_BPQ: "AddUserToBpqGroup",
  REMOVE_USER_FROM_BPQ: "RemoveUserFromBpqGroup",
};
