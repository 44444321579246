import routes from "app/routes";

const getMyListActiveTable = () => {
  const result = sessionStorage.getItem("MyListActiveTable");
  const route = Object.values(routes).find((route) => route.path === result);

  if (route && !route.disabled) {
    return result;
  }

  return routes.myListMyThirdParties.path;
};

const setMyListActiveTable = (pathname) => {
  sessionStorage.setItem("MyListActiveTable", pathname);
};

export { getMyListActiveTable, setMyListActiveTable };
