import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "classifierItems",
  initialState: {
    classifiers: {
      ControlEnvironment: null,
      ServiceCategoryL1: null,
      ServiceCategoryL2: null,
      AssessmentStatus: null,
      Country: null,
      State: null,
      PeerReviewFlagType: null,
      PeerReviewFlagStatus: null,
      AssessmentType: null,
      QCFlagMetric: null,
      QCFlagStatus: null,
    },
    error: null,
  },
  reducers: {
    loadClassifierItemsData: (state, action) => {
      const classifiers = { ...state.classifiers };
      classifiers[action.payload.classifierName] = null;
      state.classifiers = {
        ...classifiers,
      };
    },
    loadClassifierItemsByParentData: (state, action) => {
      const classifiers = { ...state.classifiers };
      classifiers[action.payload.classifierName] = null;
      state.classifiers = {
        ...classifiers,
      };
    },
    loadClassifierItemsDataSuccess: (state, action) => {
      const classifiers = { ...state.classifiers };
      classifiers[action.payload.classifierName] = action.payload.items;
      state.classifiers = classifiers;
      state.error = null;
    },
    loadClassifierItemsDataError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  loadClassifierItemsData,
  loadClassifierItemsByParentData,
  loadControlEnvironmentData,
  loadAssessmentStatusData,
  loadServiceCategoryL1Data,
  loadServiceCategoryL2Data,
  loadCountriesData,
  loadClassifierItemsDataSuccess,
  loadClassifierItemsDataError,
} = slice.actions;

export default slice;
