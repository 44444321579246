import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { SecureRoute, useOktaAuth } from "@okta/okta-react";

import {
  selectPermissions,
  selectPermissionsInitialising,
  selectPermissionsInitialised,
  selectPermissionsInitError,
} from "../AuthorizedComponent/selectors";
import { setAuthorized } from "./services/slice";
import {
  getPermissions,
  getOrganizationType,
  getStages,
  getOrganizationProductMappings,
} from "../AuthorizedComponent/slice";
import useActiveAssessmentPermissions from "pages/AssessmentPage/utils/useActiveAssessmentPermissions";
import getSessionAccessToken from "utils/useAccessToken/getSessionAccessToken";
import SecureRouteRenderer from "./components/SecureRouteRenderer/SecureRouteRenderer";

const AuthorizedRoute = ({
  component: Component,
  path,
  requiredPermissions,
  title,
  titleTemplate,
  disabled,
  exact,
}) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const permissionsInitialising = useSelector(selectPermissionsInitialising);
  const permissionsInitialised = useSelector(selectPermissionsInitialised);
  const loadPermissionsError = useSelector(selectPermissionsInitError);
  const needInit =
    !permissions && !permissionsInitialising && !loadPermissionsError;
  const hasPermissions = permissions && permissions.length > 0;
  const { authState } = useOktaAuth();

  const activeAssessmentPermissions = useActiveAssessmentPermissions();

  const internalRequiredPermissions = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions];

  const canAccess = internalRequiredPermissions.length
    ? permissions &&
      (permissions.some((p) => internalRequiredPermissions.includes(p)) ||
        activeAssessmentPermissions.some((p) =>
          internalRequiredPermissions.includes(p)
        ))
    : true;

  useEffect(() => {
    const sessionAccessToken = getSessionAccessToken();
    if (authState.isAuthenticated && needInit) {
      dispatch(getPermissions({ accessToken: sessionAccessToken }));
      dispatch(getOrganizationType({ accessToken: sessionAccessToken }));
      dispatch(getStages({ accessToken: sessionAccessToken }));
      dispatch(
        getOrganizationProductMappings({ accessToken: sessionAccessToken })
      );
    }
    if (permissionsInitialised && canAccess && hasPermissions) {
      dispatch(setAuthorized(true));
    }
    if (authState.isAuthenticated && !canAccess && permissionsInitialised) {
      dispatch(setAuthorized(false));
    }
  }, [
    dispatch,
    authState,
    needInit,
    canAccess,
    permissionsInitialised,
    hasPermissions,
  ]);

  return (
    <>
      <Helmet titleTemplate={titleTemplate}>
        <title>{title}</title>
      </Helmet>
      <SecureRoute
        path={path}
        exact={exact}
        render={(props) => (
          <SecureRouteRenderer
            {...props}
            disabled={disabled}
            canAccess={canAccess}
            Component={Component}
          />
        )}
      />
    </>
  );
};

AuthorizedRoute.defaultProps = {
  requiredPermissions: [],
  disabled: false,
  exact: false,
};

AuthorizedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  requiredPermissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
};

export default AuthorizedRoute;
