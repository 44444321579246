import { defineMessages } from "react-intl";

const scope = "pages.NotFoundPage.";

export default defineMessages({
  pageNotFoundText: {
    id: `${scope}pageNotFoundText`,
    defaultMessage: "Page not found",
  },
});
