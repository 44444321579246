import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tpResponseDrawer",
  initialState: {
    isOpen: false,
    drawerData: null,
    isLoading: false,
  },
  reducers: {
    setTpResponseDrawerOpen: (state, { payload }) => {
      state.isOpen = payload.isOpen;
      if (payload.drawerData && payload.isOpen) {
        state.drawerData = payload.drawerData;
      } else {
        state.drawerData = null;
      }
    },
    setTpResponseDrawerClosed: (state) => {
      state.isOpen = false;
    },
    setTpResponse: (state) => {
      state.isLoading = true;
    },
    setTpResponseSuccess: (state) => {
      state.isOpen = false;
      state.isLoading = false;
    },
    setTpResponseError: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  setTpResponseDrawerOpen,
  setTpResponseDrawerClosed,
  setTpResponse,
  setTpResponseSuccess,
  setTpResponseError,
} = slice.actions;

export default slice;
