import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { selectPermissionsInitError } from "components/AuthorizedComponent/selectors";
import { errorCodes } from "pages/UnauthorizedPage/utils/error-codes";
import messages from "pages/UnauthorizedPage/messages";

const UnauthorizedPage = () => {
  const intl = useIntl();
  const permissionsError = useSelector(selectPermissionsInitError);
  const organizationNotFound =
    permissionsError === errorCodes.organizationNotFound.toString();

  return (
    <h2 className="title">
      {organizationNotFound && (
        <span data-testid="error-message">
          {intl.formatMessage(messages.notAssignedOrganization)}
        </span>
      )}
      {permissionsError && !organizationNotFound && (
        <span data-testid="error-message">
          {intl.formatMessage(messages.failedUserDataLoad)}
        </span>
      )}
      {!permissionsError && (
        <span data-testid="error-message">
          {intl.formatMessage(messages.unauthorized)}
        </span>
      )}
    </h2>
  );
};

export default UnauthorizedPage;
