import { defineMessages } from "react-intl";

const scope = "pages.unauthorized.";

export default defineMessages({
  notAssignedOrganization: {
    id: `${scope}notAssignedOrganization`,
    defaultMessage: "User is not assigned to an organization. Please contact your organization administrator.",
  },
  failedUserDataLoad: {
    id: `${scope}failedUserDataLoad`,
    defaultMessage: " Failed to load user data. Please try to refresh the page.",
  },
  unauthorized: {
    id: `${scope}unauthorized`,
    defaultMessage: "Sorry, you are unauthorized to see this page. Please contact your organization administrator.",
  },
});
