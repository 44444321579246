import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "assessmentDetails",
  initialState: {
    loadingDetails: false,
    loadingDetailsError: false,
    assessmentDetails: null,
  },
  reducers: {
    setAssessmentDetails: (state, action) => {
      state.assessmentDetails = { ...action.payload };
      state.loadingDetailsError = false;
      state.loadingDetails = false;
    },
    loadAssessmentDetails: (state) => {
      state.loadingDetails = true;
      state.assessmentDetails = null;
    },
    setLoadingDetailsError(state) {
      state.loadingDetailsError = true;
      state.loadingDetails = false;
      state.assessmentDetails = null;
    },
    setAssessmentWatchStatus: (state, action) => {
      if (state.assessmentDetails) {
        state.assessmentDetails.isWatched = action.payload;
      }
    },
  },
});

export const {
  setAssessmentDetails,
  loadAssessmentDetails,
  setAssessmentWatchStatus,
  setLoadingDetailsError,
} = slice.actions;
export default slice;
