import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  columnsAvailableToHide: null,
  columnsToHide: null,
  isOpen: false,
};

const slice = createSlice({
  name: "toggleColumnsVisibilityModal",
  initialState,
  reducers: {
    setColumnsAvailableToHide: (state, { payload }) => {
      state.columnsAvailableToHide = payload;
    },
    setColumnsToHide: (state, { payload }) => {
      state.columnsToHide = payload;
    },
    setIsToggleColumnsVisibilityModalOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});

export const {
  setColumnsAvailableToHide,
  setColumnsToHide,
  setIsToggleColumnsVisibilityModalOpen,
} = slice.actions;

export default slice;
