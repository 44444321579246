import { createAction, createSlice } from "@reduxjs/toolkit";
const NAMESPACE = "thirdPartiesUpload";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    fileName: null,
    uploadedOn: null,
    uploading: false,
    errors: [],
    savedRowsCount: 0,
    currentElement: 1,
    hasChanges: false,
  },
  reducers: {
    startUploadFile: (state) => {
      state.file = null;
      state.uploadedOn = null;
      state.uploading = true;
      state.errors = [];
      state.savedRowsCount = 0;
    },
    uploadFileSuccess: (state, action) => {
      state.fileName = action.payload.fileName;
      state.uploadedOn = action.payload.uploadedOn;
      state.uploading = false;
      state.errors = action.payload.errors;
      state.savedRowsCount = action.payload.savedRowsCount;
      state.hasChanges = true;
    },
    uploadFileError: (state, action) => {
      state.fileName = action.payload.fileName;
      state.uploadedOn = action.payload.uploadedOn;
      state.uploading = false;
      state.errors = action.payload.errors;
      state.savedRowsCount = 0;
    },
    setCurrentElement: (state, action) => {
      state.currentElement = action.payload.currentElement;
    },
    setHasChanges: (state, { payload }) => {
      state.hasChanges = payload;
    },
  },
});
export const downloadTemplate = createAction(`${NAMESPACE}/downloadTemplate`);
export const uploadToServer = createAction(`${NAMESPACE}/uploadToServer`);
export const startAssessmentMatching = createAction(
  `${NAMESPACE}/startAssessmentMatching`
);

export const {
  startUploadFile,
  uploadFileSuccess,
  uploadFileError,
  savedRowsCount,
  setCurrentElement,
  setHasChanges,
} = slice.actions;

export default slice;
