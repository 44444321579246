import React from "react";
import PropTypes from "prop-types";

import LabelWithIcon from "components/GlobalComponents/Inputs/LabeledSelectInput/components/LabelWithIcon";

const SelectMultiValue = ({
  multiSelectChildren,
  setMultiValueLabel,
  setMultiValueText,
  setMultiValueIcon,
}) => {
  if (multiSelectChildren.length === 0) {
    return null;
  }

  return (
    <LabelWithIcon
      label={setMultiValueLabel(multiSelectChildren)}
      text={setMultiValueText(multiSelectChildren)}
      icon={setMultiValueIcon(multiSelectChildren)}
    />
  );
};

SelectMultiValue.defaultProps = {
  setMultiValueLabel: () => {},
  setMultiValueText: () => {},
  setMultiValueIcon: () => {},
};

SelectMultiValue.propTypes = {
  multiSelectChildren: PropTypes.array.isRequired,
  setMultiValueLabel: PropTypes.func,
  setMultiValueText: PropTypes.func,
  setMultiValueIcon: PropTypes.func,
};

export default SelectMultiValue;
