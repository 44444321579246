import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Tooltip from "components/GlobalComponents/Tooltip";
import "./toggle.scss";

const Toggle = ({
  checked,
  isDisabled,
  label,
  onChange,
  name,
  id,
  tooltipText,
  tooltipPosition,
  isDarkBackground,
  dataTestId,
  className,
}) => (
  <label
    className={classNames("toggle", className, {
      "toggle--disabled": isDisabled,
      "toggle--dark-bg": isDarkBackground,
    })}
  >
    <input
      className="toggle__hidden-input visually-hidden"
      checked={checked}
      disabled={isDisabled}
      onChange={onChange}
      type="checkbox"
      name={name}
      id={id}
      data-testid={dataTestId}
    />
    <div
      className={classNames("toggle__btn", {
        "toggle__btn--checkbox-checked": checked,
      })}
    />
    {tooltipText && (
      <Tooltip
        text={tooltipText}
        className={"toggle__tooltip tooltip--" + tooltipPosition}
        data-testid="toggle-tooltip"
      />
    )}
    {label && (
      <span
        className={classNames("toggle__label", {
          "toggle__label--checkbox-checked": checked,
        })}
        data-testid={`${dataTestId}-label`}
      >
        {label}
      </span>
    )}
  </label>
);

Toggle.defaultProps = {
  checked: false,
  isDisabled: false,
  label: "",
  onChange: () => {},
  name: "",
  id: "",
  tooltipText: "",
  tooltipPosition: "top-left",
  isDarkBackground: false,
  dataTestId: "toggle",
  className: "",
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isDarkBackground: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

export default Toggle;
