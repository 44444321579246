import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  loading: false,
  error: null,
  isAllowCustomTemplatesSaving: false,
};

const slice = createSlice({
  name: "assessmentOverview",
  initialState,
  reducers: {
    loadOverviewData: (state) => {
      state.loading = true;
    },
    setOverviewDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    setOverviewDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    cleanOverviewData: () => initialState,
    setOverviewAssessmentStage: (state, action) => {
      if (state.data) {
        state.data.status = action.payload;
      }
    },
    setAllowCustomTemplates: (state, { payload }) => {
      state.isAllowCustomTemplatesSaving = true;
      state.data.allowCustomTemplates = payload.allowCustomTemplates;
    },
    setAllowCustomTemplatesSuccess: (state) => {
      state.isAllowCustomTemplatesSaving = false;
    },
    setAllowCustomTemplatesError: (state) => {
      state.data.allowCustomTemplates = !state.data.allowCustomTemplates;
      state.isAllowCustomTemplatesSaving = false;
    },
  },
});

export const {
  loadOverviewData,
  setOverviewDataSuccess,
  setOverviewDataError,
  cleanOverviewData,
  setOverviewAssessmentStage,
  setAllowCustomTemplates,
  setAllowCustomTemplatesSuccess,
  setAllowCustomTemplatesError,
} = slice.actions;

export default slice;
