import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "connectionDetailsSlice",
  initialState: {
    connectionDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    loadConnectionDetailsSuccess: (state, { payload }) => {
      state.connectionDetails = { ...payload };
      state.error = null;
      state.loading = false;
    },
    loadConnectionDetails: (state) => {
      state.error = null;
      state.connectionDetails = null;
      state.loading = true;
    },
    loadConnectionDetailsError: (state, { payload }) => {
      state.error = payload;
      state.connectionDetails = null;
      state.loading = false;
    },
  },
});

export const {
  loadConnectionDetails,
  loadConnectionDetailsSuccess,
  loadConnectionDetailsError,
} = slice.actions;
export default slice;
