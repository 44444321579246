import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as ErrorIcon } from "assets/error-input.svg";
import { uploadToServer } from "../../services/slice";
import Spinner from "components/Spinner";
import {
  selectIsUploading,
  selectCurrentElement,
} from "../../services/selectors";
import messages from "../../messages";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import "./upload-file.scss";

const UploadFile = ({ organizationId, dispatchGoBack }) => {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const [validationError, setValidationError] = useState(null);
  const startUploading = useSelector(selectIsUploading);
  const currentElement = useSelector(selectCurrentElement);
  const intl = useIntl();
  const getErrorText = (rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      return intl.formatMessage(messages.tooManyFiles);
    }

    let errorText = intl.formatMessage(messages.fileIsRejected);

    if (rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0) {
      const errorTextJoin = rejectedFiles[0].errors
        .map((err) => {
          return err.code === "file-too-large"
            ? intl.formatMessage(messages.fileIsTooLarge)
            : err.message;
        })
        .join("; ");
      errorText = `${rejectedFiles[0].file.name}: ${errorTextJoin}`;
    }

    return errorText;
  };

  return (
    <>
      <Dropzone
        multiple={false}
        accept=".csv"
        minSize={0}
        maxSize={1024 * 1024 * 10}
        onDrop={(acceptedFiles, rejectedFiles) => {
          if (rejectedFiles && rejectedFiles.length > 0) {
            setValidationError(getErrorText(rejectedFiles));
          } else {
            setValidationError(null);
            dispatch(
              uploadToServer({
                organizationId,
                accessToken,
                file: acceptedFiles[0],
                currentElement,
              })
            );
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            data-testid="upload-file-div"
            className="upload-file"
          >
            {!startUploading ? (
              <div>
                <input {...getInputProps()} />
                <p data-testid="upload-file-text" className="upload-file__text">
                  {intl.formatMessage(messages.dragTheCompletedTemplateHere)}
                </p>
                <p
                  data-testid="upload-file-text-secondary"
                  className="upload-file__text upload-file__text--secondary"
                >
                  {intl.formatMessage(messages.orBrowseToChooseTheFile)}
                </p>
              </div>
            ) : (
              <Spinner isLarge isLight />
            )}
          </div>
        )}
      </Dropzone>
      {validationError && (
        <div className="upload-file__validation-error-wrapper">
          <ErrorIcon className="upload-file__validation-error-icon" />
          <p className="upload-file__validation-error">{validationError}</p>
        </div>
      )}
      <div
        data-testid="upload-third-parties-controls"
        className="upload-third-parties__controls"
      >
        <Button
          onClick={dispatchGoBack}
          isLarge
          isLight
          isDisabled={startUploading}
          isSecondary
          className="upload-third-parties__btn upload-third-parties__btn--light"
          data-testid="upload-third-parties-btn"
        >
          {intl.formatMessage(messages.goBack)}
        </Button>
      </div>
    </>
  );
};

UploadFile.propTypes = {
  organizationId: PropTypes.number,
  dispatchGoBack: PropTypes.func.isRequired,
};

export default UploadFile;
