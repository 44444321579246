import { takeLatest, all, put, select } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { selectIsCustomCommentDirty } from "services/form/selectors";
import { setIsCustomCommentDirty, setIsDirty } from "services/form/slice";
import { FORM_PROMPT_MESSAGE_KEYS } from "app/constants";
import { showError } from "utils/sagaEffects/sagaEffects";
import {
  getGeneratedSummaryReview,
  setAnswerAndSummaryToCommentBuilderDrawer,
  setAnswerToCommentBuilderDrawer,
  setCommandBuilderPublishInProgress,
  setCommandBuilderSaveDraftInProgress,
  setCommentBuilderDraftSaveFailed,
  setCommentBuilderDrawerData,
  setCommentBuilderDrawerOpen,
  setCommentBuilderPublishFailed,
  setSummaryDraftReview,
} from "./slice";
import {
  setAnswerToCommentBuilderDrawerSuccess,
  setRowToRefresh,
} from "../../../services/slice";

function* setAnswerToCommentBuilderDrawerSaga({ payload }) {
  try {
    yield put(setCommentBuilderDraftSaveFailed(false));
    yield put(setCommentBuilderPublishFailed(false));
    const { assessmentId, answer, accessToken, formikValues, answerChecklist } =
      payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/comment-builder`,
      {
        method: "PUT",
        body: JSON.stringify(answer),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    const responseAnswer = data?.answer;
    const responseComment = data?.comment;

    const answerChecklistUpdated = answerChecklist.map((answer) => {
      const updatedChecklistAnswer = responseAnswer.checklist[answer.serialId];
      const updatedEvidence = updatedChecklistAnswer?.evidence;
      if (updatedChecklistAnswer) {
        return {
          ...answer,
          evidence: updatedEvidence ? updatedEvidence.artifacts : [],
        };
      }

      return answer;
    });

    const updatedCommentBuilderDrawerData = {
      ...formikValues,
      answerChecklist: answerChecklistUpdated,
      isDraft: true,
    };
    const formikValuesKeys = Object.keys(formikValues);

    Object.keys(responseAnswer.review).forEach((answerFieldKey) => {
      if (
        formikValuesKeys.includes(answerFieldKey) ||
        "evidenceReferenced" === answerFieldKey
      ) {
        const updatedAnswerValueReturned =
          responseAnswer.review[answerFieldKey];

        if (updatedAnswerValueReturned?.artifacts) {
          updatedCommentBuilderDrawerData["artifacts"] =
            updatedAnswerValueReturned.artifacts;
          return;
        }

        if (updatedAnswerValueReturned?.value) {
          updatedCommentBuilderDrawerData[answerFieldKey] =
            updatedAnswerValueReturned.value;
          return;
        }

        if (
          [
            updatedAnswerValueReturned?.value,
            updatedAnswerValueReturned,
          ].includes(null)
        ) {
          updatedCommentBuilderDrawerData[answerFieldKey] = null;
        }
      }
    });

    yield put(
      setAnswerToCommentBuilderDrawerSuccess({
        questionId: answer.questionId,
        ...data,
      })
    );
    yield put(setCommentBuilderDrawerData(updatedCommentBuilderDrawerData));
    yield put(setSummaryDraftReview(responseComment));
    const isCustomCommentDirty = yield select(selectIsCustomCommentDirty);
    isCustomCommentDirty
      ? yield put(
          setIsDirty({
            value: false,
            key: FORM_PROMPT_MESSAGE_KEYS.commentBuilderDrawerCustomComment,
          })
        )
      : yield put(setIsDirty({ value: false, key: null }));
    yield put(setRowToRefresh({ questionId: answer.questionId }));
    yield put(setCommandBuilderSaveDraftInProgress(false));
  } catch {
    yield put(setCommandBuilderSaveDraftInProgress(false));
    yield put(setCommentBuilderDraftSaveFailed(true));
    yield showError();
  }
}

function* setAnswerAndSummaryToCommentBuilderDrawerSaga({ payload }) {
  try {
    yield put(setCommentBuilderDraftSaveFailed(false));
    yield put(setCommentBuilderPublishFailed(false));
    const { assessmentId, answer, accessToken, revisedSummary } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/comment-builder/publish`,
      {
        method: "POST",
        body: JSON.stringify({ ...answer, revisedSummary }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const responseAnswer = yield response.json();
    yield put(
      setAnswerToCommentBuilderDrawerSuccess({
        questionId: answer.questionId,
        ...responseAnswer,
      })
    );
    yield put(setIsDirty({ value: false, key: null }));
    yield put(setIsCustomCommentDirty({ value: false }));
    yield put(setCommandBuilderPublishInProgress(false));
    yield put(setRowToRefresh({ questionId: answer.questionId }));
    yield put(setCommentBuilderDrawerOpen({ isOpen: false }));
    yield put(setCommentBuilderDrawerData(null));
  } catch ({ message }) {
    yield put(setCommandBuilderPublishInProgress(false));
    yield put(setCommentBuilderPublishFailed(true));
    yield showError();
  }
}

function* generateSummaryReviewSaga({ payload }) {
  try {
    const { assessmentId, serialId, accessToken } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/bpq/summary-review/${serialId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      const data = yield response.json();
      yield put(setSummaryDraftReview(data?.comment));
    } else {
      throw new Error("Failed to load");
    }
  } catch ({ message }) {
    yield showError();
  }
}

export default function* bpqCommentBuilderSagas() {
  yield all([
    takeLatest(
      setAnswerToCommentBuilderDrawer.type,
      setAnswerToCommentBuilderDrawerSaga
    ),
    takeLatest(
      setAnswerAndSummaryToCommentBuilderDrawer.type,
      setAnswerAndSummaryToCommentBuilderDrawerSaga
    ),
    takeLatest(getGeneratedSummaryReview.type, generateSummaryReviewSaga),
  ]);
}
