import { put, takeLatest, select, all, call } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadQuestionnaireDocument,
  loadQuestionnaireDocumentSuccess,
  loadQuestionnaireDocumentError,
  setAnswerToQuestions,
  setSummaryToQuestions,
  setControlEnvironmentSummary,
  confirmAssessmentType,
  loadThirdPartyAddresses,
  loadThirdPartyAddressesSuccess,
  loadThirdPartyAddressesError,
  setQuestionnaireAnswersSuccess,
  loadControlEnvironments,
  loadControlEnvironmentsSuccess,
  loadControlEnvironmentsError,
  setDraftSummarySuccess,
  setIsRevisedSummarySaving,
  setRevisedSummary,
} from "./slice";
import messages from "app/messages";
import { confirmAssessmentTypeSuccess } from "../../services/slice";
import { setIsDirty } from "services/form/slice";
import { createDraftSummary } from "../../utils/draftSummaryUtils";
import { selectQuestionnaireDocument } from "./selectors";
import { selectThirdPartyName } from "../../services/selectors";
import { unregisterCustomizableResponseSummary } from "pages/AssessmentPage/Scoping/components/ScopingTable/components/ScopingTableRow/services/slice";
import { showError } from "utils/sagaEffects/sagaEffects";

export function* loadQuestionnaireDocumentSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/scoping`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const questionnaireDocument = yield response.json();
    yield put(loadQuestionnaireDocumentSuccess(questionnaireDocument));
  } catch ({ message }) {
    yield put(loadQuestionnaireDocumentError(message));
  }
}

export function* confirmAssessmentTypeSaga({ payload }) {
  try {
    const { assessmentId, templateId, controlEnvironmentId, accessToken } =
      payload;

    const body =
      controlEnvironmentId > 0
        ? {
            templateId,
            controlEnvironmentId,
          }
        : {
            templateId,
          };

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/initialize-bpq`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      const data = yield response.json();
      yield put(
        confirmAssessmentTypeSuccess({
          templateId: data.assessmentReference,
          scopingDocumentId: data.scopingDocumentId,
          assessmentName: data.assessmentName,
          bpqDocumentId: data.bpqDocumentId,
          controlEnvironmentId: data.controlEnvironmentId,
        })
      );
    } else {
      throw new Error(messages.error.defaultMessage);
    }
  } catch ({ message }) {
    yield put(loadQuestionnaireDocumentError(message));
  }
}

export function* setQuestionnaireAnswersSaga({ payload }) {
  try {
    const {
      assessmentId,
      scopingDocumentId,
      answers,
      accessToken,
      isScopingCompleted,
    } = payload;
    const questionResponse = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/scoping/${scopingDocumentId}/questions`,
      {
        method: "PUT",
        body: JSON.stringify({
          answers,
          isCompleted: isScopingCompleted,
        }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (questionResponse.status !== 200) {
      throw new Error("Failed to load");
    }
    const domainList = yield questionResponse.json();
    yield put(setQuestionnaireAnswersSuccess(domainList));
    yield all(
      answers.map((answer) =>
        put(unregisterCustomizableResponseSummary(answer.questionId))
      )
    );
    yield put(setSummaryToQuestions(domainList));
    yield put(setIsRevisedSummarySaving(false));
    const questionnaireDocument = yield select(selectQuestionnaireDocument);
    const thirdPartyName = yield select(selectThirdPartyName);
    const draftSummary = yield call(
      createDraftSummary,
      domainList.answers,
      questionnaireDocument,
      thirdPartyName
    );
    const draftResponse = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/scoping/draftSummary`,
      {
        method: "PUT",
        body: JSON.stringify({
          controlEnvironmentSummary: draftSummary,
        }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (draftResponse.status !== 200) {
      throw new Error("Failed to load");
    }
    yield put(setDraftSummarySuccess(draftSummary));
    yield put(setIsDirty({ value: false }));
    yield put(unregisterCustomizableResponseSummary());
  } catch ({ message }) {
    yield call(showError);
  }
}

export function* setSummarySaga({ payload }) {
  try {
    const { assessmentId, summary, accessToken } = payload;
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/scoping/summary`,
      {
        method: "PUT",
        body: JSON.stringify({
          controlEnvironmentSummary: summary,
        }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
  } catch ({ message }) {
    yield call(showError);
  }
}

export function* loadThirdPartyAddressesSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/thirdParties/${payload.thirdPartyId}/addresses`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const addressList = yield response.json();
    yield put(loadThirdPartyAddressesSuccess(addressList));
  } catch ({ message }) {
    yield put(loadThirdPartyAddressesError(message));
    yield call(showError);
  }
}

export function* loadControlEnvironmentsSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/third-parties/${payload.thirdPartyId}/assessments/bpq-versions`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const controlEnvironments = yield response.json();
    yield put(loadControlEnvironmentsSuccess(controlEnvironments));
  } catch ({ message }) {
    yield put(loadControlEnvironmentsError(message));
    yield call(showError);
  }
}

export default function* questionnaireDocumentSagas() {
  yield all([
    takeLatest(loadQuestionnaireDocument.type, loadQuestionnaireDocumentSaga),
    takeLatest(setAnswerToQuestions.type, setQuestionnaireAnswersSaga),
    takeLatest(setRevisedSummary.type, setQuestionnaireAnswersSaga),
    takeLatest(setControlEnvironmentSummary.type, setSummarySaga),
    takeLatest(confirmAssessmentType.type, confirmAssessmentTypeSaga),
    takeLatest(loadThirdPartyAddresses.type, loadThirdPartyAddressesSaga),
    takeLatest(loadControlEnvironments.type, loadControlEnvironmentsSaga),
  ]);
}
