import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authorizedComponent",
  initialState: {
    permissions: null,
    initialisingPermissions: false,
    initialised: false,
    initError: null,
    organizationType: [],
    isOrganizationTypeLoading: false,
    organizationTypeError: null,
    stages: [],
    stagesError: false,
    organizationProductMappings: [],
    organizationProductMappingsError: null,
  },
  reducers: {
    getPermissions: (state) => {
      state.initialisingPermissions = true;
    },
    setPermissionsSuccess: (state, action) => {
      state.permissions = action.payload;
      state.initialisingPermissions = false;
      state.initialised = true;
      state.initError = null;
    },
    setPermissionsError: (state, action) => {
      state.initialisingPermissions = false;
      state.initialised = true;
      state.initError = action.payload;
    },
    getOrganizationType: (state) => {
      state.organizationType = [];
      state.isOrganizationTypeLoading = true;
    },
    setOrganizationTypeSuccess: (state, action) => {
      state.organizationType = action.payload;
      state.isOrganizationTypeLoading = false;
      state.organizationTypeError = null;
    },
    setIsOrganizationTypeFailed: (state, action) => {
      state.organizationTypeError = action.payload;
      state.isOrganizationTypeLoading = false;
    },
    getStages: (state) => {
      state.stages = [];
      state.stagesError = false;
    },
    getStagesSuccess: (state, action) => {
      state.stages = action.payload;
    },
    getStagesError: (state) => {
      state.stagesError = true;
    },
    getOrganizationProductMappings: (state) => {
      state.organizationProductMappings = [];
      state.organizationProductMappingsError = null;
    },
    setOrganizationProductSuccess: (state, action) => {
      state.organizationProductMappings = action.payload;
      state.organizationProductMappingsError = null;
    },
    setOrganizationProductMappingsFailed: (state, action) => {
      state.organizationProductMappingsError = action.payload;
    },
  },
});

export const {
  getPermissions,
  setPermissionsSuccess,
  setPermissionsError,
  getOrganizationType,
  setOrganizationTypeSuccess,
  getStages,
  getStagesSuccess,
  getStagesError,
  setIsOrganizationTypeFailed,
  getOrganizationProductMappings,
  setOrganizationProductSuccess,
  setOrganizationProductMappingsFailed,
} = slice.actions;

export default slice;
