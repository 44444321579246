import { all, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadTagCollection,
  loadTagCollectionSuccess,
  loadTagCollectionError,
  saveTagCollectionSettings,
  saveTagCollectionSettingsSuccess,
  saveTagCollectionSettingsError,
  saveTagCollection,
  saveTagCollectionSuccess,
  saveTagCollectionError,
  showTagError,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { addToast } from "services/toast/slice";
import { setIsDirty } from "services/form/slice";

function* loadTagCollectionSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadTagCollectionSuccess(data));
  } catch ({ message }) {
    yield put(loadTagCollectionError(message));
  }
}

function* saveTagCollectionSettingsSaga({ payload }) {
  try {
    const { id, accessToken, body } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections/${id}/settings`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error();
    }
    const data = yield response.json();
    yield put(saveTagCollectionSettingsSuccess(data));
    yield put(setIsDirty({ value: false }));
  } catch {
    yield showError();
    yield put(saveTagCollectionSettingsError());
  }
}

function* saveTagCollectionSaga({ payload }) {
  try {
    const { accessToken, body } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections/${body.id}`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error();
    }
    const { modifiedOn } = yield response.json();
    const data = { isPublished: body.isPublished, modifiedOn };
    let successToast;
    if (body.isPublished) {
      successToast = {
        description: `<span>${body.name}</span> successfully <strong>Saved and published</strong>`,
      };
    } else {
      successToast = {
        description: `<span>${body.name}</span> successfully <strong>Saved as draft</strong>`,
      };
    }
    yield put(addToast(successToast));
    yield put(saveTagCollectionSuccess(data));
  } catch {
    yield put(saveTagCollectionError());
    yield showError();
  }
}

function* showTagErrorSaga({ payload }) {
  yield showError(payload);
}

export default function* tagCollectionSagas() {
  yield all([
    yield takeLatest(loadTagCollection.type, loadTagCollectionSaga),
    yield takeLatest(
      saveTagCollectionSettings.type,
      saveTagCollectionSettingsSaga
    ),
    yield takeLatest(saveTagCollection.type, saveTagCollectionSaga),
    yield takeLatest(showTagError.type, showTagErrorSaga),
  ]);
}
