import { put, takeLatest, call, all, takeEvery } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadUnmatchedThirdParties,
  loadUnmatchedThirdPartiesSuccess,
  loadUnmatchedThirdPartiesError,
  setErrorUseThisParty,
  deleteUnmatchedThirdParty,
  deleteUnmatchedThirdPartySuccess,
  deleteUnmatchedThirdPartyError,
  loadUnmatchedThirdPartyDetails,
  loadUnmatchedThirdPartyDetailsSuccess,
  loadUnmatchedThirdPartyDetailsError,
  updateUnmatchedThirdPartyDetails,
  updateUnmatchedThirdPartyDetailsSuccess,
  updateUnmatchedThirdPartyDetailsError,
  requestAssessment,
  requestAssessmentSuccess,
  requestAssessmentError,
  selectThirdParty,
  loadUnmatchedThirdPartiesPossibleMatches,
} from "./slice";
import { setIsDirty } from "services/form/slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";
import { removeThirdPartyWithPendingAction } from "components/UnmatchedThirdParties/services/slice";
import { addRowToRefreshQueue } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/services/slice";
import { UNMATCHED_THIRD_PARTIES_ACTIONS } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/utils/constants";

export function* loadUnmatchedThirdPartiesSaga({
  payload: { accessToken, setData },
}) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadUnmatchedThirdPartiesSuccess());
    yield setData(data);
  } catch ({ message }) {
    yield put(loadUnmatchedThirdPartiesError(message));
  }
}

export function* loadUnmatchedThirdPartiesPossibleMatchesSaga({ payload }) {
  const { accessToken, thirdPartyId, onSuccess, onFail } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/${thirdPartyId}/possible-matches`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        details: data,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.addDetails,
        masterGridId: thirdPartyId,
      })
    );
    if (onSuccess) {
      yield call(onSuccess, data);
    }
  } catch ({ message }) {
    if (onFail) {
      yield call(onFail, message);
    }
  }
}

export function* selectThirdPartySaga({ payload }) {
  const {
    accessToken,
    candidateId,
    matchedName,
    thirdPartyId,
    truSightId,
    uploadedName,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/match/${thirdPartyId}`,
      {
        method: "POST",
        body: JSON.stringify({ candidateId, truSightId }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      const responseBody = yield response.json();
      throw new Error(responseBody?.errorCode || "Failed to save");
    }
    yield put(setErrorUseThisParty(false));
    yield showInfo(`${uploadedName} has been matched to ${matchedName}`);
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.confirmMatch,
        masterGridId: thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(removeThirdPartyWithPendingAction(thirdPartyId));
    yield put(setErrorUseThisParty(true));
    yield showError(message);
  }
}

export function* deleteUnmatchedThirdPartySaga({ payload }) {
  const { accessToken, thirdPartyId } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/${thirdPartyId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to delete");
    }

    yield put(deleteUnmatchedThirdPartySuccess());
    yield showInfo("Third party deleted");
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.delete,
        masterGridId: thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(deleteUnmatchedThirdPartyError());
    yield showError(message);
  }
}

export function* getUnmatchedThirdPartiesDetailsSaga({ payload }) {
  const { accessToken, thirdPartyId } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/${thirdPartyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadUnmatchedThirdPartyDetailsSuccess(data));
  } catch ({ message }) {
    yield put(loadUnmatchedThirdPartyDetailsError(message));
  }
}

export function* submitUnmatchedThirdPartyDetailsUpdateSaga({ payload }) {
  const {
    accessToken,
    thirdPartyChanges,
    thirdPartyChanges: { thirdPartyId },
    updateSuccessMessage,
  } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/${thirdPartyId}`,
      {
        method: "PUT",
        body: JSON.stringify(thirdPartyChanges),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to save");
    }

    yield put(setIsDirty({ value: false }));
    yield put(updateUnmatchedThirdPartyDetailsSuccess());
    yield showInfo(updateSuccessMessage);
    yield put(
      addRowToRefreshQueue({
        id: thirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.updateDetails,
        masterGridId: thirdPartyId,
        details: thirdPartyChanges,
      })
    );
  } catch ({ message }) {
    yield put(updateUnmatchedThirdPartyDetailsError(message));
    yield showError();
  }
}

export function* requestAssessmentSaga({ payload }) {
  try {
    const result = yield fetch(
      `${process.env.REACT_APP_API_URL}/unmatched-third-parties/${payload.thirdPartyId}/request-assessment`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );

    if (result.status === 200) {
      yield put(requestAssessmentSuccess());
      yield showInfo("The assessment has been requested");
    } else {
      throw Error;
    }
  } catch {
    yield put(requestAssessmentError());
    yield showError();
  }
}

export default function* unmatchedThirdPartiesSagas() {
  yield all([
    takeLatest(loadUnmatchedThirdParties.type, loadUnmatchedThirdPartiesSaga),
    takeEvery(
      loadUnmatchedThirdPartiesPossibleMatches.type,
      loadUnmatchedThirdPartiesPossibleMatchesSaga
    ),
    takeEvery(selectThirdParty.type, selectThirdPartySaga),
    takeEvery(deleteUnmatchedThirdParty.type, deleteUnmatchedThirdPartySaga),
    takeLatest(
      loadUnmatchedThirdPartyDetails.type,
      getUnmatchedThirdPartiesDetailsSaga
    ),
    takeLatest(
      updateUnmatchedThirdPartyDetails.type,
      submitUnmatchedThirdPartyDetailsUpdateSaga
    ),
    takeEvery(requestAssessment.type, requestAssessmentSaga),
  ]);
}
