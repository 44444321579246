import { all, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import escape from "escape-html";

import { addToast } from "services/toast/slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import {
  loadCustomTemplate,
  loadCustomTemplateSuccess,
  loadCustomTemplateError,
  saveTemplateSettings,
  saveTemplateSettingsSuccess,
  saveTemplateSettingsError,
  saveCustomTemplate,
  saveCustomTemplateSuccess,
  saveCustomTemplateError,
} from "./slice";
import { setIsDirty } from "services/form/slice";
function* loadCustomTemplateSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/custom-templates/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadCustomTemplateSuccess(data));
  } catch ({ message }) {
    yield put(loadCustomTemplateError(message));
  }
}

function* saveTemplateSettingsSaga({ payload }) {
  try {
    const { id, accessToken, body } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/custom-templates/${id}/settings`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    if (response.status !== 200) {
      if (response.status === 400) {
        throw new Error(data.Title);
      }
      throw new Error();
    }
    yield put(saveTemplateSettingsSuccess(data));
    yield put(setIsDirty({ value: false }));
  } catch ({ message }) {
    yield showError(message);
    yield put(saveTemplateSettingsError());
  }
}

function* saveCustomTemplateSaga({ payload }) {
  try {
    const {
      id,
      name,
      customTemplate,
      accessToken,
      isPublished = false,
    } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/custom-templates/${id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          name: name,
          isPublished,
          customTemplate: customTemplate,
        }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error();
    }
    const data = yield response.json();
    let successToast;
    if (isPublished) {
      successToast = {
        description: `<span>${escape(
          name
        )}</span> successfully <strong>Saved and published</strong>`,
      };
    } else {
      successToast = {
        description: `<span>${escape(
          name
        )}</span> successfully <strong>Saved as draft</strong>`,
      };
    }
    yield put(addToast(successToast));
    yield put(saveCustomTemplateSuccess(data));
  } catch {
    yield showError();
    yield put(saveCustomTemplateError());
  }
}

export default function* customTemplateSagas() {
  yield all([
    yield takeLatest(loadCustomTemplate.type, loadCustomTemplateSaga),
    yield takeLatest(saveTemplateSettings.type, saveTemplateSettingsSaga),
    yield takeLatest(saveCustomTemplate.type, saveCustomTemplateSaga),
  ]);
}
