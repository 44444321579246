export function getStageByPosition(stages, position) {
  return stages.find((stage) => stage.position === position)?.id;
}

export function getPositionById(stages, id) {
  return stages.find((stage) => stage.id === id)?.position;
}

export function getStagesByPositionRange(stages, positionFrom, positionTo) {
  return stages
    .filter(
      (stage) =>
        stage.position &&
        stage.position >= positionFrom &&
        stage.position <= positionTo
    )
    .map((stage) => stage.id);
}

export function getStagesByPositionFrom(stages, positionFrom) {
  return stages
    .filter((stage) => stage.position && stage.position >= positionFrom)
    .map((stage) => stage.id);
}

export function getViewableThirdPartyReviewStages(stages) {
  return getStagesByPositionRange(stages, 9, 14);
}

export function getBpqStagesWhereSmeFieldsVisible(stages) {
  return getStagesByPositionRange(stages, 6, 14);
}

export function getStageName(stages, stageId) {
  return stages.find((stage) => stage.id === stageId)?.value;
}
