import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  loading: false,
  error: null,
  filters: null,
  inProgressIds: [],
};

const slice = createSlice({
  name: "purchasedPage",
  initialState,
  reducers: {
    loadPurchasedList: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadPurchasedListSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.assessments;
      state.filters = action.payload.filters;
      state.error = null;
    },
    loadPurchasedListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    inProgressListSync: (state, action) => {
      state.inProgressIds = action.payload;
    },
    removeInProgressId: (state, action) => {
      state.inProgressIds = state.inProgressIds.filter(
        (id) => id !== action.payload
      );
    },
    cancelLoadPurchasedAssessments: (state) => {
      state.inProgressIds = [];
    },
  },
});

export const {
  loadPurchasedList,
  loadPurchasedListSuccess,
  loadPurchasedListError,
  loadPurchasedFilters,
  loadPurchasedFiltersSuccess,
  loadPurchasedFiltersError,
  inProgressListSync,
  removeInProgressId,
  cancelLoadPurchasedAssessments,
} = slice.actions;

export default slice;
