import { defineMessages } from "react-intl";

const scope = "components.MainLayout.components.CookiePolicyModalController";

export default defineMessages({
  modalTitle: {
    id: `${scope}modalTitle`,
    defaultMessage: "TruSight Cookie Policy",
  },
  modalContentText: {
    id: `${scope}modalContentText`,
    defaultMessage: "TruSight uses cookies to provide necessary website functionality and to improve your experience. By using our website, you agree to {privacyPolicyLink} and our cookies usage.",
  },
  confirmButtonText: {
    id: `${scope}confirmButtonText`,
    defaultMessage: "Ok",
  },
  ourPrivacyPolicy: {
    id: `${scope}ourPrivacyPolicy`,
    defaultMessage: "our Privacy Policy",
  },
});
