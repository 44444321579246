import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  purchasableTemplates: null,
  loading: false,
};

const slice = createSlice({
  name: "purchaseAssessmentWithTemplates",
  initialState,
  reducers: {
    loadPurchasableTemplates: (state) => {
      state.purchasableTemplates = null;
      state.loading = true;
    },
    loadPurchasableTemplatesSuccess: (state, { payload }) => {
      payload.forEach((assessment) =>
        assessment.templates.forEach((template) => {
          if (template.id == null) {
            template.id = template.name;
            template.isProductType = true;
          }
        })
      );
      state.purchasableTemplates = payload;
      state.loading = false;
    },
    loadPurchasableTemplatesError: (state) => {
      state.loading = false;
    },
    resetPurchasableTemplates: () => initialState,
  },
});

export const {
  loadPurchasableTemplates,
  loadPurchasableTemplatesSuccess,
  loadPurchasableTemplatesError,
  resetPurchasableTemplates,
} = slice.actions;
export default slice;
