import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";
import { CLASSIFIERS } from "app/constants";

export const selectSlice = (state) => state[slice.name];

export const selectClassifiersSlice = createSelector(
  [selectSlice],
  (state) => state.classifiers
);

export const selectClassifierItemsSlice = (name) =>
  createSelector([selectClassifiersSlice], (state) => state[name]);

export const selectCountriesSlice = createSelector(
  [selectClassifiersSlice],
  (state) => state[CLASSIFIERS.country]
);

export const selectStatesSlice = createSelector(
  [selectClassifiersSlice],
  (state) => state[CLASSIFIERS.state]
);

export const selectAssessmentStatusesSlice = createSelector(
  [selectClassifiersSlice],
  (state) => state[CLASSIFIERS.assessmentStatus]
);

export const selectPeerReviewFlagTypeSlice = createSelector(
  [selectClassifiersSlice],
  (state) => state[CLASSIFIERS.peerReviewFlagType]
);

export const selectPeerReviewFlagStatusSlice = createSelector(
  [selectClassifiersSlice],
  (state) => state[CLASSIFIERS.peerReviewFlagStatus]
);
