export const updateDownloadFileName = (response) => {
  const firstPartOfFileName =
    response.headers.map["content-disposition"].split('filename="');

  // Check if file name has quotes and ignore them
  if (firstPartOfFileName.length > 1) {
    return decodeURI(firstPartOfFileName[1].split('";')[0]);
  } else {
    return decodeURI(
      response.headers.map["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
    );
  }
};

export const downloadFile = (response, filename) => {
  response.blob().then((blob) => {
    //IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    } else {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  });
};
