import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Button, {
  buttonTypes,
} from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import { ReactComponent as GearIcon } from "assets/gear.svg";
import { selectIsDirty } from "services/form/selectors";
import { setIsDirty } from "services/form/slice";
import DropdownMenu from "components/DropdownMenu";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import { getIntersectingBulkOptions } from "./utils/getIntersectingBulkOptions";
import componentMessages from "../../messages";

import "./purchase-modal-header.scss";

export const PurchaseModalHeader = ({
  selectedAssessmentsDetails,
  setSelectedAssessmentsDetails,
  isThereAssessmentWithEscalationTier,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDirty = useSelector(selectIsDirty);
  const dropdownWrapper = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [intersectingBulkOptions, setIntersectingBulkOptions] = useState(
    getIntersectingBulkOptions(selectedAssessmentsDetails)
  );

  useEffect(() => {
    setIntersectingBulkOptions(
      getIntersectingBulkOptions(selectedAssessmentsDetails)
    );
  }, [selectedAssessmentsDetails]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSetAllAssessmentsTypes = (optionId) => {
    toggleDropdown();
    const updatedAssessmentsDetails = selectedAssessmentsDetails.map(
      (selectedAssessmentDetails) => {
        const selectedOption = selectedAssessmentDetails.options.find(
          (option) => option.id === optionId
        );
        return {
          ...selectedAssessmentDetails,
          selectedOptionId:
            selectedOption?.id ?? selectedAssessmentDetails.selectedOptionId,
          selectedOptionName:
            selectedOption?.name ??
            selectedAssessmentDetails.selectedOptionName,
        };
      }
    );

    if (!isDirty) {
      dispatch(setIsDirty({ value: true }));
    }

    setSelectedAssessmentsDetails(updatedAssessmentsDetails);
  };

  useCheckOutsideClick(dropdownWrapper, toggleDropdown, isDropdownOpen);

  return (
    <div className="purchase-modal-header" data-testid="purchase-modal-header">
      <div
        className="purchase-modal-header__text"
        data-testid="purchase-modal-header-description"
      >
        {intl.formatMessage(componentMessages.purchaseAssessmentHeaderText)}
        {isThereAssessmentWithEscalationTier && (
          <FormattedMessage
            data-testid="purchase-modal-header-escalation-description"
            {...componentMessages.assessmentInEscalationText}
            values={{
              ...componentMessages.assessmentInEscalationText.values,
            }}
          />
        )}
      </div>
      {selectedAssessmentsDetails.length > 1 &&
        intersectingBulkOptions.length > 0 && (
          <div
            ref={dropdownWrapper}
            className="purchase-modal-header__set-all-products-types"
            data-testid="purchase-modal-header-set-all-products-types"
          >
            <Button
              ariaExpanded={isDropdownOpen}
              ariaHasPopup
              leadingIcon={<GearIcon />}
              followingIcon={<ChevronIcon />}
              isSecondary
              onClick={toggleDropdown}
              buttonType={buttonTypes.link}
            >
              {intl.formatMessage(componentMessages.setAllProductsTo)}
            </Button>
            <DropdownMenu isDropdownOpen={isDropdownOpen}>
              {intersectingBulkOptions.map((option) => {
                return (
                  <button
                    key={option.name}
                    className="dropdown-menu__item"
                    data-testid="purchase-modal-header-dropdown-item"
                    onClick={() => handleSetAllAssessmentsTypes(option.id)}
                    type="button"
                  >
                    <div className="purchase-modal-header__dropdown-text purchase-modal-header__dropdown-text--main">
                      {option.name}
                    </div>
                    <div className="purchase-modal-header__dropdown-text purchase-modal-header__dropdown-text--secondary">
                      {`${intl.formatMessage(componentMessages.createdBy)}: ${
                        option.createdBy
                      }`}
                    </div>
                  </button>
                );
              })}
            </DropdownMenu>
          </div>
        )}
    </div>
  );
};

PurchaseModalHeader.defaultProps = {
  selectedAssessmentsDetails: [],
  setSelectedAssessmentsDetails: () => {},
  isThereAssessmentWithEscalationTier: false,
};

PurchaseModalHeader.propTypes = {
  selectedAssessmentsDetails: PropTypes.array,
  setSelectedAssessmentsDetails: PropTypes.func,
  isThereAssessmentWithEscalationTier: PropTypes.bool,
};

export default PurchaseModalHeader;
