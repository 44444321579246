import { createSlice } from "@reduxjs/toolkit";

const NAMESPACE = "matchingHistoryPage";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    data: null,
    isLoading: false,
    error: null,
    modals: {
      modalData: null,
      undoMatchModal: {
        isOpen: false,
        isLoading: false,
      },
    },
  },
  reducers: {
    loadCustomerMatchingHistory: (state) => {
      state.data = null;
      state.isLoading = true;
    },
    loadAdminMatchingHistory: (state) => {
      state.data = null;
      state.isLoading = true;
    },
    loadMatchingHistorySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadMatchingHistoryError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeMatchFromGrid: (state, { payload }) => {
      state.data = state.data.filter((entity) => entity.id !== payload);
    },
    setModalData: (state, { payload }) => {
      state.modals.modalData = payload;
    },
    setUndoMatchModalOpen: (state, { payload }) => {
      state.modals.undoMatchModal.isOpen = payload;
    },
    undoMatch: (state) => {
      state.modals.undoMatchModal.isLoading = true;
    },
    undoMatchSuccess: (state) => {
      state.modals.undoMatchModal.isLoading = false;
      state.modals.undoMatchModal.isOpen = false;
    },
    undoMatchError: (state) => {
      state.modals.undoMatchModal.isLoading = false;
    },
  },
});

export const {
  loadCustomerMatchingHistory,
  loadAdminMatchingHistory,
  loadMatchingHistorySuccess,
  loadMatchingHistoryError,
  removeMatchFromGrid,
  setModalData,
  setUndoMatchModalOpen,
  undoMatch,
  undoMatchSuccess,
  undoMatchError,
} = slice.actions;

export default slice;
