import { LOCAL_STORAGE_DOWNLOAD_IDS_KEY } from "./constants";

export const getUserInitiatedDownloadIds = () => {
  const serializedString = localStorage.getItem(LOCAL_STORAGE_DOWNLOAD_IDS_KEY);
  return serializedString ? JSON.parse(serializedString) : [];
};

export const saveUserInitiatedDownloadIds = (array) => {
  return array?.length > 0
    ? localStorage.setItem(
        LOCAL_STORAGE_DOWNLOAD_IDS_KEY,
        JSON.stringify(array)
      )
    : localStorage.removeItem(LOCAL_STORAGE_DOWNLOAD_IDS_KEY);
};
