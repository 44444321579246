import { createSlice } from "@reduxjs/toolkit";

export const initialModalState = {
  authStateModal: {
    data: null,
    isOpen: false,
    isStatusChangePending: false,
  },
};

export const initialState = {
  isAuthorizationsDocumentLoading: false,
  document: null,
  hasPendingAuthorizations: false,
  hasAuthorizationsDocumentInitialised: false,
  ...initialModalState,
};

export const slice = createSlice({
  name: "authorizationsPage",
  initialState,
  reducers: {
    loadAuthorizationsDocument: (state) => {
      state.document = null;
      state.isAuthorizationsDocumentLoading = true;
      state.hasAuthorizationsDocumentInitialised = true;
    },
    loadAuthorizationsDocumentSuccess: (state, { payload }) => {
      state.document = payload;
      state.isAuthorizationsDocumentLoading = false;
    },
    loadAuthorizationsDocumentError: (state) => {
      state.isAuthorizationsDocumentLoading = false;
    },
    setHasPendingAuthorizations: (state, { payload }) => {
      state.hasPendingAuthorizations = payload;
    },
    resetAuthorizationsModalStore: (state) => {
      return {
        ...state,
        ...initialModalState,
      };
    },
    setIsAuthStateModalOpen: (state, { payload }) => {
      state.authStateModal.isOpen = payload;
    },
    setAuthStateModalData: (state, { payload }) => {
      state.authStateModal.data = payload;
    },
    setOrderAuthorizationStatus: (state) => {
      state.authStateModal.isStatusChangePending = true;
    },
    setOrderAuthorizationStatusSuccess: (state, { payload }) => {
      state.authStateModal.isStatusChangePending = false;

      const targetOrder = state.document.find(
        (order) => order.id === payload.authorizationId
      );
      targetOrder.status = payload.targetAuthorizationValue;
      targetOrder.authorizedBy = payload.authorizedBy;
      targetOrder.authorizedOn = new Date().getTime();
    },
    setOrderAuthorizationStatusError: (state) => {
      state.authStateModal.isStatusChangePending = false;
    },
    resetAuthorizationsStore: () => initialState,
  },
});

export const {
  loadAuthorizationsDocument,
  loadAuthorizationsDocumentSuccess,
  loadAuthorizationsDocumentError,
  resetAuthorizationsModalStore,
  setIsAuthStateModalOpen,
  setAuthStateModalData,
  setOrderAuthorizationStatus,
  setOrderAuthorizationStatusSuccess,
  setOrderAuthorizationStatusError,
  setHasPendingAuthorizations,
  resetAuthorizationsStore,
} = slice.actions;

export default slice;
