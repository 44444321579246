import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectState = (state) => state[slice.name];

export const selectData = createSelector([selectState], (state) => state.data);

export const selectIsGenerating = createSelector(
  [selectState],
  (state) => state.isGenerating
);

export const selectIsSaving = createSelector(
  [selectState],
  (state) => state.isSaving
);

export const selectIsSaveFailed = createSelector(
  [selectState],
  (state) => state.isSaveFailed
);

export const selectIsLoading = createSelector(
  [selectState],
  (state) => state.isLoading
);

export const selectError = createSelector(
  [selectState],
  (state) => state.error
);

export const selectTpData = (tpId) =>
  createSelector([selectData], (data) => {
    return data.find((tp) => tp.thirdPartyId === tpId);
  });

export const selectTpDataByCandidateId = (tpCandidateId) =>
  createSelector([selectData], (data) => {
    return data.find((tp) => tp.candidateId === tpCandidateId);
  });

export const selectRequestAssessment = (tpId, requestId) =>
  createSelector(selectTpData(tpId), (tpData) =>
    tpData?.assessments?.find(
      (assessment) => assessment.value.requestId === requestId
    )
  );

export const selectAssessmentByRequestRowGuid = (
  tpId,
  rowGuid,
  tpCandidateId
) => {
  let selector;
  if (!tpId && tpCandidateId) {
    selector = selectTpDataByCandidateId(tpCandidateId);
  } else {
    selector = selectTpData(tpId);
  }

  return createSelector(selector, (tpData) =>
    tpData?.assessments?.find((assessment) =>
      assessment.value.requests?.find((request) => request.rowGuid === rowGuid)
    )
  );
};

export const selectIsConnectRequestModalOpen = createSelector(
  [selectState],
  (state) => state.modals.connectRequestModal.isOpen
);

export const selectIsConnectRequestModalSaving = createSelector(
  [selectState],
  (state) => state.modals.connectRequestModal.isSaving
);

export const selectConnectRequestModalData = createSelector(
  [selectState],
  (state) => state.modals.connectRequestModal.modalData
);

export const selectDisconnectRequestModalOpen = createSelector(
  [selectState],
  (state) => state.modals.disconnectRequestModal.isOpen
);

export const selectRequestDisconnecting = createSelector(
  [selectState],
  (state) => state.modals.disconnectRequestModal.isDisconnecting
);

export const selectDisconnectRequestModalData = createSelector(
  [selectState],
  (state) => state.modals.disconnectRequestModal.modalData
);

export const selectIsCreateAssessmentActionPendingById = (id) =>
  createSelector([selectState], (state) =>
    state.createAssessmentPendingEntities.includes(id)
  );

export const selectNewlyCreatedThirdParty = createSelector(
  [selectState],
  (state) => state.createdThirdParty
);
