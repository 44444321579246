import PropTypes from "prop-types";

export const childrenShape = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const tagCollectionShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isTruSightCollection: PropTypes.bool.isRequired,
  modifiedOn: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  collectionDocument: PropTypes.shape({
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        checklist: PropTypes.arrayOf(
          PropTypes.shape({
            serialId: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        serialId: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
});
