import { all } from "redux-saga/effects";

import languageProviderSagas from "components/LanguageProvider/services/sagas";
import assessmentsProviderSagas from "pages/AssessmentsPage/services/sagas";
import authorizationSagas from "components/AuthorizedComponent/sagas";
import unmatchedThirdPartiesSagas from "pages/MyListPage/UnmatchedPage/services/sagas";
import catalogSagas from "pages/CatalogPage/services/sagas";
import purchasedProviderSagas from "pages/PurchasedPage/services/sagas";
import myThirdPartiesProviderSagas from "pages/MyListPage/MyThirdPartiesPage/services/sagas";
import notificationsProviderSagas from "components/Notifications/services/sagas";
import assessmentDetailsProviderSagas from "components/AssessmentDetails/services/sagas";
import thirdPartyDetailsProviderSagas from "pages/MyListPage/MyThirdPartiesPage/components/ThirdPartyDetails/services/sagas";
import watchAssessmentsSagas from "services/watchAssessments/sagas";
import thirdPartiesSagas from "pages/ThirdPartiesPage/services/sagas";
import truSightUsersSagas from "pages/TruSightUsersPage/services/sagas";
import ThirdPartyAssessmentsPageProviderSagas from "pages/ThirdPartyAssessmentsPage/services/sagas";
import thirdPartySagas from "pages/ThirdPartyPage/services/sagas";
import companyProfileSagas from "pages/ThirdPartyPage/CompanyProfile/services/sagas";
import assessmentSagas from "pages/AssessmentPage/services/sagas";
import classifierItemsSagas from "services/classifiers/sagas";
import documentSagas from "services/documents/sagas";
import questionnaireDocumentSagas from "pages/AssessmentPage/Scoping/services/sagas";
import bpqDocumentSagas from "pages/AssessmentPage/BPQ/services/sagas";
import assessmentArtifactsSagas from "pages/AssessmentPage/Artifacts/services/sagas";
import requestsPageSagas from "pages/MyListPage/RequestsPage/services/sagas";
import questionArtifactsSagas from "pages/AssessmentPage/BPQ/components/QuestionnaireTable/components/AttachmentsRenderer/services/sagas";
import qcFlagDrawerSagas from "pages/AssessmentPage/BPQ/components/QCFlagDrawer/services/sagas";
import thirdPartyResponseDrawerSaga from "pages/AssessmentPage/BPQ/components/ThirdPartyResponseDrawer/services/sagas";
import thematicFlagsDrawerSagas from "pages/AssessmentPage/BPQ/components/ThematicFlagsDrawer/services/sagas";
import bpqScopingSagas from "pages/AssessmentPage/BpqScoping/services/sagas";
import authorizationsSagas from "pages/AssessmentPage/Authorizations/services/sagas";
import productDownloadSagas from "pages/AssessmentPage/ProductDownload/services/sagas";
import purchaseAssessmentSaga from "components/BulkAssessmentActions/components/PurchaseAssessment/services/sagas";
import overviewDataSagas from "pages/AssessmentPage/Overview/services/sagas";
import apiKeysSagas from "pages/SettingsPage/components/ApiKeysSection/services/sagas";
import customTemplateSagas from "pages/TemplatesAndMappingPage/CustomTemplatePage/services/sagas";
import customTemplatesSagas from "pages/TemplatesAndMappingPage/CustomTemplates/services/sagas";
import tagCollectionSagas from "pages/TemplatesAndMappingPage/TagCollectionPage/services/sagas";
import submitBpqModalSagas from "pages/AssessmentPage/BPQ/components/SubmitBpqModal/services/sagas";
import rightSideControlsSagas from "pages/AssessmentPage/components/RightSideControls/services/sagas";
import bpqCommentBuilderSagas from "pages/AssessmentPage/BPQ/components/BPQCommentBuilder/services/sagas";
import peerReviewDrawerSagas from "pages/AssessmentPage/BPQ/components/PeerReviewDrawer/services/sagas";
import companyPeopleSagas from "pages/ThirdPartyPage/People/services/sagas";
import assessmentPeopleListSagas from "pages/AssessmentPage/People/services/sagas";
import apiDocumentationSagas from "pages/DeveloperGuidePage/services/sagas";
import tagsAndCollectionsSagas from "pages/TemplatesAndMappingPage/TagsAndCollections/services/sagas";
import templatesAndMappingSagas from "pages/TemplatesAndMappingPage/services/sagas";
import purchaseAssessmentWithTemplatesSaga from "components/BulkAssessmentActions/components/PurchaseAssessmentWithTemplates/services/sagas";
import manageCustomersSagas from "pages/ManageCustomersPage/services/sagas";
import manageCustomerSagas from "pages/ManageCustomerPage/services/sagas";
import manageCustomerUnmatchedSagas from "pages/ManageCustomerPage/UnmatchedAdminPage/services/sagas";
import uploadThirdPartiesSagas from "components/UploadThirdPartiesModal/services/sagas";
import unmatchedThirdPartiesHeadingSagas from "pages/ManageCustomerPage/UnmatchedAdminPage/components/UnmatchedThirdPartiesHeading/services/sagas";
import typeAheadSagas from "components/UnmatchedThirdParties/services/sagas";
import bowSagas from "pages/GenerateBow/services/sagas";
import myNetworksSagas from "pages/MyNetworkPage/services/sagas";
import myNetworkAddRelationshipModalSagas from "pages/MyNetworkPage/components/AddRelationshipModal/services/sagas";
import matchingHistorySagas from "pages/ManageCustomerPage/MatchingHistoryPage/services/sagas";
import connectionDetailsSagas from "pages/MyNetworkPage/components/ConnectionDetailsDrawer/services/sagas";
import headerSagas from "../components/Header/services/sagas";

export default function* rootSaga() {
  yield all([
    assessmentsProviderSagas(),
    languageProviderSagas(),
    authorizationSagas(),
    unmatchedThirdPartiesSagas(),
    catalogSagas(),
    purchasedProviderSagas(),
    myThirdPartiesProviderSagas(),
    notificationsProviderSagas(),
    assessmentDetailsProviderSagas(),
    thirdPartyDetailsProviderSagas(),
    watchAssessmentsSagas(),
    thirdPartiesSagas(),
    truSightUsersSagas(),
    ThirdPartyAssessmentsPageProviderSagas(),
    thirdPartySagas(),
    companyProfileSagas(),
    assessmentSagas(),
    classifierItemsSagas(),
    documentSagas(),
    questionnaireDocumentSagas(),
    bpqDocumentSagas(),
    assessmentArtifactsSagas(),
    requestsPageSagas(),
    qcFlagDrawerSagas(),
    thirdPartyResponseDrawerSaga(),
    thematicFlagsDrawerSagas(),
    bpqScopingSagas(),
    authorizationsSagas(),
    productDownloadSagas(),
    purchaseAssessmentSaga(),
    overviewDataSagas(),
    questionArtifactsSagas(),
    apiKeysSagas(),
    customTemplateSagas(),
    customTemplatesSagas(),
    tagCollectionSagas(),
    submitBpqModalSagas(),
    rightSideControlsSagas(),
    bpqCommentBuilderSagas(),
    peerReviewDrawerSagas(),
    companyPeopleSagas(),
    assessmentPeopleListSagas(),
    apiDocumentationSagas(),
    tagsAndCollectionsSagas(),
    templatesAndMappingSagas(),
    purchaseAssessmentWithTemplatesSaga(),
    manageCustomersSagas(),
    manageCustomerSagas(),
    manageCustomerUnmatchedSagas(),
    uploadThirdPartiesSagas(),
    unmatchedThirdPartiesHeadingSagas(),
    typeAheadSagas(),
    bowSagas(),
    myNetworksSagas(),
    matchingHistorySagas(),
    connectionDetailsSagas(),
    myNetworkAddRelationshipModalSagas(),
    headerSagas(),
  ]);
}
