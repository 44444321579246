import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
} from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import { getUpdatedBowData, getCustomerNameFromRequest } from "../utils/utils";
import {
  convertToAssessment,
  convertToAssessmentSuccess,
  generateBow,
  loadData,
  removeCreateAssessmentPendingEntity,
  setData,
  setError,
  storeBow,
  checkSavingStatus,
  checkSavingStatusSuccess,
  convertToAssessmentWithThirdParty,
  convertToAssessmentWithThirdPartySuccess,
  setCreatedNewThirdPartyId,
} from "./slice";
import { selectTpData, selectTpDataByCandidateId } from "./selectors";

export function* loadBowDataSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/book-of-work`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const jsonResponse = yield response.json();

    const data = yield call(
      getCustomerNameFromRequest,
      Array.from(jsonResponse)
    );

    const updatedData = yield call(getUpdatedBowData, data);

    yield put(setData({ thirdParties: updatedData }));
    yield put(checkSavingStatus({ accessToken }));
  } catch ({ message }) {
    yield showError(message);
    yield put(setError(message));
  }
}

export function* generateBowSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/book-of-work/request-optimizer`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to generate");
    }

    const jsonResponse = yield response.json();

    const data = yield call(
      getCustomerNameFromRequest,
      Array.from(jsonResponse)
    );

    const updatedData = yield call(getUpdatedBowData, data);

    yield put(setData({ thirdParties: updatedData }));
  } catch ({ message }) {
    yield showError(message);
    yield put(setError(message));
  }
}

export function* storeBowSaga({ payload: { accessToken, assessments } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/book-of-work`,
      {
        method: "POST",
        body: JSON.stringify(assessments),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to save");
    }
  } catch ({ message }) {
    yield showError(message);
    yield put(setError(message));
  }
}

export function* convertToAssessmentSaga({
  payload: { accessToken, tpId, candidateAssessmentId },
}) {
  try {
    const selector = yield call(selectTpData, tpId);
    const thirdParty = yield select(selector);
    const assessment = thirdParty?.assessments.find(
      ({ value }) => value.candidateAssessmentId === candidateAssessmentId
    );

    if (!assessment) {
      return;
    }

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/candidate-assessments/convert`,
      {
        method: "POST",
        body: JSON.stringify(assessment.value),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to convert");
    }

    const responseData = yield response.json();

    yield put(
      convertToAssessmentSuccess({ candidateAssessmentId, responseData, tpId })
    );
    yield put(removeCreateAssessmentPendingEntity(candidateAssessmentId));
  } catch ({ message }) {
    yield put(removeCreateAssessmentPendingEntity(candidateAssessmentId));
    yield showError(message);
    yield put(setError(message));
  }
}

export function* convertToAssessmentWithThirdSaga({
  payload: { accessToken, tpCandidateId, candidateAssessmentId },
}) {
  try {
    const selector = yield call(selectTpDataByCandidateId, tpCandidateId);
    const thirdParty = yield select(selector);
    const assessment = thirdParty?.assessments.find(
      ({ value }) => value.candidateAssessmentId === candidateAssessmentId
    );

    if (!assessment) {
      return;
    }

    const assessmentBody = { ...assessment.value, candidateId: tpCandidateId };

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/candidate-assessments/convert-with-thirdparty`,
      {
        method: "POST",
        body: JSON.stringify(assessmentBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to convert");
    }

    const responseData = yield response.json();

    yield put(
      convertToAssessmentWithThirdPartySuccess({
        candidateAssessmentId,
        responseData,
        tpCandidateId,
      })
    );
    yield put(removeCreateAssessmentPendingEntity(candidateAssessmentId));
    yield put(
      setCreatedNewThirdPartyId({
        tpCandidateId,
        thirdPartyId: responseData.thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(removeCreateAssessmentPendingEntity(candidateAssessmentId));
    yield showError(message);
    yield put(setError(message));
  }
}

export function* checkSavingStatusSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/book-of-work/save-status`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to check a status");
    }
    const statusData = yield response.json();

    yield put(checkSavingStatusSuccess(statusData));
  } catch ({ message }) {
    yield showError(message);
  }
}

export default function* bowSagas() {
  yield all([
    takeLatest(loadData.type, loadBowDataSaga),
    takeLatest(generateBow.type, generateBowSaga),
    takeLatest(storeBow.type, storeBowSaga),
    takeEvery(convertToAssessment.type, convertToAssessmentSaga),
    takeEvery(
      convertToAssessmentWithThirdParty.type,
      convertToAssessmentWithThirdSaga
    ),
    takeEvery(checkSavingStatus.type, checkSavingStatusSaga),
  ]);
}
