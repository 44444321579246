import React from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  selectIsCustomCommentDirty,
  selectIsDirty,
  selectPromptMessageKey,
} from "services/form/selectors";
import { setupPromptMessage } from "utils/setupPromptMessage/setupPromptMessage";
import { selectTemplateChangesDetected } from "pages/TemplatesAndMappingPage/CustomTemplatePage/services/selectors";
import { selectTagCollectionChangesDetected } from "pages/TemplatesAndMappingPage/TagCollectionPage/services/selectors";
import { selectDownloadingIds } from "pages/CatalogPage/services/selectors";
import { selectInProgressIds } from "pages/PurchasedPage/services/selectors";
import { PROMPT_KEYS } from "app/constants";
import routes from "app/routes";

const PromptController = () => {
  const intl = useIntl();
  const location = useLocation();
  const isDirty = useSelector(selectIsDirty);
  const isCustomCommentDirty = useSelector(selectIsCustomCommentDirty);
  const promptMessageKey = useSelector(selectPromptMessageKey);
  const customTemplateChangesDetected = useSelector(
    selectTemplateChangesDetected
  );
  const templateChangesDetected = useSelector(
    selectTagCollectionChangesDetected
  );

  const downloadingIds = useSelector(selectDownloadingIds);
  const inProgressIds = useSelector(selectInProgressIds);
  const isAssessmentProductInProgress =
    (downloadingIds.length > 0 || inProgressIds.length > 0) &&
    location.pathname === routes.purchased.path;

  const getPromptStoreKey = () => {
    if (customTemplateChangesDetected) {
      return PROMPT_KEYS.customTemplate;
    } else if (templateChangesDetected) {
      return PROMPT_KEYS.tagCollection;
    }
  };

  return (
    <Prompt
      when={
        isDirty ||
        isCustomCommentDirty ||
        customTemplateChangesDetected ||
        isAssessmentProductInProgress
      }
      message={(location, action) =>
        setupPromptMessage(
          location,
          action,
          promptMessageKey,
          intl,
          getPromptStoreKey()
        )
      }
    />
  );
};

export default PromptController;
