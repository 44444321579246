import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "watchAssessments",
  initialState: {},
  reducers: {
    watchAssessments: () => {},
    unwatchAssessments: () => {},
    addToMyList: () => {},
  },
});

export const { watchAssessments, unwatchAssessments, addToMyList } =
  slice.actions;

export default slice;
