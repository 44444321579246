export const CHECKBOX_COLUMN_ID = "checkboxSelection";
export const CONTROLS_COLUMN_ID = "controls";

export const EXPORTABLE_COLUMN_KEYS = {
  NAME: "name",
  TRU_SIGHT_ID: "truSightId",
  DUNS_ID: "dunsId",
  SERVICE_CATEGORY: "serviceCategory",
  SERVICE_CATEGORY_L2: "serviceCategoryL2",
  THIRD_PARTY_DESCRIPTION: "thirdPartyDescription",
  TYPE: "type",
  BPQ_VERSION: "bpqVersion",
  CONTROL_ENVIRONMENT: "controlEnvironment",
  CONTROL_ENVIRONMENT_DESCRIPTION: "controlEnvironmentDescription",
  YEAR: "year",
  FIELD_WORK_DATE: "fieldworkDate",
  COMPLETED_DATE: "completedDate",
  STATUS: "status",
  Assessment_Id: "assessmentId",
};

export const LOCAL_STORAGE_DOWNLOAD_IDS_KEY = "userInitiatedDownloadIds";
