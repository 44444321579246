import { createAction, createSlice } from "@reduxjs/toolkit";

export const initialState = {
  documentationKey: null,
  documentationKeyDuration: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "apiDocumentation",
  initialState,
  reducers: {
    loadApiDocumentation: (state) => {
      state.loading = true;
    },
    setApiDocumentationSuccess: (state, action) => {
      const { key, duration } = action.payload;

      state.loading = false;
      state.documentationKey = key;
      state.documentationKeyDuration = duration;
      state.error = null;
    },
    setApiDocumentationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetApiDocumentation: () => initialState,
  },
});

export const autoRefreshApiDocumentationKey = createAction(
  `${slice.name}/autoRefreshApiDocumentationKey`
);

export const cancelAutoRefreshApiDocumentationKey = createAction(
  `${slice.name}/cancelAutoRefreshApiDocumentationKey`
);

export const {
  loadApiDocumentation,
  setApiDocumentationSuccess,
  setApiDocumentationError,
  resetApiDocumentation,
} = slice.actions;

export default slice;
