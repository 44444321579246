import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import { exportPossibleMatches } from "../../services/slice";
import {
  selectCustomersList,
  selectCustomersListLoading,
  selectExportPossibleMatchesInProgress,
} from "../../services/selectors";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import pageMessages from "../../messages";

const ManageCustomersRightSideControls = () => {
  const accessToken = useAccessToken();
  const intl = useIntl();
  const dispatch = useDispatch();
  const exportPossibleMatchesInProgress = useSelector(
    selectExportPossibleMatchesInProgress
  );
  const customersList = useSelector(selectCustomersList);
  const loading = useSelector(selectCustomersListLoading);

  const handleExportPossibleMatches = () => {
    dispatch(exportPossibleMatches({ accessToken }));
  };

  return (
    <>
      {!loading && (
        <Button
          onClick={handleExportPossibleMatches}
          isLoading={exportPossibleMatchesInProgress}
          isDisabled={
            exportPossibleMatchesInProgress || customersList?.length === 0
          }
          isSecondary
        >
          {intl.formatMessage(pageMessages.exportPossibleMatches)}
        </Button>
      )}
    </>
  );
};

export default ManageCustomersRightSideControls;
