import React from "react";
import PropTypes from "prop-types";

import Toggle from "components/GlobalComponents/Toggle";
import "./table-toggle.scss";

const TableToggle = ({ offLabel, onLabel, value, isDisabled }) => {
  const toggleLabel = value ? onLabel : offLabel;

  return <Toggle label={toggleLabel} checked={value} isDisabled={isDisabled} />;
};

TableToggle.defaultProps = {
  offLabel: "Off",
  onLabel: "On",
  isDisabled: false,
};

TableToggle.propTypes = {
  value: PropTypes.bool.isRequired,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default TableToggle;
