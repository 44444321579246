import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError, showInfo } from "utils/sagaEffects/sagaEffects";
import {
  loadLookUpSearchResultsSuccess,
  loadLookUpSearchResultsError,
  proposeLookedUpMatch,
  proposeLookedUpMatchSuccess,
  proposeLookedUpMatchError,
  showAlreadyInListMessage,
  loadThirdPartiesLookUpSearchResults,
} from "components/GlobalComponents/Inputs/TypeAheadInput/services/slice";
import { setShouldInputReset } from "components/GlobalComponents/Inputs/TypeAheadInput/services/slice";
import { removeThirdPartyWithPendingAction } from "components/UnmatchedThirdParties/services/slice";
import { UNMATCHED_THIRD_PARTIES_ACTIONS } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/utils/constants";
import { addRowToRefreshQueue } from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/services/slice";

export function* loadLookUpSearchResultsSaga({ payload }) {
  try {
    const { accessToken, query, lookupFieldId } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/third-parties/manual-search/${query}`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throwCustomError(lookupFieldId, "Failed to load");
    }

    const data = yield response.json();
    yield put(loadLookUpSearchResultsSuccess({ data, lookupFieldId }));
  } catch ({ lookupFieldId }) {
    yield put(loadLookUpSearchResultsError(lookupFieldId));
    yield showError();
  }
}

export function* proposeLookedUpMatchSaga({ payload }) {
  try {
    const {
      accessToken,
      candidateId,
      toastDescription,
      truSightId,
      customerThirdPartyId,
    } = payload;
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/third-parties/manual-search/propose`,
      {
        method: "POST",
        body: JSON.stringify({ candidateId, truSightId, customerThirdPartyId }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throwCustomError(customerThirdPartyId, "Failed to propose third party.");
    }

    const lookedUpTpInformation = yield response.json();
    yield showInfo(toastDescription);
    yield put(proposeLookedUpMatchSuccess(customerThirdPartyId));
    yield put(
      setShouldInputReset({
        shouldReset: true,
        id: customerThirdPartyId,
      })
    );
    yield put(removeThirdPartyWithPendingAction(customerThirdPartyId));
    yield put(
      addRowToRefreshQueue({
        id:
          lookedUpTpInformation.truSightId || lookedUpTpInformation.candidateId,
        masterGridId: customerThirdPartyId,
        action: UNMATCHED_THIRD_PARTIES_ACTIONS.proposeLookedUp,
        newPossibleMatch: lookedUpTpInformation,
      })
    );
  } catch ({ customerThirdPartyId, message }) {
    yield put(removeThirdPartyWithPendingAction(customerThirdPartyId));
    yield put(proposeLookedUpMatchError(customerThirdPartyId));
    yield showError(message);
  }
}

function throwCustomError(customerThirdPartyId, errorText) {
  const error = new Error(errorText);
  error.customerThirdPartyId = customerThirdPartyId;
  throw error;
}

export function* showAlreadyInListMessageSaga({ payload }) {
  yield showInfo(payload);
}

export default function* thirdPartiesLookUpSagas() {
  yield all([
    takeLatest(
      loadThirdPartiesLookUpSearchResults.type,
      loadLookUpSearchResultsSaga
    ),
    takeEvery(proposeLookedUpMatch.type, proposeLookedUpMatchSaga),
    takeEvery(showAlreadyInListMessage.type, showAlreadyInListMessageSaga),
  ]);
}
