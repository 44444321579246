import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducer";
import sagas from "./sagas";
import { loadCustomerThirdPartyRequests } from "pages/MyListPage/MyThirdPartiesPage/services/slice";
import {
  loadCustomerUnmatchedThirdParties,
  loadCustomerThirdPartiesPossibleMatches,
} from "pages/ManageCustomerPage/UnmatchedAdminPage/services/slice";
import { loadUnmatchedThirdParties } from "pages/MyListPage/UnmatchedPage/services/slice";
function getStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    preloadedState: preloadedState,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [
            // Ignore serialize check as callback functions are used for ag-grid integrations
            loadCustomerThirdPartyRequests.type,
            loadUnmatchedThirdParties.type,
            loadCustomerUnmatchedThirdParties.type,
            loadCustomerThirdPartiesPossibleMatches.type,
          ],
        },
      }).concat(sagaMiddleware),

    reducer: rootReducer,
  });
  sagaMiddleware.run(sagas);
  return store;
}

export { getStore };
