import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectAuthorizedSlice = (state) => state[slice.name];

export const selectAuthorized = createSelector(
  [selectAuthorizedSlice],
  (slice) => slice.authorized
);
