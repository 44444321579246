import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { loadClassifierItemsData } from "services/classifiers/slice";
import { selectClassifierItemsSlice } from "services/classifiers/selectors";
import getSessionAccessToken from "utils/useAccessToken/getSessionAccessToken";
import "./selected-assessments-types-counter.scss";

export const SelectedAssessmentsTypesCounter = ({ selectedAssessments }) => {
  const dispatch = useDispatch();
  const ASSESSMENT_TYPE_CLASSIFIER_NAME = "assessmentType";
  const assessmentTypes = useSelector(
    selectClassifierItemsSlice(ASSESSMENT_TYPE_CLASSIFIER_NAME)
  );

  useEffect(() => {
    dispatch(
      loadClassifierItemsData({
        accessToken: getSessionAccessToken(),
        classifierName: ASSESSMENT_TYPE_CLASSIFIER_NAME,
      })
    );
  }, [dispatch]);

  return (
    <ul className="selected-assessments-types-counter">
      {assessmentTypes?.map((assessmentType) => {
        const assessmentName = assessmentType.value;
        const assessmentCounter = selectedAssessments.filter(
          (assessment) => assessment.type === assessmentName
        ).length;
        if (assessmentCounter > 0) {
          return (
            <li
              key={assessmentName}
              className="selected-assessments-types-counter__assessment-counter"
              data-testid="selected-assessments-types-counter-assessment-counter"
            >
              {assessmentCounter} {assessmentName}
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

SelectedAssessmentsTypesCounter.defaultProps = {
  selectedAssessments: [],
};

SelectedAssessmentsTypesCounter.propTypes = {
  selectedAssessments: PropTypes.array,
};

export default SelectedAssessmentsTypesCounter;
