import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadCompanyProfile,
  loadCompanyProfileError,
  loadCompanyProfileSuccess,
  updateCompanyProfile,
  updateCompanyProfileError,
} from "./slice";

function* loadCompanyProfileSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/${id}/company`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    const data = yield response.json();
    data.addresses = data.addresses.map((a) => {
      return { ...a, isMainAddress: a.id === data.mainAddressId };
    });
    yield put(loadCompanyProfileSuccess(data));
  } catch ({ message }) {
    yield put(loadCompanyProfileError(message));
  }
}

function* updateCompanyProfileSaga({ payload }) {
  try {
    yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/${payload.id}/`,
      {
        method: "PUT",
        body: JSON.stringify(payload.details),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    yield put(
      loadCompanyProfile({
        id: payload.id,
        accessToken: payload.accessToken,
      })
    );
  } catch (error) {
    yield put(updateCompanyProfileError(error.message));
  }
}

export default function* companyProfileSagas() {
  yield all([
    yield takeLatest(loadCompanyProfile.type, loadCompanyProfileSaga),
    yield takeLatest(updateCompanyProfile.type, updateCompanyProfileSaga),
  ]);
}
