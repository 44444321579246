import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { handleDirtyFormPrompt } from "utils/handleDirtyFormPrompt/handleDirtyFormPrompt";
import { selectIsDirty } from "services/form/selectors";
import "./ts-modal.scss";

const TsModal = ({
  modalTitle,
  modalHeaderContent,
  modalContent,
  showFooter,
  confirmActionText,
  confirmActionIcon,
  cancelActionText,
  isModalOpen,
  setModalOpen,
  onConfirmAction,
  className,
  type,
  isConfirmButtonDisabled,
  isConfirmButtonLoading,
  onCloseModal,
  isConfirmButtonDark,
  overlayClassName,
  showCancelButton,
  showCancelButtonTop,
  showSecondaryButton,
  secondaryActionIcon,
  onSecondaryAction,
  isSecondaryButtonLoading,
  isSecondaryButtonDisabled,
  secondaryActionText,
  footerUpperRow,
  showConfirmButton,
  shouldCloseOnEsc,
  alignRightSecondaryButton,
  alignRightPrimaryButton,
  preventClose,
  showLeavePrompt,
  allowOverflowYScroll,
}) => {
  const isDirty = useSelector(selectIsDirty);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (showLeavePrompt) {
      window.onbeforeunload = () => isDirty || null;

      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [isDirty, showLeavePrompt]);

  const closeModal = () => {
    const handleClose = () => {
      setModalOpen(false);
      onCloseModal();
    };

    if (showLeavePrompt) {
      handleDirtyFormPrompt(handleClose, isDirty, intl, dispatch);
    } else {
      handleClose();
    }
  };

  const focusCloseButton = () => {
    let button;
    if (showCancelButtonTop) {
      button = document.querySelector(".js-close-btn");
    } else if (showSecondaryButton) {
      button = document.querySelector(".ts-modal__secondary-btn");
    } else {
      return;
    }

    // Needed when multiple drawer / modal toggle is happening on same page
    setTimeout(() => {
      button.focus();
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      className={classNames("ts-modal", className)}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      onAfterOpen={focusCloseButton}
      shouldCloseOnEsc={!preventClose && shouldCloseOnEsc}
      overlayClassName={overlayClassName}
      ariaHideApp={false}
    >
      <div className="ts-modal__header" data-testid="ts-modal">
        <div className="ts-modal__title-container">
          <h3 className="ts-modal__title" data-testid="ts-modal-title">
            {modalTitle}
          </h3>
          <div className="ts-modal__close-container">
            {showCancelButtonTop && (
              <ButtonIcon
                className="js-close-btn"
                onClick={closeModal}
                label="Close modal"
                icon={<CloseIcon />}
                isDisabled={preventClose}
                dataTestId={"ts-modal-close-btn"}
              />
            )}
          </div>
        </div>
        {modalHeaderContent && (
          <div
            data-testid="ts-modal-header-content"
            className="ts-modal__header-content"
          >
            {modalHeaderContent}
          </div>
        )}
      </div>
      <div
        className={classNames("ts-modal__body", {
          "ts-modal__body--scrollY": allowOverflowYScroll,
        })}
      >
        {modalContent}
      </div>
      {showFooter && (
        <div data-testid="ts-modal-footer" className="ts-modal__footer">
          {footerUpperRow && (
            <div
              data-testid="ts-modal-footer-upper-row"
              className="ts-modal__footer-upper-row"
            >
              {footerUpperRow}
            </div>
          )}
          <div
            className={classNames("ts-modal__footer-main-row", {
              "ts-modal__footer-main-row--elements-inside-aligned-right":
                !showCancelButton && !showSecondaryButton && showConfirmButton,
            })}
          >
            {showCancelButton && (
              <div data-testid="ts-modal-cancel-button">
                <Button
                  onClick={closeModal}
                  isLight
                  isSecondary
                  isDisabled={preventClose}
                >
                  {cancelActionText}
                </Button>
              </div>
            )}
            {showSecondaryButton && (
              <div data-testid="ts-modal-secondary-button">
                <Button
                  className={classNames("ts-modal__secondary-btn", {
                    "ts-modal__btn--align-right": alignRightSecondaryButton,
                  })}
                  leadingIcon={secondaryActionIcon}
                  onClick={onSecondaryAction}
                  isLoading={isSecondaryButtonLoading}
                  isDisabled={isSecondaryButtonDisabled || preventClose}
                  isSecondary
                  isLight
                >
                  {secondaryActionText}
                </Button>
              </div>
            )}
            {showConfirmButton && (
              <Button
                className={classNames("ts-modal__confirm-btn", {
                  "ts-modal__btn--align-right": alignRightPrimaryButton,
                })}
                leadingIcon={confirmActionIcon}
                isHighlighted={!isConfirmButtonDark}
                type={type}
                onClick={onConfirmAction}
                isLoading={isConfirmButtonLoading}
                isDisabled={isConfirmButtonDisabled || preventClose}
                data-testid="ts-modal-confirm-btn"
              >
                {confirmActionText}
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

TsModal.defaultProps = {
  isConfirmButtonLoading: false,
  isConfirmButtonDisabled: false,
  showCancelButton: true,
  showCancelButtonTop: true,
  showSecondaryButton: false,
  secondaryActionIcon: null,
  onSecondaryAction: () => {},
  isSecondaryButtonLoading: false,
  isSecondaryButtonDisabled: false,
  secondaryActionText: "",
  type: "button",
  cancelActionText: "Cancel",
  confirmActionText: "",
  showConfirmButton: true,
  shouldCloseOnEsc: true,
  alignRightSecondaryButton: false,
  alignRightPrimaryButton: false,
  preventClose: false,
  showLeavePrompt: false,
  onCloseModal: () => {},
  setModalOpen: () => {},
  allowOverflowYScroll: false,
};

TsModal.propTypes = {
  modalTitle: PropTypes.string,
  modalHeaderContent: PropTypes.node,
  modalContent: PropTypes.node,
  showFooter: PropTypes.bool,
  confirmActionText: PropTypes.string,
  confirmActionIcon: PropTypes.node,
  cancelActionText: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func,
  onConfirmAction: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  isConfirmButtonDisabled: PropTypes.bool,
  isConfirmButtonDark: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
  onCloseModal: PropTypes.func,
  overlayClassName: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showCancelButtonTop: PropTypes.bool,
  showSecondaryButton: PropTypes.bool,
  secondaryActionIcon: PropTypes.node,
  onSecondaryAction: PropTypes.func,
  isSecondaryButtonLoading: PropTypes.bool,
  isSecondaryButtonDisabled: PropTypes.bool,
  secondaryActionText: PropTypes.string,
  footerUpperRow: PropTypes.node,
  showConfirmButton: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  alignRightSecondaryButton: PropTypes.bool,
  alignRightPrimaryButton: PropTypes.bool,
  preventClose: PropTypes.bool,
  showLeavePrompt: PropTypes.bool,
  allowOverflowYScroll: PropTypes.bool,
};

export default TsModal;
