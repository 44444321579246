import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    designElementsPanel: {
      isOpen: false,
      data: null,
    },
  },
  reducers: {
    setDesignElementsPanelOpen: (state, { payload }) => {
      state.designElementsPanel.isOpen = payload.isOpen;

      if (payload.drawerData) {
        state.designElementsPanel.data = payload.drawerData;
      }
    },
  },
});

export const { setDesignElementsPanelOpen } = slice.actions;

export default slice;
