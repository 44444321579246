import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import { secondsToMs } from "utils/secondsToMs/secondsToMs";
import ThirdPartyUploadWizard from "./components/ThirdPartyUploadWizard/ThirdPartyUploadWizard";
import { setCurrentElement, setHasChanges } from "./services/slice";
import { selectHasChanges } from "./services/selectors";
import TsModal from "components/GlobalComponents/Modal";
import { selectShouldCustomerUnmatchedThirdPartiesReload } from "pages/ManageCustomerPage/UnmatchedAdminPage/services/selectors";
import styles from "scss/base/_export.module.scss";
import "./upload-third-parties.scss";

const UploadThirdParties = ({
  isOpen,
  setIsOpen,
  parentPagePath,
  uploadThirdPartiesTitle,
  uploadThirdPartiesSubTitle,
  organizationId,
  reloadUnmatchedThirdPartiesReducer,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authState } = useOktaAuth();
  const closeTimeout = secondsToMs(styles.transitionBase);
  const hasChanges = useSelector(selectHasChanges);
  const hasReloadTriggered = useSelector(
    selectShouldCustomerUnmatchedThirdPartiesReload
  );

  const handleReloadUnmatchedThirdParties = () => {
    if (!hasReloadTriggered) {
      dispatch(setHasChanges(false));
      dispatch(reloadUnmatchedThirdPartiesReducer(true));
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      if (
        location.pathname === parentPagePath &&
        authState.isAuthenticated &&
        hasChanges
      ) {
        handleReloadUnmatchedThirdParties();
      }
      dispatch(setCurrentElement({ currentElement: 1 }));
    }, closeTimeout);
  };

  return (
    <TsModal
      data-testId="upload-modal"
      modalTitle={uploadThirdPartiesTitle}
      modalHeaderContent={uploadThirdPartiesSubTitle}
      className="upload-third-parties"
      isModalOpen={isOpen}
      setModalOpen={closeModal}
      shouldCloseOnEsc={true}
      showCancelButton={false}
      showSecondaryButton={false}
      modalContent={
        <ThirdPartyUploadWizard
          closeModal={closeModal}
          organizationId={organizationId}
          parentPagePath={parentPagePath}
        />
      }
    />
  );
};

UploadThirdParties.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  parentPagePath: PropTypes.string.isRequired,
  uploadThirdPartiesTitle: PropTypes.string.isRequired,
  uploadThirdPartiesSubTitle: PropTypes.string.isRequired,
  organizationId: PropTypes.number,
  reloadUnmatchedThirdPartiesReducer: PropTypes.func.isRequired,
};

export default UploadThirdParties;
