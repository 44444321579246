import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Spinner from "components/Spinner/Spinner";
import SelectInput from "components/GlobalComponents/Inputs/SelectInput";
import { selectIsDirty } from "services/form/selectors";
import { setIsDirty } from "services/form/slice";
import { REACT_SELECT_PORTAL_PLACEHOLDER } from "app/constants";
import componentMessages from "../../messages";
import SelectDropdownIcon from "components/GlobalComponents/Inputs/SelectInput/components/SelectDropdownIcon/SelectDropdownIcon";
import SelectInputField from "components/GlobalComponents/Inputs/SelectInput/components/SelectInputField/SelectInputField";
import SelectOptionWithDescription from "components/GlobalComponents/Inputs/SelectInput/components/SelectOptionWithDescription/SelectOptionWithDescription";

import "./purchase-modal-content.scss";

export const PurchaseModalContent = ({
  assessmentsDetails,
  setSelectedAssessmentsDetails,
  isLoading,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDirty = useSelector(selectIsDirty);

  const customSelectComponents = {
    DropdownIndicator: SelectDropdownIcon,
    ValueContainer: SelectInputField,
    Option: SelectOptionWithDescription,
  };

  const handleAssessmentTypeChange = (assessmentId, option) => {
    if (!isDirty) {
      dispatch(setIsDirty({ value: true }));
    }

    let updatedAssessmentsDetails = [...assessmentsDetails];
    const assessmentToUpdate = updatedAssessmentsDetails.find(
      (assessmentDetails) => assessmentDetails.assessmentId === assessmentId
    );
    const updatedAssessmentDetails = { ...assessmentToUpdate };
    updatedAssessmentDetails.selectedOptionId = option.value;
    updatedAssessmentDetails.selectedOptionName = option.label;

    updatedAssessmentsDetails = updatedAssessmentsDetails.map(
      (assessmentDetails) => {
        if (assessmentDetails.assessmentId === assessmentId) {
          return updatedAssessmentDetails;
        } else {
          return assessmentDetails;
        }
      }
    );

    setSelectedAssessmentsDetails(updatedAssessmentsDetails);
  };

  const calculateOwnedDataPoints = (assessment) => {
    let totalDataPoints = 0;
    let alreadyOwnedDataPoints = 0;
    assessment.options.forEach((option) => {
      totalDataPoints += option.dataPoints.totalPossibleCount;
      alreadyOwnedDataPoints += option.dataPoints.alreadyOwnedCount;
    });

    switch (alreadyOwnedDataPoints) {
      case 0:
        return intl.formatMessage(componentMessages.unowned);
      case totalDataPoints:
        return intl.formatMessage(componentMessages.owned);
      default:
        return intl.formatMessage(componentMessages.partiallyOwned);
    }
  };

  const getAssessmentPriceMessage = (assessment) => {
    const assessmentWithFilteredOption = {
      ...assessment,
      options: assessment.options.filter(
        (option) => option.id === assessment.selectedOptionId
      ),
    };
    const OWNERSHIP_STATUS = calculateOwnedDataPoints(
      assessmentWithFilteredOption
    );
    var selectedOption = assessment.options.find(
      (option) => option.id === assessment.selectedOptionId
    );
    switch (OWNERSHIP_STATUS) {
      case intl.formatMessage(componentMessages.unowned):
        return intl.formatMessage(componentMessages.priceFull);
      case intl.formatMessage(componentMessages.partiallyOwned):
        if (
          selectedOption.dataPoints.newPurchasedCount === 0 &&
          selectedOption.purchasePrice === 0
        ) {
          return intl.formatMessage(componentMessages.priceAlreadyOwned);
        }
        return intl.formatMessage(componentMessages.pricePartiallyOwned);
      case intl.formatMessage(componentMessages.owned):
        if (selectedOption.purchasePrice !== 0) {
          return intl.formatMessage(componentMessages.pricePartiallyOwned);
        }
        return intl.formatMessage(componentMessages.priceAlreadyOwned);
      default:
        return intl.formatMessage(componentMessages.pricePartiallyOwned);
    }
  };

  const getAssessmentPrice = (assessment) =>
    assessment.options.find(
      (option) => option.id === assessment.selectedOptionId
    ).purchasePrice;

  return (
    <div
      className="purchase-modal-content"
      data-testid="purchase-modal-content"
    >
      {isLoading && (
        <div
          className="purchase-modal-content__loading-container"
          data-testid="purchase-modal-content-loading"
        >
          <Spinner className="purchase-modal-content__spinner" isLarge />
          <p className="purchase-modal-content__loading-text">
            {intl.formatMessage(componentMessages.loading)}
          </p>
        </div>
      )}
      {!isLoading && assessmentsDetails && (
        <ul
          className="purchase-modal-content__assessments-list"
          data-testid="purchase-modal-content-assessments-list"
        >
          {assessmentsDetails.map((assessmentDetails) => (
            <li
              key={assessmentDetails.assessmentId}
              className="purchase-modal-content__assessment"
              data-testid="purchase-modal-content-assessment"
            >
              <div className="purchase-modal-content__label-with-input">
                <div
                  className="purchase-modal-content__assessment-label"
                  data-testid="purchase-modal-content-assessment-label"
                >
                  {assessmentDetails.name}
                  <span className="purchase-modal-content__assessment-label-subtitle">
                    {calculateOwnedDataPoints(assessmentDetails)}
                  </span>
                </div>
                <SelectInput
                  customSelectComponents={customSelectComponents}
                  label="Product template"
                  value={{
                    value: assessmentDetails.selectedOptionId,
                    label: assessmentDetails.selectedOptionName,
                  }}
                  options={assessmentDetails.options.map((option) => ({
                    value: option.isProductType ? option.name : option.id,
                    label: option.name,
                    info: `${intl.formatMessage(
                      componentMessages.createdBy
                    )}: ${option.createdBy}`,
                  }))}
                  onChange={(option) =>
                    handleAssessmentTypeChange(
                      assessmentDetails.assessmentId,
                      option
                    )
                  }
                  isDisabled={assessmentDetails.options.length < 2}
                  menuPortalTarget={
                    // That`s temporary solution, because react-select has bug with menu portal target registered in their repo.
                    // Should be fixed in nearest react-select release
                    // Issue #4088
                    assessmentsDetails.length <= 4
                      ? REACT_SELECT_PORTAL_PLACEHOLDER
                      : undefined
                  }
                  scrollDropdownIntoView={assessmentsDetails.length > 4}
                />
              </div>
              <div
                className="purchase-modal-content__cost"
                data-testid="purchase-modal-content-assessment-cost"
              >
                {getAssessmentPriceMessage(assessmentDetails)}
                <span className="purchase-modal-content__cost-number">
                  {intl.formatNumber(getAssessmentPrice(assessmentDetails), {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PurchaseModalContent.defaultProps = {
  assessmentsDetails: [],
  setSelectedAssessmentsDetails: () => {},
  isLoading: false,
};

PurchaseModalContent.propTypes = {
  assessmentsDetails: PropTypes.array,
  setSelectedAssessmentsDetails: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PurchaseModalContent;
