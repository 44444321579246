import React from "react";
import { useIntl } from "react-intl";

import messages from "../../messages";
import { ReactComponent as CookieIcon } from "assets/cookie.svg";
import { PRIVACY_POLICY_LINK } from "app/links";
import "./modal-content.scss";

const ModalContent = () => {
  const intl = useIntl();

  return (
    <div className="cookie-modal-content">
      <CookieIcon />
      <p className="cookie-modal-content__text">
        {intl.formatMessage(messages.modalContentText, {
          privacyPolicyLink: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={PRIVACY_POLICY_LINK}
              className="cookie-modal-content__privacy-policy-link"
            >
              {intl.formatMessage(messages.ourPrivacyPolicy)}
            </a>
          ),
        })}
      </p>
    </div>
  );
};

export default ModalContent;
