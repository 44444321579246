import { put, takeLatest, all, takeEvery } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadBpqDocument,
  loadBpqDocumentSuccess,
  loadBpqDocumentError,
  setAnswerToQuestion,
  setQuestionStatus,
  setAttachmentsToQuestion,
  setObservationToQuestion,
  setInternalNoteToQuestion,
  setSharedNoteToQuestion,
  setSmeNameToDomain,
  setSmeTitleToDomain,
  updateRedactedQuestionAnswers,
  loadQcValidationResults,
  loadQcValidationResultsSuccess,
  loadQcValidationResultsError,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { setThematicFlagDrawerOpen } from "../components/ThematicFlagsDrawer/services/slice";
import getSessionAccessToken from "utils/useAccessToken/getSessionAccessToken";

function* loadBpqDocumentSaga({ payload }) {
  yield put(setThematicFlagDrawerOpen({ isOpen: false }));
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/bpq`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const bpqDocument = yield response.json();
    yield put(loadBpqDocumentSuccess(bpqDocument));
  } catch ({ message }) {
    yield put(loadBpqDocumentError(message));
  }
}

function* loadQcValidationResultsSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/bpq/validationresults`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const qcValidationResults = yield response.json();
    yield put(loadQcValidationResultsSuccess(qcValidationResults));
  } catch ({ message }) {
    yield put(loadQcValidationResultsError(message));
  }
}

function* setAnswerToQuestionSaga({ payload }) {
  try {
    const { assessmentId, answers, accessToken } = payload;
    const url =
      process.env.REACT_APP_API_URL + `/assessments/${assessmentId}/bpq/`;
    const options = {
      method: "PUT",
      body: JSON.stringify(answers),
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };

    let response = yield fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        const newAccessToken = getSessionAccessToken();
        response = yield fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: "Bearer " + newAccessToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to load");
        }
      } else {
        throw new Error("Failed to load");
      }
    }
    const redactedAnswers = yield response.json().catch(() => null);
    if (redactedAnswers) {
      yield put(updateRedactedQuestionAnswers(redactedAnswers));
    }
  } catch {
    yield showError();
  }
}

function* setQuestionStatusSaga({ payload }) {
  try {
    const { assessmentId, questions, accessToken } = payload;
    const url =
      process.env.REACT_APP_API_URL + `/assessments/${assessmentId}/bpq/status`;
    const options = {
      method: "PUT",
      body: JSON.stringify({ questions }),
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    let response = yield fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        const newAccessToken = getSessionAccessToken();
        response = yield fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: "Bearer " + newAccessToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to sync question status");
        }
      } else {
        throw new Error("Failed to sync question status");
      }
    }
  } catch {
    yield showError();
  }
}

function* setCommentToQuestionSaga({ payload }) {
  try {
    const { assessmentId, serialId, comment, accessToken } = payload;
    const body = { ...comment, value: !comment.value ? null : comment.value };
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/bpq/${serialId}/additionalFields`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
  } catch {
    yield showError();
  }
}

function* setSmeAnswerToDomainSaga({ payload }) {
  try {
    const { assessmentId, answer, accessToken } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL + `/assessments/${assessmentId}/bpq/sme`,
      {
        method: "POST",
        body: JSON.stringify(answer),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
  } catch {
    yield showError();
  }
}

const SET_COMMENT_QUESTION_TYPES = [
  setObservationToQuestion.type,
  setInternalNoteToQuestion.type,
  setSharedNoteToQuestion.type,
];

const SET_ANSWER_TO_QUESTION_TYPES = [
  setAnswerToQuestion.type,
  setAttachmentsToQuestion.type,
];

const SET_SME_FIELD_ANSWER_TO_DOMAIN_TYPES = [
  setSmeNameToDomain.type,
  setSmeTitleToDomain.type,
];

export default function* bpqDocumentSagas() {
  yield all([
    takeLatest(loadBpqDocument.type, loadBpqDocumentSaga),
    takeLatest(loadQcValidationResults.type, loadQcValidationResultsSaga),
    takeLatest(SET_COMMENT_QUESTION_TYPES, setCommentToQuestionSaga),
    takeEvery(SET_ANSWER_TO_QUESTION_TYPES, setAnswerToQuestionSaga),
    takeLatest(SET_SME_FIELD_ANSWER_TO_DOMAIN_TYPES, setSmeAnswerToDomainSaga),
    takeEvery(setQuestionStatus, setQuestionStatusSaga),
  ]);
}
