import { put, takeLatest, all, call, delay } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { PRODUCT_STATUS_CHECK_INTERVAL } from "app/constants";
import {
  updateDownloadFileName,
  downloadFile,
} from "utils/handleDownload/handleDownload";
import {
  loadProductDownloadPage,
  loadProductDownloadPageSuccess,
  loadProductDownloadPageError,
  generateProductSet,
  generateProductSetSuccess,
  generateProductSetError,
  downloadProduct,
  downloadProductSuccess,
  downloadProductError,
  toggleThirdPartyAccess,
  toggleThirdPartyAccessSuccess,
  toggleThirdPartyAccessError,
  toggleConfirmationModal,
} from "./slice";
import {
  getUserInitiatedDownloadIds,
  saveUserInitiatedDownloadIds,
} from "../../../CatalogPage/utils/localStorage";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";

export function* loadProductDownloadPageSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/products`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const downloadProductButtons = yield response.json();
    yield put(
      loadProductDownloadPageSuccess({
        downloadProductButtons: downloadProductButtons,
      })
    );
  } catch {
    yield put(loadProductDownloadPageError());
    yield showError();
  }
}

export function* addLocalStorageDownloadIdSaga({ payload: { productId } }) {
  const userInitiatedDownloadIds = getUserInitiatedDownloadIds();

  if (!userInitiatedDownloadIds.includes(productId)) {
    const updatedDownloadIds = [productId, ...userInitiatedDownloadIds];
    yield saveUserInitiatedDownloadIds(updatedDownloadIds);
  }
}

export function* removeLocalStorageDownloadIdSaga({ payload: { productId } }) {
  const userInitiatedDownloadIds = getUserInitiatedDownloadIds();

  if (userInitiatedDownloadIds.includes(productId)) {
    const updatedDownloadIds = userInitiatedDownloadIds.filter(
      (id) => id !== productId
    );
    yield saveUserInitiatedDownloadIds(updatedDownloadIds);
  }
}

export function* generateProductSetSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/products/generate`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
        method: "POST",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }

    const downloadProductButtons = yield response.json();
    yield put(
      generateProductSetSuccess({
        downloadProductButtons: downloadProductButtons,
      })
    );
  } catch {
    yield put(generateProductSetError());
    yield showError();
  }
}

export function* downloadProductSaga({ payload }) {
  try {
    const { assessmentId, productId, accessToken, isReload } = payload;
    if (isReload) {
      yield delay(PRODUCT_STATUS_CHECK_INTERVAL);
    }

    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/products/${productId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    const isFile =
      response.headers.map["content-disposition"]?.includes("attachment");

    if (response.ok && isFile) {
      downloadFile(response, updateDownloadFileName(response));
      yield put(downloadProductSuccess({ productId: productId }));
      yield call(removeLocalStorageDownloadIdSaga, {
        payload: { productId },
      });
    }

    if (!isFile) {
      const data = yield response.json();
      if (data.ErrorCode) {
        // show an error message
        yield put(downloadProductError({ productId: payload.productId }));
        yield showError(
          "An error occurred, please contact TruSight Support for additional assistance"
        );
        // reset icon on error
        yield call(removeLocalStorageDownloadIdSaga, {
          payload: { productId },
        });
      }
      if (data.inProgress) {
        if (!isReload) {
          yield showInfo(
            "We are generating an updated product file for you! Please remain on the page."
          );
        }
        yield call(downloadProductSaga, {
          payload: { ...payload, isReload: true },
        });
      }
    }
  } catch {
    yield put(downloadProductError({ productId: payload.productId }));
    yield showError();
  }
}

export function* toggleThirdPartyAccessSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/products/third-party-access-enabled`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
          "Content-Type": "application/json",
        },
        body: payload.accessFlag,
        method: "PUT",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }

    yield put(toggleThirdPartyAccessSuccess(payload.accessFlag));
    yield put(toggleConfirmationModal(false));
  } catch {
    yield put(toggleThirdPartyAccessError());
    yield put(toggleConfirmationModal(false));
    yield showError();
  }
}

export default function* productDownloadSagas() {
  yield all([
    takeLatest(loadProductDownloadPage.type, loadProductDownloadPageSaga),
    takeLatest(generateProductSet.type, generateProductSetSaga),
    takeLatest(downloadProduct.type, downloadProductSaga),
    takeLatest(downloadProduct.type, addLocalStorageDownloadIdSaga),
    takeLatest(toggleThirdPartyAccess.type, toggleThirdPartyAccessSaga),
  ]);
}
