import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import TsModal from "components/GlobalComponents/Modal";
import messages from "./messages";
import ModalContent from "./components/ModalContent/ModalContent";
import { setIsCookiePolicyAccepted } from "utils/cookiesPolicy/cookiePolicy";
import "./cookie-policy-modal-controller.scss";

const CookiePolicyModalController = ({ uniqueUserId }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(true);

  const handleConfirmButtonClick = () => {
    setIsCookiePolicyAccepted(uniqueUserId);
    setIsOpen(false);
  };

  return (
    <TsModal
      className="cookie-policy-modal-controller"
      modalTitle={intl.formatMessage(messages.modalTitle)}
      modalContent={<ModalContent />}
      onConfirmAction={handleConfirmButtonClick}
      confirmActionText={intl.formatMessage(messages.confirmButtonText)}
      isModalOpen={isOpen}
      showCancelButton={false}
      showCancelButtonTop={false}
      shouldCloseOnEsc={false}
      showFooter
    />
  );
};

CookiePolicyModalController.defaultProps = {
  uniqueUserId: "",
};

CookiePolicyModalController.propTypes = {
  uniqueUserId: PropTypes.string,
};

export default CookiePolicyModalController;
