import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import { saveFlags, setThematicFlagDrawerOpen } from "./slice";
import { setThematicFlagsToDocumentState } from "pages/AssessmentPage/BPQ/services/slice";
import { setIsDirty } from "services/form/slice";

function* saveFlagsSaga({ payload }) {
  try {
    const { assessmentId, accessToken, flags } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/assessments/${assessmentId}/thematic-flags`,
      {
        method: "PUT",
        body: JSON.stringify({ flags }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put(setIsDirty({ value: false, key: null }));
    yield put(setThematicFlagsToDocumentState(data));
    yield put(setThematicFlagDrawerOpen(false));
  } catch ({ message }) {
    yield showError();
  }
}

export default function* flagsDrawerSagas() {
  yield all([yield takeLatest(saveFlags.type, saveFlagsSaga)]);
}
