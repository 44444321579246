import { put, all, takeLatest, takeEvery } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import escape from "escape-html";

import {
  loadAddRelationshipLookupSearchResults,
  loadLookUpSearchResultsError,
  loadLookUpSearchResultsSuccess,
} from "components/GlobalComponents/Inputs/TypeAheadInput/services/slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter/capitalizeFirstLetter";
import { addToast } from "services/toast/slice";
import { TOAST_TYPES } from "app/constants";
import {
  loadOrganizationConnections,
  loadOrganizationConnectionsError,
  loadOrganizationConnectionsSuccess,
  saveRelationship,
  saveRelationshipError,
  saveRelationshipSuccess,
  setIsAddRelationshipModalOpen,
} from "./slice";
import {
  addNewConnectionToGraphData,
  addNewConnectionToMyNetwork,
} from "pages/MyNetworkPage/services/slice";

function* loadCompanyLookupOrganizationsSaga({ payload }) {
  try {
    const { accessToken, query, lookupFieldId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/organizations/manual-search/${query}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadLookUpSearchResultsSuccess({ data, lookupFieldId }));
  } catch ({ lookupFieldId }) {
    yield put(loadLookUpSearchResultsError(lookupFieldId));
    yield showError();
  }
}

function* loadOrganizationConnectionsSaga({ payload }) {
  try {
    const { accessToken, organizationId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/graph/connections/${organizationId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();

    yield put(loadOrganizationConnectionsSuccess(data));
  } catch ({ message }) {
    yield put(loadOrganizationConnectionsError(message));
    yield showError();
  }
}

function* saveRelationshipSaga({ payload }) {
  try {
    const {
      accessToken,
      organizationId,
      organizationName,
      relationshipType,
      relationship,
      direction,
      truSightId,
      isMapViewActive,
      isListViewActive,
      isLastSave,
    } = payload;

    const body = {
      targetOrganizationId: organizationId,
      type: capitalizeFirstLetter(relationshipType),
      direction: capitalizeFirstLetter(direction),
    };

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/graph/connection`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();

    if (isMapViewActive) {
      const newGraphDataConnection = {
        edge: {
          from: String(data.fromOrganizationId),
          to: String(data.toOrganizationId),
          status: data.status,
          type: data.connectionType,
        },
        node: {
          id: String(organizationId),
          label: organizationName,
          type: null,
        },
      };

      yield put(addNewConnectionToGraphData(newGraphDataConnection));
    }

    if (isListViewActive) {
      const newMyNetworkConnection = {
        id: organizationId,
        organizationName,
        truSightId,
        connections: [
          {
            approvalPendingFrom: organizationName,
            connectionText: `${capitalizeFirstLetter(relationship)} to`,
            status: data.status,
          },
        ],
      };
      yield put(addNewConnectionToMyNetwork(newMyNetworkConnection));
    }

    if (isLastSave) {
      yield put(setIsAddRelationshipModalOpen(false));
      yield put(
        addToast({
          description: `Your proposed relationship has been saved and sent to ${escape(
            organizationName
          )} for approval.`,
          type: TOAST_TYPES.emailPaperPlane,
        })
      );
      yield put(saveRelationshipSuccess());
    }
  } catch ({ message }) {
    yield put(saveRelationshipError());
    yield showError(message);
  }
}

export default function* myNetworksSagas() {
  yield all([
    takeLatest(
      loadAddRelationshipLookupSearchResults.type,
      loadCompanyLookupOrganizationsSaga
    ),
    takeLatest(
      loadOrganizationConnections.type,
      loadOrganizationConnectionsSaga
    ),
    takeEvery(saveRelationship.type, saveRelationshipSaga),
  ]);
}
