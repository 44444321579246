import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  newTagsCollectionId: null,
  newTemplateId: null,
  bpqVersions: {
    isLoading: false,
    versions: null,
  },
};

const slice = createSlice({
  name: "templatesAndMapping",
  initialState,
  reducers: {
    setNewTemplateId: (state, { payload }) => {
      state.newTemplateId = payload;
    },
    resetNewTemplateId: (state) => {
      state.newTemplateId = null;
    },
    setNewTagsCollectionId: (state, { payload }) => {
      state.newTagsCollectionId = payload;
    },
    resetNewTagsCollectionId: (state) => {
      state.newTagsCollectionId = null;
    },
    loadBpqVersions: (state) => {
      state.bpqVersions.versions = null;
      state.bpqVersions.isLoading = true;
    },
    loadBpqVersionsError: (state) => {
      state.bpqVersions.isLoading = false;
    },
    loadBpqVersionsSuccess: (state, { payload }) => {
      state.bpqVersions.isLoading = false;
      state.bpqVersions.versions = payload.sort(
        (a, b) => parseFloat(b) - parseFloat(a)
      );
    },
    resetTemplatesAndMappingStore: () => initialState,
  },
});

export const {
  setNewTemplateId,
  resetNewTemplateId,
  setNewTagsCollectionId,
  resetNewTagsCollectionId,
  loadBpqVersions,
  loadBpqVersionsError,
  loadBpqVersionsSuccess,
  resetTemplatesAndMappingStore,
} = slice.actions;

export default slice;
