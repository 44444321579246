import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const slice = createSlice({
  name: NAMESPACE,
  initialState: {
    bpqSubmitModal: {
      isOpen: false,
      isSubmitting: false,
    },
  },
  reducers: {
    submitQuestionnaire: (state) => {
      state.bpqSubmitModal.isSubmitting = true;
    },
    submitQuestionnaireSuccess: (state) => {
      state.bpqSubmitModal.isSubmitting = false;
      state.bpqSubmitModal.isOpen = false;
    },
    submitQuestionnaireError: (state) => {
      state.bpqSubmitModal.isSubmitting = false;
    },
    setBpqModalOpen: (state, { payload }) => {
      state.bpqSubmitModal.isOpen = payload;
    },
  },
});

export const {
  submitQuestionnaire,
  submitQuestionnaireSuccess,
  submitQuestionnaireError,
  setBpqModalOpen,
} = slice.actions;

export default slice;
