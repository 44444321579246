import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import Button, {
  buttonTypes,
} from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import DropdownMenu from "components/DropdownMenu";
import { getAvailableProductTypesForAssessments } from "../../utils/utils";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import { ASSESSMENT_TYPES } from "pages/MyListPage/utils/constants";
import messages from "../../messages";
import "./purchase-assessment-header.scss";

export const PurchaseAssessmentHeader = ({
  selectedAssessmentsDetails,
  setSelectedAssessmentsDetails,
  isThereAssessmentWithEscalationTier,
}) => {
  const intl = useIntl();
  const dropdownWrapper = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSetAllAssessmentsTypes = (assessmentType) => {
    toggleDropdown();
    const updatedAssessmentsDetails = selectedAssessmentsDetails.map(
      (selectedAssessmentDetails) => ({
        ...selectedAssessmentDetails,
        type: selectedAssessmentDetails.possibleProductTypes.includes(
          assessmentType
        )
          ? assessmentType
          : selectedAssessmentDetails.type,
      })
    );
    setSelectedAssessmentsDetails(updatedAssessmentsDetails);
  };

  useCheckOutsideClick(dropdownWrapper, toggleDropdown, isDropdownOpen);

  return (
    <div className="purchase-assessment-header">
      <div className="purchase-assessment-header__text">
        {intl.formatMessage(messages.purchaseAssessmentHeaderText)}
        {isThereAssessmentWithEscalationTier && (
          <FormattedMessage
            {...messages.assessmentInEscalationText}
            values={{
              ...messages.assessmentInEscalationText.values,
            }}
          />
        )}
      </div>
      {selectedAssessmentsDetails.length > 1 && (
        <div
          ref={dropdownWrapper}
          className="purchase-assessment-header__set-all-assessments-types"
        >
          <Button
            ariaExpanded={isDropdownOpen}
            ariaHasPopup
            followingIcon={<ChevronIcon />}
            isSecondary
            onClick={toggleDropdown}
            buttonType={buttonTypes.link}
            data-testid="purchase-assessment-header-toggle"
          >
            Set all assessments types to...
          </Button>
          <DropdownMenu isDropdownOpen={isDropdownOpen}>
            {Object.values(ASSESSMENT_TYPES).map((assessmentType) => {
              return (
                <button
                  key={assessmentType}
                  className="dropdown-menu__item"
                  onClick={() => handleSetAllAssessmentsTypes(assessmentType)}
                  type="button"
                  data-testid="dropdown-menu-item"
                >
                  <div className="purchase-assessment-header__dropdown-text--main">
                    {assessmentType}
                  </div>
                  <div className="purchase-assessment-header__dropdown-text--secondary">
                    {getAvailableProductTypesForAssessments(
                      assessmentType,
                      selectedAssessmentsDetails
                    )}
                    {` of ${selectedAssessmentsDetails.length} products available as ${assessmentType}`}
                  </div>
                </button>
              );
            })}
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};

PurchaseAssessmentHeader.defaultProps = {
  isThereAssessmentWithEscalationTier: false,
};

PurchaseAssessmentHeader.propTypes = {
  selectedAssessmentsDetails: PropTypes.array.isRequired,
  setSelectedAssessmentsDetails: PropTypes.func.isRequired,
  isThereAssessmentWithEscalationTier: PropTypes.bool,
};

export default PurchaseAssessmentHeader;
