import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as CsvIcon } from "assets/csv.svg";
import UploadError from "../UploadError/UploadError";
import {
  selectFileName,
  selectSavedRowsCount,
  selectErrors,
  selectUploadedOn,
} from "components/UploadThirdPartiesModal/services/selectors";
import messages from "../../messages";
import "./find-assessments.scss";

const FindAssessments = ({ dispatchGoBack, closeModal, parentPagePath }) => {
  const savedRowsCount = useSelector(selectSavedRowsCount);
  const fileName = useSelector(selectFileName);
  const errors = useSelector(selectErrors);
  const uploadedOn = useSelector(selectUploadedOn);
  const intl = useIntl();

  return (
    <div>
      <div data-testid="find-assessments-div" className="find-assessments">
        <div
          data-testid="find-assessments-file-div"
          className="find-assessments__file"
        >
          <CsvIcon className="find-assessments__csv-icon" />
          <div
            data-testid="find-assessments-file-text-wrapper"
            className="find-assessments__file-text-wrapper"
          >
            <p
              data-testid="find-assessments-file-text"
              className="find-assessments__file-text"
            >
              {fileName}
            </p>
            <p
              data-testid="find-assessments-file-text-secondary"
              className="find-assessments__file-text find-assessments__file-text--secondary"
            >
              {intl.formatMessage(messages.findAssessmentUploadedOn, {
                uploadedOn,
              })}
            </p>
          </div>
        </div>
        <p
          data-testid="find-assessments-upload-text"
          className="find-assessments__upload-text"
        >
          {savedRowsCount === 0 &&
            !errors?.length &&
            intl.formatMessage(messages.findAssessmentAlreadyExist)}
          {savedRowsCount === 0 &&
            errors.length > 0 &&
            intl.formatMessage(messages.findAssessmentNoValidRecords)}
          {savedRowsCount > 0 &&
            intl.formatMessage(messages.findAssessmentMatchingHasBegun, {
              savedRowsCount,
            })}
        </p>
        {errors?.length > 0 && <UploadError errors={errors} />}
      </div>
      <div
        data-testid="upload-third-parties-controls"
        className="upload-third-parties__controls upload-third-parties__controls--space-between"
      >
        <Button
          data-testid="upload-third-parties-btn-light"
          className="upload-third-parties__btn upload-third-parties__btn--light"
          onClick={dispatchGoBack}
          isLarge
          isLight
          isSecondary
        >
          {intl.formatMessage(
            errors?.length > 0
              ? messages.findAssessmentUploadFileAgain
              : messages.findAssessmentAddFile
          )}
        </Button>
        {savedRowsCount > 0 && (
          <Button
            data-testid="upload-third-parties-btn-dark"
            className="upload-third-parties__btn upload-third-parties__btn--dark"
            onClick={closeModal}
            isLarge
            isLink
            isPrimary
            to={parentPagePath}
          >
            {intl.formatMessage(messages.findAssessmentViewProgress)}
          </Button>
        )}
      </div>
    </div>
  );
};
FindAssessments.propTypes = {
  dispatchGoBack: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  parentPagePath: PropTypes.string.isRequired,
};
export default FindAssessments;
