import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  refreshQueueByRow: [],
};

const slice = createSlice({
  name: "unmatchedThirdPartiesTable",
  initialState,
  reducers: {
    addRowToRefreshQueue: (state, { payload }) => {
      state.refreshQueueByRow.push(payload);
    },
    removeRowFromRefreshQueue: (state) => {
      state.refreshQueueByRow.pop();
    },
    resetUnmatchedThirdPartiesTableState: () => initialState,
  },
});

export const {
  addRowToRefreshQueue,
  removeRowFromRefreshQueue,
  resetUnmatchedThirdPartiesTableState,
} = slice.actions;

export default slice;
