import PropTypes from "prop-types";
import React from "react";

import "./input-error.scss";
import { ReactComponent as ErrorIcon } from "assets/error-input.svg";

const InputError = ({ errorText }) => (
  <div className="input-error">
    <ErrorIcon className="input-error__icon" />
    <span
      className="input-error__text"
      role="alert"
      data-testid="input-error-text"
    >
      {errorText}
    </span>
  </div>
);

InputError.propTypes = {
  errorText: PropTypes.string.isRequired,
};

export default InputError;
