import React, { Children } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./dropdown-menu.scss";

const DropdownMenu = ({ className, children, isDropdownOpen }) => {
  return (
    <nav
      className={classNames("dropdown-menu", className, {
        "is-open": isDropdownOpen,
      })}
      data-testid="dropdown-menu"
    >
      <ul className="dropdown-menu__content">
        {children &&
          Children.map(children, (child) => {
            return <li className="dropdown-menu__item-wrapper">{child}</li>;
          })}
      </ul>
    </nav>
  );
};

DropdownMenu.defaultProps = {
  className: "",
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
};

export default DropdownMenu;
