import { createSlice } from "@reduxjs/toolkit";

import { FORM_REDUCER_KEYS } from "app/constants";

const slice = createSlice({
  name: "formInfo",
  initialState: {
    isDirty: false,
    key: null,
    customComment: {
      isDirty: false,
    },
  },
  reducers: {
    [FORM_REDUCER_KEYS.setIsDirty]: (state, { payload }) => {
      state.isDirty = payload.value;
      if (payload.key !== undefined) {
        state.key = payload.key;
      }
    },
    [FORM_REDUCER_KEYS.setIsCustomCommentDirty]: (state, { payload }) => {
      state.customComment.isDirty = payload.value;
      if (payload.key !== undefined) {
        state.key = payload.key;
      }
    },
  },
});

export const { setIsDirty, setIsCustomCommentDirty } = slice.actions;

export default slice;
