import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import DownloadTemplate from "../DownloadTemplate/DownloadTemplate";
import FindAssessments from "../FindAssessments/FindAssessments";
import UploadFile from "../UploadFile/UploadFile";
import { setCurrentElement } from "../../services/slice";
import { selectCurrentElement } from "../../services/selectors";
import messages from "../../messages";

const ThirdPartyUploadWizard = ({
  parentPagePath,
  organizationId,
  closeModal,
}) => {
  const currentElement = useSelector(selectCurrentElement);
  const dispatch = useDispatch();
  const intl = useIntl();

  const dispatchGoNext = () => {
    if (currentElement < wizardElements.length) {
      dispatch(setCurrentElement({ currentElement: currentElement + 1 }));
    }
  };

  const dispatchGoBack = () => {
    if (currentElement > 1) {
      dispatch(setCurrentElement({ currentElement: currentElement - 1 }));
    }
  };

  const wizardElements = [
    {
      name: intl.formatMessage(messages.downloadThirdPartyTemplate),
      content: (
        <DownloadTemplate
          organizationId={organizationId}
          dispatchGoNext={dispatchGoNext}
        />
      ),
    },
    {
      name: intl.formatMessage(messages.uploadThirdPartyTemplate),
      content: (
        <UploadFile
          organizationId={organizationId}
          dispatchGoBack={dispatchGoBack}
        />
      ),
    },
    {
      name: intl.formatMessage(messages.findAvailableAssessments),
      content: (
        <FindAssessments
          dispatchGoBack={dispatchGoBack}
          closeModal={closeModal}
          parentPagePath={parentPagePath}
        />
      ),
    },
  ];

  return (
    <div
      data-testid="upload-third-parties-content-wrapper"
      className="upload-third-parties__content-wrapper"
    >
      <div className="upload-third-parties__content">
        <h3 className="upload-third-parties__step-title heading4">
          {intl.formatMessage(messages.stepOf, {
            currentElement,
            length: wizardElements.length,
          })}
          {` ${wizardElements[currentElement - 1].name}`}
        </h3>
        {wizardElements[currentElement - 1].content}
      </div>
    </div>
  );
};

ThirdPartyUploadWizard.propTypes = {
  parentPagePath: PropTypes.string.isRequired,
  organizationId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
};

export default ThirdPartyUploadWizard;
