import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  setAnswerToPeerReviewDrawer,
  setPeerReviewDrawerOpen,
  setPeerReviewDrawerSubmitInProgress,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import {
  setAnswerToPeerReviewDrawerSuccess,
  setRowToRefresh,
} from "../../../services/slice";
import { setIsDirty } from "services/form/slice";

function* setAnswerToPeerReviewDrawerSaga({ payload }) {
  try {
    const { assessmentId, questionId, answer, accessToken, serialId } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/bpq/peer-review/${serialId}`,
      {
        method: "POST",
        body: JSON.stringify(answer),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const responseAnswer = yield response.json();

    yield put(
      setAnswerToPeerReviewDrawerSuccess({
        questionId,
        ...responseAnswer,
      })
    );
    yield put(setPeerReviewDrawerSubmitInProgress({ isInProgress: false }));
    yield put(setIsDirty({ value: false, key: null }));
    yield put(setPeerReviewDrawerOpen({ isOpen: false }));
    yield put(setRowToRefresh({ questionId }));
  } catch ({ message }) {
    yield showError();
  }
}

export default function* peerReviewDrawerSagas() {
  yield all([
    takeLatest(
      setAnswerToPeerReviewDrawer.type,
      setAnswerToPeerReviewDrawerSaga
    ),
  ]);
}
