import { call, put, all, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { downloadFile } from "utils/handleDownload/handleDownload";
import { showError } from "utils/sagaEffects/sagaEffects";
import {
  downloadTemplate,
  startAssessmentMatching,
  uploadToServer,
  uploadFileSuccess,
  uploadFileError,
  startUploadFile,
  setCurrentElement,
} from "./slice";
import { setUnmatchedThirdPartiesLoaded } from "pages/MyListPage/UnmatchedPage/services/slice";

const TEMPLATE_FILE_NAME = "TruSight Third Party Upload Template.csv";

export function* downloadTemplateSaga({ payload }) {
  const { organizationId, accessToken } = payload;

  const url =
    process.env.REACT_APP_API_URL +
    (organizationId
      ? "/customer-management/third-parties/template"
      : "/thirdpartyassessments/template");

  try {
    const response = yield call(fetch, url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    if (response.status === 200) {
      downloadFile(response, TEMPLATE_FILE_NAME);
    } else {
      const data = yield response.json();
      yield showError(data?.Title);
    }
  } catch {
    yield showError();
  }
}

export function* startAssessmentMatchingSaga({ payload }) {
  const { organizationId, accessToken } = payload;
  const url =
    process.env.REACT_APP_API_URL +
    (organizationId
      ? `/customer-management/third-parties/${organizationId}/find`
      : "/thirdpartyassessments/find");

  try {
    const response = yield call(fetch, url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (response.status !== 200) {
      yield showError();
    }
    if (response.status >= 200 && response.status < 300) {
      yield put(setUnmatchedThirdPartiesLoaded(false));
    }
  } catch {
    yield showError();
  }
}

export function* uploadToServerSaga({ payload }) {
  const { currentElement, organizationId, file, accessToken } = payload;
  yield put(startUploadFile());
  const formData = new FormData();
  formData.append("file", file);
  const url =
    process.env.REACT_APP_API_URL +
    (organizationId
      ? `/customer-management/third-parties/${organizationId}`
      : "/thirdpartyassessments");

  try {
    const response = yield call(fetch, url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      body: formData,
    });
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      yield put(
        uploadFileSuccess({
          fileName: file.name,
          uploadedOn: new Date().toLocaleDateString("en-US"),
          errors: data.errors,
          savedRowsCount: data.savedRowsCount,
        })
      );
      if (data.savedRowsCount) {
        yield put(
          startAssessmentMatching({
            organizationId: organizationId,
            accessToken: accessToken,
          })
        );
      }
    } else {
      const data = yield response.json();
      yield put(
        uploadFileError({
          fileName: file.name,
          uploadedOn: new Date().toLocaleString("en-US"),
          errors: data.errors,
        })
      );
    }
  } catch {
    yield put(
      uploadFileError({
        fileName: file.name,
        uploadedOn: new Date().toLocaleString("en-US"),
        errors: [],
      })
    );
    yield put(
      startAssessmentMatching({
        organizationId: organizationId,
        accessToken: accessToken,
      })
    );
  }
  yield put(setCurrentElement({ currentElement: currentElement + 1 }));
}

export default function* uploadThirdPartiesSagas() {
  yield all([
    takeLatest(startAssessmentMatching.type, startAssessmentMatchingSaga),
    takeLatest(uploadToServer.type, uploadToServerSaga),
    takeLatest(downloadTemplate.type, downloadTemplateSaga),
  ]);
}
