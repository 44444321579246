import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "notifications",
  initialState: {
    notificationsOpen: false,
    data: {
      notifications: null,
      hasMore: false,
      newNotificationsCount: 0,
    },
    loading: false,
    error: null,
    showAll: false,
    drawerId: null,
  },
  reducers: {
    loadNotifications: (state) => {
      state.loading = true;
      state.data = {
        ...state.data,
        newNotificationsCount: state.data.newNotificationsCount,
        hasMore: false,
      };
      state.error = null;
      state.showAll = false;
    },
    loadNotificationsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadNotificationsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    markAsSeen: (state, action) => {
      const unseenNotifications = state.data.notifications
        .filter((x) => action.payload.ids.indexOf(x.id) !== -1)
        .filter((x) => x.seenAt == null);

      state.data.newNotificationsCount =
        state.data.newNotificationsCount - unseenNotifications.length;

      const date = JSON.stringify(new Date());
      unseenNotifications.forEach((item) => {
        item.seenAt = date;
      });
    },
    setShowAll: (state, action) => {
      state.showAll = action.payload;
    },
    setDrawerId: (state, action) => {
      state.drawerId = action.payload;
    },
  },
});

export const {
  loadNotifications,
  loadNotificationsSuccess,
  loadNotificationsError,
  markAsSeen,
  setShowAll,
  setDrawerId,
} = slice.actions;
export default slice;
