import { put, select } from "redux-saga/effects";
import { createIntl } from "react-intl";

import { addToast } from "services/toast/slice";
import { selectLocale } from "components/LanguageProvider/services/selectors";
import messages from "app/messages";
import { DEFAULT_LOCALE, TOAST_TYPES } from "app/constants";

export function* showError(message = null, toastProps = {}) {
  let errorToast = {
    description: message,
    type: TOAST_TYPES.error,
    ...toastProps,
  };

  if (!message) {
    const locale = yield select(selectLocale);
    const intl = createIntl({ locale: locale || DEFAULT_LOCALE, messages });
    errorToast.description = intl.formatMessage(messages.error);
  }

  yield put(addToast(errorToast));
}

export function* showInfo(message = null, toastProps = {}) {
  let infoToast = {
    description: message,
    type: TOAST_TYPES.info,
    ...toastProps,
  };

  if (!message) {
    const locale = yield select(selectLocale);
    const intl = createIntl({ locale: locale || DEFAULT_LOCALE, messages });
    infoToast.description = intl.formatMessage(messages.successful);
  }

  yield put(addToast(infoToast));
}
