import { createSlice } from "@reduxjs/toolkit";

import { ASSESSMENT_DOWNLOAD_STATUS_TYPES } from "app/constants";

export const initialState = {
  isProductDownloadPageLoading: false,
  generateProductSetInProgress: false,
  downloadProductButtons: [],
  downloadingProducts: [],
  downloadProductInProgress: false,
  allowThirdPartyDownload: false,
  toggleThirdPartyAccessInProgress: false,
  confirmationModalIsOpen: false,
};

const slice = createSlice({
  name: "productDownload",
  initialState,
  reducers: {
    loadProductDownloadPage: (state) => {
      state.isProductDownloadPageLoading = true;
    },
    loadProductDownloadPageSuccess: (state, { payload }) => {
      state.isProductDownloadPageLoading = false;

      const itemsInProgress = payload.downloadProductButtons.filter(
        (productButton) =>
          productButton.status === ASSESSMENT_DOWNLOAD_STATUS_TYPES.inProgress
      );
      state.generateProductSetInProgress = itemsInProgress.length > 0;
      state.downloadProductButtons = payload.downloadProductButtons;
    },
    loadProductDownloadPageError: (state) => {
      state.generateProductSetInProgress = false;
      state.downloadProductButtons = [];
    },
    generateProductSet: (state, { payload }) => {
      state.generateProductSetInProgress = true;
    },
    generateProductSetSuccess: (state, { payload }) => {
      const itemsInProgress = payload.downloadProductButtons.filter(
        (productButton) =>
          productButton.status === ASSESSMENT_DOWNLOAD_STATUS_TYPES.inProgress
      );
      state.generateProductSetInProgress = itemsInProgress.length > 0;
      state.downloadProductButtons = payload.downloadProductButtons;
    },
    generateProductSetError: (state) => {
      state.generateProductSetInProgress = false;
    },
    downloadProduct: (state, { payload }) => {
      state.downloadProductInProgress = true;
      state.downloadingProducts = [
        ...state.downloadingProducts,
        payload.productId,
      ];
    },
    downloadProductSuccess: (state, { payload }) => {
      state.downloadProductInProgress = false;
      const downloadInProgress = state.downloadingProducts.filter(
        (product) => product !== payload.productId
      );

      state.downloadingProducts = [downloadInProgress];
    },
    downloadProductError: (state, { payload }) => {
      state.downloadProductInProgress = false;
      const downloadInProgress = state.downloadingProducts.filter(
        (product) => product !== payload.productId
      );

      state.downloadingProducts = [downloadInProgress];
    },
    toggleThirdPartyAccess: (state) => {
      state.toggleThirdPartyAccessInProgress = true;
    },
    toggleThirdPartyAccessSuccess: (state, { payload }) => {
      state.toggleThirdPartyAccessInProgress = false;
      state.allowThirdPartyDownload = payload;
    },
    toggleThirdPartyAccessError: (state) => {
      state.toggleThirdPartyAccessInProgress = false;
    },
    toggleConfirmationModal: (state, { payload }) => {
      state.confirmationModalIsOpen = payload;
    },
  },
});

export const {
  loadProductDownloadPage,
  loadProductDownloadPageSuccess,
  loadProductDownloadPageError,
  generateProductSet,
  generateProductSetSuccess,
  generateProductSetError,
  downloadProduct,
  downloadProductSuccess,
  downloadProductError,
  toggleThirdPartyAccess,
  toggleThirdPartyAccessSuccess,
  toggleThirdPartyAccessError,
  toggleConfirmationModal,
} = slice.actions;

export default slice;
