import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  submitQuestionnaire,
  submitQuestionnaireError,
  submitQuestionnaireSuccess,
} from "./slice";
import { setAssessmentStage } from "pages/AssessmentPage/services/slice";
import { showError } from "utils/sagaEffects/sagaEffects";

function* submitQuestionnaireSaga({ payload }) {
  try {
    const { accessToken, assessmentId, assessmentStageToChange } = payload;
    const response = yield fetch(
      process.env.REACT_APP_API_URL + `/assessments/${assessmentId}/bpq`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ assessmentId, assessmentStageToChange }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to post");
    }
    yield put(submitQuestionnaireSuccess());
    yield put(setAssessmentStage({ stageId: assessmentStageToChange }));
  } catch {
    yield put(submitQuestionnaireError());
    yield showError();
  }
}

export default function* submitQuestionnaireSagas() {
  yield all([takeLatest(submitQuestionnaire.type, submitQuestionnaireSaga)]);
}
