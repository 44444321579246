import {
  ASSESSMENT_DOWNLOAD_STATUS_TYPES,
  ASSESSMENT_AUTHORIZATION_STATUSES,
} from "app/constants";

export const checkIsInProgress = (assessment) =>
  assessment.isPurchased &&
  assessment.authorizationStatus ===
    ASSESSMENT_AUTHORIZATION_STATUSES.authorized &&
  assessment.isInAssessmentCompleteStage &&
  assessment.productStatus === ASSESSMENT_DOWNLOAD_STATUS_TYPES.inProgress;

export const checkIsReadyForDownload = (assessment) =>
  assessment.isPurchased &&
  assessment.authorizationStatus ===
    ASSESSMENT_AUTHORIZATION_STATUSES.authorized &&
  assessment.isInAssessmentCompleteStage &&
  assessment.productStatus === ASSESSMENT_DOWNLOAD_STATUS_TYPES.done;
