import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const slice = createSlice({
  name: "expandableContainer",
  initialState,
  reducers: {
    toggle: (state, action) => {
      if (state[action.payload.name]) {
        state[action.payload.name] =
          state[action.payload.name] === action.payload.key
            ? null
            : action.payload.key;
      } else {
        state[action.payload.name] = action.payload.key;
      }
    },
    resetExpandableContainerStore: () => initialState,
  },
});

export const { toggle, resetExpandableContainerStore } = slice.actions;

export default slice;
