import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import { downloadFile } from "utils/handleDownload/handleDownload";
import {
  exportAgenda,
  exportAgendaError,
  exportAgendaSuccess,
  exportExcel,
  exportExcelSuccess,
  exportExcelError,
  exportScoping,
  exportScopingSuccess,
  exportScopingError,
  exportObservation,
  exportObservationSuccess,
  exportObservationError,
} from "./slice";

const EXPORT_AGENDA = "export-agenda.xlsx";
const EXPORT_EXCEL = "export-excel.xlsx";
const EXPORT_SCOPING = "export-scoping.xlsx";
const EXPORT_OBSERVATION = "export-observation.xlsx";

function* exportAgendaSaga({ payload }) {
  const { id, accessToken } = payload;

  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + `/assessments/${id}/bpq/export-agenda`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to post");
    }
    downloadFile(response, EXPORT_AGENDA);
    yield put(exportAgendaSuccess());
  } catch {
    yield showError();
    yield put(exportAgendaError());
  }
}

function* exportExcelSaga({ payload }) {
  const { id, accessToken } = payload;

  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + `/assessments/${id}/bpq/export-excel`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to post");
    }
    downloadFile(response, EXPORT_EXCEL);
    yield put(exportExcelSuccess());
  } catch {
    yield showError();
    yield put(exportExcelError());
  }
}

function* exportScopingSaga({ payload }) {
  const { id, accessToken } = payload;

  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${id}/scoping/export-scoping`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to post");
    }
    downloadFile(response, EXPORT_SCOPING);
    yield put(exportScopingSuccess());
  } catch {
    yield showError();
    yield put(exportScopingError());
  }
}

function* exportObservationSaga({ payload }) {
  const { id, accessToken } = payload;

  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${id}/bpq/export-observation`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to post");
    }
    downloadFile(response, EXPORT_OBSERVATION);
    yield put(exportObservationSuccess());
  } catch {
    yield showError();
    yield put(exportObservationError());
  }
}

export default function* bpqDocumentSagas() {
  yield all([
    takeLatest(exportAgenda.type, exportAgendaSaga),
    takeLatest(exportExcel.type, exportExcelSaga),
    takeLatest(exportScoping.type, exportScopingSaga),
    takeLatest(exportObservation.type, exportObservationSaga),
  ]);
}
