import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectBreadcrumbs, selectText } from "services/breadcrumbs/selectors";
import "./breadcrumbs.scss";

const Breadcrumbs = () => {
  const breadcrumbs = useSelector(selectBreadcrumbs);
  const text = useSelector(selectText);

  if ((!breadcrumbs || !breadcrumbs.length) && !text) {
    return null;
  }

  return (
    <div className="breadcrumbs__row" data-testid="breadcrumbs">
      <nav aria-label="Breadcrumbs" className="breadcrumbs">
        <ul className="breadcrumbs__wrapper">
          {breadcrumbs.map((link, i) => {
            return (
              <li
                data-testid="breadcrumbs-item"
                className="breadcrumbs__item"
                key={i}
              >
                <Link className="breadcrumbs__link" to={link.route}>
                  {link.title}
                </Link>
                <span className="breadcrumbs__separator">/</span>
              </li>
            );
          })}
        </ul>
      </nav>
      {text && <div className="breadcrumbs__right">{text}</div>}
    </div>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

export default Breadcrumbs;
