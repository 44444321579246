import React from "react";

import { childrenShape } from "app/shapes";

const LoginPageLayout = ({ children }) => {
  return <>{children}</>;
};

LoginPageLayout.propTypes = {
  children: childrenShape,
};

export default LoginPageLayout;
