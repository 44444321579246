import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

const selectSlice = (state) => state[slice.name];

export const selectCustomTemplateState = createSelector(
  [selectSlice],
  (state) => state
);

export const selectCustomTemplateDocument = createSelector(
  selectCustomTemplateState,
  (state) => state.data
);

export const selectModifiedOn = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.modifiedOn
);

export const selectRequestStateData = createSelector(
  selectCustomTemplateState,
  (state) => {
    return { saving: state.saving, error: state.error, loading: state.loading };
  }
);

export const selectTitle = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.name
);

export const selectId = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.id
);

export const selectDomainCategories = createSelector(
  selectCustomTemplateState,
  (state) => state.domainCategories
);

export const selectQuestions = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.questions
);

export const selectDomainGroups = createSelector(
  [selectCustomTemplateState],
  (state) => state.domainGroups
);

export const selectIsTemplateSettingsModalOpen = createSelector(
  selectCustomTemplateState,
  (state) => state.modals.templateSettingsModal.isOpen
);

export const selectIsTemplateSettingsModalLoading = createSelector(
  selectCustomTemplateState,
  (state) => state.modals.templateSettingsModal.isSaving
);

export const selectIsSavingTemplateModalOpen = createSelector(
  selectCustomTemplateState,
  (state) => state.modals.saveTemplateModal.isOpen
);

export const selectEditableTitleHasErrors = createSelector(
  selectCustomTemplateState,
  (state) => state.editableTitleHasErrors
);

export const selectIsCancelTemplateChangesModalOpen = createSelector(
  selectCustomTemplateState,
  (state) => state.modals.cancelTemplateChangesModal.isOpen
);

export const selectTemplateChangesDetected = createSelector(
  selectCustomTemplateState,
  (state) => state.templateChangesDetected
);

export const selectPurchases = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.purchases
);

export const selectIsGlobalCustomTemplate = createSelector(
  selectCustomTemplateDocument,
  (data) => data?.isGlobal
);
