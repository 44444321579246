import React from "react";
import { defineMessages } from "react-intl";

const scope = "pages.global.";

export default defineMessages({
  truSight: {
    id: `${scope}truSight`,
    defaultMessage: "TruSight",
  },
  error: {
    id: `${scope}error`,
    defaultMessage: "An error occurred, please try again",
  },
  badRequest: {
    id: `${scope}badRequest`,
    defaultMessage: "{badRequestMessage}",
  },
  informationNotAvailable: {
    id: `${scope}informationNotAvailable`,
    defaultMessage: "Information not available",
  },
  loading: {
    id: `${scope}loading`,
    defaultMessage: "Loading...",
  },
  unsavedChangesWarning: {
    id: `${scope}unsavedChanges`,
    defaultMessage: "Changes that you made will not be saved. Are you sure want to leave?",
  },
  requiredValidation: {
    id: `${scope}requiredField`,
    defaultMessage: "Required Field.",
  },
  over40CharactersValidation: {
    id: `${scope}over40CharactersValidation`,
    defaultMessage: "Over 40 character limit.",
  },
  over100CharactersValidation: {
    id: `${scope}over100characters`,
    defaultMessage: "Over 100 character limit.",
  },
  over300CharactersValidation: {
    id: `${scope}over300characters`,
    defaultMessage: "Over 300 character limit.",
  },
  over400CharactersValidation: {
    id: `${scope}over400characters`,
    defaultMessage: "Over 400 character limit.",
  },
  over4000CharactersValidation: {
    id: `${scope}over4000characters`,
    defaultMessage: "Over 4000 character limit.",
  },
  requiredField: {
    id: `${scope}requiredFieldError`,
    defaultMessage: "Required Field",
  },
  confirmItemRemove: {
    id: `${scope}confirmItemRemove`,
    defaultMessage: "Are you sure you want to delete this item?",
  },
  qcFlagMetric: {
    id: `${scope}QcFlagMetric`,
    defaultMessage: "You must select a flag type to continue.",
  },
  createNewControlEnvironment: {
    id: `${scope}createNewControlEnvironment`,
    defaultMessage: "Create a new Control Environment or copy an existing one from a previous <strong>{thirdPartyName}</strong> assessment. (Using an existing Control Environment will pre-populate some questions in the BPQ. Those answers could be revised).",
    values: {
      // eslint-disable-next-line react/display-name
      strong: (text) => <strong>{text}</strong>,
    },
  },
  thematicFlagSectionTitle: {
    id: `${scope}thematicFlagSectionTitle`,
    defaultMessage: "Thematic Flag {flagCounter}",
  },
  copyToClipboardSuccess: {
    id: `${scope}copyToClipboardSuccess`,
    defaultMessage: "Copied to clipboard",
  },
  copyToClipboardFail: {
    id: `${scope}copyToClipboardFail`,
    defaultMessage: "Copying to clipboard failed",
  },
  cancel: {
    id: `${scope}cancel`,
    defaultMessage: "Cancel",
  },
  save: {
    id: `${scope}save`,
    defaultMessage: "Save",
  },
  idleDocumentTitle: {
    id: `${scope}idleDocumentTitle`,
    defaultMessage: "Are you still there?",
  },
  invalidEmail: {
    id: `${scope}invalidEmailError`,
    defaultMessage: "Invalid email address.",
  },
  notTrusightSolutionsEmail: {
    id: `${scope}notTrusightSolutionsEmailError`,
    defaultMessage: "Must be an @spglobal.com email address.",
  },
  successful: {
    id: `${scope}successful`,
    defaultMessage: "Successful",
  },
  tpDetailsDrawerUpdateSuccess: {
    id: `${scope}tpDetailsDrawerUpdateSuccess`,
    defaultMessage: "You have successfully updated {name} details",
  },
  number9CharactersLong: {
    id: `${scope}number9CharactersLong`,
    defaultMessage: "Must be a 9 characters long number",
  },
  notTrusightId: {
    id: `${scope}notTrusightId`,
    defaultMessage: "Incorrect format, example TS-1234567",
  },
  dataLoadingError: {
    id: `${scope}dataLoadingError`,
    defaultMessage: "There was an error loading the data.",
  },
  viewDetails: {
    id: `${scope}viewDetails`,
    defaultMessage: "View Details",
  },
  yes: {
    id: `${scope}yes`,
    defaultMessage: "Yes",
  },
  no: {
    id: `${scope}no`,
    defaultMessage: "No",
  },
  myTp: {
    id: `${scope}myTp`,
    defaultMessage: "My Third Parties",
  },
  otherTp: {
    id: `${scope}otherTp`,
    defaultMessage: "Other Third Parties",
  },
  editColumns: {
    id: `${scope}editColumns`,
    defaultMessage: "Edit Columns",
  },
  confirmButtonText: {
    id: `${scope}confirmButtonText`,
    defaultMessage: "Apply to Table",
  },
  secondaryButtonText: {
    id: `${scope}secondaryButtonText`,
    defaultMessage: "Show All Columns",
  },
  privacyPolicy: {
    id: `${scope}privacyPolicy`,
    defaultMessage: "Privacy Policy",
  },
  organization: {
    id: `${scope}organization`,
    defaultMessage: "Organization",
  },
  organizationId: {
    id: `${scope}organizationId`,
    defaultMessage: "Organization ID",
  },
  role: {
    id: `${scope}role`,
    defaultMessage: "Role",
  },
  logout: {
    id: `${scope}logout`,
    defaultMessage: "Logout",
  },
  saveChanges: {
    id: `${scope}saveChanges`,
    defaultMessage: "Save Changes",
  },
  pressRelease: {
    id: `${scope}pressRelease`,
    defaultMessage: "Read the full press release ",
  },
  pressReleaseLink: {
    id: `${scope}pressReleaseLink`,
    defaultMessage: "here",
  },
  loginPageTrusightSPMessage: {
    id: `${scope}loginPageTrusightSPMessage`,
    defaultMessage: "TruSight will be combined with KY3P® by S&P Global to accelerate the development of common industry standards and create a leading third-party risk management solution",
  },
  termsLink: {
    id: `${scope}termsLink`,
    defaultMessage: "terms",
  },
});
