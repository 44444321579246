import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectIsUploading = createSelector(
  [selectSlice],
  (slice) => slice.uploading
);

export const selectSavedRowsCount = createSelector(
  [selectSlice],
  (slice) => slice.savedRowsCount
);

export const selectErrors = createSelector(
  [selectSlice],
  (slice) => slice.errors
);

export const selectFileName = createSelector(
  [selectSlice],
  (slice) => slice.fileName
);

export const selectUploadedOn = createSelector(
  [selectSlice],
  (slice) => slice.uploadedOn
);

export const selectCurrentElement = createSelector(
  [selectSlice],
  (slice) => slice.currentElement
);

export const selectHasChanges = createSelector(
  [selectSlice],
  (slice) => slice.hasChanges
);
