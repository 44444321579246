import { v4 as uuidv4 } from "uuid";
import { uniq } from "lodash";

import {
  DETAIL_TABLE_ACTION_BUTTON_TYPES,
  DETAIL_TABLE_ROW_TYPE,
} from "components/TableComponents/utils/constants";
import { UNALLOCATED_DIVIDER } from "pages/GenerateBow/utils/constants";

export const isPlannedAssessment = (statusPosition) =>
  Number.isFinite(statusPosition) && statusPosition < 4;

export const isInProgressAssessment = (statusPosition) =>
  Number.isFinite(statusPosition) && statusPosition >= 4 && statusPosition < 15;

export const isCompletedAssessment = (statusPosition) =>
  Number.isFinite(statusPosition) && statusPosition === 15;

export const getComputedTpFields = (tp) => ({
  changed: tp.assessments.some(
    (assessment) => assessment?.changed ?? assessment?.value?.changed
  ),
  plannedCount: tp.assessments.filter((assessment) =>
    isPlannedAssessment(
      assessment?.statusPosition ?? assessment?.value?.statusPosition
    )
  ).length,
  inProgressCount: tp.assessments.filter((assessment) =>
    isInProgressAssessment(
      assessment?.statusPosition ?? assessment?.value?.statusPosition
    )
  ).length,
  completedCount: tp.assessments.filter((assessment) =>
    isCompletedAssessment(
      assessment?.statusPosition ?? assessment?.value?.statusPosition
    )
  ).length,
  tpMatchCount: uniq(
    tp.assessments
      .flatMap(
        (assessment) => assessment?.requests ?? assessment?.value?.requests
      )
      .concat(tp.unallocated)
      .filter((assessment) => assessment)
  ).length,
});

export const getUpdatedBowData = (data) =>
  data.map((tp) => {
    let candidateIndex = 0;

    const stats = getComputedTpFields(tp);
    const assessments = tp.assessments.map((assessment) => {
      if (!assessment.assessmentId && !assessment.candidateAssessmentId) {
        candidateIndex++;
        assessment.candidateAssessmentId = uuidv4();
        assessment.label = `[Candidate Assessment #${candidateIndex}]`;
      }

      if (assessment.assessmentId) {
        assessment.label = `[Existing Assessment ${assessment.assessmentId}]`;
      }

      return assessment;
    });

    return {
      ...tp,
      stats,
      assessments,
    };
  });

export const manageRowOrderAndDividersOnDisconnect = (
  thirdParty,
  request,
  assessmentId
) => {
  const result = thirdParty.assessments;
  const hasUnallocatedRequests = !!thirdParty.unallocated.length;

  if (!hasUnallocatedRequests) {
    result.push(UNALLOCATED_DIVIDER);
  }

  const disconnectedRequestIds = result
    .map(
      (row) =>
        row.type === DETAIL_TABLE_ROW_TYPE.disconnected && row.value.requestId
    )
    .filter((requestId) => !!requestId);

  if (!disconnectedRequestIds.includes(request.value.requestId)) {
    result.push(request);
  }

  const indexOfConnectedAssessment = thirdParty.assessments.findIndex(
    (assessment) =>
      assessment.value.assessmentId === assessmentId ||
      assessment.value.candidateAssessmentId === assessmentId
  );

  result[indexOfConnectedAssessment].value.requests = thirdParty.assessments[
    indexOfConnectedAssessment
  ].value.requests.filter(
    (assessmentRequest) =>
      (request.value.id && assessmentRequest.id !== request.value.id) ||
      assessmentRequest.requestId !== request.value.requestId
  );

  result[indexOfConnectedAssessment].value.changed = true;

  return result;
};

export const manageRowOrderAndDividersOnConnect = (
  thirdParty,
  request,
  assessmentId
) => {
  const result = thirdParty.assessments;
  const hasUnallocatedRequests = !!thirdParty.unallocated.length;
  const dividerIndex = result.findIndex(
    (assessment) => assessment.type === DETAIL_TABLE_ROW_TYPE.unallocated
  );

  if (!hasUnallocatedRequests) {
    result.splice(dividerIndex, 1);
  }

  const indexOfConnectedAssessment = result.findIndex(
    (assessment) =>
      assessment.value.assessmentId === assessmentId ||
      assessment.value.candidateAssessmentId === assessmentId
  );

  result.splice(indexOfConnectedAssessment + 1, 0, {
    type: DETAIL_TABLE_ACTION_BUTTON_TYPES.request.connected,
    value: request.value,
  });

  const indexOfDisconnectedRequest = result.findIndex(
    (assessment) =>
      assessment.value.requestId === request.value.requestId &&
      assessment.type === DETAIL_TABLE_ROW_TYPE.disconnected
  );

  if (indexOfDisconnectedRequest !== -1) {
    result.splice(indexOfDisconnectedRequest, 1);
  }

  result[indexOfConnectedAssessment].value.changed = true;

  return result;
};

export const flattenAssessments = (thirdParties) =>
  thirdParties.map((thirdParty) => {
    const assessments = thirdParty.assessments.reduce((acc, assessment) => {
      let assessmentRequests = assessment.requests;
      let mappedRequest = assessmentRequests.map((request) => ({
        type: DETAIL_TABLE_ROW_TYPE.request,
        value: {
          ...request,
          thirdPartyId: thirdParty.thirdPartyId,
          thirdPartyName: thirdParty.name,
          connectedAssessmentId: assessment.assessmentId,
        },
      }));

      return acc.concat(
        {
          type: assessment.assessmentId
            ? DETAIL_TABLE_ROW_TYPE.assessment
            : DETAIL_TABLE_ROW_TYPE.candidate,
          value: assessment,
        },
        mappedRequest
      );
    }, []);

    const unallocated = thirdParty.unallocated.map((request) => ({
      type: DETAIL_TABLE_ACTION_BUTTON_TYPES.request.disconnected,
      value: {
        ...request,
        thirdPartyId: thirdParty.thirdPartyId,
        thirdPartyName: thirdParty.name,
      },
    }));

    thirdParty.unallocated.length && unallocated.unshift(UNALLOCATED_DIVIDER);

    return {
      ...thirdParty,
      assessments: assessments.concat(unallocated),
      unallocated: thirdParty.unallocated.map((u) => u.rowGuid),
    };
  });

export const getSaveRequestBody = (thirdParties) =>
  thirdParties.reduce((acc, thirdParty) => {
    const assessments = thirdParty.assessments.filter((assessment) =>
      [
        DETAIL_TABLE_ROW_TYPE.assessment,
        DETAIL_TABLE_ROW_TYPE.candidate,
      ].includes(assessment.type)
    );
    return acc.concat(assessments.map((assessment) => assessment.value));
  }, []);

export function getCustomerNameFromRequest(data) {
  return data.map((thirdParty) => {
    if (thirdParty.assessments) {
      thirdParty.assessments.forEach((assessment) => {
        if (assessment.requests) {
          let customers = "";
          assessment.requests.forEach((request) => {
            customers =
              customers !== ""
                ? customers + `, ${request.customerName}`
                : request.customerName;
          });

          assessment["customers"] = customers;
        }
      });
    }

    return thirdParty;
  });
}
