import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authorization",
  initialState: {
    authorized: false,
  },
  reducers: {
    setAuthorized: (state, action) => {
      state.authorized = action.payload;
    },
  },
});

export const { setAuthorized } = slice.actions;

export default slice;
