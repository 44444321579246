import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";

import Header from "components/Header";
import LoadingPage from "pages/LoadingPage";
import LoginPageLayout from "components/LoginPageLayout";
import { selectAuthorized } from "components/AuthorizedRoute/services/selectors";
import { selectPermissionsInitialised } from "components/AuthorizedComponent/selectors";
import Breadcrumbs from "components/Header/components/Breadcrumbs/index";
import AssessmentControls from "./components/AssessmentControls";
import PromptController from "./components/PromptController";
import ToastController from "components/MainLayout/components/ToastController";
import IdleModalController from "components/MainLayout/components/IdleModalController";
import TotalSessionTimerController from "components/MainLayout/components/TotalSessionTimerController";
import CookiePolicyModalController from "./components/CookiePolicyModalController/CookiePolicyModalController";
import { getIsCookiePolicyAccepted } from "utils/cookiesPolicy/cookiePolicy";
import "./main-layout.scss";

const MainLayout = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const permissionsInitialised = useSelector(selectPermissionsInitialised);
  const authorized = useSelector(selectAuthorized);

  const uniqueUserId = authState?.idToken?.claims?.sub;
  const isCookiePolicyAccepted = getIsCookiePolicyAccepted(uniqueUserId);

  useEffect(() => {
    if (authState && !authState.isAuthenticated && permissionsInitialised) {
      // User authentication expired, logging out
      oktaAuth.signOut();
    }
  }, [oktaAuth, authState, permissionsInitialised]);

  if (!authState) {
    return <LoadingPage />;
  }

  if (!authState.isAuthenticated) {
    return <LoginPageLayout>{children}</LoginPageLayout>;
  }

  return (
    <div id="main-layout" className="main-layout" data-testid="main-layout">
      <PromptController />
      <ToastController />
      <IdleModalController />
      {!isCookiePolicyAccepted && (
        <CookiePolicyModalController uniqueUserId={uniqueUserId} />
      )}
      <Header />
      <TotalSessionTimerController />
      <div className="main-layout__frame">
        {authorized && (
          <div className="main-layout__controls" data-testid="controls">
            <AssessmentControls />
          </div>
        )}
        {authorized && <Breadcrumbs />}
        {children}
      </div>
    </div>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.node,
};
