import { put, takeLatest, all, call } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadAuthorizationsDocument,
  loadAuthorizationsDocumentSuccess,
  loadAuthorizationsDocumentError,
  setOrderAuthorizationStatus,
  setOrderAuthorizationStatusSuccess,
  setOrderAuthorizationStatusError,
  setIsAuthStateModalOpen,
  setHasPendingAuthorizations,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { AUTHORIZATION_STATUS_VALUES } from "../components/AuthorizationsTable/utils/constants";

export function* loadAuthorizationsDocumentSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/authorizations`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to load");
    }
    const authorizationsData = yield response.json();
    const hasPendingAuthorizations = authorizationsData.some(
      (order) => order.status === AUTHORIZATION_STATUS_VALUES.pending
    );
    if (hasPendingAuthorizations) {
      yield put(setHasPendingAuthorizations(true));
    }
    yield put(loadAuthorizationsDocumentSuccess(authorizationsData));
  } catch {
    yield put(loadAuthorizationsDocumentError());
    yield showError();
  }
}

export function* setOrderAuthorizationStatusSaga({ payload }) {
  try {
    const {
      assessmentId,
      accessToken,
      targetAuthorizationValue,
      authorizationId,
      isLastPendingOrder,
    } = payload;
    const body = { targetAuthorizationValue, authorizationId };

    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL +
        `/assessments/${assessmentId}/authorizations`,
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to load");
    }

    yield put(setOrderAuthorizationStatusSuccess(payload));
    if (isLastPendingOrder) {
      yield put(setHasPendingAuthorizations(false));
    }
    yield put(setIsAuthStateModalOpen(false));
  } catch {
    yield showError();
    yield put(setOrderAuthorizationStatusError());
    yield put(setIsAuthStateModalOpen(false));
  }
}

export default function* authorizationsSagas() {
  yield all([
    takeLatest(loadAuthorizationsDocument.type, loadAuthorizationsDocumentSaga),
    takeLatest(
      setOrderAuthorizationStatus.type,
      setOrderAuthorizationStatusSaga
    ),
  ]);
}
