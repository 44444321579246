// Imports needed for IE11 support
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding";
import "webcrypto-shim";
import "./utils/polyfills/polyfills";
import "classlist-polyfill";
import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";
import { PropertyKeys } from "ag-grid-community";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import { getStore } from "app/store";
import { CUSTOM_AG_GRID_COLUMN_PROPERTIES } from "app/constants";
import * as serviceWorker from "./serviceWorker";
import { userPromptController } from "utils/userPromptController/userPromptController";
import "scss/index.scss";

const store = getStore();

JavascriptTimeAgo.addLocale(en);

PropertyKeys.ALL_PROPERTIES = [
  ...PropertyKeys.ALL_PROPERTIES,
  ...Object.values(CUSTOM_AG_GRID_COLUMN_PROPERTIES),
];

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const App = require("./App").default;
const container = document.getElementById("root");
const root = createRoot(container); // createRoot is a new method in React 18
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter
        getUserConfirmation={(payload, callback) =>
          userPromptController(payload, callback, store)
        }
      >
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

moduleHotAccept(module);

export function moduleHotAccept(mod) {
  if (process.env.NODE_ENV === "development" && mod.hot) {
    mod.hot.accept("./App");
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
