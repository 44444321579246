import { call, put, all, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadApiKeys,
  setApiKeysSuccess,
  setApiKeysError,
  generateApiKey,
  setGenerateApiKeySuccess,
  setGenerateApiKeyError,
  deleteApiKey,
  setDeleteApiKeySuccess,
  setDeleteApiKeyError,
  resetDeleteApiKey,
  refreshApiKey,
  setRefreshApiKeySuccess,
  setRefreshApiKeyError,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { addToast } from "services/toast/slice";

const checkErrors = (data) => {
  let message;

  if (data.errors) {
    const title = data.title.split(".").join("");
    const formattedErrors = [].concat(...Object.values(data.errors)).join(" ");

    message = `${title}: ${formattedErrors}`;
  } else {
    message = data.Title;
  }

  throw new Error(message);
};

export function* loadApiKeysSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + `/apikeys`,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const apiKeysData = yield response.json();
    yield put(setApiKeysSuccess(apiKeysData));
  } catch ({ message }) {
    yield put(setApiKeysError(message));
    yield showError(message);
  }
}

export function* generateApiKeySaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + `/apikeys`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(payload.data),
      }
    );

    const generatedKeyData = yield response.json();

    if (response.status >= 400) {
      checkErrors(generatedKeyData);
    }

    yield put(setGenerateApiKeySuccess(generatedKeyData));
  } catch ({ message }) {
    yield put(setGenerateApiKeyError(message));
    yield showError(message);
  }
}

export function* deleteApiKeySaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + `/apikeys/` + payload.apiKeyName,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );

    if (response.status >= 400) {
      const data = yield response.json();
      checkErrors(data);
    }

    yield put(addToast({ description: payload.successMessage }));
    yield put(setDeleteApiKeySuccess(payload.apiKeyName));
    yield put(resetDeleteApiKey());
  } catch ({ message }) {
    yield put(setDeleteApiKeyError(message));
    yield showError(message);
  }
}

export function* refreshApiKeySaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + `/apikeys/` + payload.apiKeyName,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );

    const refreshedKeyData = yield response.json();

    if (response.status >= 400) {
      checkErrors(refreshedKeyData);
    }

    yield put(setRefreshApiKeySuccess(refreshedKeyData));
  } catch ({ message }) {
    yield put(setRefreshApiKeyError(message));
    yield showError(message);
  }
}

export default function* apiKeysSagas() {
  yield all([
    takeLatest(loadApiKeys.type, loadApiKeysSaga),
    takeLatest(generateApiKey.type, generateApiKeySaga),
    takeLatest(deleteApiKey.type, deleteApiKeySaga),
    takeLatest(refreshApiKey.type, refreshApiKeySaga),
  ]);
}
