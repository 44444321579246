import classNames from "classnames";
import FocusLock from "react-focus-lock";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import NotificationsDrawer from "./components/NotificationsDrawer/NotificationsDrawer";
import { ReactComponent as NotificationIcon } from "assets/notification.svg";
import { loadNotifications as loadAction, markAsSeen } from "./services/slice";
import { selectNotifications } from "./services/selectors";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import "./notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const accessToken = useAccessToken();
  const { notifications, newNotificationsCount } =
    useSelector(selectNotifications);
  const [showAssessmentDetails, setShowAssessmentDetails] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeNotifications = () => {
    if (!showAssessmentDetails && newNotificationsCount > 0) {
      dispatch(
        markAsSeen({
          ids: newNotifications.map((x) => x.id),
          accessToken,
        })
      );
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setShowAssessmentDetails(false);
    closeNotifications();
  };

  const loadNotifications = useCallback(
    (all) => {
      dispatch(loadAction({ accessToken, all }));
    },
    [dispatch, accessToken]
  );

  useEffect(() => {
    loadNotifications(false);
  }, [loadNotifications]);

  const toggleNotifications = () => {
    if (drawerOpen && newNotificationsCount > 0) {
      dispatch(
        markAsSeen({
          ids: newNotifications.map((x) => x.id),
          accessToken,
        })
      );
    }
    setDrawerOpen(!drawerOpen);
    setShowAssessmentDetails(false);
  };

  const hasNotifications = notifications && notifications.length > 0;
  const newNotifications =
    (hasNotifications && notifications.filter((x) => x.seenAt == null)) || [];

  useCheckOutsideClick(ref, closeDrawer, drawerOpen);

  return (
    <FocusLock
      group="drawer-modal"
      disabled={!drawerOpen}
      returnFocus
      data-testid="notifications-focus-lock"
    >
      <div
        className="notifications"
        data-testid="notifications-content"
        ref={ref}
      >
        <div className="notifications__icon-wrapper">
          <ButtonIcon
            ariaExpanded={drawerOpen}
            ariaHasPopup
            className={classNames("notifications__icon", {
              "notifications__icon--drawer-open": drawerOpen,
            })}
            data-testid="notifications-button-icon"
            icon={<NotificationIcon />}
            onClick={toggleNotifications}
            label={`${
              newNotificationsCount > 0 ? "You have new notifications, " : ""
            } Toggle notifications`}
            tooltipHidden={drawerOpen}
            tooltipText="Notifications"
          />
          {newNotificationsCount > 0 && (
            <span
              data-testid="notifications-count-span"
              className={classNames("notifications__count", {
                "notifications__count--medium":
                  newNotificationsCount >= 10 && newNotificationsCount < 100,
                "notifications__count--large":
                  newNotificationsCount >= 100 && newNotificationsCount < 1000,
                "notifications__count--indicator":
                  newNotificationsCount >= 1000,
              })}
            >
              {newNotificationsCount < 1000 && newNotificationsCount}
            </span>
          )}
        </div>
        <NotificationsDrawer
          data-testid="notifications-drawer"
          closeNotifications={closeNotifications}
          closeDrawer={closeDrawer}
          loadNotifications={loadNotifications}
          newNotifications={newNotifications}
          notifications={notifications}
          setShowAssessmentDetails={setShowAssessmentDetails}
          setDrawerOpen={setDrawerOpen}
          showAssessmentDetails={showAssessmentDetails}
          drawerOpen={drawerOpen}
        />
      </div>
    </FocusLock>
  );
};

export default Notifications;
