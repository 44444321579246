import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import { selectSlice } from "./services/selectors";
import { setTimer } from "./services/slice";
import messages from "./messages";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import { logoutSaga } from "../../services/logout/sagas";

import "./total-session-timer.scss";

const TotalSessionTimer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { timer } = useSelector(selectSlice);
  const { oktaAuth } = useOktaAuth();

  const accessToken = useAccessToken();

  let reduceTimerEveryXMin = 5;

  if (timer <= 0) {
    logoutSaga({ accessToken });
    oktaAuth.signOut();
  }

  let outOfSyncMinutes = timer % reduceTimerEveryXMin;
  if (outOfSyncMinutes > 0) {
    reduceTimerEveryXMin = outOfSyncMinutes;
  }

  if (timer <= 5) {
    reduceTimerEveryXMin = 1;
  }
  setTimeout(
    () => {
      const newTime = timer - reduceTimerEveryXMin;

      if (newTime <= 0) {
        dispatch(setTimer(0));
        oktaAuth.signOut();
      } else {
        dispatch(setTimer(newTime));
      }
    },
    reduceTimerEveryXMin * 60 * 1000
  );

  return (
    <div className="total-session-timer">
      <p className="total-session-timer__content">
        <FormattedMessage
          {...messages.timerMessage}
          values={{
            ...messages.timerMessage.values,
            timer,
          }}
        />
      </p>
      <p className="total-session-timer__content">
        {intl.formatMessage(messages.saveYourWork)}
      </p>
    </div>
  );
};

export default TotalSessionTimer;
