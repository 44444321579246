import { stringSortingComparator } from "utils/table/comparators";

export const onlyUnique = (filterOption, index, array) => {
  const filterValue = filterOption.value;
  if (filterValue) {
    return array.findIndex((x) => x.value === filterValue) === index;
  }
};

export const sortItemsByLabel = (a, b) =>
  stringSortingComparator(a.label, b.label);
