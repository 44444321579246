import React from "react";
import { defineMessages } from "react-intl";

const scope = "components.totalSessionTimer.";

export default defineMessages({
  timerMessage: {
    id: `${scope}timerMessage`,
    defaultMessage: "Your session will automatically expire in <strong>{timer} minute(s)</strong>.",
    values: {
      strong: (text) => <strong>{text}</strong>,
    },
  },
  saveYourWork: {
    id: `${scope}saveYourWork`,
    defaultMessage: "Please save your work! You can also log out and re-login to continue working.",
  },
});
