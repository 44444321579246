import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  hasCustomerUnmatchedThirdPartiesLoaded: false,
  customerUnmatchedThirdPartiesLoading: false,
  customerUnmatchedThirdPartiesError: null,
  shouldCustomerUnmatchedThirdPartiesReload: false,
  unmatchedVendorInfo: null,
  unmatchedVendorInfoError: null,
  unmatchedVendorInfoLoading: false,
  matchedVendorInfo: {},
  displayUploadedVendorInfo: true,
  displayPossibleMatchVendorInfo: false,
  drawers: {
    vendorInfoDrawer: {
      isOpen: false,
    },
  },
  modals: {
    deleteVendorModal: {
      isOpen: false,
      isLoading: false,
    },
    createCandidateModal: {
      isOpen: false,
      isLoading: false,
    },
    uploadThirdPartiesModal: {
      isOpen: false,
    },
    modalData: null,
  },
  overrideThirdPartyLoading: false,
  overrideThirdPartyError: null,
  overrideThirdPartyModal: {
    candidateId: null,
    isOpen: false,
    uploadedThirdParty: "",
    suggestedThirdParty: "",
    truSightId: "",
    organizationId: "",
    thirdPartyId: 0,
  },
  formSavePending: false,
  formSaveError: null,
};

const slice = createSlice({
  name: "manageCustomerUnmatchedPage",
  initialState,
  reducers: {
    loadCustomerUnmatchedThirdParties: (state) => {
      state.customerUnmatchedThirdPartiesLoading = true;
      state.customerUnmatchedThirdPartiesError = null;
      state.shouldCustomerUnmatchedThirdPartiesReload = false;
    },
    loadUnmatchedCustomerThirdPartiesSuccess: (state) => {
      state.hasCustomerUnmatchedThirdPartiesLoaded = true;
      state.customerUnmatchedThirdPartiesLoading = false;
      state.customerUnmatchedThirdPartiesError = null;
    },
    loadUnmatchedCustomerThirdPartiesError: (state, action) => {
      state.customerUnmatchedThirdPartiesLoading = false;
      state.customerUnmatchedThirdPartiesError = action.payload;
    },
    setShouldCustomerUnmatchedThirdPartiesReload: (state, { payload }) => {
      state.shouldCustomerUnmatchedThirdPartiesReload = payload;
    },
    loadCustomerThirdPartiesPossibleMatches: () => {},
    loadUnmatchedThirdPartyDetails: (state) => {
      state.unmatchedVendorInfoError = null;
      state.unmatchedVendorInfo = null;
      state.unmatchedVendorInfoLoading = true;
    },
    loadUnmatchedThirdPartyDetailsSuccess: (state, action) => {
      state.unmatchedVendorInfoError = null;
      state.unmatchedVendorInfo = { ...action.payload };
      state.unmatchedVendorInfoLoading = false;
    },
    loadUnmatchedThirdPartyDetailsError: (state, action) => {
      state.unmatchedVendorInfoError = action.payload;
      state.unmatchedVendorInfo = null;
      state.unmatchedVendorInfoLoading = false;
    },
    setPossibleMatchVendorInfo: (state, action) => {
      state.matchedVendorInfo = { ...action.payload };
    },
    setParentVendorInfo: (state, action) => {
      state.matchedVendorInfo.matchedThirdPartyId =
        action.payload.matchedThirdPartyId;
      state.matchedVendorInfo.uploadedName = action.payload.uploadedName;
    },
    setDisplayUploadedVendorInfo: (state, action) => {
      state.displayUploadedVendorInfo = action.payload;
    },
    setDisplayPossibleMatchVendorInfo: (state, action) => {
      state.displayPossibleMatchVendorInfo = action.payload;
    },
    deleteUnmatchedThirdParty: (state) => {
      state.modals.deleteVendorModal.isLoading = true;
    },
    deleteUnmatchedThirdPartySuccess: (state, action) => {
      state.modals.deleteVendorModal.isLoading = false;
      state.modals.deleteVendorModal.isOpen = false;
    },
    deleteUnmatchedThirdPartyError: (state) => {
      state.modals.deleteVendorModal.isLoading = false;
    },
    updateUnmatchedThirdPartyDetails: (state) => {
      state.formSavePending = true;
    },
    updateUnmatchedThirdPartyDetailsSuccess: (state) => {
      state.formSavePending = false;
      state.formSaveError = null;
    },
    updateUnmatchedThirdPartyDetailsError: (state, action) => {
      state.formSavePending = false;
      state.formSaveError = action.payload;
    },
    createCandidate: (state) => {
      state.modals.createCandidateModal.isLoading = true;
    },
    createCandidateSuccess: (state) => {
      state.modals.createCandidateModal.isLoading = false;
      state.modals.createCandidateModal.isOpen = false;
    },
    createCandidateError: (state) => {
      state.modals.createCandidateModal.isLoading = false;
    },
    setVendorInfoDrawerOpen: (state, { payload }) => {
      state.drawers.vendorInfoDrawer.isOpen = payload;
    },
    setDeleteVendorModalOpen: (state, { payload }) => {
      state.modals.deleteVendorModal.isOpen = payload;
    },
    setCreateCandidateModalOpen: (state, { payload }) => {
      state.modals.createCandidateModal.isOpen = payload;
    },
    setModalData: (state, { payload }) => {
      state.modals.modalData = payload;
    },
    overrideThirdParty: (state) => {
      state.overrideThirdPartyLoading = true;
      state.overrideThirdPartyError = null;
    },
    overrideThirdPartySuccess: (state) => {
      state.overrideThirdPartyLoading = false;
      state.overrideThirdPartyModal.isOpen = false;
      state.overrideThirdPartyError = null;
    },
    overrideThirdPartyError: (state, action) => {
      state.overrideThirdPartyLoading = false;
      state.overrideThirdPartyError = action.payload;
    },
    toggleOverrideThirdPartyModal: (state, { payload }) => {
      state.overrideThirdPartyModal = payload;
    },
    setUploadThirdPartiesModalOpen: (state, { payload }) => {
      state.modals.uploadThirdPartiesModal.isOpen = payload;
    },
    proposeMatch: () => {},
    removeProposedMatch: () => {},
    resetManageCustomerUnmatchedPageState: () => initialState,
  },
});

export const {
  loadCustomerUnmatchedThirdParties,
  loadUnmatchedCustomerThirdPartiesSuccess,
  loadUnmatchedCustomerThirdPartiesError,
  setShouldCustomerUnmatchedThirdPartiesReload,
  loadUnmatchedThirdPartyDetails,
  loadCustomerThirdPartiesPossibleMatches,
  loadUnmatchedThirdPartyDetailsSuccess,
  loadUnmatchedThirdPartyDetailsError,
  setDisplayUploadedVendorInfo,
  setPossibleMatchVendorInfo,
  setParentVendorInfo,
  setDisplayPossibleMatchVendorInfo,
  deleteUnmatchedThirdParty,
  deleteUnmatchedThirdPartySuccess,
  deleteUnmatchedThirdPartyError,
  updateUnmatchedThirdPartyDetails,
  updateUnmatchedThirdPartyDetailsSuccess,
  updateUnmatchedThirdPartyDetailsError,
  createCandidate,
  createCandidateSuccess,
  createCandidateError,
  setVendorInfoDrawerOpen,
  setCreateCandidateModalOpen,
  setDeleteVendorModalOpen,
  setModalData,
  overrideThirdParty,
  overrideThirdPartySuccess,
  overrideThirdPartyError,
  toggleOverrideThirdPartyModal,
  proposeMatch,
  setUploadThirdPartiesModalOpen,
  removeProposedMatch,
  resetManageCustomerUnmatchedPageState,
} = slice.actions;

export default slice;
