import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "lodash";
import { useIntl } from "react-intl";

import useAccessToken from "utils/useAccessToken/useAccessToken";
import TsModal from "components/GlobalComponents/Modal";
import PurchaseModalHeader from "./components/PurchaseModalHeader";
import PurchaseModalContent from "./components/PurchaseModalContent";
import { setIsDirty } from "services/form/slice";
import {
  selectAssessmentsToPurchase,
  selectIsBulkPurchaseModalOpen,
  selectIsPurchaseSubmitting,
} from "../PurchaseAssessment/services/selectors";
import {
  setIsBulkPurchaseModalOpen,
  submitAssessmentPurchase,
  resetPurchaseAssessments,
} from "../PurchaseAssessment/services/slice";
import {
  selectIsPurchasableTemplatesLoading,
  selectPurchasableTemplates,
} from "./services/selectors";
import {
  loadPurchasableTemplates,
  resetPurchasableTemplates,
} from "./services/slice";
import { ORGANIZATION_TYPES, GLOBAL_TEMPLATE_SORT_ORDER } from "app/constants";
import componentMessages from "./messages";

import "./purchase-assessment-with-templates.scss";

const PurchaseAssessmentWithTemplates = () => {
  const intl = useIntl();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const isPurchaseSubmitting = useSelector(selectIsPurchaseSubmitting);
  const isBulkPurchaseModalOpen = useSelector(selectIsBulkPurchaseModalOpen);
  const purchaseAssessmentDetails = useSelector(selectAssessmentsToPurchase);
  const purchasableTemplates = useSelector(selectPurchasableTemplates);
  const isLoading = useSelector(selectIsPurchasableTemplatesLoading);

  const [selectedAssessments, setSelectedAssessments] = useState(
    purchaseAssessmentDetails.filter(
      (assessment) =>
        assessment.canPurchase && assessment.possibleProductTypes.length > 0
    )
  );
  const [assessmentsWithOptions, setAssessmentsWithOptions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const initialOptionsValue = purchasableTemplates?.map((assessment) => {
    const sortedGlobalTemplates = sortBy(
      assessment.templates.filter(
        (option) =>
          option.createdBy === ORGANIZATION_TYPES.truSight &&
          option.id !== "undefined"
      ),
      function (item) {
        return GLOBAL_TEMPLATE_SORT_ORDER.indexOf(item.name);
      }
    );

    const sortedCustomTemplates = sortBy(
      assessment.templates.filter(
        (option) =>
          option.createdBy !== ORGANIZATION_TYPES.truSight &&
          option.id !== "undefined"
      ),
      "name"
    );
    const bpqProductTypeTemplates = sortBy(
      assessment.templates.filter((option) => option.id === "undefined"),
      "name"
    );

    const options = [
      ...bpqProductTypeTemplates,
      ...sortedGlobalTemplates,
      ...sortedCustomTemplates,
    ];

    return {
      assessmentId: assessment.assessmentId,
      name: purchaseAssessmentDetails.find(
        (product) => product.assessmentId === assessment.assessmentId
      )?.name,
      selectedOptionId: options[0].id,
      selectedOptionName: options[0].name,
      options: options,
    };
  });

  const getPurchasableTemplatesPayload = () => {
    const assessmentIds = purchaseAssessmentDetails
      .filter(
        (assessment) =>
          assessment.canPurchase && assessment.possibleProductTypes.length > 0
      )
      .map((assessment) => `assessmentIds=${assessment.assessmentId}`)
      .join("&");

    return {
      accessToken,
      assessmentsIds: assessmentIds,
    };
  };

  useEffect(() => {
    setSelectedAssessments(
      purchaseAssessmentDetails.filter(
        (assessment) =>
          assessment.canPurchase && assessment.possibleProductTypes.length > 0
      )
    );
    if (purchasableTemplates) {
      setAssessmentsWithOptions(initialOptionsValue);
    }
    // eslint-disable-next-line
  }, [purchasableTemplates, purchaseAssessmentDetails]);

  useEffect(() => {
    const purchasableTemplatesPayload = getPurchasableTemplatesPayload();

    if (purchasableTemplatesPayload.assessmentsIds) {
      dispatch(loadPurchasableTemplates(purchasableTemplatesPayload));
    }
    // eslint-disable-next-line
  }, [dispatch, purchaseAssessmentDetails]);

  useEffect(() => {
    let total = 0;
    if (assessmentsWithOptions) {
      assessmentsWithOptions.forEach((assessment) => {
        total += assessment.options.find(
          (option) => option.id === assessment.selectedOptionId
        ).purchasePrice;
      });

      setTotalPrice(total);
    }
  }, [assessmentsWithOptions]);

  const handlePurchaseAssessmentsClick = () => {
    const data = assessmentsWithOptions.map((assessment) => {
      const purchasableTemplate = assessment.options.find(
        (option) => assessment.selectedOptionId === option.id
      );
      if (purchasableTemplate.isProductType) {
        return {
          assessmentId: assessment.assessmentId,
          productType: purchasableTemplate.name,
        };
      } else {
        return {
          assessmentId: assessment.assessmentId,
          productType: purchasableTemplate.name,
          customTemplateId: purchasableTemplate.id,
        };
      }
    });

    const assessmentsNames = assessmentsWithOptions.map((assessment) => ({
      assessmentName: assessment.name,
      assessmentId: assessment.assessmentId,
    }));

    dispatch(
      submitAssessmentPurchase({
        accessToken,
        data,
        selectedAssessmentsCount: selectedAssessments.length,
        assessmentsNames,
      })
    );
    dispatch(setIsDirty({ value: false }));
  };

  const handlePurchaseModalOpen = (isOpen) => {
    dispatch(setIsBulkPurchaseModalOpen(isOpen));

    if (!isOpen) {
      dispatch(resetPurchasableTemplates());
      dispatch(resetPurchaseAssessments());
      setAssessmentsWithOptions([]);
    }
  };

  const PURCHASE_MODAL_TITLE =
    selectedAssessments.length === 1
      ? intl.formatMessage(componentMessages.purchaseSingleAssessmentTitle)
      : intl.formatMessage(componentMessages.purchaseMultipleAssessmentsTitle, {
          amount: selectedAssessments.length,
        });

  const isThereAssessmentWithEscalationTier = purchaseAssessmentDetails.some(
    (a) => a.escalationTier
  );

  return (
    <TsModal
      className="purchase-modal"
      isModalOpen={isBulkPurchaseModalOpen}
      setModalOpen={handlePurchaseModalOpen}
      modalTitle={PURCHASE_MODAL_TITLE}
      modalHeaderContent={
        <PurchaseModalHeader
          selectedAssessmentsDetails={assessmentsWithOptions}
          setSelectedAssessmentsDetails={setAssessmentsWithOptions}
          isThereAssessmentWithEscalationTier={
            isThereAssessmentWithEscalationTier
          }
        />
      }
      confirmActionText={
        selectedAssessments.length === 1
          ? intl.formatMessage(componentMessages.purchaseSingleAssessment)
          : intl.formatMessage(componentMessages.purchaseMultipleAssessments)
      }
      onConfirmAction={handlePurchaseAssessmentsClick}
      isConfirmButtonLoading={isPurchaseSubmitting}
      isConfirmButtonDisabled={isPurchaseSubmitting || isLoading}
      modalContent={
        <PurchaseModalContent
          assessmentsDetails={assessmentsWithOptions}
          setSelectedAssessmentsDetails={setAssessmentsWithOptions}
          isLoading={isLoading}
        />
      }
      footerUpperRow={
        <div
          className="purchase-modal__total-cost"
          data-testid="purchase-modal-total-cost"
        >
          {intl.formatMessage(componentMessages.total)}
          <span>
            {intl.formatNumber(isLoading ? 0 : totalPrice, {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </div>
      }
      showFooter
      showLeavePrompt
    />
  );
};

export default PurchaseAssessmentWithTemplates;
