import { put, all, takeEvery } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadClassifierItemsData,
  loadClassifierItemsByParentData,
  loadClassifierItemsDataSuccess,
  loadClassifierItemsDataError,
} from "./slice";

function* loadClassifierItemsSaga({ payload }) {
  try {
    const { classifierName, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/classifiers/${classifierName}/items`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    const data = yield response.json();
    yield put(
      loadClassifierItemsDataSuccess({
        classifierName,
        items: data,
      })
    );
  } catch ({ message }) {
    yield put(loadClassifierItemsDataError(message));
  }
}

function* loadClassifierItemsByParentSaga({ payload }) {
  try {
    const { classifierName, parentId, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/classifiers/${classifierName}/child-items/${parentId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    const data = yield response.json();
    yield put(
      loadClassifierItemsDataSuccess({
        classifierName,
        items: data,
      })
    );
  } catch ({ message }) {
    yield put(loadClassifierItemsDataError(message));
  }
}

export default function* loadClassifierItemsDataSagas() {
  yield all([
    yield takeEvery(loadClassifierItemsData.type, loadClassifierItemsSaga),
    yield takeEvery(
      loadClassifierItemsByParentData.type,
      loadClassifierItemsByParentSaga
    ),
  ]);
}
