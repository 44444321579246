import { put, takeLatest, all, select } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import { createIntl } from "react-intl";
import escape from "escape-html";

import { selectLocale } from "components/LanguageProvider/services/selectors";
import { addToast } from "services/toast/slice";
import { TOAST_TYPES } from "app/constants";
import messages from "app/messages";
import thirdPartyMessages from "../messages";
import {
  loadThirdPartyUsers,
  loadThirdPartyUsersError,
  loadThirdPartyUsersSuccess,
  loadRoles,
  loadRolesSuccess,
  loadRolesError,
  addUser,
  addThirdPartySuccess,
  addThirdPartyError,
  loadContacts,
  loadContactsSuccess,
  loadContactsError,
  validateUserEmail,
  validateUserEmailSuccess,
  validateUserEmailError,
  updateUser,
  updateUserSuccess,
  updateUserError,
} from "./slice";

export function* loadThirdPartyUsersSaga({ payload }) {
  try {
    const { id, accessToken } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/${id}/users`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadThirdPartyUsersSuccess(data));
  } catch ({ message }) {
    yield put(loadThirdPartyUsersError(message));
  }
}

export function* loadRolesSaga({ payload }) {
  try {
    const { accessToken, thirdPartyId } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdparties/${thirdPartyId}/users/thirdpartyroles`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadRolesSuccess(data));
  } catch ({ message }) {
    yield put(loadRolesError(message));
  }
}

export function* loadContactsSaga({ payload }) {
  try {
    const { accessToken, thirdPartyId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdparties/${thirdPartyId}/contacts`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error();
    }
    const data = yield response.json();
    yield put(loadContactsSuccess(data));
  } catch ({ message }) {
    yield put(loadContactsError());
    const locale = yield select(selectLocale);
    const intl = createIntl({ locale: locale, messages: thirdPartyMessages });
    const errorToast = {
      description: intl.formatMessage(
        thirdPartyMessages.loadThirdPartyContactError
      ),
      type: TOAST_TYPES.error,
    };
    yield put(addToast(errorToast));
  }
}

export function* addUserSaga({ payload }) {
  try {
    const { accessToken, values, thirdPartyId } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/ThirdParties/${thirdPartyId}/users`,
      {
        method: "POST",
        body: JSON.stringify({ ...values }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = yield response.json();

      yield put(addThirdPartySuccess(data));
      yield put(
        addToast({
          description: `${escape(data.firstName)} ${escape(
            data.lastName
          )} has been invited to complete an account`,
        })
      );
    } else {
      const locale = yield select(selectLocale);
      const intl = createIntl({ locale: locale, messages: messages });
      const errorToast = {
        description: intl.formatMessage(messages.error),
        type: TOAST_TYPES.error,
      };

      yield put(addToast(errorToast));
    }
  } catch ({ message }) {
    yield put(addThirdPartyError(message));
  }
}

export function* validateUserEmailSaga({ payload }) {
  try {
    const { accessToken, email, thirdPartyId } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/ThirdParties/${thirdPartyId}/users/validate`,
      {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      yield put(validateUserEmailSuccess({ email }));
    } else {
      const data = yield response.json();
      yield put(validateUserEmailError({ ...data, email }));
    }
  } catch ({ message }) {
    yield put(validateUserEmailError(message));
  }
}

export function* updateUserSaga({ payload }) {
  try {
    const { accessToken, updatedDetails, thirdPartyId, userId } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/thirdParties/${thirdPartyId}/users/${userId}`,
      {
        method: "PUT",
        body: JSON.stringify(updatedDetails),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      yield put(updateUserSuccess());
      yield put(
        addToast({
          description: `${escape(updatedDetails.FirstName)} ${escape(
            updatedDetails.LastName
          )} details were updated`,
        })
      );
    } else {
      const locale = yield select(selectLocale);
      const intl = createIntl({ locale: locale, messages: messages });
      const errorToast = {
        description: intl.formatMessage(messages.error),
        type: TOAST_TYPES.error,
      };
      yield put(addToast(errorToast));
    }
  } catch ({ message }) {
    yield put(updateUserError(message));
  }
}

export default function* companyPeopleSagas() {
  yield all([
    yield takeLatest(loadThirdPartyUsers.type, loadThirdPartyUsersSaga),
    yield takeLatest(loadRoles.type, loadRolesSaga),
    yield takeLatest(addUser.type, addUserSaga),
    yield takeLatest(loadContacts.type, loadContactsSaga),
    yield takeLatest(validateUserEmail.type, validateUserEmailSaga),
    yield takeLatest(updateUser.type, updateUserSaga),
  ]);
}
