import { defineMessages } from "react-intl";

const scope = "commentBuilder.drawer.";

export default defineMessages({
  customCommentUnsaved: {
    id: `${scope}customComment`,
    defaultMessage: "Changes made to the Custom Comment will not be saved. Are you sure you want to leave?",
  },
  customCommentWarning: {
    id: `${scope}customCommentWarning`,
    defaultMessage: "Custom editing will restrict TruSight's ability to fully utilize data in this product. A custom comment cannot be saved as a draft.",
  },
  customCommentWarningReadOnly: {
    id: `${scope}customCommentWarningReadOnly`,
    defaultMessage: "The assessor chose to write a custom comment instead of using the generated comment.",
  },
  commentBuilderDrawerUnsaved: {
    id: `${scope}commentBuilderDrawerUnsaved`,
    defaultMessage: "Changes made to the Custom Comment and Assessment Review fields will not be saved. Are you sure you want to leave?",
  },
});
