import { createSlice } from "@reduxjs/toolkit";

const initialGenerationState = {
  generatedKey: null,
  generationLoading: false,
  generationError: null,
};
const initialDeleteState = {
  deleteLoading: false,
  deleteError: null,
  deleteSuccess: false,
};
const initialRefreshState = {
  refreshedKey: null,
  refreshLoading: false,
  refreshError: null,
};
export const initialState = {
  data: null,
  loading: false,
  error: null,
  ...initialGenerationState,
  ...initialDeleteState,
  ...initialRefreshState,
};

const slice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    loadApiKeys: (state) => {
      state.loading = true;
    },
    setApiKeysSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    setApiKeysError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetApiKeys: () => initialState,
    generateApiKey: (state) => {
      state.generationLoading = true;
    },
    setGenerateApiKeySuccess: (state, action) => {
      const { apiKey, details } = action.payload;
      state.generationLoading = false;
      state.generatedKey = apiKey;
      state.data.apiKeys = [details, ...state.data.apiKeys];
    },
    setGenerateApiKeyError: (state, action) => {
      state.generationLoading = false;
      state.generationError = action.payload;
    },
    resetGenerateApiKey: (state) => ({ ...state, ...initialGenerationState }),
    deleteApiKey: (state) => {
      state.deleteLoading = true;
    },
    setDeleteApiKeySuccess: (state, action) => {
      state.deleteLoading = false;
      state.deleteSuccess = true;
      state.data.apiKeys = state.data.apiKeys.filter(
        (key) => key.name !== action.payload
      );
    },
    setDeleteApiKeyError: (state, action) => {
      state.deleteLoading = false;
      state.deleteError = action.payload;
    },
    resetDeleteApiKey: (state) => ({ ...state, ...initialDeleteState }),
    refreshApiKey: (state) => {
      state.refreshLoading = true;
    },
    setRefreshApiKeySuccess: (state, action) => {
      const { apiKey, details } = action.payload;
      state.refreshLoading = false;
      state.refreshedKey = apiKey;
      state.data.apiKeys = state.data.apiKeys.filter(
        (key) => key.name !== details.name
      );
      state.data.apiKeys = [details, ...state.data.apiKeys];
    },
    setRefreshApiKeyError: (state, action) => {
      state.refreshLoading = false;
      state.refreshError = action.payload;
    },
    resetRefreshApiKey: (state) => ({ ...state, ...initialRefreshState }),
  },
});

export const {
  loadApiKeys,
  setApiKeysSuccess,
  setApiKeysError,
  resetApiKeys,
  generateApiKey,
  setGenerateApiKeySuccess,
  setGenerateApiKeyError,
  resetGenerateApiKey,
  deleteApiKey,
  setDeleteApiKeySuccess,
  setDeleteApiKeyError,
  resetDeleteApiKey,
  refreshApiKey,
  setRefreshApiKeySuccess,
  setRefreshApiKeyError,
  resetRefreshApiKey,
} = slice.actions;

export default slice;
