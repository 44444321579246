import React, { lazy, Suspense, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

import MainLayout from "components/MainLayout";
import routes from "app/routes";
import LanguageProvider from "components/LanguageProvider";
import NotFoundPage from "pages/NotFoundPage";
import LoadingPage from "pages/LoadingPage";
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute";
import { DEFAULT_TITLE } from "components/AuthorizedRoute/utils/constants";
import { appInsights } from "services/appInsights/appInsights";
import { OKTA_AUTH_CONFIG } from "./app/constants";
import "scss/index.scss";

function App() {
  const oktaAuth = new OktaAuth(OKTA_AUTH_CONFIG);
  const titleTemplate = `%s | ${DEFAULT_TITLE}`;
  const history = useHistory();

  const onAuthRequired = () => {
    const referrerPath = sessionStorage.getItem("referrerPath");
    const originalUrl = sessionStorage.getItem(OKTA_AUTH_CONFIG.originalURL);
    if (originalUrl === null || originalUrl === "null") {
      sessionStorage.setItem(OKTA_AUTH_CONFIG.originalURL, referrerPath);
    }
    history.push("/login");
  };

  const restoreOriginalUri = (_oktaAuth) => {
    const originalUrl = sessionStorage.getItem(OKTA_AUTH_CONFIG.originalURL);
    history.replace(
      toRelativeUrl(
        originalUrl === null || originalUrl === "null"
          ? "/"
          : sessionStorage.getItem(OKTA_AUTH_CONFIG.originalURL),
        window.location.origin
      )
    );
  };

  useEffect(() => {
    if (history.location.pathname === "/login") {
      sessionStorage.setItem(OKTA_AUTH_CONFIG.originalURL, null);
    }
  }, [history]);

  const allRoutes = useMemo(
    () =>
      Object.values(routes).map(
        ({
          path,
          getComponent,
          allowAnonymous,
          title,
          permission,
          disabled,
          exact,
        }) => ({
          path,
          LazyRouteComponent: lazy(getComponent),
          allowAnonymous,
          title,
          permission,
          disabled,
          exact,
        })
      ),
    []
  );

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong, please reload your page</h1>}
      appInsights={appInsights}
    >
      <LanguageProvider>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={onAuthRequired}
        >
          {/* This has to be outside of MainLayout otherwise it points to loader before loginCallback */}
          <Route path="/implicit/callback" component={LoginCallback} />
          <MainLayout>
            <Suspense fallback={<LoadingPage />}>
              <Helmet>
                <title>{DEFAULT_TITLE}</title>
              </Helmet>
              <Switch>
                <Redirect
                  from="/:url*(/+)"
                  to={window.location.pathname.slice(0, -1)}
                />
                {allRoutes.map(
                  ({
                    path,
                    LazyRouteComponent,
                    allowAnonymous,
                    title,
                    permission,
                    disabled,
                    exact,
                  }) => {
                    return allowAnonymous ? (
                      <Route key={path} path={path} exact>
                        <Helmet titleTemplate={titleTemplate}>
                          <title>{title}</title>
                        </Helmet>
                        <LazyRouteComponent />
                      </Route>
                    ) : (
                      <AuthorizedRoute
                        component={LazyRouteComponent}
                        key={path}
                        path={path}
                        requiredPermissions={permission}
                        title={title}
                        titleTemplate={titleTemplate}
                        disabled={disabled}
                        exact={exact}
                      />
                    );
                  }
                )}
                <Route path={"/"}>
                  <Helmet titleTemplate={titleTemplate}>
                    <title>Not Found</title>
                  </Helmet>
                  <NotFoundPage />
                </Route>
              </Switch>
            </Suspense>
          </MainLayout>
        </Security>
      </LanguageProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
