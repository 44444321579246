import React, { Children } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { components } from "react-select";

import SelectMultiValue from "../SelectMultiValue/SelectMultiValue";
import { getMultiSelectChildren, getSubText } from "./utils/utils";

const SelectInputField = ({ children, ...props }) => {
  const { Placeholder, ValueContainer, MultiValue } = components;
  const { selectProps } = props;
  const multiSelectChildren = getMultiSelectChildren(children);

  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.isFocused}
        className={classNames({
          [`${selectProps.classNamePrefix}__placeholder--required`]:
            selectProps.isRequired,
          [`${selectProps.classNamePrefix}__placeholder--has-value`]:
            selectProps.value,
        })}
      >
        {getSubText(selectProps)}
      </Placeholder>
      {Children.map(children, (child) => {
        if (child?.type === MultiValue) {
          return;
        }
        return child?.type !== Placeholder ? child : null;
      })}
      <SelectMultiValue
        multiSelectChildren={multiSelectChildren}
        {...selectProps.selectMultiValueProps}
      />
    </ValueContainer>
  );
};

SelectInputField.defaultProps = {
  children: null,
  isFocused: false,
  selectProps: {
    value: null,
    prefixText: "",
    placeholder: "",
  },
};

SelectInputField.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object,
};

export default SelectInputField;
