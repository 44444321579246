import { defineMessages } from "react-intl";

const scope = "pages.myListPage.";

export default defineMessages({
  uploadThirdPartiesTitle: {
    id: `${scope}uploadThirdPartiesTitle`,
    defaultMessage: "Manage all of your third party assessments in one place.",
  },
  uploadThirdPartiesSubTitle: {
    id: `${scope}uploadThirdPartiesSubTitle`,
    defaultMessage: "Upload all your third parties to view available assessments and receive notifications if action is needed.",
  },
  allThirdParties: {
    id: `${scope}allThirdParties`,
    defaultMessage: "All Third Parties",
  },
  requests: {
    id: `${scope}requests`,
    defaultMessage: "Requests",
  },
  unmatched: {
    id: `${scope}unmatched`,
    defaultMessage: "Unmatched",
  },
  addThirdParties: {
    id: `${scope}addThirdParties`,
    defaultMessage: "Add Third Parties",
  },
});
