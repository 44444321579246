import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useIntl } from "react-intl";

import { getPermissions } from "components/AuthorizedComponent/slice";
import {
  selectPermissions,
  selectPermissionsInitialising,
} from "components/AuthorizedComponent/selectors";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import messages from "./utils/messages";

const NotFoundPage = () => {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const { authState } = useOktaAuth();
  const intl = useIntl();

  const permissions = useSelector(selectPermissions);
  const permissionsInitialising = useSelector(selectPermissionsInitialising);

  const needInit = !permissions && !permissionsInitialising;

  useEffect(() => {
    if (authState.isAuthenticated && needInit) {
      dispatch(getPermissions({ accessToken }));
    }
  });

  return (
    <h2 className="title" data-testid="not-found-path-value">
      {intl.formatMessage(messages.pageNotFoundText)}
    </h2>
  );
};

export default NotFoundPage;
