import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./tooltip.scss";

const Tooltip = ({ text, className, isHidden, position }) => {
  return (
    <span
      aria-hidden="true"
      className={classNames("tooltip", className, {
        [`tooltip--${position}`]: position,
        "tooltip--hidden": isHidden,
      })}
    >
      {text}
    </span>
  );
};

Tooltip.defaultProps = {
  isHidden: false,
  position: "",
  className: "",
};

Tooltip.propTypes = {
  isHidden: PropTypes.bool,
  position: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Tooltip;
