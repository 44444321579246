import {
  all,
  put,
  delay,
  select,
  call,
  fork,
  take,
  takeLatest,
  cancel,
} from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { PRODUCT_STATUS_CHECK_INTERVAL } from "app/constants";
import { selectData } from "./selectors";
import { selectDownloadingIds } from "pages/CatalogPage/services/selectors";
import {
  loadPurchasedList,
  loadPurchasedListSuccess,
  loadPurchasedListError,
  inProgressListSync,
  cancelLoadPurchasedAssessments,
} from "./slice";
import { checkIsInProgress, checkIsReadyForDownload } from "../utils/utils";
import { downloadAssessment } from "pages/CatalogPage/services/slice";
import {
  getUserInitiatedDownloadIds,
  saveUserInitiatedDownloadIds,
} from "pages/CatalogPage/utils/localStorage";

export function* loadPurchasedAssessmentsSagaCancellable({ payload }) {
  try {
    const { accessToken, isReload } = payload;
    if (isReload) yield delay(PRODUCT_STATUS_CHECK_INTERVAL);

    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/purchased-assessments",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    const oldData = yield select(selectData);

    const productsInProgress =
      data?.assessments?.filter((assessment) =>
        checkIsInProgress(assessment)
      ) || [];
    const oldProductsInProgress =
      oldData?.filter((assessment) => checkIsInProgress(assessment)) || [];

    const productsWithProgressChangedToDone = [];
    oldProductsInProgress.forEach((oldAssessment) => {
      const productsMatched = data?.assessments?.filter(
        (assessment) => assessment.productId === oldAssessment.productId
      );
      productsMatched?.forEach((assessment) => {
        if (checkIsReadyForDownload(assessment))
          productsWithProgressChangedToDone.push(assessment);
      });
    });

    // update store on first load or when product status changed
    if (!isReload || productsWithProgressChangedToDone.length > 0) {
      yield put(loadPurchasedListSuccess(data));
    }

    const userInitiatedDownloadIds = getUserInitiatedDownloadIds();

    // auto-start download
    yield* productsWithProgressChangedToDone
      .filter((assessment) =>
        userInitiatedDownloadIds.includes(assessment.productId)
      )
      .map((assessment) =>
        put(
          downloadAssessment({
            assessmentId: assessment.assessmentId,
            productId: assessment.productId,
            accessToken,
          })
        )
      );

    // remove generated products from the list
    const productsLeftInProgress = productsInProgress.filter(
      (productInProgress) =>
        !productsWithProgressChangedToDone.some(
          (assessmentWithProgressDone) =>
            assessmentWithProgressDone.productId === productInProgress.productId
        )
    );

    const productIdsLeftInProgress = productsLeftInProgress.map(
      (assessment) => assessment.productId
    );

    // if we have dwonloadingIds in localStorage from the past load, leave only the same as in productsLeftInProgress or currently added to download
    const downloadingIds = yield select(selectDownloadingIds);
    const productIdsLocalStorageIntersectBoth = userInitiatedDownloadIds.filter(
      (productId) =>
        productIdsLeftInProgress.includes(productId) ||
        downloadingIds.includes(productId)
    );
    yield saveUserInitiatedDownloadIds(productIdsLocalStorageIntersectBoth);

    // save inProgressIds to the store, but only those initiated by the current user
    const productIdsLocalStorageIntersectInProgress =
      userInitiatedDownloadIds.filter((productId) =>
        productIdsLeftInProgress.includes(productId)
      );
    yield put(inProgressListSync(productIdsLocalStorageIntersectInProgress));

    if (productIdsLocalStorageIntersectInProgress.length > 0) {
      yield call(loadPurchasedAssessmentsSaga, {
        payload: { accessToken, isReload: true },
      });
    }
  } catch ({ message }) {
    yield put(loadPurchasedListError(message));
  }
}

export function* loadPurchasedAssessmentsSaga({ payload }) {
  const loadListTask = yield fork(loadPurchasedAssessmentsSagaCancellable, {
    payload,
  });
  yield take(cancelLoadPurchasedAssessments);
  yield cancel(loadListTask);
}

export default function* purchasedProviderSagas() {
  yield all([takeLatest(loadPurchasedList.type, loadPurchasedAssessmentsSaga)]);
}
