import {
  onlyUnique,
  sortItemsByLabel,
} from "utils/arrayUtilities/arrayUtilities";
import { TAG_CONSTRAINT_REGEX } from "./constants";
import { INPUT_LENGTH_RESTRICTIONS } from "app/constants";

export const getAllTags = (questions) => {
  const tags = questions.reduce((accumulator, question) => {
    const questionTags = question.tags || [];
    const innerTags = question.checklist
      ? question.checklist?.reduce((checklistAccumulator, checklist) => {
          const checklistTags = checklist.tags || [];
          return [...checklistAccumulator, ...checklistTags];
        }, [])
      : [];
    return [...accumulator, ...questionTags, ...innerTags];
  }, []);

  return tags
    .map((tagString) => ({
      value: tagString,
      label: tagString,
    }))
    .filter(onlyUnique)
    .sort(sortItemsByLabel);
};

export const validateInputValue = (inputValue) => {
  const value = inputValue.replace(/\s+/g, " ").trim(); // trim multiple whitespace
  const lengthInvalid = value.length > INPUT_LENGTH_RESTRICTIONS.tagName;
  const charsInvalid = value.length ? !TAG_CONSTRAINT_REGEX.test(value) : false;
  return { lengthInvalid, charsInvalid };
};
