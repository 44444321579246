import { put, all, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadMyNetwork,
  loadMyNetworkSuccess,
  loadMyNetworkError,
  loadGraphData,
  loadGraphDataSuccess,
  loadGraphDataError,
  loadOrganizationName,
  loadOrganizationNameSuccess,
  loadOrganizationNameError,
} from "pages/MyNetworkPage/services/slice";

function* loadMyNetworkSaga({ payload }) {
  try {
    const { accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/graph/network-list`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadMyNetworkSuccess(data.networkListItems));
  } catch ({ message }) {
    yield put(loadMyNetworkError(message));
  }
}

function* loadGraphDataSaga({ payload }) {
  try {
    const { accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/graph/network-list/visual`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadGraphDataSuccess(data));
  } catch ({ message }) {
    yield put(loadGraphDataError(message));
  }
}

function* loadOrganizationNameSaga({ payload }) {
  try {
    const { accessToken } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/users/organization-name`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.text();

    yield put(loadOrganizationNameSuccess(data));
  } catch ({ message }) {
    yield put(loadOrganizationNameError(message));
  }
}

export default function* myNetworksSagas() {
  yield all([
    takeLatest(loadMyNetwork.type, loadMyNetworkSaga),
    takeLatest(loadGraphData.type, loadGraphDataSaga),
    takeLatest(loadOrganizationName.type, loadOrganizationNameSaga),
  ]);
}
