import React, { useEffect, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";

import { setShowTotalSessionTimer } from "components/TotalSessionTimer/services/slice";
import TotalSessionTimer from "components/TotalSessionTimer/TotalSessionTimer";
import { selectSlice as totalSessionTimerSlice } from "components/TotalSessionTimer/services/selectors";

const TotalSessionTimerController = () => {
  const dispatch = useDispatch();
  const { showTotalSessionTimer } = useSelector(totalSessionTimerSlice);
  const { authState } = useOktaAuth();
  const showTimerWhenMinutesRemaining =
    process.env.REACT_APP_SHOW_SESSION_TIMER_WHEN_MINUTES_REMAINING ?? 30;
  const totalSessionTimeMinutes =
    process.env.REACT_APP_TOTAL_SESSION_TIME_MINUTES ?? 600;
  const totalSessionTimerCheckInterval = useRef(null);

  useEffect(() => {
    const getRemainingSessionTime = () => {
      const userLoginTime = new Date(
        authState?.accessToken?.claims?.auth_time * 1000
      );
      const currentTime = new Date();
      const minutesSinceLogin = Math.floor(
        (currentTime - userLoginTime) / (1000 * 60)
      );
      const sessionTimeRemaining = totalSessionTimeMinutes - minutesSinceLogin;
      return sessionTimeRemaining;
    };

    const showTotalSessionTimerIfRightTime = () => {
      const sessionTimeRemaining = getRemainingSessionTime();
      if (sessionTimeRemaining <= showTimerWhenMinutesRemaining) {
        dispatch(
          setShowTotalSessionTimer({
            showTotalSessionTimer: true,
            timer: sessionTimeRemaining,
          })
        );
      }
    };

    if (!showTotalSessionTimer && !totalSessionTimerCheckInterval.current) {
      showTotalSessionTimerIfRightTime();
      const checkEveryXMinutes =
        1000 *
        60 *
        (getRemainingSessionTime() - showTimerWhenMinutesRemaining < 10
          ? 1
          : 5);
      totalSessionTimerCheckInterval.current = setInterval(
        () => showTotalSessionTimerIfRightTime(),
        checkEveryXMinutes
      );
    } else if (showTotalSessionTimer) {
      clearInterval(totalSessionTimerCheckInterval.current);
    }
  }, [
    showTotalSessionTimer,
    authState?.accessToken?.claims?.auth_time,
    dispatch,
    showTimerWhenMinutesRemaining,
    totalSessionTimeMinutes,
  ]);

  return showTotalSessionTimer ? <TotalSessionTimer /> : null;
};

export default TotalSessionTimerController;
