import { createSelector } from "@reduxjs/toolkit";

import { getAllTags } from "pages/TemplatesAndMappingPage/TagCollectionPage/utils/utils";
import slice from "./slice";

const selectSlice = (state) => {
  const sliceName = slice.name;
  return state[sliceName];
};

export const selectTagCollectionDocument = createSelector(
  [selectSlice],
  (state) => {
    return state.data;
  }
);

export const selectIsTagCollectionSettingsModalOpen = createSelector(
  [selectSlice],
  (state) => {
    return state.modals.tagCollectionSettingsModal.isOpen;
  }
);

export const selectIsTagCollectionSettingsModalLoading = createSelector(
  [selectSlice],
  (state) => {
    return state.modals.tagCollectionSettingsModal.isSaving;
  }
);

export const selectRequestStateData = createSelector([selectSlice], (state) => {
  return { saving: state.saving, error: state.error, loading: state.loading };
});

export const selectRequestStateLoading = createSelector(
  [selectSlice],
  (state) => {
    return state.loading;
  }
);

export const selectRequestStateError = createSelector(
  [selectSlice],
  (state) => {
    return state.error;
  }
);

export const selectTagsCollectionTitle = createSelector(
  selectTagCollectionDocument,
  (data) => data?.name
);

export const selectDomainCategories = createSelector(
  [selectSlice],
  (state) => state.domainCategories
);

export const selectQuestions = createSelector(
  selectTagCollectionDocument,
  (data) => data?.questions
);

export const selectIsSaveTagCollectionModalOpen = createSelector(
  [selectSlice],
  (state) => state.modals.saveTagCollectionModal.isOpen
);

export const selectIsCancelTagCollectionModalOpen = createSelector(
  [selectSlice],
  (state) => state.modals.cancelTagCollectionChangesModal.isOpen
);

export const selectModifiedOn = createSelector(
  [selectSlice],
  (state) => state.data?.modifiedOn
);

export const selectTagCollectionChangesDetected = createSelector(
  [selectSlice],
  (state) => state.tagCollectionChangesDetected
);

export const selectIsCollectionSaving = createSelector(
  [selectSlice],
  (state) => state.modals.saveTagCollectionModal.isSaving
);

export const selectEditableTitleHasErrors = createSelector(
  [selectSlice],
  (state) => state.editableTitleHasErrors
);

export const selectIsGlobalTagCollection = createSelector(
  selectTagCollectionDocument,
  (data) => data?.isGlobal
);

export const selectAllTags = createSelector(
  selectTagCollectionDocument,
  (data) => {
    return getAllTags(data?.questions);
  }
);
