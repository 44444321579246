import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectUserInfo = createSelector(
  [selectSlice],
  (slice) => slice.information
);

export const selectUserName = createSelector(
  [selectUserInfo],
  (userInformation) => userInformation?.fullName
);
