import { put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadThirdPartiesList,
  loadThirdPartiesListSuccess,
  loadThirdPartiesListError,
} from "./slice";

function* loadThirdPartiesSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/thirdparties",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadThirdPartiesListSuccess(data));
  } catch ({ message }) {
    yield put(loadThirdPartiesListError(message));
  }
}

export default function* thirdPartiesSagas() {
  yield takeLatest(loadThirdPartiesList.type, loadThirdPartiesSaga);
}
