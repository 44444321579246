import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectIsDirty = createSelector(
  [selectSlice],
  (slice) => slice?.isDirty
);

export const selectIsCustomCommentDirty = createSelector(
  [selectSlice],
  (slice) => slice?.customComment.isDirty
);

export const selectPromptMessageKey = createSelector(
  [selectSlice],
  (slice) => slice?.key
);
