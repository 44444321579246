import {
  ASSESSMENT_REVIEW_TYPES,
  BPQ_FIELDS,
  MAIN_ANSWER_OPTION,
  MAIN_ANSWER_RADIO_OPTIONS,
  BPQ_ANSWERS,
  BPQ_ANSWER_TYPES,
} from "app/constants";

export const evaluateIsQuestionAnswered = (question) => {
  const mainAnswer = question.answer?.question?.[BPQ_FIELDS.mainAnswer]?.value;
  const additionalCommentsField = question?.fields?.find(
    (field) => field.name === BPQ_FIELDS.additionalComments
  );

  return (
    mainAnswer && !isRequiredFieldMissing(question, additionalCommentsField)
  );
};

export const isQuestionComplete = (question) => {
  if (!isQuestionStarted(question)) {
    return false;
  }

  return question.fields
    .filter((x) => x.name !== BPQ_FIELDS.mainAnswer)
    .every((field) => !isRequiredFieldMissing(question, field));
};

export const isQuestionStarted = (question) => {
  const mainAnswer = question?.answer?.question?.[BPQ_FIELDS.mainAnswer]?.value;

  let hasMainAnswer = !!mainAnswer;
  if (Array.isArray(mainAnswer)) {
    hasMainAnswer = mainAnswer.length > 0;
  }

  return hasMainAnswer;
};

export const getAssessmentReviewType = (question) => {
  const review = question?.answer?.review;
  const checklist = question?.answer?.checklist;
  if (review?.revisedSummary?.value) {
    return ASSESSMENT_REVIEW_TYPES.overridden;
  }

  if (review?.summary?.value) {
    return ASSESSMENT_REVIEW_TYPES.complete;
  }

  if (
    question?.review?.summaryTemplate &&
    (checklist || (review && isCommentBuilderStarted(review)))
  ) {
    return ASSESSMENT_REVIEW_TYPES.incomplete;
  }

  return ASSESSMENT_REVIEW_TYPES.notStarted;
};

export const getMainAnswerType = (question) => {
  const mainAnswer = question.answer?.question?.[BPQ_FIELDS.mainAnswer];

  if (!mainAnswer) {
    return MAIN_ANSWER_OPTION.NoAnswer;
  }

  const { value } = mainAnswer;

  if (!value || (Array.isArray(value) && !value.length)) {
    return MAIN_ANSWER_OPTION.NoAnswer;
  }

  const field = question.fields
    ?.filter((field) => field.name === BPQ_FIELDS.mainAnswer)
    ?.shift();

  if (field?.type !== BPQ_ANSWER_TYPES.select) {
    return MAIN_ANSWER_OPTION.Other;
  }

  if (value === BPQ_ANSWERS.notApplicable) {
    return MAIN_ANSWER_OPTION.NA;
  }

  if (MAIN_ANSWER_RADIO_OPTIONS.includes(value)) {
    return value;
  }

  return MAIN_ANSWER_OPTION.Other;
};

const isCommentBuilderStarted = (assessmentReview) => {
  const { internalNotes, sharedNotes, ...commentBuilderFieldValues } =
    assessmentReview;
  const commentBuilderAnswers = Object.values(commentBuilderFieldValues);
  return commentBuilderAnswers.some((answer) => !!answer);
};

const isRequiredFieldMissing = (question, field) => {
  if (field.hidden || !field.required) {
    return false;
  }

  const answer = question?.answer?.question?.[field.name];

  if (field.name === BPQ_FIELDS.evidence) {
    return false;
  }

  if (Array.isArray(answer?.value)) {
    return answer?.value?.length === 0;
  }

  return !answer?.value;
};

export const evaluateIsQuestionArtifactRecommended = (question) => {
  const questionAnswerObject = question.answer?.question;

  const hasSensitiveArtifacts =
    questionAnswerObject?.[BPQ_FIELDS.evidence]?.hasSensitiveArtifacts?.value;
  if (hasSensitiveArtifacts) {
    return false;
  }

  const isAttachmentFieldRecomended = question.fields.find(
    (field) => field.name === BPQ_FIELDS.evidence
  )?.required;

  const hasAttachments =
    questionAnswerObject?.[BPQ_FIELDS.evidence]?.artifacts &&
    questionAnswerObject[BPQ_FIELDS.evidence].artifacts.length > 0;

  if (isAttachmentFieldRecomended && !hasAttachments) {
    return true;
  }

  return false;
};
