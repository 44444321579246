import { createAction, createSlice } from "@reduxjs/toolkit";

const NAMESPACE = "artifacts";

export const initialState = {
  uploading: false,
  errors: {},
  loadSuccess: false,
  loading: false,
  data: null,
  loadError: null,
  artifactReferences: null,
  modals: {
    deleteArtifact: {
      isLoading: false,
      isOpen: false,
      isDeleting: false,
      modalData: null,
    },
  },
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startUploadFiles: (state) => {
      state.uploading = true;
    },
    stopUploadFiles: (state) => {
      state.uploading = false;
    },
    uploadFileSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.files];
      state.uploading = false;
      state.loadSuccess = true;
      state.errors = {};
    },
    uploadFileError: (state, action) => {
      state.uploading = false;
      state.errors = action.payload.errors;
    },
    resetInfo: (state) => {
      state.loadSuccess = false;
      state.uploading = false;
      state.errors = {};
    },
    loadArtifactsData: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadArtifactsDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.errors = {};
    },
    loadArtifactsDataError: (state, action) => {
      state.loading = false;
      state.errors = {};
      state.loadError = action.payload;
    },
    setDeleteArtifactModalOpen: (state, { payload }) => {
      state.modals.deleteArtifact.modalData = payload.isOpen
        ? payload.modalData
        : null;
      state.modals.deleteArtifact.isOpen = payload.isOpen;
    },
    resetArtifactsStore: () => {
      return { ...initialState };
    },
    loadArtifactReferences: (state) => {
      state.modals.deleteArtifact.isLoading = true;
    },
    loadArtifactReferencesSuccess: (state, { payload }) => {
      state.modals.deleteArtifact.isLoading = false;
      state.artifactReferences = payload;
    },
    loadArtifactReferencesError: (state) => {
      state.modals.deleteArtifact.isLoading = false;
      state.modals.deleteArtifact.isOpen = false;
    },
    deleteArtifact: (state) => {
      state.modals.deleteArtifact.isDeleting = true;
    },
    deleteArtifactSuccess: (state) => {
      state.modals.deleteArtifact.isDeleting = false;
      state.modals.deleteArtifact.isOpen = false;
      state.modals.deleteArtifact.modalData = null;
    },
    deleteArtifactError: (state) => {
      state.modals.deleteArtifact.isDeleting = false;
      state.modals.deleteArtifact.isOpen = false;
      state.modals.deleteArtifact.modalData = null;
    },
  },
});

export const downloadArtifact = createAction(`${NAMESPACE}/downloadArtifact`);

export const {
  startUploadFiles,
  stopUploadFiles,
  uploadFileSuccess,
  uploadFileError,
  resetInfo,
  loadArtifactsData,
  loadArtifactsDataSuccess,
  loadArtifactsDataError,
  setDeleteArtifactModalOpen,
  resetArtifactsStore,
  loadArtifactReferences,
  loadArtifactReferencesSuccess,
  loadArtifactReferencesError,
  deleteArtifact,
  deleteArtifactSuccess,
  deleteArtifactError,
} = slice.actions;

export default slice;
