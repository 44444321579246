import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";

import { SUPPORTED_LOCALES } from "app/constants";

/* eslint-disable no-extend-native */
if (!String.prototype.includes) {
  String.prototype.includes = function (str) {
    return this.indexOf(str) !== -1;
  };
}

if (!Array.prototype.includes) {
  Array.prototype.includes = function (el) {
    return this.indexOf(el) !== -1;
  };
}

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

if (!String.prototype.repeat) {
  String.prototype.repeat = function (count) {
    if (this == null)
      throw new TypeError("can't convert " + this + " to object");

    var str = "" + this;
    // To convert string to integer.
    count = +count;
    // Check NaN
    // eslint-disable-next-line no-self-compare
    if (count !== count) count = 0;

    if (count < 0) throw new RangeError("repeat count must be non-negative");

    if (count === Infinity)
      throw new RangeError("repeat count must be less than infinity");

    count = Math.floor(count);
    if (str.length === 0 || count === 0) return "";

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28)
      throw new RangeError(
        "repeat count must not overflow maximum string size"
      );

    var maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}

async function polyfillIntl() {
  const locales = Object.values(SUPPORTED_LOCALES);

  if (shouldPolyfillCanonicalLocales()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }

  if (shouldPolyfillLocale()) {
    await import("@formatjs/intl-locale/polyfill");
  }

  if (shouldPolyfillPluralRules()) {
    await import("@formatjs/intl-pluralrules/polyfill");

    for (const locale of locales) {
      await import(`@formatjs/intl-pluralrules/locale-data/${locale}`);
    }
  }
}
polyfillIntl();
