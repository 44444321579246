import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    information: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    loadUserInfo: (state) => {
      state.information = null;
      state.loading = true;
      state.error = null;
    },
    loadUserInfoError: (state, { payload }) => {
      state.information = null;
      state.loading = false;
      state.error = payload;
    },
    loadUserInfoSuccess: (state, { payload }) => {
      state.information = payload;
      state.loading = false;
      state.error = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const {
  setToken,
  loadUserInfo,
  loadUserInfoError,
  loadUserInfoSuccess,
} = slice.actions;

export default slice;
