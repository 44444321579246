import React from "react";
import { components } from "react-select";

import { ReactComponent as ChevronIcon } from "assets/chevron.svg";

const SelectDropdownIcon = (props) => {
  const { DropdownIndicator } = components;
  return (
    <DropdownIndicator {...props}>
      <ChevronIcon className="select-input__icon" />
    </DropdownIndicator>
  );
};

export default SelectDropdownIcon;
