import {
  put,
  takeLatest,
  takeEvery,
  delay,
  select,
  all,
} from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadNotifications,
  loadNotificationsError,
  loadNotificationsSuccess,
  markAsSeen,
  setShowAll,
} from "./slice";
import { selectSlice as selectNotificationsSlice } from "./selectors";
import { selectSlice as selectUserSlice } from "components/Header/services/selectors";

const notificationsFetchFrequencyInMs =
  (process.env.REACT_APP_FETCH_NOTIFICATIONS_FREQUENCY_SECONDS || 60) * 1000;

function* loadNotificationsSaga({ payload }) {
  try {
    const showAll = payload.all || false;
    yield put(setShowAll(showAll));

    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/notifications?all=" + showAll,
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadNotificationsSuccess(data));
  } catch ({ message }) {
    yield put(loadNotificationsError(message));
  }
}

function* markAsSeenSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/notifications/seen",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload.ids),
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to mark notifications as seen.");
    }
  } catch ({ message }) {
    // eslint-disable-next-line no-console
    console.error(message);
  }
}

function* loadNotificationsPeriodicallySaga() {
  while (true) {
    yield delay(notificationsFetchFrequencyInMs);
    const { token } = yield select(selectUserSlice);
    if (!token) {
      continue;
    }

    const { showAll } = yield select(selectNotificationsSlice);

    try {
      const response = yield fetch(
        process.env.REACT_APP_API_URL + "/notifications?all=" + showAll,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to load");
      }
      const data = yield response.json();
      yield put(loadNotificationsSuccess(data));
    } catch ({ message }) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch notifications in background.", message);
    }
  }
}

export default function* notificationsProviderSagas() {
  yield all([
    yield takeLatest(loadNotifications.type, loadNotificationsSaga),
    yield takeEvery(markAsSeen.type, markAsSeenSaga),
    yield loadNotificationsPeriodicallySaga(),
  ]);
}
