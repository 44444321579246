import { call, put, all, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadBpqVersions,
  loadBpqVersionsSuccess,
  loadBpqVersionsError,
} from "./slice";

export function* loadBpqVersionsSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/documentTemplates/bpqversions`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(
      loadBpqVersionsSuccess({
        items: data,
      })
    );
  } catch ({ message }) {
    yield put(loadBpqVersionsError(message));
  }
}

export default function* documentSagas() {
  yield all([yield takeLatest(loadBpqVersions.type, loadBpqVersionsSaga)]);
}
