import classNames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import Tooltip from "components/GlobalComponents/Tooltip";
import "./button-icon.scss";

const ButtonIcon = forwardRef(
  (
    {
      ariaExpanded,
      ariaHasPopup,
      className,
      dataTestId,
      icon,
      isActive,
      isAnchor,
      href,
      isDisabled,
      label,
      onClick,
      tooltipHidden,
      tooltipText,
      tooltipPosition,
    },
    ref
  ) => {
    return isAnchor ? (
      <a
        className={classNames("btn-icon", className, {
          "btn-icon--active": isActive,
          "btn-icon--has-tooltip": tooltipText,
        })}
        data-testid={dataTestId}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="btn-icon__content">
          {icon}
          <span className="visually-hidden" data-testid="btn-icon-label">
            {label ? label : tooltipText}
          </span>
        </span>
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            className={"btn-icon__tooltip tooltip--" + tooltipPosition}
            data-testid="btn-icon-tooltip"
          />
        )}
      </a>
    ) : (
      <button
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        className={classNames("btn-icon", className, {
          "btn-icon--active": isActive,
          "btn-icon--has-tooltip": tooltipText,
        })}
        data-testid={dataTestId}
        disabled={isDisabled}
        type="button"
        onClick={onClick}
        ref={ref}
      >
        <span className="btn-icon__content">
          {icon}
          <span className="visually-hidden" data-testid="btn-icon-label">
            {label ? label : tooltipText}
          </span>
        </span>
        {tooltipText && (
          <Tooltip
            text={tooltipText}
            className="btn-icon__tooltip"
            isHidden={tooltipHidden}
            position={tooltipPosition}
            data-testid="btn-icon-tooltip"
          />
        )}
      </button>
    );
  }
);

ButtonIcon.defaultProps = {
  ariaExpanded: null,
  ariaHasPopup: false,
  className: "",
  dataTestId: "btn-icon",
  isActive: false,
  isDisabled: false,
  label: "",
  onClick: () => {},
  tooltipHidden: false,
  tooltipText: "",
  tooltipPosition: "",
  isAnchor: false,
  href: "",
};

ButtonIcon.propTypes = {
  ariaExpanded: PropTypes.bool,
  ariaHasPopup: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tooltipHidden: PropTypes.bool,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isAnchor: PropTypes.bool,
  href: PropTypes.string,
};

ButtonIcon.displayName = "ButtonIcon";
export default ButtonIcon;
