import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadTruSightUsersData,
  loadTruSightUsersDataSuccess,
  loadTruSightUsersDataError,
  loadRoles,
  loadRolesSuccess,
  loadRolesError,
  loadExternalOrganizations,
  loadExternalOrganizationsSuccess,
  loadExternalOrganizationsError,
  onAddUserFormSubmit,
  setFormSubmitErrors,
  setIsFormSubmitting,
  setIsFormSubmitSuccess,
} from "./slice";
import { default as globalMessages } from "app/messages";

export function* loadTruSightUsersDataSaga({ payload }) {
  try {
    const response = yield fetch(process.env.REACT_APP_API_URL + "/users", {
      headers: {
        Authorization: "Bearer " + payload.accessToken,
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadTruSightUsersDataSuccess(data));
  } catch ({ message }) {
    yield put(loadTruSightUsersDataError(message));
  }
}

export function* loadRolesSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/users/trusight-roles",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadRolesSuccess(data));
  } catch ({ message }) {
    yield put(loadRolesError(message));
  }
}

export function* loadExternalOrganizationsSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/organizations/external",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadExternalOrganizationsSuccess(data));
  } catch ({ message }) {
    yield put(loadExternalOrganizationsError(message));
  }
}

export function* submitTrusightUserInviteSaga({ payload }) {
  try {
    yield put(setIsFormSubmitting(true));
    const response = yield fetch(process.env.REACT_APP_API_URL + "/users", {
      method: "post",
      body: JSON.stringify(payload.values),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + payload.accessToken,
      },
    });
    if (response.status === 200) {
      yield put(setIsFormSubmitSuccess(true));
      yield put(setFormSubmitErrors({}));
    } else {
      const data = yield response.json();
      if (data) {
        yield put(setFormSubmitErrors(data));
      }
    }
    yield put(setIsFormSubmitting(false));
  } catch {
    yield put(setFormSubmitErrors([globalMessages.error]));
    yield put(setIsFormSubmitting(false));
  }
}

export default function* truSightUsersSagas() {
  yield all([
    takeLatest(loadTruSightUsersData.type, loadTruSightUsersDataSaga),
    takeLatest(loadRoles.type, loadRolesSaga),
    takeLatest(loadExternalOrganizations.type, loadExternalOrganizationsSaga),
    takeLatest(onAddUserFormSubmit.type, submitTrusightUserInviteSaga),
  ]);
}
