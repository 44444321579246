import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectState = (state) => state[slice.name];

export const selectData = createSelector([selectState], (state) => state.data);

export const selectFilters = createSelector(
  [selectState],
  (state) => state.filters
);

export const selectLoading = createSelector(
  [selectState],
  (state) => state.loading
);

export const selectError = createSelector(
  [selectState],
  (state) => state.error
);

export const selectDownloadingIds = createSelector(
  [selectState],
  (state) => state.downloadingIds
);
