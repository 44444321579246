import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "breadcrumbs",
  initialState: {
    breadcrumbs: [],
    text: null,
  },
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = [...action.payload];
    },
    setText: (state, action) => {
      state.text = action.payload;
    },
    resetText: (state, action) => {
      state.text = null;
    },
  },
});

export const { setBreadcrumbs, setText, resetText } = slice.actions;
export default slice;
