import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadMyThirdPartiesList,
  loadMyThirdPartiesListSuccess,
  loadMyThirdPartiesListError,
  disconnectRequest,
  disconnectRequestError,
  disconnectRequestSuccess,
  loadCustomerThirdParties,
  loadCustomerThirdPartiesSuccess,
  loadCustomerThirdPartiesError,
  loadCustomerThirdPartyRequests,
  sendConnectRequest,
  sendConnectRequestCompleted,
} from "./slice";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";

export function* loadMyThirdPartyAssessmentsSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/my-third-parties`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadMyThirdPartiesListSuccess(data));
  } catch ({ message }) {
    yield put(loadMyThirdPartiesListError(message));
  }
}

export function* loadCustomerThirdPartiesSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/my-third-parties/book-of-work`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(loadCustomerThirdPartiesSuccess(data));
  } catch ({ message }) {
    yield showError(message);
    yield put(loadCustomerThirdPartiesError(message));
  }
}

export function* loadCustomerThirdPartyRequestsSaga({ payload }) {
  const { accessToken, id, onFail, onSuccess } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/my-third-parties/assessments-requests/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();

    if (onSuccess) {
      yield call(onSuccess, data);
    }
  } catch ({ message }) {
    yield showError(message);
    if (onFail) {
      yield call(onFail);
    }
  }
}

export function* disconnectRequestSaga({
  payload: { accessToken, requestData, successMessage },
}) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/my-third-parties/disconnect-request/${requestData.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to disconnect a request");
    }
    yield put(disconnectRequestSuccess());
    yield showInfo(successMessage);
    yield put(
      loadCustomerThirdPartyRequests({
        accessToken,
        id: requestData.thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(disconnectRequestError());
    yield showError(message);
  }
}

export function* sendConnectRequestSaga({
  payload: { accessToken, body, successMessage },
}) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/my-third-parties/connect/${body.assessmentId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to send a request");
    }

    yield put(sendConnectRequestCompleted());
    yield showInfo(successMessage);
    yield put(
      loadCustomerThirdPartyRequests({
        accessToken,
        id: body.thirdPartyId,
      })
    );
  } catch ({ message }) {
    yield put(sendConnectRequestCompleted());
    yield showError(message);
  }
}

export default function* myThirdPartiesProviderSagas() {
  yield all([
    takeLatest(loadMyThirdPartiesList.type, loadMyThirdPartyAssessmentsSaga),
    takeLatest(disconnectRequest.type, disconnectRequestSaga),
    takeLatest(loadCustomerThirdParties.type, loadCustomerThirdPartiesSaga),
    takeLatest(
      loadCustomerThirdPartyRequests.type,
      loadCustomerThirdPartyRequestsSaga
    ),
    takeLatest(sendConnectRequest.type, sendConnectRequestSaga),
  ]);
}
