import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_KEY ?? "";
const reactPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

const appInsightsController = appInsights.appInsights;

export function trackTrace(message, props) {
  if (
    process.env.REACT_APP_APPINSIGHTS_KEY &&
    process.env.REACT_APP_APPINSIGHTS_TRACK_TRACE
  ) {
    const p = { message, properties: { ...props } };
    appInsightsController.trackTrace(p);
  }
}

export function trackException(exception, props) {
  if (
    process.env.REACT_APP_APPINSIGHTS_KEY &&
    process.env.REACT_APP_APPINSIGHTS_TRACK_EXCEPTION
  ) {
    const p = { exception, properties: { ...props } };
    appInsightsController.trackException(p);
  }
}
