import { createSlice, createAction } from "@reduxjs/toolkit";

const NAMESPACE = "thirdPartyInfo";

export const initialState = {
  data: null,
  loading: false,
  error: null,
  unauthorized: false,
  organizationThirdPartyId: null,
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    loadThirdPartyData: (state) => {
      state.data = null;
      state.loading = true;
      state.unauthorized = false;
    },
    loadThirdPartyDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadThirdPartyDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUnauthorized: (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
      state.unauthorized = true;
    },
    setOrganizationThirdPartyId: (state, action) => {
      state.organizationThirdPartyId = action.payload;
    },
  },
});

export const getOrganizationThirdParty = createAction(
  `${NAMESPACE}/getOrganizationThirdParty`
);

export const {
  loadThirdPartyData,
  loadThirdPartyDataSuccess,
  loadThirdPartyDataError,
  setUnauthorized,
  setOrganizationThirdPartyId,
} = slice.actions;

export default slice;
