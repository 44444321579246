import { fetch } from "whatwg-fetch";

export async function logoutSaga({ accessToken }) {
  const logoutUrl = `${process.env.REACT_APP_API_URL}/authentication/logout`;
  const request = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await fetch(logoutUrl, request);

  if (response.status !== 204) {
    throw new Error("Failed to load");
  }
}
