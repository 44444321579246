import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  thirdPartiesWithPendingActions: [],
};

const slice = createSlice({
  name: "unmatchedPageAdministrativeButtons",
  initialState,
  reducers: {
    addThirdPartyWithPendingAction: (state, { payload }) => {
      state.thirdPartiesWithPendingActions.push(payload);
    },
    removeThirdPartyWithPendingAction: (state, { payload }) => {
      state.thirdPartiesWithPendingActions =
        state.thirdPartiesWithPendingActions.filter(
          (customerThirdPartyId) => customerThirdPartyId !== payload
        );
    },
  },
});

export const {
  addThirdPartyWithPendingAction,
  removeThirdPartyWithPendingAction,
} = slice.actions;

export default slice;
