import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "manageCustomerUnmatchedPageHeading",
  initialState: {
    isNotificationSending: false,
  },
  reducers: {
    notifyCustomer: (state) => {
      state.isNotificationSending = true;
    },
    setIsNotificationSending: (state, { payload }) => {
      state.isNotificationSending = payload;
    },
  },
});

export const { notifyCustomer, setIsNotificationSending } = slice.actions;

export default slice;
