import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";

import LoadingPage from "pages/LoadingPage";
import UnauthorizedPage from "pages/UnauthorizedPage";
import { selectPermissionsInitialised } from "components/AuthorizedComponent/selectors";

const SecureRouteRenderer = ({
  disabled,
  canAccess,
  Component,
  ...restProps
}) => {
  const { authState } = useOktaAuth();
  const permissionsInitialised = useSelector(selectPermissionsInitialised);

  if (
    authState.isAuthenticated !== null &&
    authState.isAuthenticated === false
  ) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  if (disabled) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  if (!permissionsInitialised) {
    return <LoadingPage />;
  }

  if (!canAccess) {
    return <Route component={UnauthorizedPage} />;
  }

  return <Component {...restProps} />;
};

SecureRouteRenderer.defaultProps = {
  disabled: false,
  canAccess: false,
};

SecureRouteRenderer.propTypes = {
  disabled: PropTypes.bool,
  canAccess: PropTypes.bool,
  Component: PropTypes.object,
};

export default SecureRouteRenderer;
