import { createSlice } from "@reduxjs/toolkit";

import {
  DETAIL_TABLE_ACTION_BUTTON_TYPES,
  DETAIL_TABLE_ROW_TYPE,
} from "components/TableComponents/utils/constants";
import {
  flattenAssessments,
  getComputedTpFields,
  manageRowOrderAndDividersOnConnect,
  manageRowOrderAndDividersOnDisconnect,
} from "pages/GenerateBow/utils/utils";

export const initialState = {
  isLoading: false,
  isGenerating: false,
  isSaving: false,
  isSaveFailed: false,
  saveTime: null,
  error: null,
  data: null,
  modals: {
    connectRequestModal: {
      isOpen: false,
      modalData: null,
    },
    disconnectRequestModal: {
      isOpen: false,
      modalData: null,
    },
  },
  createAssessmentPendingEntities: [],
  createdThirdParty: null,
};

const slice = createSlice({
  name: "bow",
  initialState,
  reducers: {
    loadData: (state) => {
      state.isLoading = true;
    },
    generateBow: (state) => {
      state.isGenerating = true;
    },
    storeBow: (state) => {
      state.isSaving = true;
      const currentTime = new Date();
      currentTime.setMinutes(currentTime.getMinutes() - 1);
      state.saveTime = currentTime.toISOString();
    },
    convertToAssessment: () => {},
    convertToAssessmentSuccess: (
      state,
      { payload: { candidateAssessmentId, responseData, tpId } }
    ) => {
      const thirdParty = state.data.find((tp) => tp.thirdPartyId === tpId);
      const assessment = thirdParty?.assessments.find(
        ({ value }) => value.candidateAssessmentId === candidateAssessmentId
      );
      assessment.type = DETAIL_TABLE_ROW_TYPE.assessment;
      assessment.value.assessmentId = responseData.id;
      assessment.value.id = responseData.id;
      assessment.value.status = responseData.status;
      assessment.value.candidateAssessmentId = null;
      assessment.value.label = `[Existing Assessment ${assessment.value.assessmentId}]`;
      thirdParty.stats = getComputedTpFields(thirdParty);
    },
    convertToAssessmentWithThirdParty: () => {},
    convertToAssessmentWithThirdPartySuccess: (
      state,
      { payload: { candidateAssessmentId, responseData, tpCandidateId } }
    ) => {
      const thirdParty = state.data.find(
        (tp) => tp.candidateId === tpCandidateId
      );
      const assessment = thirdParty?.assessments.find(
        ({ value }) => value.candidateAssessmentId === candidateAssessmentId
      );

      thirdParty.candidateId = null;
      thirdParty.trusightId = responseData.trusightId;
      thirdParty.thirdPartyId = responseData.thirdPartyId;

      assessment.type = DETAIL_TABLE_ROW_TYPE.assessment;
      assessment.value.assessmentId = responseData.assessmentId;
      assessment.value.id = responseData.assessmentId;
      assessment.value.status = responseData.status;
      assessment.value.candidateAssessmentId = null;
      assessment.value.label = `[Existing Assessment ${assessment.value.assessmentId}]`;

      thirdParty?.assessments.forEach((a) => {
        a.value.thirdPartyId = responseData.thirdPartyId;
      });

      thirdParty.stats = getComputedTpFields(thirdParty);
    },
    setCreatedNewThirdPartyId: (
      state,
      { payload: { tpCandidateId, thirdPartyId } }
    ) => {
      state.createdThirdParty = {
        tpCandidateId,
        thirdPartyId,
      };
    },
    clearCreatedNewThirdPartyId: (state) => {
      state.createdThirdParty = null;
    },
    setData: (state, { payload }) => {
      state.data = flattenAssessments(payload.thirdParties);
      state.isLoading = false;
      state.isGenerating = false;
      state.isSaving = payload.isSavingInProgress || false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isGenerating = false;
      state.isSaving = false;
      state.saveTime = null;
    },
    linkRequest: (state, { payload: { tpId, rowGuid, assessmentId } }) => {
      const thirdParty = state.data.find(
        (thirdParty) => thirdParty.thirdPartyId === tpId
      );
      let assessment = thirdParty.assessments.find(
        (assessment) =>
          assessment.value.assessmentId === assessmentId ||
          assessment.value.candidateAssessmentId === assessmentId
      );
      const requestIndex = thirdParty.assessments.findIndex(
        (assessment) => assessment.value.rowGuid === rowGuid
      );

      thirdParty.unallocated.splice(
        thirdParty.unallocated.findIndex(
          (unallocatedRequestId) => unallocatedRequestId === rowGuid
        ),
        1
      );

      const request = thirdParty.assessments[requestIndex];
      assessment.value.requests.push(request.value);

      thirdParty.assessments = manageRowOrderAndDividersOnConnect(
        thirdParty,
        request,
        assessmentId
      );
      thirdParty.stats = getComputedTpFields(thirdParty);
      state.modals.connectRequestModal.isOpen = false;
    },
    removeRequest: (
      state,
      {
        payload: {
          tpId,
          tpCandidateId,
          assessmentId,
          rowGuid,
          connectedAssessmentId,
        },
      }
    ) => {
      let thirdPartyData;
      if (tpId !== 0) {
        thirdPartyData = state.data.find(
          (thirdParty) => thirdParty.thirdPartyId === tpId
        );
      }
      if (tpId === 0 && tpCandidateId) {
        thirdPartyData = state.data.find(
          (thirdParty) => thirdParty.candidateId === tpCandidateId
        );
      }
      const assessments = thirdPartyData.assessments;
      const requestIndex = assessments.findIndex(
        (assessment) =>
          assessment.value.rowGuid === rowGuid &&
          assessment.value.connectedAssessmentId === connectedAssessmentId
      );

      const request = {
        type: DETAIL_TABLE_ACTION_BUTTON_TYPES.request.disconnected,
        value: assessments[requestIndex]?.value,
      };

      assessments.splice(requestIndex, 1);

      thirdPartyData.assessments = manageRowOrderAndDividersOnDisconnect(
        thirdPartyData,
        request,
        assessmentId
      );
      thirdPartyData.unallocated.push(request.value.rowGuid);
      thirdPartyData.stats = getComputedTpFields(thirdPartyData);
      state.modals.disconnectRequestModal.isOpen = false;
    },
    setDisconnectRequestModalOpen: (state, { payload }) => {
      state.modals.disconnectRequestModal.isOpen = payload.isOpen;
      state.modals.disconnectRequestModal.modalData = payload?.data || null;
    },
    setConnectRequestModalOpen: (state, { payload }) => {
      state.modals.connectRequestModal.isOpen = payload.isOpen;
      state.modals.connectRequestModal.modalData = payload?.data || null;
    },
    addCreateAssessmentPendingEntity: (state, { payload }) => {
      state.createAssessmentPendingEntities.push(payload);
    },
    removeCreateAssessmentPendingEntity: (state, { payload }) => {
      state.createAssessmentPendingEntities =
        state.createAssessmentPendingEntities.filter(
          (entity) => entity !== payload
        );
    },
    resetState: () => initialState,
    checkSavingStatus: () => {},
    checkSavingStatusSuccess: (state, { payload }) => {
      if (state.saveTime) {
        if (payload.createdOn > state.saveTime) {
          state.isSaving = payload.inProgress;
          state.saveTime = null;
        }
      } else {
        state.isSaving = payload.inProgress;
      }
      state.isSaveFailed = payload.finishedWithErrors;
    },
  },
});

export const {
  loadData,
  generateBow,
  storeBow,
  setData,
  setError,
  convertToAssessment,
  convertToAssessmentSuccess,
  convertToAssessmentWithThirdParty,
  convertToAssessmentWithThirdPartySuccess,
  linkRequest,
  removeRequest,
  setDisconnectRequestModalOpen,
  setConnectRequestModalOpen,
  addCreateAssessmentPendingEntity,
  removeCreateAssessmentPendingEntity,
  resetState,
  checkSavingStatus,
  checkSavingStatusSuccess,
  setCreatedNewThirdPartyId,
  clearCreatedNewThirdPartyId,
} = slice.actions;

export default slice;
