import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectState = (state) => state[slice.name];

export const selectCustomersList = createSelector(
  [selectState],
  (state) => state.data
);

export const selectCustomersListLoading = createSelector(
  [selectState],
  (state) => state.loading
);

export const selectCustomersListError = createSelector(
  [selectState],
  (state) => state.error
);

export const selectExportPossibleMatchesInProgress = createSelector(
  [selectState],
  (state) => state.exportPossibleMatchesInProgress
);
