import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useOktaAuth } from "@okta/okta-react";
import { useIdleTimer } from "react-idle-timer";

import globalMessages from "app/messages";
import TsModal from "components/GlobalComponents/Modal";
import messages from "./messages";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import { logoutSaga } from "../../../../services/logout/sagas";

const IdleModalController = () => {
  const intl = useIntl();
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();

  const showIdleModalAfterMs =
    1000 * (process.env.REACT_APP_IDLELOGOUT_MODAL_APPEAR_SECONDS || 900);
  const logoutTimeInMs =
    1000 * (process.env.REACT_APP_IDLELOGOUT_LOGOUT_AFTER_MODAL_SECONDS || 600);
  const logoutTimeInS = logoutTimeInMs / 1000;
  const [secondsRemaining, setSecondsRemaining] = useState(logoutTimeInS);
  const signOutTimer = useRef(null);
  const documentTitleInterval = useRef(null);
  const [originalPageTitle, setOriginalPageTitle] = useState(null);
  const accessToken = useAccessToken();

  const handleOnIdle = () => {
    if (!isIdleModalOpen && authState.isAuthenticated) {
      setSecondsRemaining(logoutTimeInS);
      setIsIdleModalOpen(true);
      setOriginalPageTitle(document.title);
      if (!signOutTimer.current) {
        signOutTimer.current = setTimeout(logoutUser, logoutTimeInMs);
      }
    }
  };

  const { reset } = useIdleTimer({
    timeout: showIdleModalAfterMs,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const resetIdleState = () => {
    setIsIdleModalOpen(false);
    clearTimeout(signOutTimer.current);
    signOutTimer.current = null;
    clearInterval(documentTitleInterval.current);
    documentTitleInterval.current = null;
    if (originalPageTitle) {
      document.title = originalPageTitle;
    }
    setOriginalPageTitle(null);
    reset();
  };

  const idleModalRemainingTimeString = () => {
    const idleMinutes = Math.floor(showIdleModalAfterMs / 1000 / 60);
    const remainingMinutes = Math.floor(secondsRemaining / 60);
    const remainingSeconds = secondsRemaining - remainingMinutes * 60;
    const loggedOutInTimeString = `${remainingMinutes}:${(
      "00" + remainingSeconds
    ).slice(-2)}.`;
    return (
      <FormattedMessage
        {...messages.modalContentText}
        values={{
          idleMinutes,
          loggedOutIn: loggedOutInTimeString,
        }}
      />
    );
  };
  const continueWorking = () => {
    resetIdleState();
  };

  const logoutUser = () => {
    logoutSaga({ accessToken });

    resetIdleState();
    oktaAuth.signOut();
  };

  useEffect(() => {
    if (isIdleModalOpen && secondsRemaining > 0) {
      setTimeout(() => setSecondsRemaining(secondsRemaining - 1), 1000);
    }
  }, [secondsRemaining, isIdleModalOpen]);

  useEffect(() => {
    if (!documentTitleInterval.current && originalPageTitle) {
      documentTitleInterval.current = setInterval(() => {
        document.title =
          document.title === originalPageTitle
            ? intl.formatMessage(globalMessages.idleDocumentTitle)
            : originalPageTitle;
      }, 1000);
    }
  }, [originalPageTitle, intl]);

  return (
    <TsModal
      isModalOpen={isIdleModalOpen}
      setModalOpen={setIsIdleModalOpen}
      modalTitle={intl.formatMessage(globalMessages.idleDocumentTitle)}
      modalContent={idleModalRemainingTimeString()}
      onConfirmAction={continueWorking}
      confirmActionText={intl.formatMessage(messages.continueWorking)}
      showFooter
      showCancelButton={false}
      showCancelButtonTop={false}
      type="submit"
      showSecondaryButton
      onSecondaryAction={logoutUser}
      secondaryActionText={intl.formatMessage(messages.logout)}
      shouldCloseOnEsc={false}
    />
  );
};

export default IdleModalController;
