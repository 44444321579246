import { useSelector } from "react-redux";

import { selectAssessmentDetails } from "../services/selectors";
import {
  selectPermissionsForAssessment,
  selectStages,
} from "components/AuthorizedComponent/selectors";
import { getActiveAssessmentPermissions } from "./assessmentPermissions";

export default function useActiveAssessmentPermissions() {
  const permissions = useSelector(selectPermissionsForAssessment);
  const { data, loading, error } = useSelector(selectAssessmentDetails);
  const stages = useSelector(selectStages);

  if (loading || error || !stages || !permissions) {
    return [];
  }

  return getActiveAssessmentPermissions(permissions, data, stages).map(
    (x) => x.permission
  );
}
