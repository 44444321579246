import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";
import { SCHEDULE_DATE_KEYS } from "app/constants";

export const selectSlice = (state) => state[slice.name];

export const selectAssessmentDetails = createSelector(
  [selectSlice],
  (state) => state.assessmentDetails
);

export const selectAssessmentData = createSelector(
  [selectSlice],
  (state) => state.assessmentDetails?.data
);

export const selectAssessmentId = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.id
);

export const selectThirdPartyName = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.organizationName
);

export const selectThirdPartyMainAddress = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.thirdPartyMainAddress
);

export const selectAssessmentName = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.name
);

export const selectScopingDocumentId = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.scopingDocumentId
);

export const selectBpqDocumentId = createSelector(
  [selectAssessmentDetails],
  (state) => state.data?.bpqDocumentId
);

export const selectIsScopingCompleted = createSelector(
  [selectSlice],
  (slice) => slice.isScopingCompleted
);

export const selectStatusId = createSelector(
  [selectAssessmentDetails],
  (slice) => slice.data?.statusId
);
export const selectIsAssigned = createSelector(
  [selectAssessmentDetails],
  (slice) => slice.data?.isAssigned
);

export const selectFieldWorkDate = createSelector(
  [selectAssessmentDetails],
  (slice) => slice.data?.fieldWorkDate
);

export const selectFieldWorkStartDate = createSelector(
  [selectAssessmentDetails],
  (slice) => slice.data?.fieldWorkStartDate
);

export const selectIsFinalized = createSelector(
  [selectAssessmentDetails],
  (slice) => slice.data?.isFinalized
);

export const selectFinalizeModalOpen = createSelector(
  [selectSlice],
  (slice) => slice.finalizeModal.isOpen
);

export const selectIsFinalizing = createSelector(
  [selectSlice],
  (slice) => slice.finalizeModal.isFinalizing
);

export const selectChangeStageModal = createSelector(
  [selectSlice],
  (slice) => slice.changeStageModal
);

export const selectScheduleDates = createSelector(
  [selectAssessmentDetails],
  (slice) => {
    const scheduleDates = [];
    if (slice.data) {
      Object.values(SCHEDULE_DATE_KEYS).forEach((value) => {
        scheduleDates.push(slice.data[value]);
      });
    }
    return scheduleDates;
  }
);
