import classNames from "classnames";
import React, { useEffect, useRef, useState, memo } from "react";

import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import { ReactComponent as HamburgerIcon } from "assets/hamburger.svg";
import GlobalNavigation from "../GlobalNavigation";
import { KEYS } from "app/keys";
import "./hamburger.scss";

const Hamburger = () => {
  const navigationWrapper = useRef();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const toggleDropdown = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  useEffect(() => {
    const closeNavigationOnKeyDown = (e) => {
      if (e.code === KEYS.escape.code || e.keyCode === KEYS.escape.keyCode) {
        setIsNavigationOpen(false);
      }
    };

    if (isNavigationOpen) {
      window.addEventListener("keydown", closeNavigationOnKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", closeNavigationOnKeyDown);
    };
  }, [isNavigationOpen, setIsNavigationOpen]);

  useCheckOutsideClick(navigationWrapper, toggleDropdown, isNavigationOpen);

  return (
    <div className="hamburger" ref={navigationWrapper}>
      <button
        aria-expanded={isNavigationOpen}
        aria-haspopup="true"
        className={classNames("hamburger__btn", {
          "hamburger__btn--active": isNavigationOpen,
        })}
        onClick={toggleDropdown}
        type="button"
        data-testid="hamburger"
      >
        <span className="hamburger__btn-content">
          <HamburgerIcon />
          <span className="visually-hidden">Toggle navigation menu</span>
        </span>
      </button>
      <GlobalNavigation
        setIsNavigationOpen={setIsNavigationOpen}
        isNavigationOpen={isNavigationOpen}
      />
    </div>
  );
};

export default memo(Hamburger);
