import { takeLatest, put, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import escape from "escape-html";

import { TOAST_TYPES } from "app/constants";
import { addToast } from "services/toast/slice";
import {
  loadPeopleList,
  setTruSightUsers,
  setThirdPartyUsers,
  setIsLoading,
  setLoadingErrorMessage,
  loadAvailableThirdPartyUsers,
  setAvailableThirdPartyUsers,
  setAvailableThirdPartyUsersLoading,
  assignThirdPartyUser,
  setAssignThirdPartyUserIsSaving,
  setAssignThirdPartyUserModalOpen,
  loadAvailableTruSightUsers,
  setAvailableTruSightUsers,
  setAvailableTruSightUsersLoading,
  assignTruSightUser,
  setAssignTruSightUserIsSaving,
  setAssignTruSightUserModalOpen,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { USER_TYPES } from "../utils/constants";
import { getSortedAndFormattedUsersList } from "../utils/utils";
import { setIsDirty } from "services/form/slice";

export function* loadPeopleListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/users`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    const userInfo = data.userInfo;

    const thirdpartyUsers = yield getSortedAndFormattedUsersList(
      userInfo,
      USER_TYPES.thirdparty
    );

    const trusightUsers = yield getSortedAndFormattedUsersList(
      userInfo,
      USER_TYPES.trusight
    );

    yield put(setIsLoading(false));
    yield put(setThirdPartyUsers(thirdpartyUsers));
    yield put(setTruSightUsers(trusightUsers));
  } catch ({ message }) {
    yield showError(message);
    yield put(setIsLoading(false));
    yield put(setLoadingErrorMessage(message));
  }
}

export function* loadAvailableThirdPartyUsersSaga({ payload }) {
  try {
    yield put(setAvailableThirdPartyUsersLoading(true));
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/users/thirdparty/available-to-assign`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(setAvailableThirdPartyUsers(data));
    yield put(setAvailableThirdPartyUsersLoading(false));
  } catch ({ message }) {
    yield showError(message);
    yield put(setAvailableThirdPartyUsersLoading(false));
  }
}

export function* assignThirdPartyUserSaga({ payload }) {
  try {
    yield put(setAssignThirdPartyUserIsSaving(true));

    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/users/thirdparty/${payload.user.userId}`,
      {
        method: "put",
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );
    if (response.status !== 200 && response.status !== 204) {
      throw new Error("Failed to add user.");
    }

    yield put(
      loadPeopleList({
        accessToken: payload.accessToken,
        assessmentId: payload.assessmentId,
      })
    );
    yield put(setAssignThirdPartyUserModalOpen(false));
    yield put(setAssignThirdPartyUserIsSaving(false));
    yield put(
      addToast({
        description: `<strong>${escape(
          payload.user.fullName
        )}</strong> has been added to this assessment.`,
        type: TOAST_TYPES.user,
      })
    );
    yield put(setIsDirty({ value: false }));
  } catch ({ message }) {
    yield showError(message);
    yield put(setAssignThirdPartyUserIsSaving(false));
  }
}

export function* loadAvailableTruSightUsersSaga({ payload }) {
  try {
    yield put(setAvailableTruSightUsersLoading(true));
    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/users/trusight/available-to-assign`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(setAvailableTruSightUsers(data));
    yield put(setAvailableTruSightUsersLoading(false));
  } catch ({ message }) {
    yield showError(message);
    yield put(setAvailableTruSightUsersLoading(false));
  }
}

export function* assignTruSightUserSaga({ payload }) {
  try {
    yield put(setAssignTruSightUserIsSaving(true));

    const response = yield fetch(
      process.env.REACT_APP_API_URL +
        `/assessments/${payload.assessmentId}/users/trusight/${payload.user.userId}`,
      {
        method: "put",
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );
    if (response.status !== 200 && response.status !== 204) {
      throw new Error("Failed to add user.");
    }

    yield put(
      loadPeopleList({
        accessToken: payload.accessToken,
        assessmentId: payload.assessmentId,
      })
    );
    yield put(setAssignTruSightUserModalOpen(false));
    yield put(setAssignTruSightUserIsSaving(false));
    yield put(
      addToast({
        description: `<strong>${escape(
          payload.user.fullName
        )}</strong> has been added to this assessment.`,
        type: TOAST_TYPES.user,
      })
    );
    yield put(setIsDirty({ value: false }));
  } catch ({ message }) {
    yield showError(message);
    yield put(setAssignTruSightUserIsSaving(false));
  }
}

export default function* assessmentPeopleSagas() {
  yield all([
    takeLatest(loadPeopleList.type, loadPeopleListSaga),
    takeLatest(
      loadAvailableThirdPartyUsers.type,
      loadAvailableThirdPartyUsersSaga
    ),
    takeLatest(assignThirdPartyUser.type, assignThirdPartyUserSaga),
    takeLatest(loadAvailableTruSightUsers.type, loadAvailableTruSightUsersSaga),
    takeLatest(assignTruSightUser.type, assignTruSightUserSaga),
  ]);
}
