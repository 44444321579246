export const AUTHORIZATION_STATUS_VALUES = {
  pending: "Pending",
  authorized: "Authorized",
  rejected: "Rejected",
};

export const AUTHORIZATION_STATUS_OPTIONS = Object.values(
  AUTHORIZATION_STATUS_VALUES
).map((option) => {
  return {
    value: option,
    label: option,
  };
});

export const TABLE_CLASS = "js-authorizations-table";

export const FIELDS = {
  requestingCompany: "requestingCompany",
  requester: "requester",
  requesterEmail: "requesterEmail",
  requestedOn: "requestedOn",
  status: "status",
  authorizedBy: "authorizedBy",
  authorizedOn: "authorizedOn",
};
