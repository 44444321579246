import { defineMessages } from "react-intl";
import React from "react";

const scope = "components.purchaseAssessmentWithTemplates.";

export default defineMessages({
  purchaseAssessmentHeaderText: {
    id: `${scope}purchaseAssessmentHeaderText`,
    defaultMessage: "The selected assessments are available in multiple Product templates.",
  },
  assessmentInEscalationText: {
    id: `${scope}assessmentInEscalationText`,
    defaultMessage: "<strong> 1 or more of the selected products is in escalation and cannot be purchased at this time.</strong>",
    values: {
      // eslint-disable-next-line react/display-name
      strong: (text) => <strong>{text}</strong>,
    },
  },
  purchaseSingleAssessment: {
    id: `${scope}purchaseSingleAssessment`,
    defaultMessage: "Purchase Assessment",
  },
  purchaseMultipleAssessments: {
    id: `${scope}purchaseMultipleAssessments`,
    defaultMessage: "Purchase Assessments",
  },
  purchaseSingleAssessmentTitle: {
    id: `${scope}purchaseSingleAssessmentTitle`,
    defaultMessage: "Purchase 1 Assessment",
  },
  purchaseMultipleAssessmentsTitle: {
    id: `${scope}purchaseMultipleAssessmentsTitle`,
    defaultMessage: "Purchase {amount} Assessments",
  },
  setAllProductsTo: {
    id: `${scope}setAllProductsTo`,
    defaultMessage: "Set all Products to...",
  },
  createdBy: {
    id: `${scope}createdBy`,
    defaultMessage: "Created by",
  },
  total: {
    id: `${scope}total`,
    defaultMessage: "Total",
  },
  loading: {
    id: `${scope}loading`,
    defaultMessage: "Loading...",
  },
  owned: {
    id: `${scope}owned`,
    defaultMessage: "Owned",
  },
  unowned: {
    id: `${scope}unowned`,
    defaultMessage: "Unowned",
  },
  partiallyOwned: {
    id: `${scope}partiallyOwned`,
    defaultMessage: "Partially owned",
  },
  priceFull: {
    id: `${scope}priceFull`,
    defaultMessage: "Cost of assessment",
  },
  priceAlreadyOwned: {
    id: `${scope}priceAlreadyOwned`,
    defaultMessage: "Included in previous purchase",
  },
  pricePartiallyOwned: {
    id: `${scope}pricePartiallyOwned`,
    defaultMessage: "Cost of additional insights",
  },
});
