import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import routes from "app/routes";
import Button from "components/GlobalComponents/Buttons/Button";
import PurchaseAssessment from "./components/PurchaseAssessment";
import PurchaseAssessmentWithTemplates from "./components/PurchaseAssessmentWithTemplates";
import { ReactComponent as AddIcon } from "assets/add.svg";
import { ReactComponent as StarIcon } from "assets/star.svg";
import { selectSlice } from "./services/selectors";
import { setSelectedAssessments } from "./services/slice";
import { watchAssessments, addToMyList } from "services/watchAssessments/slice";
import { addToast } from "services/toast/slice";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";
import {
  setMultiplePurchaseAssessmentDetails,
  setIsBulkPurchaseModalOpen,
} from "./components/PurchaseAssessment/services/slice";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import "./bulk-assessment-actions.scss";

function BulkAssessmentActions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = useAccessToken();
  const { selectedAssessments } = useSelector(selectSlice);
  const permissions = useSelector(selectPermissions);

  // TODO TPAP-5072 DISABLE BUTTON TEMPORARILY
  // REMOVE VARIABLE WHEN FURTHER IMPLEMENTATION IS NEEDED
  // EDIT UNIT TEST FOR BUTTONS CHECK
  const temporarilyDisabledButton = false;

  const cleanSelections = () => {
    dispatch(setSelectedAssessments([]));
  };

  useEffect(cleanSelections, [location, dispatch]);

  const watchSelected = () => {
    let assessmentIds = selectedAssessments
      .map((row) => row.assessmentId)
      .filter((x) => x != null);

    if (assessmentIds < 1) {
      return;
    }

    dispatch(
      watchAssessments({
        accessToken,
        assessmentIds: assessmentIds,
        assessments: selectedAssessments,
      })
    );
  };

  const addToMyListSelected = () => {
    let assessmentIds = selectedAssessments
      .map((row) => row.assessmentId)
      .filter((x) => x != null);

    if (assessmentIds.length < 1) {
      return;
    }

    dispatch(
      addToMyList({
        accessToken,
        assessmentIds: assessmentIds,
        assessments: selectedAssessments,
      })
    );
  };

  const purchaseSelected = () => {
    const assessmentsValidForPurchase =
      process.env
        .REACT_APP_FEATURE_PURCHASE_ASSESSMENT_WITH_CUSTOM_TEMPLATES === "true"
        ? selectedAssessments.filter((assessment) => assessment.canPurchase)
        : selectedAssessments.filter(
            (assessment) => assessment.canPurchase && !assessment.isPurchased
          );

    if (assessmentsValidForPurchase.length < 1) {
      const toastInfo = {
        description:
          "Selected assessment(s) were previously purchased or insufficient assessment status has been reached",
      };
      dispatch(addToast(toastInfo));
      return;
    }

    dispatch(
      setMultiplePurchaseAssessmentDetails({
        selectedAssessments,
        selectedAssessmentsCount: selectedAssessments.length,
      })
    );

    dispatch(setIsBulkPurchaseModalOpen(true));
  };

  const watchAssessmentButtonTitle = useMemo(
    () =>
      selectedAssessments.length === 1
        ? `Watch ${selectedAssessments.length} Assessment`
        : `Watch ${selectedAssessments.length} Assessments`,
    [selectedAssessments.length]
  );

  const purchaseAssessmentButtonTitle = useMemo(
    () =>
      selectedAssessments.length === 1
        ? `Purchase ${selectedAssessments.length} Assessment`
        : `Purchase ${selectedAssessments.length} Assessments`,
    [selectedAssessments.length]
  );

  return (
    <>
      {selectedAssessments.length > 0 && (
        <div className="bulk-assessment-actions">
          {temporarilyDisabledButton &&
            location.pathname !== routes.myListMyThirdParties.path &&
            permissions &&
            permissions.includes(PERMISSIONS.MyList) && (
              <Button
                className="bulk-assessment-actions__btn"
                isPrimary
                isSmall
                leadingIcon={<AddIcon />}
                onClick={addToMyListSelected}
              >
                Add to my list
              </Button>
            )}
          <Button
            className="bulk-assessment-actions__btn bulk-assessment-actions__btn--large-icon"
            isPrimary
            isSmall
            leadingIcon={<StarIcon />}
            onClick={watchSelected}
            data-testid="bulk-assessment-actions-btn-watch"
          >
            {watchAssessmentButtonTitle}
          </Button>
          {/* Do not display button if the assessment is of unpurchasable type and unpurchasable status id */}
          {selectedAssessments.filter((selectedAssessment) => {
            let display = false;
            !process.env.REACT_APP_UNPURCHASABLE_ASSESSMENT_TYPES.split(",")
              .map((type) => type.trim())
              .includes(selectedAssessment?.type) &&
            !process.env.REACT_APP_UNPURCHASABLE_ASSESSMENT_STATUS_IDS.split(
              ","
            )
              .map((type) => parseInt(type.trim()))
              .includes(selectedAssessment?.assessmentStatusId)
              ? (display = true)
              : (display = false);
            return display;
          }).length > 0 && (
            <Button
              className="bulk-assessment-actions__btn bulk-assessment-actions__btn--border"
              isPrimary
              isSmall
              onClick={purchaseSelected}
              data-testid="bulk-assessment-actions-btn-purchase"
            >
              {purchaseAssessmentButtonTitle}
            </Button>
          )}
          <span aria-live="polite" className="visually-hidden" role="alert">
            Bulk actions available
          </span>
        </div>
      )}
      {process.env
        .REACT_APP_FEATURE_PURCHASE_ASSESSMENT_WITH_CUSTOM_TEMPLATES ===
      "true" ? (
        <PurchaseAssessmentWithTemplates />
      ) : (
        <PurchaseAssessment />
      )}
    </>
  );
}

BulkAssessmentActions.propTypes = {};

export default BulkAssessmentActions;
