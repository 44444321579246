import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "thirdPartiesPage",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    loadThirdPartiesList: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadThirdPartiesListSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadThirdPartiesListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadThirdPartiesList,
  loadThirdPartiesListSuccess,
  loadThirdPartiesListError,
} = slice.actions;

export default slice;
