import { put, takeLatest, call, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import { addToast } from "services/toast/slice";
import {
  loadCustomTemplates,
  loadCustomTemplatesSuccess,
  loadCustomTemplatesError,
  createNewTemplate,
  createNewTemplateSuccess,
  createNewTemplateError,
  deleteTemplate,
  duplicateTemplate,
  duplicateTemplateSuccess,
  resetTemplatesAndMappingModals,
  loadTagCollectionsByVersion,
  loadTagCollectionsByVersionSuccess,
  loadTagCollectionsByVersionError,
  setCurrentWizardStep,
} from "./slice";
import { setNewTemplateId } from "pages/TemplatesAndMappingPage/services/slice";
import { setIsDirty } from "services/form/slice";

export function* loadCustomTemplatesSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/custom-templates`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadCustomTemplatesSuccess(data));
  } catch ({ message }) {
    yield put(loadCustomTemplatesError(message));
  }
}

export function* createNewTemplateSaga({ payload }) {
  const { accessToken, requestBody } = payload;
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/custom-templates`,
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    if (response.status !== 200) {
      if (response.status === 400) {
        throw new Error(data.Title);
      }
      throw new Error();
    }
    yield put(createNewTemplateSuccess());
    yield put(setIsDirty(false));
    yield put(resetTemplatesAndMappingModals());
    yield put(setNewTemplateId(data));
  } catch ({ message }) {
    yield put(createNewTemplateError());
    yield showError(message);
    yield put(resetTemplatesAndMappingModals());
  }
}

export function* duplicateTemplateSaga({ payload }) {
  try {
    const { id, accessToken, redirect } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/custom-templates/${id}/duplicate`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    if (response.status !== 200) {
      throw new Error(data.Title);
    }

    if (redirect) {
      yield put(duplicateTemplateSuccess());
      yield put(setNewTemplateId(data));
    } else {
      const toastInfo = {
        description: "Template duplicated",
      };
      yield put(addToast(toastInfo));
      yield call(loadCustomTemplatesSaga, { payload: { accessToken } });
    }
  } catch ({ message }) {
    yield showError(message);
  }

  yield put(resetTemplatesAndMappingModals());
}

export function* deleteTemplateSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/custom-templates/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to delete");
    }

    const toastInfo = {
      description: "Template deleted",
    };

    yield put(addToast(toastInfo));
    yield call(loadCustomTemplatesSaga, { payload: { accessToken } });
  } catch ({ message }) {
    yield showError(message);
  }
}

export function* loadTagCollectionsByVersionSaga({ payload }) {
  try {
    const { accessToken, version } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/tags-collections/version/${version}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadTagCollectionsByVersionSuccess(data));
    yield put(setCurrentWizardStep(1));
  } catch ({ message }) {
    yield put(loadTagCollectionsByVersionError(message));
  }
}

export default function* customTemplatesSagas() {
  yield all([
    takeLatest(loadCustomTemplates.type, loadCustomTemplatesSaga),
    takeLatest(createNewTemplate.type, createNewTemplateSaga),
    takeLatest(duplicateTemplate.type, duplicateTemplateSaga),
    takeLatest(deleteTemplate.type, deleteTemplateSaga),
    takeLatest(
      loadTagCollectionsByVersion.type,
      loadTagCollectionsByVersionSaga
    ),
  ]);
}
