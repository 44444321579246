import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadCustomersList,
  setCustomersListSuccess,
  setCustomersListError,
  exportPossibleMatches,
  exportPossibleMatchesCompleted,
} from "pages/ManageCustomersPage/services/slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import { downloadFile } from "utils/handleDownload/handleDownload";

const EXPORT_POSSIBLE_MATCHES_FILE_NAME = "possible-matches.csv";

export function* loadCustomersSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const data = yield response.json();
    yield put(setCustomersListSuccess(data));
  } catch ({ message }) {
    yield put(setCustomersListError(message));
    yield showError(message);
  }
}

export function* exportPossibleMatchesSaga({ payload: { accessToken } }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-management/export-possible-matches`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to export");
    }

    downloadFile(response, EXPORT_POSSIBLE_MATCHES_FILE_NAME);
    yield put(exportPossibleMatchesCompleted());
  } catch ({ message }) {
    yield put(exportPossibleMatchesCompleted());
    yield showError(message);
  }
}

export default function* manageCustomersSagas() {
  yield all([
    takeLatest(loadCustomersList.type, loadCustomersSaga),
    takeLatest(exportPossibleMatches.type, exportPossibleMatchesSaga),
  ]);
}
