import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectState = (state) => state[slice.name];

export const selectProposedThirdPartyById = (id) =>
  createSelector([selectState], (state) =>
    state.customerUnmatchedThirdParties
      ?.find((thirdParty) => thirdParty.thirdPartyId === id)
      .possibleMatches.find((possibleMatch) => possibleMatch.isProposedByTs)
  );

export const selectCustomerUnmatchedThirdPartiesLoading = createSelector(
  [selectState],
  (state) => state.customerUnmatchedThirdPartiesLoading
);

export const selectCustomerUnmatchedThirdPartiesError = createSelector(
  [selectState],
  (state) => state.customerUnmatchedThirdPartiesError
);

export const selectUnmatchedVendorInfo = createSelector(
  [selectState],
  (state) => state.unmatchedVendorInfo
);

export const selectDisplayPossibleMatchVendorInfo = createSelector(
  [selectState],
  (state) => state.displayPossibleMatchVendorInfo
);

export const selectDisplayUploadedVendorInfo = createSelector(
  [selectState],
  (state) => state.displayUploadedVendorInfo
);

export const selectMatchedVendorInfo = createSelector(
  [selectState],
  (state) => state.matchedVendorInfo
);

export const selectUnmatchedVendorInfoLoading = createSelector(
  [selectState],
  (state) => state.unmatchedVendorInfoLoading
);

export const selectUnmatchedVendorInfoError = createSelector(
  [selectState],
  (state) => state.unmatchedVendorInfoError
);

export const selectFormSavePending = createSelector(
  [selectState],
  (state) => state.formSavePending
);

export const selectFormSaveError = createSelector(
  [selectState],
  (state) => state.formSaveError
);

export const selectVendorInfoDrawerOpen = createSelector(
  [selectState],
  (state) => state.drawers.vendorInfoDrawer.isOpen
);

export const selectIsDeleteVendorModalOpen = createSelector(
  [selectState],
  (state) => state.modals.deleteVendorModal.isOpen
);

export const selectIsDeleteVendorModalLoading = createSelector(
  [selectState],
  (state) => state.modals.deleteVendorModal.isLoading
);

export const selectIsCreateCandidateModalOpen = createSelector(
  [selectState],
  (state) => state.modals.createCandidateModal.isOpen
);

export const selectIsCreateCandidateModalLoading = createSelector(
  [selectState],
  (state) => state.modals.createCandidateModal.isLoading
);

export const selectModalData = createSelector(
  [selectState],
  (state) => state.modals.modalData
);

export const selectOverrideThirdPartyModalData = createSelector(
  [selectState],
  (state) => state.overrideThirdPartyModal
);

export const selectIsOverrideThirdPartyModalOpen = createSelector(
  [selectState],
  (state) => state.overrideThirdPartyModal.isOpen
);

export const selectIsOverrideThirdPartyLoading = createSelector(
  [selectState],
  (state) => state.overrideThirdPartyLoading
);

export const selectIsUploadThirdPartiesModalOpen = createSelector(
  [selectState],
  (state) => state.modals.uploadThirdPartiesModal.isOpen
);

export const selectShouldCustomerUnmatchedThirdPartiesReload = createSelector(
  [selectState],
  (state) => state.shouldCustomerUnmatchedThirdPartiesReload
);

export const selectHasCustomerUnmatchedThirdPartiesLoaded = createSelector(
  [selectState],
  (state) => state.hasCustomerUnmatchedThirdPartiesLoaded
);
