import { defineMessages } from "react-intl";

const scope = "components.uploadThirdPartiesModal.";

export default defineMessages({
  thirdPartyTemplateName: {
    id: `${scope}thirdPartyTemplateName`,
    defaultMessage: "Third Party Template.csv",
  },
  thirdPartyTemplateVersion: {
    id: `${scope}thirdPartyTemplateVersion`,
    defaultMessage: "Version 1.0",
  },
  downloadTemplateDescription: {
    id: `${scope}downloadTemplateDescription`,
    defaultMessage: "Once download is complete, open the file and fill out the template.",
  },
  downloadTemplateDescriptionParagraph: {
    id: `${scope}downloadTemplateDescriptionParagraph`,
    defaultMessage: "Have the completed template?",
  },
  findAssessmentUploadedOn: {
    id: `${scope}findAssessmentUploadedOn`,
    defaultMessage: "Uploaded {uploadedOn}",
  },
  findAssessmentAlreadyExist: {
    id: `${scope}findAssessmentAlreadyExist`,
    defaultMessage: "All uploaded third parties already exist.",
  },
  findAssessmentNoValidRecords: {
    id: `${scope}findAssessmentNoValidRecords`,
    defaultMessage: "No valid records found, check for errors below",
  },
  findAssessmentMatchingHasBegun: {
    id: `${scope}findAssessmentMatchingHasBegun`,
    defaultMessage: "Matching has begun for {savedRowsCount} record(s)",
  },
  findAssessmentUploadFileAgain: {
    id: `${scope}findAssessmentUploadFileAgain`,
    defaultMessage: "Upload File Again",
  },
  findAssessmentAddFile: {
    id: `${scope}findAssessmentAddFile`,
    defaultMessage: "Add File",
  },
  findAssessmentViewProgress: {
    id: `${scope}findAssessmentViewProgress`,
    defaultMessage: "View Progress",
  },
  downloadThirdPartyTemplate: {
    id: `${scope}downloadThirdPartyTemplate`,
    defaultMessage: "download third party template",
  },
  uploadThirdPartyTemplate: {
    id: `${scope}uploadThirdPartyTemplate`,
    defaultMessage: "upload third party template",
  },
  findAvailableAssessments: {
    id: `${scope}findAvailableAssessments`,
    defaultMessage: "find available assessments",
  },
  stepOf: {
    id: `${scope}stepOf`,
    defaultMessage: "Step {currentElement} of {length}:",
  },
  errorsFound: {
    id: `${scope}errorsFound`,
    defaultMessage: "{count} error(s) found",
  },
  tooManyFiles: {
    id: `${scope}tooManyFiles`,
    defaultMessage: "Too many files",
  },
  fileIsRejected: {
    id: `${scope}fileIsRejected`,
    defaultMessage: "File is rejected",
  },
  fileIsTooLarge: {
    id: `${scope}fileIsTooLarge`,
    defaultMessage: "File is too large (over 10MB)",
  },
  dragTheCompletedTemplateHere: {
    id: `${scope}dragTheCompletedTemplateHere`,
    defaultMessage: "Drag the completed template here",
  },
  orBrowseToChooseTheFile: {
    id: `${scope}orBrowseToChooseTheFile`,
    defaultMessage: "or browse to choose the file",
  },
  goBack: {
    id: `${scope}goBack`,
    defaultMessage: "Go Back",
  },
  continueToUpload: {
    id: `${scope}continueToUpload`,
    defaultMessage: "Continue to upload",
  },
});
