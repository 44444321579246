import { useEffect } from "react";

import { KEYS } from "app/keys";

const UseCheckOutsideClick = (
  ref,
  changeStateFunc,
  state,
  checkBoth,
  disabled
) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!disabled) {
        if (
          (e.code === KEYS.tab.code ||
            e.keyCode === KEYS.tab.keyCode ||
            e.type === "mousedown") &&
          ref.current
        ) {
          if (checkBoth) {
            if (!ref.current.contains(e.target)) {
              changeStateFunc(true);
            } else {
              changeStateFunc(false);
            }
          } else {
            if (!ref.current.contains(e.target) && state) {
              changeStateFunc(e);
            }
          }
        }
      }
    };

    setTimeout(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("keyup", handleOutsideClick);
    }, 0);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keyup", handleOutsideClick);
    };
  }, [ref, changeStateFunc, state, checkBoth, disabled]);
};

export default UseCheckOutsideClick;
