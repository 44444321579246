import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

const selectPermissionsSlice = (state) => state[slice.name];

/*
  This does not include extended permissions related to assessment
  by assessment stage or user relationship to assessment.
  Should be used for generic access checks not related to a single assessment.
*/
export const selectPermissions = createSelector(
  [selectPermissionsSlice],
  (slice) => {
    return slice.permissions
      ?.filter((x) => !x.stage && !x.relationshipToAssessment)
      ?.map((x) => x.permission);
  }
);

export const selectPermissionsForAssessment = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.permissions
);

export const selectPermissionsInitialising = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.initialisingPermissions
);

export const selectPermissionsInitialised = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.initialised
);

export const selectPermissionsInitError = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.initError
);

export const selectOrganizationType = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.organizationType
);

export const selectIsOrganizationTypeLoading = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.isOrganizationTypeLoading
);

export const selectOrganizationTypeError = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.organizationTypeError
);

export const selectStages = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.stages
);

export const selectOrganizationProductMappings = createSelector(
  [selectPermissionsSlice],
  (slice) => slice.organizationProductMappings
);
