import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

const initialState = {
  artifactAssociation: {
    showArtifactAssociationModal: false,
    questionId: null,
    questionLabel: null,
    questionSubText: null,
    artifactIds: null,
    hasSensitiveArtifacts: false,
    viewOnly: false,
  },
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    showArtifactAssociation: (state, { payload }) => {
      state.artifactAssociation = { ...payload };
    },
    resetArtifactAssociationStore: () => {
      return { ...initialState };
    },
  },
});

export const { showArtifactAssociation, resetArtifactAssociationStore } =
  slice.actions;

export default slice;
