import { call, put, takeLatest, takeEvery, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadAdminMatchingHistory,
  loadCustomerMatchingHistory,
  loadMatchingHistorySuccess,
  loadMatchingHistoryError,
  undoMatch,
  undoMatchSuccess,
  undoMatchError,
  removeMatchFromGrid,
} from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";

function* loadCustomerMatchingHistorySaga({ payload }) {
  try {
    const { accessToken } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-matching-history`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadMatchingHistorySuccess(data));
  } catch ({ message }) {
    yield put(loadMatchingHistoryError(message));
  }
}

function* loadAdminMatchingHistorySaga({ payload }) {
  try {
    const { accessToken, id } = payload;

    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-matching-history/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadMatchingHistorySuccess(data));
  } catch ({ message }) {
    yield put(loadMatchingHistoryError(message));
  }
}

export function* undoMatchSaga({ payload }) {
  const { accessToken, organizationId, customerThirdPartyId } = payload;

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/customer-matching-history/${organizationId}/undo/${customerThirdPartyId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to undo match.");
    }

    yield put(undoMatchSuccess());
    yield put(removeMatchFromGrid(customerThirdPartyId));
  } catch ({ message }) {
    yield put(undoMatchError());
    yield showError(message);
  }
}

export default function* manageCustomerSagas() {
  yield all([
    yield takeLatest(
      loadCustomerMatchingHistory.type,
      loadCustomerMatchingHistorySaga
    ),
    yield takeLatest(
      loadAdminMatchingHistory.type,
      loadAdminMatchingHistorySaga
    ),
    yield takeEvery(undoMatch.type, undoMatchSaga),
  ]);
}
