import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  document: null,
  isDocumentLoading: false,
  isBpqScopingInitialised: false,
};

const slice = createSlice({
  name: "bpqScoping",
  initialState,
  reducers: {
    loadBpqScopingDocument: (state) => {
      state.document = null;
      state.isDocumentLoading = true;
      state.isBpqScopingInitialised = true;
    },
    loadBpqScopingSuccess: (state, { payload }) => {
      state.document = payload;
      state.isDocumentLoading = false;
    },
    loadBpqScopingError: (state) => {
      state.isDocumentLoading = false;
    },
    resetBpqScopingStore: () => {
      return { ...initialState };
    },
    setQuestionsIsMasked: (state, { payload }) => {
      payload.questions.forEach((question) => {
        const bpqQestion = state.document?.questions.find(
          (q) => q.serialId === question.serialId
        );
        bpqQestion.masked = question.masked;
      });
    },
  },
});

export const {
  loadBpqScopingDocument,
  loadBpqScopingSuccess,
  loadBpqScopingError,
  resetBpqScopingStore,
  setQuestionsIsMasked,
} = slice.actions;

export default slice;
