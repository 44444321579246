import PropTypes from "prop-types";
import React from "react";
import ReactTimeAgo from "react-time-ago";

import "./assessment-fieldwork-changed-notification.scss";
import { dateFormatter } from "utils/dateFormatter/dateFormatter";

const AssessmentFieldworkChangedNotification = ({
  createdAt,
  messageData,
  onClick,
}) => {
  return (
    <div className="assessment-fieldwork-changed-notification">
      <button
        className="assessment-fieldwork-changed-notification__details-button"
        type="button"
        onClick={() => onClick(messageData.AssessmentId)}
      >
        <span className="assessment-fieldwork-changed-notification__details paragraph">
          {messageData.Fieldwork && (
            <>
              <strong>{messageData.ThirdPartyName}</strong> {messageData.Year}{" "}
              {messageData.Type} assessment has an updated fieldwork date of{" "}
              <strong>{dateFormatter(messageData.Fieldwork)}</strong>
            </>
          )}
          {!messageData.Fieldwork && (
            <>
              <strong>{messageData.ThirdPartyName}</strong> {messageData.Year}{" "}
              {messageData.Type} assessment fieldwork date is in the process of{" "}
              <strong>being rescheduled</strong>
            </>
          )}
        </span>
        <ReactTimeAgo
          className="assessment-fieldwork-changed-notification__time"
          date={new Date(createdAt)}
        />
        <span className="visually-hidden">Show assessment details</span>
      </button>
    </div>
  );
};

export default AssessmentFieldworkChangedNotification;

AssessmentFieldworkChangedNotification.propTypes = {
  messageData: PropTypes.exact({
    AssessmentId: PropTypes.string,
    ThirdPartyName: PropTypes.string,
    Year: PropTypes.number,
    Type: PropTypes.string,
    Fieldwork: PropTypes.string,
  }).isRequired,
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
