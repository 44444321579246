import messages from "app/messages";
import { setIsCustomCommentDirty, setIsDirty } from "services/form/slice";

export const handleDirtyFormPrompt = (callback, isDirty, intl, dispatch) => {
  if (isDirty) {
    if (window.confirm(intl.formatMessage(messages.unsavedChangesWarning))) {
      callback();
      dispatch(setIsDirty({ value: false, key: null }));
      dispatch(
        setIsCustomCommentDirty({
          value: false,
          key: null,
        })
      );
    }
  } else {
    callback();
  }
};
