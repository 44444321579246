import { createAction, createSlice } from "@reduxjs/toolkit";

import { TYPE_AHEAD_TYPES } from "components/GlobalComponents/Inputs/TypeAheadInput/utils/constants";

const NAMESPACE = "typeAhead";

export const initialTypeAheadState = {
  shouldReset: false,
};

export const initialLookUpState = {
  ...initialTypeAheadState,
  isLoading: false,
  isLoaded: false,
  lookUpResults: [],
  isPosting: false,
};

const getInitialStateByType = (type) => {
  switch (type) {
    case TYPE_AHEAD_TYPES.lookUp:
      return initialLookUpState;
    default:
      return initialTypeAheadState;
  }
};

const loadLookupSearchResultsHandler = (
  state,
  { payload: { lookupFieldId } }
) => {
  if (lookupFieldId in state) {
    state[lookupFieldId].isLoading = true;
    state[lookupFieldId].lookUpResults = [];
  } else {
    state[lookupFieldId] = {
      isLoading: true,
      lookUpResults: [],
    };
  }
};

const slice = createSlice({
  name: "typeAhead",
  initialState: {},
  reducers: {
    registerTypeAheadComponent: (state, { payload: { id, type } }) => {
      state[id] = getInitialStateByType(type);
    },
    unRegisterTypeAheadComponent: (state, { payload }) => {
      delete state[payload];
    },
    setShouldInputReset: (state, { payload: { shouldReset, id } }) => {
      state[id].shouldReset = shouldReset;
    },
    loadThirdPartiesLookUpSearchResults: loadLookupSearchResultsHandler,
    loadAddRelationshipLookupSearchResults: loadLookupSearchResultsHandler,
    loadLookUpSearchResultsError: (state, { payload }) => {
      state[payload].isLoading = false;
    },
    loadLookUpSearchResultsSuccess: (
      state,
      { payload: { data, lookupFieldId } }
    ) => {
      if (lookupFieldId in state) {
        state[lookupFieldId].isLoading = false;
        state[lookupFieldId].isLoaded = true;
        state[lookupFieldId].lookUpResults = data;
      }
    },
    proposeLookedUpMatch: (state, { payload: { customerThirdPartyId } }) => {
      state[customerThirdPartyId].isPosting = true;
    },
    proposeLookedUpMatchSuccess: (state, { payload }) => {
      state[payload].isPosting = false;
    },
    proposeLookedUpMatchError: (state, { payload }) => {
      state[payload].isPosting = false;
    },
  },
});

export const showAlreadyInListMessage = createAction(
  `${NAMESPACE}/showAlreadyInListMessage`
);

export const {
  registerTypeAheadComponent,
  unRegisterTypeAheadComponent,
  setShouldInputReset,
  loadThirdPartiesLookUpSearchResults,
  loadAddRelationshipLookupSearchResults,
  loadLookUpSearchResultsSuccess,
  loadLookUpSearchResultsError,
  proposeLookedUpMatch,
  proposeLookedUpMatchSuccess,
  proposeLookedUpMatchError,
} = slice.actions;

export default slice;
