import { defineMessages } from "react-intl";
import React from "react";

const scope = "components.purchaseAssessment.";

export default defineMessages({
  purchaseAssessmentHeaderText: {
    id: `${scope}purchaseAssessmentHeaderText`,
    defaultMessage: "Some of the selected products are available in multiple assessment types. Customize your purchases below.",
  },
  assessmentInEscalationText: {
    id: `${scope}assessmentInEscalationText`,
    defaultMessage: "<strong> 1 or more of the selected products is in escalation and cannot be purchased at this time.</strong>",
    values: {
      // eslint-disable-next-line react/display-name
      strong: (text) => <strong>{text}</strong>,
    },
  },
  purchaseSingleAssessmentTitleText: {
    id: `${scope}purchaseSingleAssessmentTitleText`,
    defaultMessage: "Purchase 1 Assessment",
  },
  purchaseMultipleAssessmentTitleText: {
    id: `${scope}purchaseMultipleAssessmentTitleText`,
    defaultMessage: "Purchase {amount} Assessments",
  },
});
