import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";

export const PERMISSIONS_TO_SEE_ALL_CONTROLS = [
  PERMISSIONS.CanWatch,
  PERMISSIONS.CanPurchase,
  PERMISSIONS.MyList,
];

export const PERMISSIONS_TO_SEE_BULK_ACTIONS = [
  PERMISSIONS.CanWatch,
  PERMISSIONS.CanPurchase,
];
