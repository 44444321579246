import classNames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import Spinner from "components/Spinner";
import { getPrefixedDataTestId } from "utils/getPrefixedDataTestId/getPrefixedDataTestId";
import "./button.scss";

export const buttonTypes = {
  tab: "tab",
  link: "link",
};

const Button = forwardRef(
  (
    {
      dataTestIdPrefix,
      ariaExpanded,
      ariaHasPopup,
      ariaControls,
      ariaLabel,
      ariaPressed,
      children,
      className,
      followingIcon,
      hasDropdownOpen,
      isDisabled,
      isFocused,
      isHighlighted,
      isActive,
      isPrimary,
      isSecondary,
      isSmall,
      isLarge,
      isLight,
      isLink,
      leadingIcon,
      onClick,
      tabIndex,
      to,
      type,
      buttonType,
      isLoading,
      loadingText,
      ...otherProps
    },
    ref
  ) => {
    const btnShade = isLight ? "btn-light" : "btn-dark";
    const btnClass = `btn${buttonType ? "-" + buttonType : ""}`;
    let buttonStyles;

    switch (buttonType) {
      case buttonTypes.tab:
        buttonStyles = [
          {
            "btn-tab--active": isActive,
            "btn-tab--disabled": isDisabled,
          },
          "btn-tab",
        ];
        break;
      case buttonTypes.link:
        buttonStyles = [
          {
            "btn-link--primary": isPrimary,
            "btn-link--secondary": isSecondary,
            "btn-link--dropdown-open": ariaExpanded && ariaHasPopup,
          },
          "btn-link",
        ];
        break;
      default:
        buttonStyles = [
          {
            [btnShade]: !buttonType,
            [`${btnShade}--primary`]: isPrimary,
            [`${btnShade}--secondary`]: isSecondary,
            [`${btnShade}--focused`]: isFocused,
            [`${btnShade}--dropdown-open`]: hasDropdownOpen,
            "btn--highlighted": isHighlighted,
            "btn--small": isSmall,
            "btn--large": isLarge,
          },
          "btn",
        ];
    }

    return isLink ? (
      <Link
        data-testid={getPrefixedDataTestId(dataTestIdPrefix, "button-link")}
        className={classNames(...buttonStyles, className)}
        onClick={onClick}
        to={to}
        ref={ref}
      >
        {leadingIcon && (
          <span
            className={classNames(
              {
                [`${btnShade}__icon`]: !buttonType,
              },
              `${btnClass}__icon ${btnClass}__icon--leading`
            )}
          >
            {leadingIcon}
          </span>
        )}
        <span className={`${btnClass}__text`}>{children}</span>
        {followingIcon && (
          <span
            className={classNames(
              "btn__icon btn__icon--following",
              `${btnShade}__icon`
            )}
          >
            {followingIcon}
          </span>
        )}
      </Link>
    ) : (
      <button
        aria-live={isLoading ? "polite" : "off"}
        data-testid={getPrefixedDataTestId(dataTestIdPrefix, "button-default")}
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        aria-controls={ariaControls}
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
        className={classNames(...buttonStyles, className)}
        disabled={isDisabled}
        onClick={onClick}
        ref={ref}
        tabIndex={isDisabled ? -1 : tabIndex}
        type={type}
      >
        {leadingIcon && (
          <span
            className={classNames(
              {
                [`${btnShade}__icon`]: !buttonType,
              },
              `${btnClass}__icon ${btnClass}__icon--leading`
            )}
          >
            {leadingIcon}
          </span>
        )}
        {isLoading && buttonType !== buttonTypes.link ? (
          <>
            <Spinner
              className={`${btnClass}__spinner`}
              isLight={isHighlighted || isLight}
            />
            <span
              data-testid={getPrefixedDataTestId(
                dataTestIdPrefix,
                "button-default-text"
              )}
            >
              {loadingText}
            </span>
          </>
        ) : (
          <span
            className={`${btnClass}__text`}
            data-testid={getPrefixedDataTestId(
              dataTestIdPrefix,
              "button-default-text"
            )}
          >
            {isLoading ? loadingText : children}
          </span>
        )}
        {followingIcon && (
          <span
            className={classNames(
              {
                [`${btnShade}__icon`]: !buttonType,
              },
              `${btnClass}__icon ${btnClass}__icon--following`
            )}
          >
            {followingIcon}
          </span>
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  dataTestIdPrefix: "",
  ariaExpanded: null,
  ariaHasPopup: false,
  ariaControls: "",
  className: "",
  followingIcon: null,
  hasDropdownOpen: false,
  isDisabled: false,
  isHighlighted: false,
  isFocused: false,
  isActive: false,
  isPrimary: false,
  isSecondary: false,
  isSmall: false,
  isLarge: false,
  isLight: false,
  isLink: false,
  leadingIcon: null,
  onClick: () => {},
  tabIndex: 0,
  to: "",
  type: "button",
  buttonType: "",
  isLoading: false,
  loadingText: "Processing",
};

export const buttonPropTypes = {
  dataTestIdPrefix: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  ariaHasPopup: PropTypes.bool,
  ariaControls: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaPressed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  followingIcon: PropTypes.node,
  hasDropdownOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isFocused: PropTypes.bool,
  isActive: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  isLight: PropTypes.bool,
  isLink: PropTypes.bool,
  leadingIcon: PropTypes.node,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  to: PropTypes.string,
  type: PropTypes.string,
  buttonType: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
};

Button.propTypes = buttonPropTypes;

Button.displayName = "Button";
export default Button;
