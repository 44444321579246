import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  modals: {
    createTagsCollection: {
      isOpen: false,
      isSubmitting: false,
      assessmentVersions: null,
      isLoading: false,
    },
    duplicateTagsCollection: {
      isOpen: false,
      isDuplicating: false,
    },
    deleteTagsCollection: {
      isOpen: false,
      isDeleting: false,
    },
    modalData: null,
  },
  loading: false,
  error: null,
  saving: false,
};

const slice = createSlice({
  name: "tagsAndCollections",
  initialState,
  reducers: {
    loadTagsCollections: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadTagsCollectionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    },
    loadTagsCollectionsError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setCreateTagsCollectionModalOpen: (state, { payload }) => {
      state.modals.createTagsCollection.isOpen = payload;
    },
    submitCreateTagsCollection: (state) => {
      state.modals.createTagsCollection.isSubmitting = true;
    },
    setIsCreateTagsCollectionSubmitting: (state, { payload }) => {
      state.modals.createTagsCollection.isSubmitting = payload;
    },
    setDuplicateTagsCollectionModalOpen: (state, { payload }) => {
      state.modals.duplicateTagsCollection.isOpen = payload;
    },
    duplicateTagsCollection: (state) => {
      state.modals.duplicateTagsCollection.isDuplicating = true;
    },
    setIsDuplicating: (state, { payload }) => {
      state.modals.duplicateTagsCollection.isDuplicating = payload;
    },
    setModalData: (state, { payload }) => {
      state.modals.modalData = payload;
    },
    deleteTagsCollection: (state) => {
      state.modals.deleteTagsCollection.isDeleting = true;
    },
    setIsDeleting: (state, { payload }) => {
      state.modals.deleteTagsCollection.isDeleting = payload;
    },
    setDeleteCollectionModalOpen: (state, { payload }) => {
      state.modals.deleteTagsCollection.isOpen = payload;
    },
    resetTagsAndCollectionsStore: () => initialState,
  },
});

export const {
  loadTagsCollections,
  loadTagsCollectionsSuccess,
  loadTagsCollectionsError,
  setCreateTagsCollectionModalOpen,
  submitCreateTagsCollection,
  setIsCreateTagsCollectionSubmitting,
  setDuplicateTagsCollectionModalOpen,
  setModalData,
  duplicateTagsCollection,
  setIsDuplicating,
  deleteTagsCollection,
  setIsDeleting,
  setDeleteCollectionModalOpen,
  resetTagsAndCollectionsStore,
} = slice.actions;

export default slice;
