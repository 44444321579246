export const TIERS = { LOW: "Low", MEDIUM: "Medium", HIGH: "High" };

export const ASSESSMENT_TYPES = {
  COMPREHENSIVE: "Comprehensive",
  STANDARD: "Standard",
  KEY_CONTROLS: "Key Controls",
};

export const UNMATCHED_ASSESSMENT_TYPES = {
  COMPREHENSIVE: "Comprehensive",
  KEY_CONTROLS: "Key Controls",
};

export const ORGANIZATION_ASSESSMENT_TYPES = {
  ENHANCED_COMPREHENSIVE: "Enhanced Comprehensive",
};

export const FREQUENCIES = { ONE: 1, TWO: 2, THREE: 3 };

export const SERVICE_CATEGORIES = {
  BANKING: "Banking",
  FACILITIES: "Facilities",
  HUMAN_RESOURCE_MANAGEMENT: "Human Resource Management",
  INFORMATION_SERVICES_AND_TECHNOLOGY: "Information Services & Technology",
  MARKETING_COMMUNICATIONS: "Marketing Communications",
  PROFESSIONAL_SERVICES: "Professional Services",
  SHARED_SERVICES: "Shared Services",
};

export const BANKING_SUBCATEGORIES = {
  AGENTS: "Agents",
  BROKERS: "Brokers",
  CARD_AND_CREDIT_SERVICES: "Card & Credit Services",
  CARD_AND_CREDIT_SERVICES_ADD_ON_PRODUCTS:
    "Card & Credit Services - Add-on Products",
  CASH_MANAGEMENT_PHYSICAL_ASSETS: "Cash Management (Physical Assets)",
  CASH_MANAGEMENT_SERVICES: "Cash Management (Services)",
  COLLECTIONS: "Collections",
  CORRESPONDENT_BANK_AGENT_BANK: "Correspondent Bank/Agent Bank",
  CUSTODIAN: "Custodian",
  FULFILLMENT: "Fulfillment",
  INSURANCE_PROVIDER: "Insurance Provider",
  ORIGINATION: "Origination",
  SERVICING_OTHER: "Servicing - Other",
  SERVICING_PAYMENTS: "Servicing - Payments",
  SERVICING_STATEMENTS: "Servicing - Statements",
  TRANSACTION_PROCESSING_FINANCIAL_MARKET_UTILITIES:
    "Transaction Processing - Financial Market Utilities",
  TRANSACTION_PROCESSING_MONEY_SERVICES_BUSINESS:
    "Transaction Processing - Money Services Business",
  TRANSACTION_PROCESSING_PAYMENTS: "Transaction Processing - Payments",
  TREASURY_SERVICES: "Treasury Services",
};

export const FACILITIES_SUBCATEGORIES = {
  BUILDING_OPERATIONS: "Building Operations",
  MANAGEMENT_COMPANY: "Management Company",
};

export const HUMAN_RESOURCE_MANAGEMENT_SUBCATEGORIES = {
  COMPENSATION_AND_BENEFITS: "Compensation & Benefits",
  INSURANCE: "Insurance",
  RECRUITING: "Recruiting",
  RELOCATION: "Relocation",
  TRAINING: "Training",
};

export const INFORMATION_SERVICES_AND_TECHNOLOGY_SUBCATEGORIES = {
  APPLICATION_SERVICE_PROVIDER: "Application Service Provider (ASP)",
  CLOUD_SERVICES: "Cloud Services",
  DATA_CENTER_COLOCATION_SUPPORT_SERVICES:
    "Data Center - Colocation/Support Services",
  INFORMATION_DATA_SERVICES: "Information/Data Services",
  INFRASTRUCTURE_MAINTENANCE_AND_SUPPORT_SERVICES:
    "Infrastructure Maintenance & Support Services",
  IT_HARDWARE_AND_PHYSICAL_ASSETS: "IT Hardware and Physical Assets",
  MARKET_DATA: "Market Data",
  PHYSICAL_STORAGE_MANAGEMENT: "Physical Storage Management",
  SHRED_AND_RECORD_DESTRUCTION: "Shred and Record Destruction",
  SOFTWARE_DEVELOPMENT_AND_MAINTENANCE: "Software, Development & Maintenance",
};

export const MARKETING_COMMUNICATIONS_SUBCATEGORIES = {
  ADVERTISING_AND_MARKETING_AGENCIES: "Advertising & Marketing Agencies",
  DIGITAL_MARKETING: "Digital Marketing",
  MARKET_RESEARCH: "Market Research",
  MEDIA_RELATIONS: "Media Relations",
  PRINT_MARKETING: "Print Marketing",
  SPONSORSHIP: "Sponsorship",
  TELEMARKETING: "Telemarketing",
};

export const PROFESSIONAL_SERVICES_SUBCATEGORIES = {
  ADVISORY_CONSULTING: "Advisory/Consulting",
  AUDIT: "Audit",
  CONTINGENT_LABOR_CONTRACTOR: "Contingent Labor/Contractor",
  GOVERNMENT_DEPARTMENT_SERVICES: "Government Department Services",
  LEGAL_SERVICES: "Legal Services",
  OUTSOURCING_MANAGED_SERVICES: "Outsourcing/Managed Services",
  TAX: "Tax",
};

export const SHARED_SERVICES_SUBCATEGORIES = {
  ACCOUNTING_AND_FINANCE: "Accounting & Finance",
  CALL_CENTER_HELPDESK_CUSTOMER_FACING:
    "Call center/Helpdesk - Customer facing",
  CALL_CENTER_HELPDESK_INTERNAL_SUPPORT:
    "Call center/Helpdesk - Internal Support",
  PRINTING_SERVICES_CONFIDENTIAL: "Printing Services (Confidential)",
  PRINTING_SERVICES_NON_CONFIDENTIAL: "Printing Services (non-confidential)",
  SHIPPING_AND_LOGISTICS: "Shipping & Logistics",
  TRAVEL_AND_ENTERTAINMENT_SERVICES: "Travel & Entertainment Services",
};

export const SERVICE_CATEGORIES_TREE = [
  {
    category: SERVICE_CATEGORIES.BANKING,
    subcategories: Object.values(BANKING_SUBCATEGORIES),
  },
  {
    category: SERVICE_CATEGORIES.FACILITIES,
    subcategories: Object.values(FACILITIES_SUBCATEGORIES),
  },
  {
    category: SERVICE_CATEGORIES.HUMAN_RESOURCE_MANAGEMENT,
    subcategories: Object.values(HUMAN_RESOURCE_MANAGEMENT_SUBCATEGORIES),
  },
  {
    category: SERVICE_CATEGORIES.INFORMATION_SERVICES_AND_TECHNOLOGY,
    subcategories: Object.values(
      INFORMATION_SERVICES_AND_TECHNOLOGY_SUBCATEGORIES
    ),
  },
  {
    category: SERVICE_CATEGORIES.MARKETING_COMMUNICATIONS,
    subcategories: Object.values(MARKETING_COMMUNICATIONS_SUBCATEGORIES),
  },
  {
    category: SERVICE_CATEGORIES.PROFESSIONAL_SERVICES,
    subcategories: Object.values(PROFESSIONAL_SERVICES_SUBCATEGORIES),
  },
  {
    category: SERVICE_CATEGORIES.SHARED_SERVICES,
    subcategories: Object.values(SHARED_SERVICES_SUBCATEGORIES),
  },
];
