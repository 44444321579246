import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectToasts = createSelector(
  [selectSlice],
  (slice) => slice.toasts
);
