import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  companyId: null,
  data: null,
  isLoading: false,
  error: null,
  userInEditing: null,
  isAddModalOpen: false,
  modalContent: {
    thirdPartyRoles: null,
    thirdPartyContacts: null,
    areContactsLoading: false,
    selectedContact: null,
    wizardStep: 0,
    isLoading: false,
    isClosingModal: false,
    validationError: "",
    validatedContact: "",
    initialStoredValues: null,
  },
  availableThirdPartyRoles: [],
};

const slice = createSlice({
  name: "thirdPartyPeople",
  initialState,
  reducers: {
    loadThirdPartyUsers: (state, { payload }) => {
      state.data = null;
      state.companyId = payload.id;
      state.isLoading = true;
    },
    loadThirdPartyUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadThirdPartyUsersError: (state, action) => {
      state.companyId = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    loadRoles: () => {},
    loadRolesSuccess: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        thirdPartyRoles: action.payload,
      };

      state.availableThirdPartyRoles = action.payload;
    },
    loadRolesError: (state, action) => {
      state.error = action.payload;
      state.modalContent = {
        ...state.modalContent,
        thirdPartyRoles: [],
      };
    },
    addUser: (state) => {
      state.isLoading = true;
      state.modalContent.isClosingModal = true;
    },
    addThirdPartySuccess: (state, action) => {
      state.data = [...state.data, action.payload];
      state.isLoading = false;
    },
    addThirdPartyError: (state) => {
      state.isLoading = false;
    },
    loadContacts: (state) => {
      state.modalContent = {
        ...state.modalContent,
        areContactsLoading: true,
      };
    },
    loadContactsSuccess: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        thirdPartyContacts: action.payload,
        areContactsLoading: false,
      };
    },
    loadContactsError: (state) => {
      state.modalContent = {
        ...state.modalContent,
        thirdPartyContacts: [],
        areContactsLoading: false,
      };
    },
    validateUserEmail: (state) => {
      state.modalContent.isLoading = true;
    },
    validateUserEmailSuccess: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        validationError: "",
        validatedContact: "",
        selectedContact: action.payload.email,
        wizardStep: 1,
        isLoading: false,
      };
    },
    validateUserEmailError: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        validationError: action.payload.Title,
        validatedContact: action.payload.email,
        isLoading: false,
      };
    },
    setWizardStep: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        wizardStep: action.payload,
      };
    },
    clearModalContent: (state) => {
      state.userInEditing = null;
      state.modalContent = {
        thirdPartyRoles: null,
        thirdPartyContacts: null,
        selectedContact: null,
        wizardStep: 0,
        isLoading: false,
        validationError: "",
        isClosingModal: false,
        initialStoredValues: null,
      };
    },
    updateUser: (state, { payload }) => {
      state.modalContent.isLoading = true;
      let userDetails = state.data.find((user) => user.id === payload.userId);
      userDetails.firstName = payload.updatedDetails.FirstName;
      userDetails.lastName = payload.updatedDetails.LastName;
      userDetails.title = payload.updatedDetails.Title;
      userDetails.location = payload.updatedDetails.Location;
      userDetails.isDisabled = payload.updatedDetails.isDisabled;
    },
    updateUserSuccess: (state) => {
      state.modalContent.isLoading = false;
      state.modalContent.isClosingModal = true;
    },
    updateUserError: (state) => {
      state.modalContent.isLoading = false;
      state.modalContent.isClosingModal = true;
    },
    setIsAddModalOpen: (state, { payload }) => {
      state.isAddModalOpen = payload;
    },
    setInitialModalValues: (state, { payload }) => {
      state.modalContent = {
        ...state.modalContent,
        initialStoredValues: payload,
      };
    },
    setUserInEditing: (state, { payload }) => {
      state.userInEditing = payload;
    },
  },
});

export const {
  loadThirdPartyUsers,
  loadThirdPartyUsersSuccess,
  loadThirdPartyUsersError,
  loadRoles,
  loadRolesSuccess,
  loadRolesError,
  addUser,
  addThirdPartySuccess,
  addThirdPartyError,
  loadContacts,
  loadContactsSuccess,
  loadContactsError,
  validateUserEmail,
  validateUserEmailSuccess,
  validateUserEmailError,
  setWizardStep,
  clearModalContent,
  updateUser,
  updateUserSuccess,
  updateUserError,
  setIsAddModalOpen,
  setInitialModalValues,
  setUserInEditing,
} = slice.actions;

export default slice;
