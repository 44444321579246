import { kebabCase } from "lodash";

import formInfoSlice from "services/form/slice";
import customTemplateSlice from "pages/TemplatesAndMappingPage/CustomTemplatePage/services/slice";
import tagCollectionSlice from "pages/TemplatesAndMappingPage/TagCollectionPage/services/slice";
import { FORM_REDUCER_KEYS } from "app/constants";
import {
  CUSTOM_TEMPLATE_REDUCER_KEYS,
  CUSTOM_TEMPLATE_NAMESPACE,
} from "pages/TemplatesAndMappingPage/CustomTemplatePage/utils/constants";
import { PROMPT_KEYS } from "app/constants";
import routes from "app/routes";
import { TAG_COLLECTION_NAMESPACE } from "pages/TemplatesAndMappingPage/TagCollectionPage/utils/constants";

export const userPromptController = (payload, callback, store) => {
  const { destinationPathname, currentPathname, action, message, promptKey } =
    JSON.parse(payload);
  // We treat default location pathname as invalid as it can trigger unwanted
  // prompts appearing since application thinks that the user is navigating out of page when some form is dirty
  const defaultLocationPathname = "/";

  if (
    currentPathname !== destinationPathname &&
    destinationPathname !== defaultLocationPathname
  ) {
    switch (promptKey) {
      case PROMPT_KEYS.customTemplate:
        handleCustomTemplatePrompt(
          store,
          destinationPathname,
          message,
          callback
        );
        break;
      case PROMPT_KEYS.tagCollection:
        handleTagCollectionPrompt(
          store,
          destinationPathname,
          message,
          callback
        );
        break;
      default:
        handlePrompt(message, callback, store, action);
        break;
    }
  }
};

const handleCustomTemplatePrompt = (
  store,
  destinationPathname,
  message,
  callback
) => {
  const state = store.getState();
  const customTemplatePath = routes.customTemplate.path.replace(
    ":id",
    state[CUSTOM_TEMPLATE_NAMESPACE].data.id
  );
  const customTemplatePaths = [customTemplatePath];
  state[CUSTOM_TEMPLATE_NAMESPACE].domainCategories.forEach((category) => {
    const path = `${customTemplatePath}/${kebabCase(category.domainCategory)}`;
    customTemplatePaths.push(path);
  });

  if (!customTemplatePaths.includes(destinationPathname)) {
    const allowTransition = window.confirm(message);
    callback(allowTransition);

    if (allowTransition) {
      store.dispatch({
        type: `${customTemplateSlice.name}/${CUSTOM_TEMPLATE_REDUCER_KEYS.cancelTemplateChanges}`,
      });
    }
  } else {
    callback(true);
  }
};

const handleTagCollectionPrompt = (
  store,
  destinationPathname,
  message,
  callback
) => {
  const state = store.getState();
  const tagCollectionPath = routes.tagCollection.path.replace(
    ":id",
    state[TAG_COLLECTION_NAMESPACE].data.id
  );
  const tagCollectionPaths = [tagCollectionPath];
  state[TAG_COLLECTION_NAMESPACE].domainCategories.forEach((category) => {
    const path = `${tagCollectionPath}/${kebabCase(category.domainCategory)}`;
    tagCollectionPaths.push(path);
  });

  if (!tagCollectionPaths.includes(destinationPathname)) {
    const allowTransition = window.confirm(message);
    callback(allowTransition);

    if (allowTransition) {
      store.dispatch({
        type: `${tagCollectionSlice.name}/${CUSTOM_TEMPLATE_REDUCER_KEYS.cancelTemplateChanges}`,
      });
    }
  } else {
    callback(true);
  }
};

const handlePrompt = (message, callback, store, action) => {
  const allowTransition = window.confirm(message);
  callback(allowTransition);

  if (allowTransition) {
    store.dispatch({
      type: `${formInfoSlice.name}/${FORM_REDUCER_KEYS.setIsDirty}`,
      payload: { value: false, key: null },
    });
    store.dispatch({
      type: `${formInfoSlice.name}/${FORM_REDUCER_KEYS.setIsCustomCommentDirty}`,
      payload: { value: false },
    });
  }

  // Currently there is a bug with some browsers that they don't stop history stack from being popped on cancel of prompt message
  // this will return most recent url in case of cancel action of prompt
  if (!allowTransition && action === "POP") {
    window.history.forward();
  }
};
