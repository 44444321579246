import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./checkbox.scss";
import { ReactComponent as CheckboxChecked } from "assets/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "assets/checkbox-unchecked.svg";

const Checkbox = ({
  isAutoFocus,
  isChecked,
  onChange,
  onBlur,
  value,
  isDisabled,
  isDark,
  isFocusable,
  dataTestId,
}) => {
  return (
    <label
      className={classNames("checkbox", {
        "checkbox--is-dark": isDark,
      })}
    >
      <input
        autoFocus={isAutoFocus}
        className="checkbox__input visually-hidden"
        checked={isChecked}
        onChange={onChange}
        onBlur={onBlur}
        type="checkbox"
        value={value}
        disabled={isDisabled}
        tabIndex={isFocusable ? 0 : -1}
        data-testid={dataTestId}
      />
      <div className="checkbox__icon-wrapper">
        {isChecked ? (
          <CheckboxChecked
            className={classNames("checkbox__icon", {
              "checkbox__icon--disabled": isDisabled,
            })}
          />
        ) : (
          <CheckboxUnchecked
            className={classNames("checkbox__icon checkbox__icon--unchecked", {
              "checkbox__icon--disabled": isDisabled,
            })}
          />
        )}
      </div>
      <span
        className={classNames("checkbox__label", {
          "checkbox__label--disabled": isDisabled,
        })}
        data-testid="checkbox-label"
      >
        {value}
      </span>
    </label>
  );
};

Checkbox.defaultProps = {
  isAutoFocus: false,
  isChecked: false,
  onBlur: () => {},
  isDisabled: false,
  isDark: false,
  isFocusable: true,
  value: "",
  onChange: () => {},
  dataTestId: "checkbox",
};

Checkbox.propTypes = {
  isAutoFocus: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDark: PropTypes.bool,
  isFocusable: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default Checkbox;
