import { DEFAULT_LOCALE } from "components/LanguageProvider/utils/constants";

export const stringSortingComparator = (valueA, valueB) => {
  if (valueA && valueB) {
    return valueA.trim().localeCompare(valueB.trim(), DEFAULT_LOCALE, {
      numeric: true,
      sensitivity: "accent",
    });
  } else if (valueA) {
    return 1;
  } else if (valueB) {
    return -1;
  }
};
