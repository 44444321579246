import { all, put, takeLatest, call } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { showError } from "utils/sagaEffects/sagaEffects";
import {
  submitCreateTagsCollection,
  setCreateTagsCollectionModalOpen,
  setIsCreateTagsCollectionSubmitting,
  duplicateTagsCollection,
  setDuplicateTagsCollectionModalOpen,
  loadTagsCollections,
  loadTagsCollectionsSuccess,
  loadTagsCollectionsError,
  setIsDuplicating,
  deleteTagsCollection,
  setIsDeleting,
} from "./slice";
import { setNewTagsCollectionId } from "pages/TemplatesAndMappingPage/services/slice";
import { setIsDirty } from "services/form/slice";
import { addToast } from "services/toast/slice";

function* loadTagsAndCollectionsSaga({ payload: { accessToken } }) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadTagsCollectionsSuccess(data));
  } catch ({ message }) {
    yield put(loadTagsCollectionsError(message));
  }
}

function* submitCreateTagsCollectionSaga({ payload }) {
  try {
    const { accessToken, body } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    const data = yield response.json();
    if (response.status !== 200) {
      if (response.status === 400) {
        throw new Error(data.Title);
      }
      throw new Error();
    }
    yield put(setIsDirty(false));
    yield put(setIsCreateTagsCollectionSubmitting(false));
    yield put(setNewTagsCollectionId(data));
    yield put(setCreateTagsCollectionModalOpen(false));
  } catch ({ message }) {
    yield showError(message);
    yield put(setIsCreateTagsCollectionSubmitting(false));
  }
}

function* duplicateTagsCollectionSaga({ payload }) {
  try {
    const { id, accessToken, redirect } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections/${id}/duplicate`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    const data = yield response.json();
    yield put(setIsDuplicating(false));
    if (response.status !== 200) {
      throw new Error(data.Title);
    }

    yield put(setDuplicateTagsCollectionModalOpen(false));

    if (redirect) {
      yield put(setNewTagsCollectionId(data));
    } else {
      const toastInfo = {
        description: "Tag collection duplicated",
      };
      yield put(addToast(toastInfo));
      yield call(loadTagsAndCollectionsSaga, { payload: { accessToken } });
    }
  } catch ({ message }) {
    yield showError(message);
  }
}

function* deleteCollectionSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/tags-collections/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to delete");
    }

    const toastInfo = {
      description: "Tag collection deleted",
    };

    yield put(setIsDeleting(false));
    yield put(addToast(toastInfo));
    yield call(loadTagsAndCollectionsSaga, { payload: { accessToken } });
  } catch ({ message }) {
    yield showError(message);
  }
}

export default function* tagsAndCollectionsSagas() {
  yield all([
    yield takeLatest(
      submitCreateTagsCollection.type,
      submitCreateTagsCollectionSaga
    ),
    yield takeLatest(duplicateTagsCollection.type, duplicateTagsCollectionSaga),
    yield takeLatest(loadTagsCollections.type, loadTagsAndCollectionsSaga),
    yield takeLatest(deleteTagsCollection.type, deleteCollectionSaga),
  ]);
}
