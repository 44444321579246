import globalMessages from "app/messages";
import commentBuilderMessages from "pages/AssessmentPage/BPQ/components/BPQCommentBuilder/utils/messages";
import { FORM_PROMPT_MESSAGE_KEYS } from "app/constants";

export const setupPromptMessage = (
  location,
  action,
  promptMessageKey,
  intl,
  promptKey
) =>
  JSON.stringify({
    destinationPathname: location.pathname,
    currentPathname: window.location.pathname,
    action,
    message: setupMessage(promptMessageKey, intl),
    promptKey,
  });

export const setupMessage = (promptMessageKey, intl) => {
  switch (promptMessageKey) {
    case FORM_PROMPT_MESSAGE_KEYS.commentBuilderDrawerCustomComment:
      return intl.formatMessage(commentBuilderMessages.customCommentUnsaved);
    case FORM_PROMPT_MESSAGE_KEYS.commentBuilderDrawer:
      return intl.formatMessage(
        commentBuilderMessages.commentBuilderDrawerUnsaved
      );
    default:
      return intl.formatMessage(globalMessages.unsavedChangesWarning);
  }
};
