import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectBreadcrumbs = createSelector(
  [selectSlice],
  (slice) => slice.breadcrumbs
);

export const selectText = createSelector([selectSlice], (slice) => slice.text);
