import { defineMessages } from "react-intl";

const scope = "pages.ManageCustomersPage.";

export default defineMessages({
  exportPossibleMatches: {
    id: `${scope}exportPossibleMatches`,
    defaultMessage: "Export Possible Matches",
  },
});
