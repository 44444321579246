import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { sanitize } from "dompurify";

import Button from "components/GlobalComponents/Buttons/Button";
import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { removeToast } from "services/toast/slice";
import { TOAST_TYPES } from "app/constants";
import { getToastIcon } from "./utils/utils";
import "./toast.scss";

const Toast = ({
  autoWidth,
  buttonText,
  customIcon,
  description,
  hasButton,
  id,
  isPausedInitial,
  isLink,
  onClick,
  showCloseButton,
  to,
  type,
}) => {
  const dispatch = useDispatch();
  const toastRef = useRef();
  const toastButton = useRef();
  const [isHidden, setIsHidden] = useState(false);
  const [isPaused, setIsPaused] = useState(isPausedInitial);
  const toastIcon = getToastIcon(type, customIcon);
  const isError = type === TOAST_TYPES.error;
  const isEmailPaperPlane = type === TOAST_TYPES.emailPaperPlane;

  useEffect(() => {
    const toast = toastRef.current;
    if (toast && toast.contains(document.activeElement)) {
      setIsPaused(true);
    }
  }, [isPaused]);

  useEffect(() => {
    const toast = toastRef.current;

    function handleClose() {
      dispatch(removeToast({ id }));
    }

    function handlePause() {
      if (!isPaused) {
        setIsPaused(true);
      }
    }

    function handleFocusOut() {
      setIsPaused(false);
    }

    function handleMouseLeave() {
      if (!toast.contains(document.activeElement)) {
        setIsPaused(false);
      }
    }

    function handleProgress(e) {
      if (e.animationName === "progress") {
        closeToast();
      }
    }

    if (toast) {
      toast.addEventListener("mouseenter", handlePause);
      toast.addEventListener("mouseleave", handleMouseLeave);
      toast.addEventListener("focusin", handlePause);
      toast.addEventListener("focusout", handleFocusOut);
      toast.addEventListener("animationend", handleProgress);
      if (isHidden) {
        toast.addEventListener("transitionend", handleClose);
      }
    }

    return () => {
      toast.removeEventListener("mouseenter", handlePause);
      toast.removeEventListener("mouseleave", handleMouseLeave);
      toast.removeEventListener("focusin", handlePause);
      toast.removeEventListener("focusout", handleFocusOut);
      toast.removeEventListener("animationend", handleProgress);
      toast.removeEventListener("transitionend", handleClose);
    };
  });

  const closeToast = () => {
    setIsHidden(true);
  };

  return (
    <div
      aria-label="information"
      aria-live="assertive"
      className={classNames("toast", {
        "toast--is-hidden": isHidden,
        "toast--is-paused": isPaused,
        "toast--error": isError,
        "toast--auto-width": autoWidth,
      })}
      data-testid="toast"
      ref={toastRef}
      role="alertdialog"
    >
      <div className="toast__content-wrapper">
        {toastIcon}
        <p
          className={classNames("toast__description paragraph", {
            "toast__description--no-padding-top": isEmailPaperPlane,
          })}
          data-testid="toast-description"
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        />
        <div className="toast__controls">
          {hasButton && (
            <Button
              className="toast__btn"
              data-testid="toast-button"
              isLink={isLink}
              onClick={isLink ? closeToast : onClick}
              ref={toastButton}
              to={to}
            >
              {buttonText}
            </Button>
          )}
          {showCloseButton && (
            <ButtonIcon
              className="toast__btn-icon"
              data-testid="toast-close-button"
              icon={<CloseIcon />}
              label={"Close notification"}
              onClick={closeToast}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  autoWidth: false,
  buttonText: "",
  customIcon: "",
  hasButton: false,
  isPausedInitial: true,
  isLink: false,
  onClick: () => {},
  showCloseButton: true,
  to: "",
  type: TOAST_TYPES.info,
};

Toast.propTypes = {
  autoWidth: PropTypes.bool,
  buttonText: PropTypes.string,
  customIcon: PropTypes.string,
  description: PropTypes.string.isRequired,
  hasButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isPausedInitial: PropTypes.bool,
  isLink: PropTypes.bool,
  onClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TOAST_TYPES)),
};

export default Toast;
