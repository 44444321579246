import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { ReactComponent as CsvIcon } from "assets/csv.svg";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { downloadTemplate } from "components/UploadThirdPartiesModal/services/slice";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import Button from "components/GlobalComponents/Buttons/Button";
import messages from "../../messages";
import "./download-template.scss";

const DownloadTemplate = ({ organizationId, dispatchGoNext }) => {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const intl = useIntl();

  const download = () => {
    dispatch(
      downloadTemplate({
        organizationId,
        accessToken,
      })
    );
  };

  return (
    <div>
      <div data-testid="download-template-div" className="download-template">
        <button
          data-testid="download-template-btn"
          className="download-template__btn"
          onClick={download}
          type="button"
        >
          <span
            data-testid="download-template-btn-content"
            className="download-template__btn-content"
          >
            <CsvIcon className="download-template__csv-icon" />
            <div
              data-testid="download-template-btn-text-wrapper"
              className="download-template__btn-text-wrapper"
            >
              <span
                data-testid="download-template-btn-text"
                className="download-template__btn-text"
              >
                {intl.formatMessage(messages.thirdPartyTemplateName)}
              </span>
              <span
                data-testid="download-template-btn-text-secondary"
                className="download-template__btn-text download-template__btn-text--secondary"
              >
                {intl.formatMessage(messages.thirdPartyTemplateVersion)}
              </span>
            </div>
            <DownloadIcon className="download-template__download-icon" />
          </span>
        </button>
        <p
          data-testid="download-template-description"
          className="download-template__description paragraph"
        >
          {intl.formatMessage(messages.downloadTemplateDescription)}
        </p>
      </div>
      <div
        data-testid="upload-third-parties-controls"
        className="upload-third-parties__controls upload-third-parties__controls--flex-end"
      >
        <p
          data-testid="upload-third-parties-description"
          className="upload-third-parties__description paragraph"
        >
          {intl.formatMessage(messages.downloadTemplateDescriptionParagraph)}
        </p>
        <Button
          data-testid="upload-third-parties-btn"
          className="upload-third-parties__btn"
          onClick={dispatchGoNext}
          isLarge
          isLight
          isPrimary
        >
          {intl.formatMessage(messages.continueToUpload)}
        </Button>
      </div>
    </div>
  );
};

DownloadTemplate.propTypes = {
  organizationId: PropTypes.number,
  dispatchGoNext: PropTypes.func.isRequired,
};
export default DownloadTemplate;
