import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useIntl } from "react-intl";

import componentMessages from "./messages";
import { ReactComponent as StarIcon } from "assets/star.svg";
import Button from "components/GlobalComponents/Buttons/Button";

const WatchButton = ({ assessmentDetails, watch, unwatch }) => {
  const isAssessmentWatched = assessmentDetails.isWatched;
  const intl = useIntl();

  return (
    <Button
      dataTestIdPrefix="watch"
      onClick={isAssessmentWatched ? unwatch : watch}
      className="drawer__header-btn"
      isSmall
      leadingIcon={
        <StarIcon
          className={classNames({
            "drawer__star-icon--is-watched": isAssessmentWatched,
            "drawer__star-icon--not-watched": !isAssessmentWatched,
          })}
        />
      }
    >
      {isAssessmentWatched
        ? intl.formatMessage(componentMessages.watching)
        : intl.formatMessage(componentMessages.watch)}
    </Button>
  );
};

WatchButton.defaultProps = {
  assessmentDetails: {
    isWatched: false,
  },
};

WatchButton.propTypes = {
  assessmentDetails: PropTypes.object,
  unwatch: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};

export default WatchButton;
