import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "qcFlagDrawer",
  initialState: {
    isOpen: false,
    data: null,
    loading: false,
  },
  reducers: {
    setQCFlagDrawerOpen: (state, { payload }) => {
      state.isOpen = payload.isOpen;
      if (payload.drawerData) {
        state.data = payload.drawerData;
      }
    },
    saveFlag: (state, action) => {
      state.loading = true;
    },
    saveFlagSuccess: (state, { payload }) => {
      state.loading = false;
    },
    saveFlagError: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { setQCFlagDrawerOpen, saveFlag, saveFlagSuccess, saveFlagError } =
  slice.actions;

export default slice;
