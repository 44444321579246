import moment from "moment";
import momentTimezone from "moment-timezone";

import { DATE_FORMATS, momentTimezones } from "app/constants";

export const dateFormatter = (value) => {
  return value ? new Date(value).toLocaleDateString("en-US") : "";
};

export const dateTimeFormatter = (value) => {
  return value ? new Date(value).toLocaleString("en-US") : "";
};

export const utcToLocalDateTimeFormatter = (
  value,
  dateFormat = DATE_FORMATS.dateTime
) => {
  return value ? moment.utc(value).local().format(dateFormat) : "";
};

export const getFormattedDateAccordingToTimezone = (date, timezone) => {
  if (date) {
    const utcDate = moment.utc(date);
    if (timezone) {
      return momentTimezone(utcDate).tz(timezone);
    } else {
      return utcDate;
    }
  }
};

export const getUSESTFormatDate = (date) =>
  getFormattedDateAccordingToTimezone(date, momentTimezones.USEastern);
