import { createSlice } from "@reduxjs/toolkit";
import { uniqBy, isEqual } from "lodash";

import {
  CUSTOM_TEMPLATE_REDUCER_KEYS,
  CUSTOM_TEMPLATE_NAMESPACE,
} from "pages/TemplatesAndMappingPage/CustomTemplatePage/utils/constants";

const initialState = {
  data: null,
  domainCategories: null,
  initialTemplate: null,
  modals: {
    templateSettingsModal: {
      isOpen: false,
      isSaving: false,
    },
    saveTemplateModal: {
      isOpen: false,
    },
    cancelTemplateChangesModal: {
      isOpen: false,
    },
  },
  loading: false,
  error: null,
  saving: false,
  editableTitleHasErrors: false,
  templateChangesDetected: false,
};

const slice = createSlice({
  name: CUSTOM_TEMPLATE_NAMESPACE,
  initialState,
  reducers: {
    loadCustomTemplate: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadCustomTemplateSuccess: (state, { payload }) => {
      state.domainCategories = uniqBy(payload.questions, "domainCategory");
      state.loading = false;
      state.data = payload;
      state.initialTemplate = payload;
      state.error = null;
    },
    loadCustomTemplateError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [CUSTOM_TEMPLATE_REDUCER_KEYS.cancelTemplateChanges]: (state) => {
      state.data = state.initialTemplate;
      state.templateChangesDetected = false;
    },
    setTemplateSettingsModalOpen: (state, { payload }) => {
      state.modals.templateSettingsModal.isOpen = payload;
    },
    saveTemplateSettings: (state) => {
      state.modals.templateSettingsModal.isSaving = true;
    },
    saveTemplateSettingsSuccess: (state, { payload }) => {
      state.data.isPublished = payload.isPublished;
      state.data.description = payload.description;
      state.data.modifiedOn = payload.modifiedOn;
      state.modals.templateSettingsModal.isSaving = false;
      state.modals.templateSettingsModal.isOpen = false;
    },
    saveTemplateSettingsError: (state) => {
      state.modals.templateSettingsModal.isSaving = false;
    },
    setEditableTitleErrorState: (state, { payload }) => {
      state.editableTitleHasErrors = payload;
    },
    setNewTitle: (state, { payload }) => {
      state.data.name = payload;
    },
    setSaveTemplateModalOpen: (state, { payload }) => {
      state.modals.saveTemplateModal.isOpen = payload;
    },
    setCancelTemplateChangesModalOpen: (state, { payload }) => {
      state.modals.cancelTemplateChangesModal.isOpen = payload;
    },
    saveCustomTemplate: (state) => {
      state.saving = true;
    },
    saveCustomTemplateSuccess: (state, action) => {
      if (action.payload.modifiedOn) {
        state.data.modifiedOn = action.payload.modifiedOn;
      }
      state.data.isPublished = action.payload.isPublished;
      state.saving = false;
      state.initialTemplate = state.data;
      state.templateChangesDetected = false;
    },
    saveCustomTemplateError: (state) => {
      state.saving = false;
    },
    setCustomTemplate: (state, { payload }) => {
      if (payload?.serialId) {
        let question;
        if (payload?.questionSerialId) {
          const parentQuestion = state.data.questions.find(
            (question) => question.serialId === payload.questionSerialId
          );
          question = parentQuestion.checklist.find(
            (designElement) => designElement.serialId === payload.serialId
          );
          if (!parentQuestion.isIncluded && payload.isIncluded) {
            parentQuestion.isIncluded = payload.isIncluded;
          }
        } else {
          question = state.data.questions.find(
            (question) => question.serialId === payload.serialId
          );
        }
        question.isIncluded = payload.isIncluded;
        question.checklist?.forEach(
          (designElement) => (designElement.isIncluded = payload.isIncluded)
        );
      } else {
        const questions = state.data.questions.filter(
          (question) => question.domain === payload.domain
        );
        questions.forEach((question) => {
          question.isIncluded = payload.isIncluded;
          question.checklist?.forEach(
            (designElement) => (designElement.isIncluded = payload.isIncluded)
          );
        });
      }
      state.templateChangesDetected = !isEqual(
        state.data,
        state.initialTemplate
      );
    },
    resetCustomTemplateStore: () => initialState,
  },
});

export const {
  loadCustomTemplate,
  loadCustomTemplateSuccess,
  loadCustomTemplateError,
  saveCustomTemplate,
  saveCustomTemplateSuccess,
  saveCustomTemplateError,
  setCustomTemplate,
  resetCustomTemplateStore,
  setTemplateSettingsModalOpen,
  saveTemplateSettings,
  saveTemplateSettingsSuccess,
  saveTemplateSettingsError,
  setEditableTitleErrorState,
  setNewTitle,
  setSaveTemplateModalOpen,
  setCancelTemplateChangesModalOpen,
  cancelTemplateChanges,
} = slice.actions;

export default slice;
