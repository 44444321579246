export const KEYS = {
  tab: {
    code: "Tab",
    keyCode: 9,
  },
  enter: {
    code: "Enter",
    keyCode: 13,
  },
  escape: {
    code: "Escape",
    keyCode: 27,
  },
  space: {
    code: "Space",
    keyCode: 32,
  },
  end: {
    code: "End",
    keyCode: 35,
  },
  home: {
    code: "Home",
    keyCode: 36,
  },
  arrowLeft: {
    code: "ArrowLeft",
    keyCode: 37,
  },
  arrowUp: {
    code: "ArrowUp",
    keyCode: 38,
  },
  arrowRight: {
    code: "ArrowRight",
    keyCode: 39,
  },
  arrowDown: {
    code: "ArrowDown",
    keyCode: 40,
  },
  keyB: {
    code: "B",
    keyCode: 66,
  },
  keyD: {
    code: "D",
    keyCode: 68,
  },
};
