import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectPurchasableTemplates = createSelector(
  [selectSlice],
  (slice) => slice.purchasableTemplates
);

export const selectIsPurchasableTemplatesLoading = createSelector(
  [selectSlice],
  (slice) => slice.loading
);
