import { takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import { downloadQuestionArtifacts } from "./slice";
import { showError } from "utils/sagaEffects/sagaEffects";
import {
  updateDownloadFileName,
  downloadFile,
} from "utils/handleDownload/handleDownload";

function* downloadQuestionArtifactSaga({ payload }) {
  const queryParams = new URLSearchParams();
  queryParams.append("isRedacted", payload.isRedacted);
  payload.artifactIds.forEach((artifactId) => {
    queryParams.append("artifactIds", artifactId);
  });
  const url =
    process.env.REACT_APP_API_URL +
    `/assessments/${payload.assessmentId}/artifacts/download?${queryParams}`;

  try {
    const response = yield fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + payload.accessToken,
      },
    });
    if (response.status === 200) {
      downloadFile(response, updateDownloadFileName(response));
    } else {
      const data = yield response.json();
      yield showError(data?.Title);
    }
  } catch {
    yield showError();
  }
}

export default function* questionArtifactsSagas() {
  yield all([
    takeLatest([downloadQuestionArtifacts.type], downloadQuestionArtifactSaga),
  ]);
}
