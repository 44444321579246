import PropTypes from "prop-types";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import { useDispatch, useSelector } from "react-redux";

import { downloadAssessment } from "pages/CatalogPage/services/slice";
import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { selectDownloadingIds } from "pages/CatalogPage/services/selectors";
import { selectPermissions } from "components/AuthorizedComponent/selectors";
import Spinner from "components/Spinner";
import { PERMISSIONS } from "components/AuthorizedRoute/utils/constants";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import "./assessment-status-notification.scss";

const AssessmentStatusChangedNotification = ({
  createdAt,
  messageData,
  onClick,
}) => {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const downloadingIds = useSelector(selectDownloadingIds);
  let isDownloading = downloadingIds.includes(messageData.AssessmentId);
  const permissions = useSelector(selectPermissions);

  const download = (productId, accessToken, fileName) => {
    dispatch(downloadAssessment({ productId, accessToken, fileName }));
  };

  return (
    <div className="assessment-status-changed-notification">
      <button
        className="assessment-status-changed-notification__details-button"
        type="button"
        onClick={() => onClick(messageData.AssessmentId)}
      >
        <span className="assessment-status-changed-notification__details paragraph">
          <strong>{messageData.ThirdPartyName}</strong> {messageData.Year}{" "}
          {messageData.Type} assessment is now{" "}
          {messageData.IsPurchased && messageData.IsAuthorized && (
            <>
              {messageData.Status} <strong>and ready for download </strong>
            </>
          )}
          {(!messageData.IsPurchased || !messageData.IsAuthorized) && (
            <strong>{messageData.Status} </strong>
          )}
        </span>
        <ReactTimeAgo
          className="assessment-status-changed-notification__time"
          date={new Date(createdAt)}
        />
        <span className="visually-hidden">Show assessment details</span>
      </button>
      {messageData.IsPurchased &&
        permissions &&
        permissions.includes(PERMISSIONS.CanPurchase) && (
          <div className="assessment-status-changed-notification__button-wrapper">
            {!isDownloading && (
              <ButtonIcon
                className="assessment-status-changed-notification__btn-icon"
                icon={<DownloadIcon />}
                isDisabled={!messageData.IsAuthorized}
                label={"Download assessment"}
                onClick={() =>
                  download(
                    messageData.AssessmentId,
                    accessToken,
                    messageData.ThirdPartyName + ".zip"
                  )
                }
              />
            )}
            {isDownloading && (
              <Spinner
                className="assessment-status-changed-notification__spinner"
                isLight
              />
            )}
          </div>
        )}
    </div>
  );
};

export default AssessmentStatusChangedNotification;

AssessmentStatusChangedNotification.propTypes = {
  messageData: PropTypes.exact({
    AssessmentId: PropTypes.string,
    ThirdPartyName: PropTypes.string,
    Year: PropTypes.number,
    Type: PropTypes.string,
    Status: PropTypes.string,
    IsPurchased: PropTypes.bool,
    IsAuthorized: PropTypes.bool,
  }).isRequired,
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
