import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  getPermissions,
  setPermissionsSuccess,
  setPermissionsError,
  getOrganizationType,
  setOrganizationTypeSuccess,
  getStages,
  getStagesSuccess,
  getStagesError,
  setIsOrganizationTypeFailed,
  setOrganizationProductSuccess,
  getOrganizationProductMappings,
  setOrganizationProductMappingsFailed,
} from "./slice";
import { trackTrace, trackException } from "services/appInsights";

const getTokenClaims = (accessToken) => {
  const tokenClaims = JSON.parse(atob(accessToken.split(".")[1]));

  return {
    UserName: tokenClaims["sub"],
    OktaUserGroups: tokenClaims["user-groups"],
  };
};

export function* getPermissionsSaga({ payload: { accessToken } }) {
  const tokenClaims = getTokenClaims(accessToken);

  try {
    trackTrace("GetPermissions method started", {
      ...tokenClaims,
    });
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/permissions",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status !== 200) {
      const responseBody = yield response.json();
      const errorCode = responseBody && responseBody.errorCode;
      throw new Error(errorCode || "Failed to load");
    }
    const permissions = yield response.json();
    trackTrace("GetPermissions method completed", {
      ...tokenClaims,
      permissions,
    });

    yield put(setPermissionsSuccess(permissions || []));
  } catch (error) {
    trackException(error, {
      Message: "GetPermissions method failed",
      ...tokenClaims,
    });
    yield put(setPermissionsError((error && error.message) || error));
  }
}

export function* getOrganizationTypeSaga({ payload: { accessToken } }) {
  const tokenClaims = getTokenClaims(accessToken);

  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/organizations/type",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      const error = yield response.json();
      throw Error(error || "Failed to load");
    }

    const types = yield response.json();
    yield put(setOrganizationTypeSuccess(types || []));
  } catch (error) {
    trackException(error, {
      Message: "GetOrganizationTypes method failed",
      ...tokenClaims,
    });
    yield put(setIsOrganizationTypeFailed((error && error.message) || error));
  }
}

export function* getStagesSaga({ payload: { accessToken } }) {
  const tokenClaims = getTokenClaims(accessToken);

  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/classifiers/AssessmentStatus/items",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      throw Error("Failed to load");
    }

    const stages = yield response.json();
    yield put(getStagesSuccess(stages || []));
  } catch (error) {
    trackException(error, {
      Message: "GetStages method failed",
      ...tokenClaims,
    });
    yield put(getStagesError());
  }
}

export function* getOrganizationProductMappingsSaga({
  payload: { accessToken },
}) {
  const tokenClaims = getTokenClaims(accessToken);

  try {
    const response = yield call(
      fetch,
      process.env.REACT_APP_API_URL + "/organizations/product-mappings",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (response.status !== 200) {
      const error = yield response.json();
      throw Error(error || "Failed to load");
    }

    const mappings = yield response.json();
    yield put(setOrganizationProductSuccess(mappings));
  } catch (error) {
    trackException(error, {
      Message: "Get organization product mappings method failed",
      ...tokenClaims,
    });
    yield put(
      setOrganizationProductMappingsFailed((error && error.message) || error)
    );
  }
}

export default function* messagesSagas() {
  yield all([
    yield takeLatest(getPermissions.type, getPermissionsSaga),
    yield takeLatest(getOrganizationType.type, getOrganizationTypeSaga),
    yield takeLatest(getStages.type, getStagesSaga),
    yield takeLatest(
      getOrganizationProductMappings.type,
      getOrganizationProductMappingsSaga
    ),
  ]);
}
