import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  loading: false,
  error: null,
  inviteTruSightUser: {
    roles: [],
    rolesLoading: false,
    rolesError: null,
    externalOrganizations: [],
    externalOrganizationsLoading: false,
    externalOrganizationsError: null,
    formSubmitErrors: {},
    isFormSubmitting: false,
    isFormSubmitSuccess: false,
  },
};

const slice = createSlice({
  name: "truSightUsers",
  initialState,
  reducers: {
    loadTruSightUsersData: (state) => {
      state.data = null;
      state.loading = true;
    },
    loadTruSightUsersDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadTruSightUsersDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //AddTruSightUserModal
    loadRoles: (state) => {
      state.inviteTruSightUser.roles = [];
      state.inviteTruSightUser.rolesLoading = true;
    },
    loadRolesSuccess: (state, action) => {
      state.inviteTruSightUser.rolesLoading = false;
      state.inviteTruSightUser.roles = action.payload;
      state.inviteTruSightUser.rolesError = null;
    },
    loadRolesError: (state, action) => {
      state.inviteTruSightUser.rolesLoading = false;
      state.inviteTruSightUser.rolesError = action.payload;
    },
    loadExternalOrganizations: (state) => {
      state.inviteTruSightUser.externalOrganizations = [];
      state.inviteTruSightUser.externalOrganizationsLoading = true;
    },
    loadExternalOrganizationsSuccess: (state, action) => {
      state.inviteTruSightUser.externalOrganizationsLoading = false;
      state.inviteTruSightUser.externalOrganizations = action.payload;
      state.inviteTruSightUser.externalOrganizationsError = null;
    },
    loadExternalOrganizationsError: (state, action) => {
      state.inviteTruSightUser.externalOrganizationsLoading = false;
      state.inviteTruSightUser.externalOrganizationsError = action.payload;
    },
    onAddUserFormSubmit: () => {},
    setFormSubmitErrors: (state, action) => {
      state.inviteTruSightUser.formSubmitErrors = action.payload;
    },
    setIsFormSubmitting: (state, action) => {
      state.inviteTruSightUser.isFormSubmitting = action.payload;
    },
    setIsFormSubmitSuccess: (state, action) => {
      state.inviteTruSightUser.isFormSubmitSuccess = action.payload;
    },
    clearModalState: (state) => {
      state.inviteTruSightUser = {
        roles: [],
        rolesLoading: false,
        rolesError: null,
        externalOrganizations: [],
        externalOrganizationsLoading: false,
        externalOrganizationsError: null,
        formSubmitErrors: {},
        isFormSubmitting: false,
        isFormSubmitSuccess: false,
      };
    },
  },
});

export const {
  loadTruSightUsersData,
  loadTruSightUsersDataSuccess,
  loadTruSightUsersDataError,
  loadRoles,
  loadRolesSuccess,
  loadRolesError,
  loadExternalOrganizations,
  loadExternalOrganizationsSuccess,
  loadExternalOrganizationsError,
  onAddUserFormSubmit,
  setFormSubmitErrors,
  setIsFormSubmitting,
  setIsFormSubmitSuccess,
  clearModalState,
} = slice.actions;

export default slice;
