import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "thirdPartyCompanyProfile",
  initialState: {
    companyId: null,
    data: null,
    initialFormData: null,
    loading: false,
    error: null,
    isEditing: false,
    isFormSavePending: false,
  },
  reducers: {
    loadCompanyProfile: (state, { payload }) => {
      state.data = null;
      state.companyId = payload.id;
      state.loading = true;
      state.isFormSavePending = false;
      state.isEditing = false;
    },
    loadCompanyProfileSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadCompanyProfileError: (state, action) => {
      state.loading = false;
      state.companyId = null;
      state.error = action.payload;
    },
    setInitialFormData: (state, { payload }) => {
      state.initialFormData = payload;
    },
    updateCompanyProfile: (state) => {
      state.isFormSavePending = true;
      state.initialFormData = null;
    },
    updateCompanyProfileError: (state) => {
      state.isFormSavePending = false;
    },
    toggleIsEditing: (state) => {
      state.isEditing = !state.isEditing;
      state.initialFormData = null;
    },
  },
});

export const {
  loadCompanyProfile,
  loadCompanyProfileSuccess,
  loadCompanyProfileError,
  setInitialFormData,
  updateCompanyProfile,
  updateCompanyProfileError,
  toggleIsEditing,
} = slice.actions;

export default slice;
