import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./spinner.scss";
import { ReactComponent as SpinnerIcon } from "assets/spinner.svg";

const Spinner = ({ className, isLarge, isLight }) => {
  return (
    <SpinnerIcon
      role="spinner"
      className={classNames(
        "spinner",
        { "spinner--large": isLarge, "spinner--light": isLight },
        className
      )}
    />
  );
};

Spinner.defaultProps = {
  classNames: "",
  isLarge: false,
  isLight: false,
};

Spinner.propTypes = {
  className: PropTypes.string,
  isLarge: PropTypes.bool,
  isLight: PropTypes.bool,
};

export default Spinner;
