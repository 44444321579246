import React from "react";
import PropTypes from "prop-types";

import Checkbox from "components/GlobalComponents/Inputs/Checkbox";
import "./label-with-icon.scss";

const LabelWithIcon = ({
  label,
  labelText,
  icon,
  text,
  isMultiSelect,
  isSelected,
}) => (
  <div data-testid="label-with-icon" className="option">
    {isMultiSelect && <Checkbox isDark isChecked={isSelected} />}
    {icon && <div className="option__icon">{icon}</div>}
    <div className="option__body">
      {(labelText || label) && (
        <div className="option__title" data-testid="option-title">
          {labelText || label}
        </div>
      )}
      {text && (
        <div className="option__description" data-testid="option-description">
          {text}
        </div>
      )}
    </div>
  </div>
);

LabelWithIcon.defaultProps = {
  label: "",
  labelText: "",
  icon: null,
  text: "",
  isMultiSelect: false,
  isSelected: false,
};

LabelWithIcon.propTypes = {
  label: PropTypes.string,
  labelText: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default LabelWithIcon;
