import { createSlice } from "@reduxjs/toolkit";

import { NAMESPACE } from "./constants";

export const initialState = {
  trusightUsers: [],
  thirdpartyUsers: [],
  isLoading: false,
  loadingErrorMessage: "",
  assignThirdPartyUser: {
    availableThirdPartyUsers: [],
    availableThirdPartyUsersLoading: false,
    isModalOpen: false,
    isSaving: false,
    error: "",
  },
  assignTruSightUser: {
    availableTruSightUsers: [],
    availableTruSightUsersLoading: false,
    isModalOpen: false,
    isSaving: false,
    error: "",
  },
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    loadPeopleList: () => {},
    setTruSightUsers: (state, { payload }) => {
      state.trusightUsers = payload;
    },
    setThirdPartyUsers: (state, { payload }) => {
      state.thirdpartyUsers = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLoadingErrorMessage: (state, { payload }) => {
      state.loadingErrorMessage = payload;
    },
    loadAvailableThirdPartyUsers: () => {},
    setAvailableThirdPartyUsers: (state, { payload }) => {
      state.assignThirdPartyUser.availableThirdPartyUsers = payload;
    },
    setAvailableThirdPartyUsersLoading: (state, { payload }) => {
      state.assignThirdPartyUser.availableThirdPartyUsersLoading = payload;
    },
    assignThirdPartyUser: () => {},
    setAssignThirdPartyUserIsSaving: (state, { payload }) => {
      state.assignThirdPartyUser.isSaving = payload;
    },
    setAssignThirdPartyUserModalOpen: (state, { payload }) => {
      state.assignThirdPartyUser.isModalOpen = payload;
    },
    loadAvailableTruSightUsers: () => {},
    setAvailableTruSightUsers: (state, { payload }) => {
      state.assignTruSightUser.availableTruSightUsers = payload;
    },
    setAvailableTruSightUsersLoading: (state, { payload }) => {
      state.assignTruSightUser.availableTruSightUsersLoading = payload;
    },
    assignTruSightUser: () => {},
    setAssignTruSightUserIsSaving: (state, { payload }) => {
      state.assignTruSightUser.isSaving = payload;
    },
    setAssignTruSightUserModalOpen: (state, { payload }) => {
      state.assignTruSightUser.isModalOpen = payload;
    },
  },
});

export const {
  loadPeopleList,
  setTruSightUsers,
  setThirdPartyUsers,
  setIsLoading,
  setLoadingErrorMessage,
  loadAvailableThirdPartyUsers,
  setAvailableThirdPartyUsers,
  setAvailableThirdPartyUsersLoading,
  assignThirdPartyUser,
  setAssignThirdPartyUserIsSaving,
  setAssignThirdPartyUserModalOpen,
  loadAvailableTruSightUsers,
  setAvailableTruSightUsers,
  setAvailableTruSightUsersLoading,
  assignTruSightUser,
  setAssignTruSightUserIsSaving,
  setAssignTruSightUserModalOpen,
} = slice.actions;

export default slice;
