import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import messages from "app/messages";
import "./assessment-details.scss";

const AssessmentDetails = ({ assessmentDetails, loading, error }) => {
  const intl = useIntl();

  if (loading) {
    return (
      <div
        className="assessment-details"
        data-testid="assessment-details-loader"
      >
        {intl.formatMessage(messages.loading)}
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="assessment-details"
        data-testid="assessment-details-error"
      >
        {intl.formatMessage(messages.dataLoadingError)}
      </div>
    );
  }

  if (assessmentDetails && Object.keys(assessmentDetails).length) {
    return (
      <div className="assessment-details">
        <h2 className="assessment-details__title" data-testid="name">
          {assessmentDetails.name}
        </h2>
        {assessmentDetails.escalationTier && (
          <div className="assessment-details__escalation-wrapper">
            <h3
              className="assessment-details__label assessment-details__label--heavy"
              data-testid="escalation-tier"
            >
              {assessmentDetails.escalationTier}
            </h3>
          </div>
        )}
        <p className="assessment-details__label">Assessment Status</p>
        <p
          className="assessment-details__description paragraph"
          data-testid="status"
        >
          {assessmentDetails.status
            ? assessmentDetails.status
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        <p className="assessment-details__label">Service Category L1</p>
        <p
          className="assessment-details__description paragraph"
          data-testid="service-category"
        >
          {assessmentDetails.serviceCategory
            ? assessmentDetails.serviceCategory
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        <p className="assessment-details__label">Service Category L2</p>
        <p
          className="assessment-details__description paragraph"
          data-testid="service-category-l2"
        >
          {assessmentDetails.serviceCategoryL2
            ? assessmentDetails.serviceCategoryL2
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        <p className="assessment-details__label">Description</p>
        <p
          className="assessment-details__description paragraph"
          data-testid="third-party-description"
        >
          {assessmentDetails.thirdPartyDescription
            ? assessmentDetails.thirdPartyDescription
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        <p className="assessment-details__label">Product Types Available</p>
        <p
          className="assessment-details__description paragraph"
          data-testid="assessment-product-types-available"
        >
          {assessmentDetails.possibleProductTypes
            ? assessmentDetails.possibleProductTypes.join(", ")
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        <h2 className="assessment-details__title">Control Environments</h2>
        <p
          className="assessment-details__label"
          data-testid="control-environment"
        >
          {assessmentDetails.controlEnvironment
            ? assessmentDetails.controlEnvironment
            : intl.formatMessage(messages.informationNotAvailable)}
        </p>
        {assessmentDetails.controlEnvironment && (
          <p
            className="assessment-details__description paragraph"
            data-testid="control-environment-description"
          >
            {assessmentDetails.controlEnvironmentDescription
              ? assessmentDetails.controlEnvironmentDescription
              : intl.formatMessage(messages.informationNotAvailable)}
          </p>
        )}
      </div>
    );
  }

  return null;
};

AssessmentDetails.defaultProps = {
  assessmentDetails: null,
  loading: false,
  error: false,
};

AssessmentDetails.propTypes = {
  assessmentDetails: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default AssessmentDetails;
