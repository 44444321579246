import { createSlice } from "@reduxjs/toolkit";

export const initialModalsState = {
  modals: {
    duplicateTemplateModal: {
      isOpen: false,
    },
    deleteTemplateModal: {
      isOpen: false,
    },
    createTemplateModal: {
      isOpen: false,
      isLoading: false,
      currentWizardStep: 0,
      availableTagCollections: [],
      totalBpqDatapointsCount: null,
      selectedTagCollections: [],
    },
    modalData: null,
  },
  duplicateTemplateInProgress: false,
};

export const initialState = {
  data: null,
  loading: false,
  error: null,
  createNewTemplateInProgress: false,
  ...initialModalsState,
};

const slice = createSlice({
  name: "customTemplates",
  initialState,
  reducers: {
    loadCustomTemplates: (state) => {
      state.data = null;
      state.loading = true;
      state.newTemplateId = null;
    },
    loadCustomTemplatesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadCustomTemplatesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setCreateTemplateModalOpen: (state, { payload }) => {
      state.modals.createTemplateModal.isOpen = payload;
    },
    createNewTemplate: (state) => {
      state.createNewTemplateInProgress = true;
      state.modals.createTemplateModal.isLoading = true;
    },
    createNewTemplateSuccess: (state) => {
      state.createNewTemplateInProgress = false;
      state.modals.createTemplateModal.isLoading = false;
    },
    createNewTemplateError: (state) => {
      state.createNewTemplateInProgress = false;
      state.modals.createTemplateModal.isLoading = false;
    },
    setCurrentWizardStep: (state, { payload }) => {
      state.modals.createTemplateModal.currentWizardStep = payload;
    },
    addSelectedTagCollection: (state, { payload }) => {
      state.modals.createTemplateModal.selectedTagCollections.push(payload);
    },
    removeSelectedTagCollection: (state, { payload }) => {
      const newSelectedTagCollections =
        state.modals.createTemplateModal.selectedTagCollections.filter(
          (collection) => collection.id !== payload
        );
      state.modals.createTemplateModal.selectedTagCollections =
        newSelectedTagCollections;
    },
    deleteTemplate: () => {},
    duplicateTemplate: (state) => {
      state.duplicateTemplateInProgress = true;
    },
    duplicateTemplateSuccess: (state, action) => {
      state.duplicateTemplateInProgress = false;
    },
    setDuplicateTemplateModalOpen: (state, { payload }) => {
      state.modals.duplicateTemplateModal.isOpen = payload;
    },
    setDeleteTemplateModalOpen: (state, { payload }) => {
      state.modals.deleteTemplateModal.isOpen = payload;
    },
    setModalData: (state, { payload }) => {
      state.modals.modalData = payload;
    },
    loadTagCollectionsByVersion: (state) => {
      state.modals.createTemplateModal.isLoading = true;
    },
    loadTagCollectionsByVersionSuccess: (state, { payload }) => {
      state.modals.createTemplateModal.availableTagCollections =
        payload.tagCollections;
      state.modals.createTemplateModal.totalBpqDatapointsCount =
        payload.totalBpqDataPointsCount;
      state.modals.createTemplateModal.isLoading = false;
    },
    loadTagCollectionsByVersionError: (state) => {
      state.modals.createTemplateModal.isLoading = false;
    },
    resetTemplatesAndMappingModals: (state) => ({
      ...state,
      ...initialModalsState,
    }),
    resetTemplatesAndMappingStore: () => initialState,
  },
});

export const {
  loadCustomTemplates,
  loadCustomTemplatesSuccess,
  loadCustomTemplatesError,
  setCreateTemplateModalOpen,
  createNewTemplate,
  createNewTemplateSuccess,
  createNewTemplateError,
  setCurrentWizardStep,
  addSelectedTagCollection,
  removeSelectedTagCollection,
  setDuplicateTemplateModalOpen,
  setDeleteTemplateModalOpen,
  setModalData,
  deleteTemplate,
  duplicateTemplate,
  duplicateTemplateSuccess,
  loadTagCollectionsByVersion,
  loadTagCollectionsByVersionSuccess,
  loadTagCollectionsByVersionError,
  resetTemplatesAndMappingModals,
  resetTemplatesAndMappingStore,
} = slice.actions;

export default slice;
