import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timer: null,
  showTotalSessionTimer: false,
};

const slice = createSlice({
  name: "totalSessionTimer",
  initialState,
  reducers: {
    setShowTotalSessionTimer: (state, { payload }) => {
      state.timer = payload.timer;
      state.showTotalSessionTimer = payload.showTotalSessionTimer;
    },
    setTimer: (state, { payload }) => {
      state.timer = payload;
    },
  },
});

export const { setShowTotalSessionTimer, setTimer } = slice.actions;

export default slice;
