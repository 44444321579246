export const GROUP_ROW_HEIGHT = 48;
export const LOOKUP_ROW_HEIGHT = 56;
export const UNMATCHED_THIRD_PARTIES_TABLE_CLASS =
  "unmatched-third-parties-table";

export const UNMATCHED_THIRD_PARTIES_ACTIONS = {
  propose: "propose",
  proposeLookedUp: "propose-looked-up",
  removeProposal: "remove-proposal",
  confirmMatch: "confirm-match",
  delete: "delete",
  createCandidate: "create-candidate",
  addDetails: "add-details",
  updateDetails: "update-details",
};
