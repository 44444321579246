import { put, takeLatest, all, select, call } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";
import { createIntl } from "react-intl";

import { selectLocale } from "components/LanguageProvider/services/selectors";
import { selectStages } from "components/AuthorizedComponent/selectors";
import {
  loadOverviewData,
  setOverviewDataSuccess,
  setOverviewDataError,
  setAllowCustomTemplates,
  setAllowCustomTemplatesSuccess,
  setAllowCustomTemplatesError,
  setOverviewAssessmentStage,
} from "./slice";
import { setAssessmentStage } from "pages/AssessmentPage/services/slice.js";
import { showError, showInfo } from "utils/sagaEffects/sagaEffects";
import { getStageName } from "utils/stageByPosition/stageByPosition";
import messages from "../messages";

export function* loadOverviewDataSaga({ payload }) {
  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/assessments/${payload.assessmentId}/overview`,
      {
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }

    const overviewData = yield response.json();

    yield put(setOverviewDataSuccess(overviewData));
  } catch ({ message }) {
    yield put(setOverviewDataError(message));
    yield showError(message);
  }
}

export function* setAllowCustomTemplatesSaga({ payload }) {
  const locale = yield select(selectLocale);
  const intl = yield call(createIntl, { locale, messages });

  try {
    const response = yield call(
      fetch,
      `${process.env.REACT_APP_API_URL}/assessments/${payload.assessmentId}/allowCustomTemplates`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
          "Content-Type": "application/json",
        },
        body: payload.allowCustomTemplates,
      }
    );

    if (response.status !== 200) {
      throw new Error(intl.formatMessage(messages.saveFailed));
    }

    yield put(setAllowCustomTemplatesSuccess());
    yield showInfo(intl.formatMessage(messages.saveCompleted));
  } catch ({ message }) {
    yield put(setAllowCustomTemplatesError());
    yield showError(message);
  }
}

export function* setAssessmentStageSaga({ payload }) {
  const stages = yield select(selectStages);
  const stageName = yield call(getStageName, stages, payload.stageId);

  yield put(setOverviewAssessmentStage(stageName));
}

export default function* overviewDataSagas() {
  yield all([
    takeLatest(loadOverviewData.type, loadOverviewDataSaga),
    takeLatest(setAllowCustomTemplates.type, setAllowCustomTemplatesSaga),
    takeLatest(setAssessmentStage.type, setAssessmentStageSaga),
  ]);
}
