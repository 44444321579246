import { defineMessages } from "react-intl";

const scope = "components.MainLayout.components.IdleModalController";

export default defineMessages({
  modalContentText: {
    id: `${scope}modalContentText`,
    defaultMessage: "Session has been idle for {idleMinutes} minutes. You will be logged out in {loggedOutIn} Do you want to continue working?",
  },
  continueWorking: {
    id: `${scope}continueWorking`,
    defaultMessage: "Continue working",
  },
  logout: {
    id: `${scope}logout`,
    defaultMessage: "Logout",
  },
  remainingMultipleMinutes: {
    id: `${scope}remainingMultipleMinutes`,
    defaultMessage: "{remainingMinutes} minutes.",
  },
  remainingOneMinute: {
    id: `${scope}remainingOneMinute`,
    defaultMessage: "1 minute.",
  },
  remainingMultipleSeconds: {
    id: `${scope}remainingMultipleSeconds`,
    defaultMessage: "{secondsRemaining} seconds.",
  },
  remainingOneSecond: {
    id: `${scope}remainingOneSecond`,
    defaultMessage: "1 second.",
  },
});
